import { cloneDeep } from 'lodash';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { Subscription, forkJoin } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { IMeasurementPoint } from '../shared/classes/measurementpoint.interface';
import { AuthService, TokenService } from '../shared/services';
import { IAccount } from '../shared/classes/account';
import { IUser } from '../shared/classes/user';
import * as fromHierarchy from './../_store/_reducers';
import * as fromUser from './../_store/_reducers';
import { NotificationsService } from '../shared/modules/notifications/shared/notifications.service';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '../shared/classes/component-destroy.class';
import { MeasurementPointsService } from '../shared/services/measurement-points.service';

@Component({
  selector: 'app-fleet',
  templateUrl: './fleet.component.html',
  styleUrls: ['./fleet.component.scss'],
})
export class FleetComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  public statusControl = new FormControl('');
  public statusFilter = 'All';
  public partnerFilter = '';
  public customerFilter = '';
  public mpFilter = '';
  public locationFilter = '';
  public withEventsOnly = false;
  public userPrefs;
  public isAlExpanded = false;

  public allPartners = [];
  public partners = [];
  public sites: any = [] as any;
  private user: IUser;
  private account: IAccount;
  public openAllPanels = true;

  public qubeScanEnabled = false;
  public isSystemAdmin = false;
  public isAdmin = false;
  private fleetSub$: Subscription;

  private mpStatusChecked = false;

  public statusArray = [
    'All',
    'Pending Installation',
    'Pending Commissioning',
    'Commissioned',
  ];
  private pendingInstallationStatusInts = [1, 2, 3];
  private pendingCommissioningStatusInts = [4, 5, 6, 7];
  private activeStatusInts = [8];
  private decomissionedStatusInts = [9];
  private suspendedStatusInts = [10];
  public loading = false;

  public icons = {
    1: {
      icon: 'alert-octagon',
      class: 'negative',
    },
    2: {
      icon: 'check-circle-outline',
      class: 'positive',
    },
    3: {
      icon: 'help-circle-outline',
      class: 'neutral',
    },
    4: {
      icon: 'block-helper',
      class: 'neutral block-helper',
    },
  };

  public trends = [
    'power',
    'powerFactorPrior30Days',
    'voltageFluctuationsPrior30Days',
    'imbalancePrior30Days',
    'harmonicsPrior30Days',
    'groundCurrentPrior30Days',
  ];

  public tableColumns = [
    'mpId',
    'location',
    'status',
    'events',
    'trends',
    'comms',
    'lastTemperature',
    // 'endOfService',
    'choose',
  ];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChildren(MatTable) mpTables!: QueryList<MatTable<any>>;

  constructor(
    private authService: AuthService,
    private measurementPointService: MeasurementPointsService,
    private route: ActivatedRoute,
    private token: TokenService,
    private store: Store<fromHierarchy.State>,
    private notificationsService: NotificationsService,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.isSystemAdmin = this.token.metaData.isSystemAdministrator === '1';

    this.loading = true;

    this.statusControl.setValue('All');

    this.route.queryParams
      .pipe(untilComponentDestroyed(this))
      .subscribe(async (params: { mpId: string; account: string }) => {
        this.fleetSub$ = forkJoin([
          this.store.pipe(select(fromUser.getUser)).pipe(take(1)),
          this.authService.preferences.pipe(take(1)),
          this.store.pipe(select(fromHierarchy.getHierarchy)).pipe(take(1)),
          this.authService.accountO.pipe(take(1)),
          this.authService.getEnvironmentSettings('featureEnableQubeScan').pipe(take(1)),
        ]).subscribe(
          ([user, userPrefs, hierarchy, account, featQubeScan]) => {
            this.qubeScanEnabled = featQubeScan && featQubeScan.featureEnableQubeScan;
            this.user = user;
            this.account = account;
            if (!this.user) {
              return;
            }
            let accountId;
            if (this.account) {
              accountId = this.account.id;
            }
            if (params.account && account && account.isPartner) {
              accountId = parseInt(params.account, 10);
            }

            let mpId = parseInt(params.mpId, 10);

            this.isAdmin = user.role.name === 'Administrator';
            // this.account = account;
            this.userPrefs = userPrefs;
            accountId = accountId || userPrefs.account;
            mpId = mpId || userPrefs.mpId;
            const sites: any = cloneDeep(hierarchy);
            this.allPartners = sites.partners.map((partner) => {
              partner.customers = partner.customers.map((customer) => {
                if (customer.accountId === accountId) {
                  customer.expanded = true;
                }
                if (customer.measurementPoints.length > 1) {
                  this.mpStatusChecked = true;
                }
                customer.measurementPoints = customer.measurementPoints.map((mp: any) => {
                  if (mp.measurementPointId === mpId) {
                    mp.active = true;
                  }
                  return mp;
                });

                if (
                  !customer.expanded &&
                  customer.measurementPoints.find((mp: any) => mp.active)
                ) {
                  customer.expanded = true;
                }

                return customer;
              });

              return partner;
            });
            if (!this.mpStatusChecked && this.qubeScanEnabled) {
              this.measurementPointService
                .getMeasurementPoint(mpId)
                .pipe(take(1))
                .subscribe((currentMp) => {
                  if (currentMp.measurementPointTypeId === 1) {
                    if (currentMp.measurementPointStatusId < 4) {
                      // Pending Installation
                      this.notificationsService.alert(
                        this.translateService.instant(
                          'fleet.notifications.registerMeasurementPoint.message',
                          { mpName: currentMp.mpId }
                        ),
                        this.translateService.instant(
                          'fleet.notifications.registerMeasurementPoint.title'
                        )
                      );
                    } else if (
                      currentMp.measurementPointStatusId < 8 &&
                      currentMp.measurementPointStatusId > 4
                    ) {
                      // Pending commissioning
                      this.notificationsService.alert(
                        this.translateService.instant(
                          'fleet.notifications.finishMeasurementPointCommissioning.message'
                        ),
                        this.translateService.instant(
                          'fleet.notifications.finishMeasurementPointCommissioning.title'
                        )
                      );
                    }
                    this.mpStatusChecked = true;
                  }
                });
            }
            this.partners = this.allPartners;
            this.loading = false;
          },
          (error) => {
            console.error(error);
          }
        );
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.fleetSub$.unsubscribe();
  }

  toggleAllPanels() {
    this.isAlExpanded = this.openAllPanels;
    this.partners.forEach((partner) => {
      partner.customers.forEach((customer) => (customer.expanded = this.openAllPanels));
    });
    this.openAllPanels = !this.openAllPanels;
    this.partners.forEach((partner) => (partner.expand = this.openAllPanels));
  }

  filterMpsByStatus() {
    if (this.statusFilter === 'All') {
      this.partners = this.allPartners;
      return;
    }

    const tempPartners = this.allPartners.map((partner) => {
      const newPartner = Object.assign({}, partner);
      newPartner.customers = newPartner.customers.map((customer) => {
        const newCustomer = Object.assign({}, customer);
        newCustomer.measurementPoints = newCustomer.measurementPoints.filter(
          (mp: IMeasurementPoint) => {
            // if ( mp.measurementPointStatusId
            switch (this.statusFilter) {
              case 'Pending Installation':
                if (
                  this.pendingInstallationStatusInts.includes(mp.measurementPointStatusId)
                ) {
                  return true;
                }
                return false;
                break;
              case 'Pending Commissioning':
                if (
                  this.pendingCommissioningStatusInts.includes(
                    mp.measurementPointStatusId
                  )
                ) {
                  return true;
                }
                return false;
                break;
              case 'Commissioned':
                if (this.activeStatusInts.includes(mp.measurementPointStatusId)) {
                  return true;
                }
                return false;
                break;
              case 'Decommissioned':
                if (this.decomissionedStatusInts.includes(mp.measurementPointStatusId)) {
                  return true;
                }
                return false;
                break;
              case 'Suspended':
                if (this.suspendedStatusInts.includes(mp.measurementPointStatusId)) {
                  return true;
                }
                return false;
                break;
              default:
                return false;
            }
          }
        );
        return newCustomer;
      });
      newPartner.customers = newPartner.customers.filter(
        (customer) => customer.measurementPoints.length > 0
      );
      return newPartner;
    });

    this.partners = tempPartners.filter((partner) => partner.customers.length > 0);
  }
}
