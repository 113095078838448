export const StandardTabs = [
  {
    name: 'charts.tabs.power',
    memberCharts: [
      /**  temporary comment out activeEnergy & reactiveEnergy on Power tab
       */

      'totalActivePower',
      'totalReactivePower',
      'apparentPower',
      'notes',
      'pqEvents',
    ],
    chartSetName: 'Power Energy',
    editable: false,
    sortOrder: 0,
  },
  {
    name: 'charts.tabs.power-factor',
    memberCharts: [
      'totalPowerFactor',
      'totalReactivePower',
      'totalActivePower',
      'pqEvents',
    ],
    chartSetName: 'Power Factor',
    editable: false,
    sortOrder: 1,
  },
  {
    name: 'charts.tabs.voltage-flux',
    memberCharts: [
      'voltageLNRMSMagnitude',
      'voltageLLRMSMagnitude',
      'currentMagnitudeAndVariations',
      'flickerPinst',
      'flickerPst',
      'pqEvents',
    ],
    chartSetName: 'Voltage Fluctuation',
    editable: false,
    sortOrder: 2,
  },
  {
    name: 'charts.tabs.unbalance',
    memberCharts: ['negativeVoltage', 'negativeCurrent', 'pqEvents'],
    chartSetName: 'Unbalance',
    editable: false,
    sortOrder: 3,
  },
  {
    name: 'charts.tabs.harmonics',
    memberCharts: [
      'totalHarmonicDistortion',
      'totalDemandDistortion',
      'voltageOddHarmonics',
      'currentOddHarmonics',
      'pqEvents',
    ],
    chartSetName: 'Harmonics',
    editable: false,
    sortOrder: 4,
  },
  {
    name: 'charts.tabs.ground-current',
    memberCharts: ['groundCurrent', 'neutralCurrent', 'pqEvents'],
    chartSetName: 'Ground',
    editable: false,
    sortOrder: 5,
  },
];
