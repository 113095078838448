import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Inject,
  OnDestroy,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DeleteMemberData } from 'src/app/shared/classes/deleteMemberData.interface';
import { IUserUpdateParams } from 'src/app/shared/classes/userUpdateParams.interface';
import { IMember } from './../../shared/classes/member.interface';
import { Role } from 'src/app/shared/enum/role.enum';
import { AuthService } from 'src/app/shared/services';
import { IAccount } from 'src/app/shared/classes/account';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from 'src/app/shared/classes/component-destroy.class';

@Component({
  selector: 'app-delete-member-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './delete-member-dialog.component.html',
  styleUrls: ['./delete-member-dialog.component.scss'],
})
export class DeleteMemberDialogComponent
  extends OnDestroyMixin
  implements OnDestroy, OnInit {
  public accountName: string;
  public member: IMember;

  constructor(
    public dialogRef: MatDialogRef<DeleteMemberDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteMemberData,
    private authService: AuthService
  ) {
    super();
    this.member = data.member;
  }

  ngOnInit() {
    this.authService.accountO
      .pipe(untilComponentDestroyed(this))
      .subscribe((userAccount: IAccount) => {
        this.accountName = userAccount.name;
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public deleteMember(): void {
    const addUserParams: IUserUpdateParams = {
      accountId: null,
      firstName: this.member.firstName,
      middleName: this.member.middleName,
      lastName: this.member.lastName,
      organization: this.member.organization,
      email: this.member.email,
      homePhone: this.member.homePhone,
      workPhone: this.member.workPhone,
      mobilePhone: this.member.mobilePhone,
      preferredLocaleId: this.member.preferredLocaleId,
      retired: 1,
      roleId: Role[this.member.role],
    };
    this.dialogRef.close(addUserParams);
  }
}
