import { select, Store } from '@ngrx/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, RouterEvent, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';

import { IAccount } from '../../classes/account';
import { AuthService, TokenService } from '../../services';
import { IUser } from '../../classes/user';
import * as fromUser from './../../../_store/_reducers';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '../../classes/component-destroy.class';
import { MeasurementPointsService } from '../../services/measurement-points.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  public current: any = null;
  public account: IAccount;
  public queryParams: any = {};
  public isSystemAdministrator = false;
  public isCustomerAdmin = false;
  public isQubeScan = false;
  private userSub$: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private mpService: MeasurementPointsService,
    private token: TokenService,
    private store: Store<fromUser.State>
  ) {
    super();
    this.router.events.pipe(untilComponentDestroyed(this)).subscribe((u: RouterEvent) => {
      if (u.url) {
        this.current = u.url.split('?').shift();
      }
    });

    this.route.queryParamMap
      .pipe(untilComponentDestroyed(this))
      .subscribe((params: Params) => {
        this.queryParams = params;
        if (
          this.mpService.selectedMeasurementPoint &&
          this.mpService.selectedMeasurementPoint.measurementPointTypeId === 1
        ) {
          this.isQubeScan = true;
        } else {
          this.isQubeScan = false;
        }
      });

    this.authService.accountO
      .pipe(untilComponentDestroyed(this))
      .subscribe(async (account: IAccount) => {
        this.account = account;
      });

    this.isSystemAdministrator = this.token.metaData.isSystemAdministrator
      ? this.token.metaData.isSystemAdministrator === '1'
      : false;

    this.userSub$ = this.store.pipe(select(fromUser.getUser)).subscribe((user: IUser) => {
      if (user) {
        this.isCustomerAdmin = user.role.name === 'Administrator';
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    super.ngOnDestroy();
    this.userSub$.unsubscribe();
  }

  public navigate(path) {
    this.router.navigate(path, {
      queryParams: {
        account: this.queryParams.params.account,
        mpId: this.queryParams.params.mpId,
      },
    });
  }
}
