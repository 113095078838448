import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';

import { AuthService } from '../shared/services';
import { NotificationsService } from '../shared/modules/notifications/shared/notifications.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-register-account',
  templateUrl: './register-account.component.html',
  styleUrls: ['./register-account.component.scss'],
})
export class RegisterAccountComponent implements OnInit, OnDestroy {
  public registerForm: FormGroup;
  public loading = false;

  // reCaptcha
  public readonly siteKey = environment.google.reCaptchaSiteKey;
  
  public get accountName(): AbstractControl {
    return this.registerForm.get('accountName');
  }

  public get mainTelephone(): AbstractControl {
    return this.registerForm.get('mainTelephone');
  }

  public get firstName(): AbstractControl {
    return this.registerForm.get('firstName');
  }

  public get lastName(): AbstractControl {
    return this.registerForm.get('lastName');
  }

  public get email(): AbstractControl {
    return this.registerForm.get('email');
  }

  public get recaptcha(): AbstractControl {
    return this.registerForm.get('recaptcha');
  }

  constructor(
    private router: Router,
    private auth: AuthService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.registerForm = new FormGroup({
      accountName: new FormControl('', [Validators.required]),
      mainTelephone: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ]),
      recaptcha: new FormControl('', [Validators.required])
    });

    this.registerForm.get('email').valueChanges.subscribe((event) => {
      this.registerForm
        .get('email')
        .setValue(event.toLowerCase(), { emitEvent: false });
    });
  }

  ngOnDestroy() {}

  public onCancel(): void {
    this.router.navigate(['login']);
  }

  public registerUserAccount($event): void {
    this.loading = true;
    if ($event) {
      $event.stopPropagation();
    }
    const userAccount = {
      accountName: this.registerForm.get('accountName').value,
      mainTelephone: this.registerForm.get('mainTelephone').value,
      firstName: this.registerForm.get('firstName').value,
      lastName: this.registerForm.get('lastName').value,
      email: this.registerForm.get('email').value,
    };
    this.auth.registerUserAccount(userAccount).subscribe(
      () => {
        this.loading = false;
        const successDialog = this.notificationsService.alert(
          this.translateService.instant('registerUserAccount.registrationSent'),
          this.translateService.instant('registerUserAccount.success')
        );
        this.router.navigate(['login']);
      },
      (error) => {
        console.error(error);
        this.loading = false;
        this.notificationsService.alert(
          this.translateService.instant(
            'registerUserAccount.registrationFailed'
          ),
          this.translateService.instant('registerUserAccount.error')
        );
      }
    );
  }
}
