import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ForgotPasswordDialogData } from '../../shared/classes/forgotPasswordDialogData';

@Component({
  selector: 'app-forgot-password-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss'],
})
export class ForgotPasswordDialogComponent {
  public requestForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ForgotPasswordDialogData
  ) {
    this.requestForm = new FormGroup({
      email: new FormControl(data.email, [
        Validators.required,
        Validators.email,
      ]),
    });
  }

  public get email(): AbstractControl {
    return this.requestForm.get('email');
  }

  public onCancel(): void {
    this.dialogRef.close(null);
  }

  public submitForm($event: Event): void {
    $event.stopPropagation();
    this.dialogRef.close(this.email.value);
  }
}
