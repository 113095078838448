import { MapsAPILoader } from '@agm/core';
import {
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

import { ISiteMeasurementPoint } from '../shared/classes/SiteMeasurementPoint.interface';
import { NotificationsService } from '../shared/modules/notifications/shared/notifications.service';
import { AuthService, SitesService } from '../shared/services';
import * as fromHierarchy from './../_store/_reducers';

@Component({
  selector: 'app-create-mp',
  templateUrl: './create-mp.component.html',
  styleUrls: ['./create-mp.component.scss'],
})
export class CreateMPComponent implements OnInit, OnDestroy {
  public createMPForm: FormGroup;
  public loading = false;

  @ViewChild('search')
  public searchElementRef: ElementRef;

  public get siteName(): AbstractControl {
    return this.createMPForm.get('siteName');
  }

  public get street1(): AbstractControl {
    return this.createMPForm.get('street1');
  }

  public get street2(): AbstractControl {
    return this.createMPForm.get('street2');
  }

  public get city(): AbstractControl {
    return this.createMPForm.get('city');
  }

  public get state(): AbstractControl {
    return this.createMPForm.get('state');
  }

  public get country(): AbstractControl {
    return this.createMPForm.get('country');
  }

  public get zipCode(): AbstractControl {
    return this.createMPForm.get('zipCode');
  }

  public get measurementPointName(): AbstractControl {
    return this.createMPForm.get('measurementPointName');
  }
  constructor(
    private sitesService: SitesService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private store: Store<fromHierarchy.State>
  ) {
    this.initForm();
  }

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          const address = place.address_components;
          let streetNumber = '';
          let street = '';
          let city = '';
          let state = '';
          let zip = '';
          let country = '';
          address.forEach((component) => {
            const types = component.types;
            if (types.indexOf('street_number') > -1) {
              streetNumber = component.long_name;
            }
            if (types.indexOf('route') > -1) {
              street = component.long_name;
            }
            if (types.indexOf('locality') > -1) {
              city = component.long_name;
            }
            if (types.indexOf('administrative_area_level_1') > -1) {
              state = component.short_name;
            }
            if (types.indexOf('postal_code') > -1) {
              zip = component.long_name;
            }
            if (types.indexOf('country') > -1) {
              country = component.long_name;
            }
          });
          if (streetNumber || street) {
            this.createMPForm.get('street1').setValue(streetNumber + ' ' + street);
          }
          if (city) {
            this.createMPForm.get('city').setValue(city, { emitEvent: false });
          }
          if (state) {
            this.createMPForm.get('state').setValue(state, { emitEvent: false });
          }
          if (country) {
            this.createMPForm.get('country').setValue(country, { emitEvent: false });
          }
          if (zip) {
            this.createMPForm.get('zipCode').setValue(zip, { emitEvent: false });
          }
        });
      });
    });
  }

  public createMP(): void {
    const siteMeasurementPoint: ISiteMeasurementPoint = {
      site: {
        locationName: this.createMPForm.get('siteName').value,
        address1: this.createMPForm.get('street1').value,
        address2: this.createMPForm.get('street2').value || '',
        city: this.createMPForm.get('city').value,
        state: this.createMPForm.get('state').value,
        zipCode: this.createMPForm.get('zipCode').value,
        country: this.createMPForm.get('country').value,
        siteInformation: this.createMPForm.get('siteInformation').value || '',
      },
      measurementPoint: {
        mpId: this.createMPForm.get('measurementPointName').value,
        measurementPointTypeId: 1,
      },
    };
    const accountId = parseInt(this.route.snapshot.paramMap.get('accountId'), 10);
    this.sitesService
      .createSiteMeasurementPoint(accountId, siteMeasurementPoint)
      .pipe(take(1))
      .subscribe((response) => {
        if (response) {
          this.authService
            .savePreferences({
              account: accountId,
              mpId: response.measurementPointId,
            })
            .then((saved) => {
              if (saved) {
                this.authService.user.pipe(take(1)).subscribe((user) => {
                  this.store.dispatch({
                    type: '[App Init] Init hierarchy - user',
                    payload: { id: user.id, accountId: accountId },
                  });

                  this.router.navigate(['fleet'], {
                    queryParams: {
                      account: accountId,
                      mpId: response.measurementPointId,
                    },
                  });
                });
              } else {
                this.notificationsService.notify(
                  this.translateService.instant('manageSiteMeasurementPoint.save.failed')
                );
              }
            });
        } else {
          this.notificationsService.notify(
            this.translateService.instant('manageSiteMeasurementPoint.save.failed')
          );
        }
      });
  }

  public onCancel(): void {
    this.initForm();
  }

  public initForm(): void {
    this.createMPForm = new FormGroup({
      siteName: new FormControl('', [Validators.required]),
      street1: new FormControl('', [Validators.required]),
      street2: new FormControl(''),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zipCode: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      measurementPointName: new FormControl('', [Validators.required]),
      siteInformation: new FormControl(''),
    });
  }
}
