import { takeUntil } from 'rxjs/operators';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { Component, OnDestroy } from '@angular/core';

const ON_DESTROY_SUBJECT_KEY = Symbol('ON_DESTROY_SUBJECT_KEY');

export type ComponentWithOnDestroyObservable = {
  observeOnDestroy(): Observable<void>;
};

@Component({
  template: '',
})
// tslint:disable-next-line: component-class-suffix
export abstract class OnDestroyMixin implements OnDestroy {
  constructor() {
    (this as any)[ON_DESTROY_SUBJECT_KEY] = new ReplaySubject();
  }

  observeOnDestroy() {
    return (this as any)[ON_DESTROY_SUBJECT_KEY];
  }

  ngOnDestroy() {
    (this.observeOnDestroy() as Subject<void>).next();
  }
}

export function componentDestroyed(
  target: ComponentWithOnDestroyObservable
): Observable<void> {
  const onDestroySubject = (target as any)[ON_DESTROY_SUBJECT_KEY];
  if (onDestroySubject === undefined) {
    const proto = Object.getPrototypeOf(target);
    const compInfo =
      proto !== undefined &&
      ((proto.constructor !== undefined) !== proto.constructor.name) !==
        undefined
        ? ` (component: ${proto.constructor.name})`
        : '';

    throw new Error(
      `You are almost there! Please extends the base class 'OnDestroyMixin'${compInfo}.`
    );
  }

  return onDestroySubject;
}

export function untilComponentDestroyed<T>(
  component: ComponentWithOnDestroyObservable
): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>) =>
    source.pipe(takeUntil(componentDestroyed(component)));
}
