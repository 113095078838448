import { cloneDeep } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { ChangeSelectionComponent } from './change-selection/change-selection.component';
import { AuthService, TokenService } from '../../services';
import { NotificationsService } from '../../modules/notifications/shared/notifications.service';
import packageJson from '../../../../../package.json';
import { IAccount } from './../../classes/account';
import { IUser } from './../../classes/user';
import * as fromHierarchy from './../../../_store/_reducers';
import * as fromUser from './../../../_store/_reducers';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '../../classes/component-destroy.class';
import { MeasurementPointsService } from '../../services/measurement-points.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  public showContactInfo = false;

  public user: IUser;
  public userAccount: IAccount;
  public contactInfo: IAccount;
  public isAdministrator: boolean;

  public accountName: string;
  public locationCity: string;
  public locationState: string;
  public mpName: string;
  public threeLetterTimezone: string;
  public zone: string;

  public userPrefs: any;

  public version = packageJson.version;

  private needsMeasurement = ['/dashboard', '/reports', '/charts', '/fleet'];
  private hierarchySub$: Subscription;
  private userSub$: Subscription;

  @HostListener('psl-show-change-selection')
  onShowChangeSelection() {
    this.changeSelection();
  }

  constructor(
    private authService: AuthService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private mpService: MeasurementPointsService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private token: TokenService,
    private store: Store<fromHierarchy.State | fromUser.State>
  ) {
    super();
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(untilComponentDestroyed(this))
      .subscribe((params: Params) => {
        this.authService.accountO.pipe(take(1)).subscribe((userAccount: IAccount) => {
          this.contactInfo = userAccount;
          this.userAccount = userAccount;
          this.userSub$ = this.store
            .pipe(select(fromUser.getUser))
            .subscribe((user: IUser) => {
              this.user = user;
              if (!this.user) {
                return;
              }

              this.isAdministrator = this.user.role.name === 'Administrator';

              let mpId = parseInt(params.mpId || 0, 10);
              let accountId = parseInt(params.account, 10);
              if (!params.account) {
                accountId = this.userAccount.id;
              }
              let sites: any;

              this.hierarchySub$ = this.store
                .pipe(select(fromHierarchy.getHierarchy))
                .subscribe((site) => (sites = cloneDeep(site)));

              this.authService.preferences
                .pipe(take(1))
                .subscribe(async (userPrefs: any) => {
                  this.userPrefs = userPrefs;
                  if (
                    this.userAccount &&
                    (this.userAccount.isPartner ||
                      this.token.metaData.isSystemAdministrator === '1' ||
                      this.isAdministrator)
                  ) {
                    if (params.hasOwnProperty('account') && !isNaN(params.account)) {
                      accountId = parseInt(params.account, 10);
                    } else if (
                      this.route.snapshot[`_routerState`].url.split('/')[1] ===
                        'event-graph' &&
                      this.route.snapshot[`_routerState`].url.split('/')[3]
                    ) {
                      accountId = parseInt(
                        this.route.snapshot[`_routerState`].url.split('/')[3],
                        10
                      );
                    } else if (userPrefs.account) {
                      accountId = userPrefs.account;
                    }
                  }

                  mpId = mpId || userPrefs.mpId;

                  if (Object.keys(userPrefs).length === 0) {
                    // user has no preferences, make them choose a measurement point
                    this.changeSelection();
                  } else {
                    // get the URL so we can figure out if we need a measurement point
                    const url = this.router.url.split('?').shift();

                    if (this.needsMeasurement.includes(url)) {
                      if (this.user && accountId && mpId) {
                        // we've got an account an measurement point we can use, set them
                        // in the URL, make sure we actually _need_ to navigate and, if so,
                        // navigate away!!
                        const queryParams = {
                          ...params,
                          account: accountId.toString(),
                          mpId: mpId.toString(),
                        };

                        if (JSON.stringify(queryParams) !== JSON.stringify(params)) {
                          this.router.navigate([], {
                            queryParams: queryParams,
                          });
                        }
                      } else if (this.user) {
                        // if there's no account or mpID in the URL, make the user select something
                        this.changeSelection();
                      }
                    }
                  }

                  if (sites.partners && sites.partners.length > 0) {
                    for (const partner of sites.partners) {
                      const customer = partner.customers.find(
                        (c) => c.accountId === accountId
                      );

                      if (customer) {
                        this.accountName = customer.accountName;
                      }
                    }
                  }

                  if (this.mpService.selectedMeasurementPoint) {
                    const point = this.mpService.selectedMeasurementPoint;
                    this.locationCity = point.city;
                    this.locationState = point.state;
                    this.mpName = point.mpId;
                    this.threeLetterTimezone = this.mpService.selectedMeasurementPoint.threeLetterTimezone;
                    this.zone = this.mpService.selectedMeasurementPoint.timezone;
                  } else {
                    if (accountId && mpId) {
                      this.mpService
                        .refreshMeasurementPoint(userPrefs.accountId, userPrefs.mpId)
                        .then(() => {
                          const point = this.mpService.selectedMeasurementPoint;
                          this.locationCity = point.city;
                          this.locationState = point.state;
                          this.mpName = point.mpId;
                          this.threeLetterTimezone = this.mpService.selectedMeasurementPoint.threeLetterTimezone;
                          this.zone = this.mpService.selectedMeasurementPoint.timezone;
                        });
                    }
                  }
                });
            });
        });
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.hierarchySub$) {
      this.hierarchySub$.unsubscribe();
    }
    this.userSub$.unsubscribe();
  }

  public changeSelection(): void {
    this.dialog.open(ChangeSelectionComponent, {
      autoFocus: false,
      width: '800px',
    });
  }

  public logout(): void {
    this.store.dispatch({ type: '[App Logout] Logout' });
    this.authService.logout();
    this.router.navigate(['/login']);
    this.notificationsService.notify(this.translateService.instant('login.logged-out'));
  }
}
