export const MeterChartOptions = {
  chart: {
    style: {
      fontFamily: 'Roboto, sans-serif',
    },
    // scrollablePlotArea: {
    //     minWidth: 700
    // }
  },
  time: {},
  scrollbar: {
    enabled: false,
  },
  navigator: {
    enabled: false,
  },
  rangeSelector: {
    enabled: false,
  },
  subtitle: {
    text: '',
  },
  exporting: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  title: false,
  legend: {
    rtl: true,
    enabled: true,
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'top',
    floating: false,
    itemMarginBottom: 10,
    padding: 0,
  },
  xAxis: {
    type: 'datetime',
    minPadding: 0,
    labels: {
      // align: 'left'
    },
    ordinal: false,
    dateTimeLabelFormats: {
      day: {
        main: '%b %d',
      },
      hour: {
        main: '%l:%M %p',
      },
      minute: {
        main: '%l:%M %p',
      },
      second: {
        main: '%l:%M<br/>:%S %p',
      },
    },
  },

  yAxis: [
    {
      top: '20%',
      height: '80%',
      minPadding: 0,
      title: {
        text: null,
      },
      labels: {
        align: 'right',
        x: 0,
        y: 16,
        format: '{value:.,0f}',
      },
      showFirstLabel: false,
    },
  ],
  tooltip: {
    shared: true,
  },

  plotOptions: {
    series: {
      marker: {
        enabled: true,
        // lineWidth: 1,
        // radius: 4
      },
      dataLabels: {
        // allowOverlap: true,
        // style: {
        //   fontSize: '20px'
        // },
        enabled: false,
        // align: 'right',
        // defer: false,
        // x: 80
      },
    },
    line: {
      enableMouseTracking: true,
      findNearestPointBy: 'y',
      boostThreshold: 0,
      dataGrouping: {
        enabled: false,
      },
      dataLabels: {
        y: 5,
        borderWidth: 0,
        useHTML: true,
        enabled: false,
      },
    },
    dataLabels: {
      defer: false,
      allowOverlap: true,
      enabled: false,
    },
  },
  series: [],
};
