<div id="login">
  <img src="./assets/images/psl-logo-white.svg" alt="Powerside" />

  <div class="boxed-in">
    <form (submit)="login($event)" [formGroup]="loginForm">
      <mat-form-field appearance="fill" data-cy="email">
        <mat-label>{{ 'login.email' | translate }}</mat-label>
        <input type="email" matInput formControlName="email" />
        <mat-error *ngIf="email.hasError('email')">{{
          'login.errors.email.invalid' | translate
        }}</mat-error>
        <mat-error *ngIf="email.hasError('required')">{{
          'global.errors.fieldRequired' | translate
        }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" data-cy="password">
        <mat-label>{{ 'login.password' | translate }}</mat-label>
        <input
          [type]="maskPassword ? 'password' : 'text'"
          matInput
          formControlName="password"
        />
        <mat-icon
          class="mask-toggle"
          matSuffix
          color="accent"
          (click)="toggleMasking()"
          [svgIcon]="maskPassword ? 'eye' : 'eye-off'"
        ></mat-icon>
        <mat-error *ngIf="password.hasError('minLength')">{{
          'login.errors.password.minLength'
            | translate: { minLength: minLength }
        }}</mat-error>
        <mat-error *ngIf="password.hasError('required')">{{
          'global.errors.fieldRequired' | translate
        }}</mat-error>
      </mat-form-field>
      <a
        href="javascript:void(0);"
        (click)="showForgotPassword()"
        class="mat-small"
        >{{ 'login.forgot-password.title' | translate }}</a
      >
      <a
      *ngIf="qubeScanEnabled"
      data-cy="register-account"
      [routerLink]="['/register-account']"
        class="mat-small"
        >{{ 'login.registerUserAccount.title' | translate }}</a
      >
      <button
        type="submit"
        mat-flat-button
        color="accent"
        [disabled]="loginForm.invalid || loading"
        data-cy="login_button"
      >
        {{ 'login.login' | translate }}
      </button>
    </form>
  </div>

  <div class="login-footer">
    {{ 'login.footer' | translate }}
  </div>
</div>
