import { Component, OnInit, OnDestroy } from '@angular/core';
import { ParamMap, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { take } from 'rxjs/operators';

import { AuthService } from '../shared/services';
import { IEnergyPowerChartUpdate } from '../shared/classes/energyPowerChartUpdate.interface';
import { IEnergyMeasure } from '../shared/classes/energyMeasure.interface';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '../shared/classes/component-destroy.class';
import { MeasurementPointsService } from '../shared/services/measurement-points.service';

@Component({
  selector: 'app-energy-power',
  templateUrl: './energy-power.component.html',
  styleUrls: ['./energy-power.component.scss'],
})
export class EnergyPowerComponent extends OnDestroyMixin implements OnDestroy, OnInit {
  public tabs = ['energyConsumption', 'powerDemand', 'powerFactorVars'];
  public start: moment.Moment;
  public end: moment.Moment;
  public mp: string;
  public mpTimezone: string;
  public energyMeasure: IEnergyMeasure;
  public duration: moment.Duration;
  public period: string;
  public scope: moment.unitOfTime.DurationConstructor;
  public maxDemandTz: string;
  public dateFormat = 'M/d/yyyy';
  public date: moment.Moment = null;
  private startPeriod: moment.Moment;
  private endPeriod: moment.Moment;

  constructor(
    private route: ActivatedRoute,
    private mpService: MeasurementPointsService,
    private authService: AuthService,
    private datePipe: DatePipe
  ) {
    super();
  }

  ngOnInit() {
    this.route.queryParamMap
      .pipe(untilComponentDestroyed(this))
      .subscribe((params: ParamMap) => {
        if (!this.mpService.selectedMeasurementPoint) {
          this.authService.preferences.pipe(take(1)).subscribe((userPrefs: any) => {
            this.mpService
              .refreshMeasurementPoint(userPrefs.account, userPrefs.mpId)
              .then(() => {
                this.mp = this.mpService.selectedMeasurementPoint.measurementPointId.toString();
                this.mpTimezone = this.mpService.selectedMeasurementPoint.timezone;
                this.end = moment().tz(this.mpTimezone);
                this.start = moment().tz(this.mpTimezone).startOf('day');
              });
          });
        } else {
          this.mp = this.mpService.selectedMeasurementPoint.measurementPointId.toString();
          this.mpTimezone = this.mpService.selectedMeasurementPoint.timezone;
          if (this.date === null) {
            this.scope = 'month';
            this.date = moment().tz(this.mpTimezone);
            this.end = moment().tz(this.mpTimezone);
            this.start = moment().tz(this.mpTimezone).startOf('day');
          }
        }
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  public setDetailBox(event): void {
    this.energyMeasure = event.energyMeasure;
    this.energyMeasure.maxActivePowerDemand =
      this.energyMeasure.maxActivePowerDemand / 1000;
    this.energyMeasure.minActivePowerDemand =
      this.energyMeasure.minActivePowerDemand / 1000;
    if (this.energyMeasure.dateTimeOfMaxActivePowerDemand) {
      this.energyMeasure.dateTimeOfMaxActivePowerDemand = moment(
        this.energyMeasure.dateTimeOfMaxActivePowerDemand
      ).tz(this.mpTimezone);
      this.maxDemandTz = this.energyMeasure.dateTimeOfMaxActivePowerDemand.format('z');
    }
  }

  public updateChart(event: IEnergyPowerChartUpdate): void {
    this.start = event.start;
    this.end = event.end;
    this.scope = event.scope;
    this.date = event.date;
    this.setPeriod();
  }

  private setPeriod(): void {
    this.duration = moment.duration(this.end.diff(this.start, 'days'));
    this.startPeriod = moment()
      .tz(this.mpTimezone)
      .year(this.start.year())
      .month(this.start.month())
      .date(this.start.date());
    this.endPeriod = moment()
      .tz(this.mpTimezone)
      .year(this.end.year())
      .month(this.end.month())
      .date(this.end.date());

    if (this.scope === 'day') {
      this.period = this.datePipe.transform(this.startPeriod, this.dateFormat) + ' (1 d)';
    } else if (this.scope === 'week') {
      if (this.duration.valueOf() < 7) {
        const duration = +this.duration.valueOf() + 1;
        this.period =
          this.datePipe.transform(this.startPeriod, this.dateFormat) +
          ' - ' +
          this.datePipe.transform(this.endPeriod, this.dateFormat) +
          ' (' +
          duration +
          'd)';
      } else {
        this.period =
          this.datePipe.transform(this.startPeriod, this.dateFormat) +
          ' - ' +
          this.datePipe.transform(
            moment(this.endPeriod).subtract(1, 'day'),
            this.dateFormat
          ) +
          ' (' +
          this.duration.valueOf() +
          'd)';
      }
    } else if (this.scope === 'month') {
      this.startPeriod = moment()
        .tz(this.mpTimezone)
        .year(this.start.year())
        .month(this.start.month())
        .date(this.start.date());
      this.endPeriod = moment()
        .tz(this.mpTimezone)
        .year(this.end.year())
        .month(this.end.month())
        .date(this.end.date());
      if (this.duration.valueOf() < this.start.daysInMonth()) {
        const duration = +this.duration.valueOf() + 1;
        this.period =
          this.datePipe.transform(this.startPeriod, this.dateFormat) +
          ' - ' +
          this.datePipe.transform(this.endPeriod, this.dateFormat) +
          ' (' +
          duration +
          'd)';
      } else {
        this.period =
          this.datePipe.transform(this.startPeriod, this.dateFormat) +
          ' - ' +
          this.datePipe.transform(
            moment(this.endPeriod).subtract(1, 'day'),
            this.dateFormat
          ) +
          ' (' +
          this.duration.valueOf() +
          'd)';
      }
    } else if (this.scope === 'year') {
      let duration;
      if (moment.duration(this.end.diff(this.start, 'month')).valueOf() < 12) {
        duration = +moment.duration(this.end.diff(this.start, 'month')).valueOf() + 1;
      } else {
        duration = moment.duration(this.end.diff(this.start, 'month')).valueOf();
      }
      this.period =
        this.datePipe.transform(this.startPeriod, this.dateFormat) +
        ' - ' +
        this.datePipe.transform(this.endPeriod, this.dateFormat) +
        ' (' +
        duration +
        'm)';
    }
  }
}
