<h1 mat-dialog-title>
    {{ 'login.reset-password.title' | translate }}
</h1>
<form (submit)="submitForm($event)" [formGroup]="resetForm" class="reset-form">
    <div mat-dialog-content>
        <p>{{ 'login.reset-password.copy' | translate }}</p>
        <mat-form-field appearance="fill">
            <mat-label>{{ 'profile.new-password' | translate }}</mat-label>
            <input matInput [type]="maskPassword ? 'password' : 'text'" formControlName="newPassword">
            <mat-icon class="mask-toggle" matSuffix color="accent" (click)="maskPassword = !maskPassword" [svgIcon]="maskPassword ? 'eye' : 'eye-off'"></mat-icon>
            <mat-error *ngIf="newPassword.hasError('passwordMismatch')">{{ 'profile.password-mismatch' | translate }}</mat-error>
            <mat-error *ngIf="newPassword.hasError('pattern')">{{ 'profile.password-rules' | translate }}</mat-error>
            <mat-error *ngIf="newPassword.hasError('required')">{{ 'global.errors.fieldRequired' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{ 'profile.confirm-password' | translate }}</mat-label>
            <input matInput [type]="maskConfirm ? 'password' : 'text'" formControlName="confirmPassword">
            <mat-icon class="mask-toggle" matSuffix color="accent" (click)="maskConfirm = !maskConfirm" [svgIcon]="maskConfirm ? 'eye' : 'eye-off'"></mat-icon>
            <mat-error *ngIf="confirmPassword.hasError('passwordMismatch')">{{ 'profile.password-mismatch' | translate }}</mat-error>
            <mat-error *ngIf="confirmPassword.hasError('required')">{{ 'global.errors.fieldRequired' | translate }}</mat-error>
        </mat-form-field>
    </div>
    <div mat-dialog-actions>
        <button mat-flat-button type="button" (mousedown)="onCancel()" *ngIf="from === 'profile'">{{ 'global.cancel' | translate }}</button>
        <button mat-flat-button color="accent" type="submit" [disabled]="resetForm.invalid">{{ 'global.OK' | translate }}</button>
    </div>
</form>