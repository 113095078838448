import { cloneDeep } from 'lodash';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Moment } from 'moment';
import { Observable, forkJoin, from, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

import { DiligentApiService } from './diligent-api.service';
import { MeasurementPoint } from '../classes/MeasurementPoint';
import { IMeasurementPoint } from '../classes/measurementpoint.interface';
import { NotificationsService } from '../modules/notifications/shared/notifications.service';
import { SitesService } from './sites.service';
import { TokenService } from './token.service';
import * as fromHierarchy from './../../_store/_reducers';

@Injectable({
  providedIn: 'root',
})
export class MeasurementPointsService {
  private mSelectedMeasurementPoint: MeasurementPoint;

  public get selectedMeasurementPoint(): MeasurementPoint {
    return this.mSelectedMeasurementPoint;
  }

  constructor(
    private diligentService: DiligentApiService,
    private sitesService: SitesService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private router: Router,
    private token: TokenService,
    private store: Store<fromHierarchy.State>
  ) {}

  private notifyWrongIdAccount(): void {
    this.notificationsService
      .alert(
        'Your log in account privileges do not allow you to view this information. You might be using multiple accounts. Please logout and login using the appropriate user account.'
      )
      .afterClosed()
      .subscribe((stream) => this.router.navigate(['dashboard']));
  }

  public refreshMeasurementPoint(
    accountId: number,
    mpId: number
  ): Promise<MeasurementPoint> {
    const isAdmin = this.token.metaData.isSystemAdministrator === '1';
    return this.diligentService
      .getMeasurementPoint(mpId)
      .pipe(
        switchMap((measurementPoint) => {
          if (isAdmin) {
            return this.crawlMPHierarchy(measurementPoint.mpId, of(measurementPoint));
          }
          return this.traverseFlatMPArray(accountId, mpId, of(measurementPoint));
        }),
        map((response: any) => {
          if (!response) return null;
          this.mSelectedMeasurementPoint = new MeasurementPoint(response);
          return this.mSelectedMeasurementPoint;
        }),
        catchError((err) => {
          this.notifyWrongIdAccount();
          return of(err);
        })
      )
      .toPromise();
  }

  private crawlMPHierarchy(
    mpName: string,
    measurementPoint: Observable<MeasurementPoint>
  ): Observable<MeasurementPoint> {
    return forkJoin([
      this.store.select(fromHierarchy.getHierarchy).pipe(take(1)),
      measurementPoint,
    ]).pipe(
      map(([sitesStore, mpLimited]) => {
        if (sitesStore.length === 0) {
          return;
        }
        const sites = cloneDeep(sitesStore);
        const partnerCustomerMatchedMP = sites.partners.reduce((acc, partner) => {
          const customerMPMatches = partner.customers.reduce((accumulator, customer) => {
            return [
              ...accumulator,
              ...customer.measurementPoints.filter((mp) => mp.mpId === mpName),
            ];
          }, []);
          return [...acc, ...customerMPMatches];
        }, []);
        const matchedMp = partnerCustomerMatchedMP[0];
        matchedMp.commissionedWhen = mpLimited.commissionedWhen;
        return matchedMp;
      })
    );
  }

  private traverseFlatMPArray(
    accountId: number,
    mpId: number,
    measurementPoint: Observable<MeasurementPoint>
  ) {
    return forkJoin([this.getMeasurementPoints(accountId), measurementPoint]).pipe(
      map(([response, mpLimited]: any) => {
        if (response.errno) {
          this.mSelectedMeasurementPoint = undefined;
          if (this.notificationsService.alerts === 0) {
            this.notificationsService.alert(
              response.sqlMessage ||
                this.translateService.instant('global.loading-mp-error')
            );
          }
          return null;
        }
        const mpObject = response.find((mp) => parseInt(mp.roomId, 10) === mpId);
        if (!mpObject) {
          if (this.notificationsService.alerts === 0) {
            this.notifyWrongIdAccount();
          }
          return null;
        }

        mpObject.timezone = mpLimited.timezone;
        mpObject.commissionedWhen = mpLimited.commissionedWhen;

        return mpObject;
      })
    );
  }

  public getMeasurementPoints(accountId: number): Observable<IMeasurementPoint[]> {
    // we do this because the old way (diligentService.getMeasurementPoints) is
    // paginated without any indication of whether there are more results, which
    // would have been a nightmare to work with. so, we use the 'getSites' function
    // which will return all sites/customers/MPs that a user has access to. we
    // then extract the MPs from those results and stuff the "measurementPointId"
    // into the "roomId" field so upstream things will continue to work.
    return from(
      new Promise(async (resolve) => {
        // get all the sites a user has access to
        const sites: any = await this.sitesService
          .getSites(accountId)
          .toPromise()
          .catch((err) => {
            this.notifyWrongIdAccount();
          });
        let mps = [];

        if (sites.partners) {
          // pull the MPs out of the partners/customers
          for (const partner of sites.partners) {
            for (const customer of partner.customers) {
              mps = mps.concat(
                customer.measurementPoints.map((mp) => {
                  // make the `roomId` match the `measurementPointId` because a lot
                  // of other places use the `roomId` field
                  mp.roomId = mp.measurementPointId;
                  return mp;
                })
              );
            }
          }
        }

        // finally, we can hand back measurement points
        resolve(mps);
      })
    ).pipe(map((r) => r as IMeasurementPoint[]));
  }

  public async getMeasurementPointEvents(
    mpId: number,
    accountId: number,
    startDate: Moment = null,
    endDate: Moment = null,
    deviceEventTypeId: any = null,
    severeOnly: boolean = false,
    includeRetired: boolean = false,
    offset: number = 0,
    count: number = 20
  ) {
    return this.diligentService.getMeasurementPointEvents(
      mpId,
      accountId,
      startDate,
      endDate,
      deviceEventTypeId,
      severeOnly,
      includeRetired,
      offset,
      count
    );
  }

  public getEventsData(
    mpId: string,
    accountId: string,
    startDate: Moment,
    endDate: Moment,
    eventTypeId = -1,
    severeOnly?: boolean
  ) {
    return this.diligentService.getEventsData(
      mpId,
      accountId,
      startDate,
      endDate,
      eventTypeId,
      10000,
      severeOnly
    );
  }

  public getEventImages(id: number, ttl: number = 300): Promise<Array<string>> {
    return new Promise(async (resolve, reject) => {
      const eventImages = await this.diligentService.getEventImages(id);
      const promises: Array<string> = eventImages.map((i: any) =>
        this.diligentService.getDocumentDownload(i.documentId, ttl)
      );

      Promise.all(promises)
        .then((images) => {
          // make sure the images display with RMS first and Waveform last
          images.sort((a: string, b: string) => {
            if (/_RMS\./.test(a)) {
              return -1;
            }

            if (/_Waveform\./.test(a)) {
              return 1;
            }

            return 0;
          });
          resolve(images);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getDocumentDownload(id: number) {
    return this.diligentService.getDocumentDownload(id);
  }

  public countByAccount(accountId: number): Observable<any> {
    return this.diligentService.countMeasurementPointsByAccount(accountId);
  }

  public getMeasurementPoint(mpId: number): Observable<any> {
    return this.diligentService.getMeasurementPoint(mpId);
  }

  public getChannelDefinition(mpId: string): Observable<any> {
    return this.diligentService.getChannelDefinition(mpId);
  }
}
