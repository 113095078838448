import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { Store } from '@ngrx/store';

import {
  AuthService,
  LoaderService,
  SitesService,
  TokenService,
} from '../shared/services';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from './../shared/classes/component-destroy.class';
import { IAccount } from '../shared/classes/account';
import { NotificationsService } from '../shared/modules/notifications/shared/notifications.service';
import { ForgotPasswordDialogComponent } from './forgot-password-dialog/forgot-password-dialog.component';
import { ResetPasswordDialogComponent } from './reset-password-dialog/reset-password-dialog.component';
import { IJwtPayload } from '../shared/classes/generic.interfaces';
import { ILoginResponse } from '../shared/classes/user';
import * as fromUser from './../_store/_reducers';
import { MeasurementPointsService } from '../shared/services/measurement-points.service';

@Component({
  selector: 'app-login',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends OnDestroyMixin implements OnDestroy {
  public loginForm: FormGroup;

  public loading = false;
  public minLength = 6;
  public maskPassword = true;
  public qubeScanEnabled = false;
  public forgotPasswordEmail = '';
  public resetPasswordEmail = '';
  private token: string;
  private returnUrl: string;
  private account: IAccount;
  public userPrefs: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private measurementPointService: MeasurementPointsService,
    private sitesService: SitesService,
    private dialog: MatDialog,
    private loaderService: LoaderService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private tokenService: TokenService,
    private store: Store<fromUser.State>
  ) {
    super();
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(this.minLength),
      ]),
    });
    this.route.queryParams
      .pipe(untilComponentDestroyed(this))
      .subscribe((params: Params) => {
        // password reset is happening
        if (params.token) {
          this.token = params.token;
          // strip the token out of the URL
          this.router.navigate(['/login']);
          // show the reset dialog
          this.showResetPassword();
        }
        if (params.returnUrl) {
          this.returnUrl = params.returnUrl;
        }
      });

    forkJoin({
      flag: this.auth.getEnvironmentSettings('featureEnableQubeScan').pipe(take(1)),
      prefs: this.auth.preferences.pipe(take(1)),
    }).subscribe(
      (result: any) => {
        this.qubeScanEnabled = result.flag.featureEnableQubeScan;
        this.userPrefs = result.prefs;
        // check to see if we're authed (but don't keep listening)
        this.auth.authed
          .pipe(untilComponentDestroyed(this))
          .subscribe((payload: IJwtPayload) => {
            if (payload && payload.accountId) {
              this.auth
                .getAccountById(payload.accountId)
                .pipe(take(1))
                .subscribe((account: IAccount) => {
                  this.store.dispatch({
                    type: '[Login] Set User IsPartner Success',
                    payload: { isPartner: account.isPartner },
                  });
                });

              this.auth.accountO
                .pipe(untilComponentDestroyed(this))
                .subscribe((account: IAccount) => {
                  if (!account) {
                    return;
                  }
                  if (JSON.stringify(account) !== JSON.stringify(this.account)) {
                    this.account = account;

                    if (this.returnUrl) {
                      this.router.navigateByUrl(this.returnUrl);
                    } else {
                      if (
                        account.isPartner ||
                        tokenService.metaData.isSystemAdministrator === '1'
                      ) {
                        this.router.navigate(['fleet']);
                      } else {
                        if (this.qubeScanEnabled && account.id) {
                          this.measurementPointService
                            .countByAccount(account.id)
                            .subscribe(
                              (res) => {
                                if (res) {
                                  if (res.count === 0) {
                                    // No MPs ==> QubeScan ==> Create MP / Site
                                    this.router.navigate(['create-mp', account.id]);
                                  } else if (res.count === 1) {
                                    this.sitesService
                                      .getMeasurementPointsForMap()
                                      .then((mps: Array<any>) => {
                                        if (mps.length > 0) {
                                          // If it's a unique MP save it to preferences
                                          this.auth
                                            .savePreferences({
                                              ...this.userPrefs,
                                              account: mps[0].accountId,
                                              mpId: mps[0].measurementPointId,
                                            })
                                            .then((saved) => {
                                              if (saved) {
                                                if (mps[0].measurementPointTypeId === 1) {
                                                  // If the unique MP is a QubeScan
                                                  if (
                                                    mps[0].measurementPointStatusId === 8
                                                  ) {
                                                    // If commissioned navigate to the events list
                                                    this.router.navigate(['events-list']);
                                                  } else {
                                                    // If not go to Fleet
                                                    this.router.navigate(['fleet'], {
                                                      queryParams: {
                                                        account: mps[0].accountId,
                                                        mpId: mps[0].measurementPointId,
                                                      },
                                                    });
                                                  }
                                                } else {
                                                  // If the unique MP is a In-Site to navigate to dashboard
                                                  this.router.navigate(['dashboard']);
                                                }
                                              }
                                            });
                                        } else {
                                          // We shouldn't hit this but who knows! No MPs ==> QubeScan ==> Create MP / Site
                                          this.router.navigate(['create-mp', account.id]);
                                        }
                                      });
                                  } else {
                                    // Found may MPs
                                    // If preferences are set navigate to the latest MP
                                    if (this.userPrefs && this.userPrefs.mpId) {
                                      measurementPointService
                                        .getMeasurementPoint(this.userPrefs.mpId)
                                        .subscribe((mp: any) => {
                                          if (mp.measurementPointTypeId === 1) {
                                            if (mp.measurementPointStatusId === 8) {
                                              // If commissioned navigate to the events list
                                              this.router.navigate(['events-list']);
                                            } else {
                                              // If not go to Fleet
                                              this.router.navigate(['fleet'], {
                                                queryParams: {
                                                  account: mp.accountId,
                                                  mpId: mp.roomId,
                                                },
                                              });
                                            }
                                          } else {
                                            this.router.navigate(['dashboard']);
                                          }
                                        });
                                    } else {
                                      // No prefs. Navigate to dashboard. The MP selection popup will be displayed.
                                      this.router.navigate(['dashboard']);
                                    }
                                  }
                                } else {
                                  this.router.navigate(['dashboard']);
                                }
                              },
                              (error) => {
                                this.router.navigate(['dashboard']);
                              }
                            );
                        } else {
                          this.router.navigate(['dashboard']);
                        }
                      }
                    }
                  }
                });
            }
          });
      },
      (error) => {
        console.error(error);
      }
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  public get email(): AbstractControl {
    return this.loginForm.get('email');
  }

  public get password(): AbstractControl {
    return this.loginForm.get('password');
  }

  public login($event: Event): void {
    this.loading = true;
    if ($event) {
      $event.stopPropagation();
    }
    // wait for the login event to finish then redirect to the dashboard
    this.auth.login(this.email.value, this.password.value).then(
      () => {
        // we really don't need to do anything here since the user will be
        // redirected very quickly, but it can't hurt to clean this up :)
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.notificationsService.alert(
          this.translateService.instant('login.login-error'),
          this.translateService.instant('login.login-failed')
        );
      }
    );
  }

  public toggleMasking(): void {
    this.maskPassword = !this.maskPassword;
  }

  public showForgotPassword(): void {
    const forgotDialog = this.dialog.open(ForgotPasswordDialogComponent, {
      data: {
        email: this.email.value,
      },
      width: '350px',
    });

    forgotDialog.afterClosed().subscribe((email: string) => {
      if (email) {
        this.loaderService.show();
        this.auth.requestPasswordReset(email).then(
          () => {
            this.loaderService.hide();
            this.notificationsService.notify(
              this.translateService.instant('login.forgot-password.reset-sent')
            );
          },
          (error) => {
            console.error(error);
            this.loaderService.hide();
            this.notificationsService.notify(
              this.translateService.instant('login.forgot-password.reset-not-sent')
            );
          }
        );
      }
    });
  }

  private showResetPassword(): void {
    // make sure we've got the things we need to actually do something useful
    if (!this.token) {
      return;
    }

    const resetDialog = this.dialog.open(ResetPasswordDialogComponent, {
      width: '350px',
      disableClose: true,
      data: {
        from: 'reset',
      },
    });

    resetDialog.afterClosed().subscribe((password: string) => {
      if (!password) {
        return;
      }

      this.loaderService.show();
      this.auth.resetPasswordWithToken(password, this.token).then(
        async (response: ILoginResponse) => {
          this.loaderService.hide();
          if (response) {
            this.notificationsService.notify(
              this.translateService.instant(
                'login.reset-password.password-change-success'
              )
            );
            this.loginForm.setValue({
              email: response.email,
              password: password,
            });
            this.login(null);
          } else {
            this.showResetPassword();
            this.notificationsService.alert(
              this.translateService.instant('login.reset-password.password-change-error')
            );
          }
        },
        () => {
          this.loaderService.hide();
          this.showResetPassword();
          this.notificationsService.alert(
            this.translateService.instant('login.reset-password.password-change-error')
          );
        }
      );
    });
  }
}
