export const CurrentIHarmonics = [
  { name: 'IH1', factoryName: 'IIH1' },
  { name: 'IH2', factoryName: 'IIH2' },
  { name: 'IH3', factoryName: 'IIH3' },
  { name: 'IH4', factoryName: 'IIH4' },
  { name: 'IH5', factoryName: 'IIH5' },
  { name: 'IH6', factoryName: 'IIH6' },
  { name: 'IH7', factoryName: 'IIH7' },
  { name: 'IH8', factoryName: 'IIH8' },
  { name: 'IH9', factoryName: 'IIH9' },
  { name: 'IH10', factoryName: 'IIH10' },
  { name: 'IH11', factoryName: 'IIH11' },
  { name: 'IH12', factoryName: 'IIH12' },
  { name: 'IH13', factoryName: 'IIH13' },
  { name: 'IH14', factoryName: 'IIH14' },
  { name: 'IH15', factoryName: 'IIH15' },
  { name: 'IH16', factoryName: 'IIH16' },
  { name: 'IH17', factoryName: 'IIH17' },
  { name: 'IH18', factoryName: 'IIH18' },
  { name: 'IH19', factoryName: 'IIH19' },
  { name: 'IH20', factoryName: 'IIH20' },
  { name: 'IH21', factoryName: 'IIH21' },
  { name: 'IH22', factoryName: 'IIH22' },
  { name: 'IH23', factoryName: 'IIH23' },
  { name: 'IH24', factoryName: 'IIH24' },
  { name: 'IH25', factoryName: 'IIH25' },
  { name: 'IH26', factoryName: 'IIH26' },
  { name: 'IH27', factoryName: 'IIH27' },
  { name: 'IH28', factoryName: 'IIH28' },
  { name: 'IH29', factoryName: 'IIH29' },
  { name: 'IH30', factoryName: 'IIH30' },
  { name: 'IH31', factoryName: 'IIH31' },
  { name: 'IH32', factoryName: 'IIH32' },
  { name: 'IH33', factoryName: 'IIH33' },
  { name: 'IH34', factoryName: 'IIH34' },
  { name: 'IH35', factoryName: 'IIH35' },
  { name: 'IH36', factoryName: 'IIH36' },
  { name: 'IH37', factoryName: 'IIH37' },
  { name: 'IH38', factoryName: 'IIH38' },
  { name: 'IH39', factoryName: 'IIH39' },
  { name: 'IH40', factoryName: 'IIH40' },
  { name: 'IH41', factoryName: 'IIH41' },
  { name: 'IH42', factoryName: 'IIH42' },
  { name: 'IH43', factoryName: 'IIH43' },
  { name: 'IH44', factoryName: 'IIH44' },
  { name: 'IH45', factoryName: 'IIH45' },
  { name: 'IH46', factoryName: 'IIH46' },
  { name: 'IH47', factoryName: 'IIH47' },
  { name: 'IH48', factoryName: 'IIH48' },
  { name: 'IH49', factoryName: 'IIH49' },
];
