import { Component, Inject } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidationErrors,
  AbstractControl,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-reset-password-dialog',
  templateUrl: 'reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.scss'],
})
export class ResetPasswordDialogComponent {
  public resetForm: FormGroup;

  public passwordPattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  public minLength = 8;
  public maskPassword = true;
  public maskConfirm = true;
  public from: string;

  constructor(
    private dialogRef: MatDialogRef<ResetPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.from = data.from;
    this.resetForm = new FormGroup(
      {
        newPassword: new FormControl('', [
          Validators.required,
          Validators.pattern(this.passwordPattern),
          Validators.minLength(this.minLength),
        ]),
        confirmPassword: new FormControl('', [
          Validators.required,
          Validators.pattern(this.passwordPattern),
          Validators.minLength(this.minLength),
        ]),
      },
      {
        validators: (control: FormGroup): ValidationErrors | null => {
          const pwd = control.get('newPassword');
          const cfm = control.get('confirmPassword');

          const valid = pwd && cfm && pwd.value === cfm.value;
          const error = { passwordMismatch: true };

          let pErrors = { ...pwd.errors };
          let cErrors = { ...cfm.errors };

          if (!valid) {
            pErrors = { ...pErrors, ...error };
            cErrors = { ...cErrors, ...error };
          } else {
            if (pErrors.hasOwnProperty('passwordMismatch')) {
              delete pErrors.passwordMismatch;
            }

            if (cErrors.hasOwnProperty('passwordMismatch')) {
              delete cErrors.passwordMismatch;
            }
          }

          pwd.setErrors(Object.keys(pErrors).length > 0 ? pErrors : null);
          cfm.setErrors(Object.keys(cErrors).length > 0 ? cErrors : null);

          return valid ? null : error;
        },
      }
    );
  }

  public get newPassword(): AbstractControl {
    return this.resetForm.get('newPassword');
  }

  public get confirmPassword(): AbstractControl {
    return this.resetForm.get('confirmPassword');
  }

  public onCancel(): void {
    this.dialogRef.close(null);
  }

  public submitForm($event: Event): void {
    $event.stopPropagation();
    this.dialogRef.close(this.newPassword.value);
  }
}
