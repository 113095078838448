import { ILiveChart, ILiveSeriesOptions, ILiveMeterSeriesOptions, ILiveMeter, IDataPoint } from './live-chart.interface';

// @Injectable({
//   providedIn: 'root'
// })

class LiveSeriesOptions implements ILiveSeriesOptions {
  public type: string;
  public color: string;
  public name: string;
  public id: string;
  public tooltip: Highcharts.TooltipOptions;
  private mChannelInfo: [{name: string, channelId: number}];
  private mNextDataPoint: IDataPoint = { x: null, y: null};
  public data: number[][] = [];
  public gapSize = 30000;
  public gapUnit = 'value';
  private mUnitScaling: number;

  // public get type(): string {
  //   return this.mType;
  // }

  // public get color(): string {
  //   return this.mColor;
  // }

  // public get name(): string {
  //   return this.mName;
  // }

  // public get tooltip(): Highcharts.TooltipOptions {
  //   return this.mTooltip;
  // }

  public get channelInfo(): [{name: string, channelId: number}] {
    return this.mChannelInfo;
  }

  public get nextDataPoint(): IDataPoint {
    return Object.assign({}, this.mNextDataPoint);
  }

  public populateNextDataPoint(time: number, data: {}[]) {
    this.mChannelInfo.forEach((info, index) => {
      this.mNextDataPoint.x = time;
      const dataObj = data.find(datum => datum[info.name]);
      if (dataObj && dataObj[info.name]) {
        let dataPoint = parseFloat(dataObj[info.name]);
        if (this.mUnitScaling) {
          dataPoint *= this.mUnitScaling;
        }
        this.mNextDataPoint.y = dataPoint;
      }
      else {
        this.mNextDataPoint.y = null;
      }
    });
  }

  constructor(type: string, 
              color: string, 
              name: string, 
              tooltip: Highcharts.TooltipOptions, 
              channelInfo: [{name: string, channelId: number}],
              unitScaling: number = null) {
    this.type = type;
    this.color = color;
    this.name = name;
    this.id = name;
    this.tooltip = tooltip;
    this.mChannelInfo = channelInfo;
    this.mUnitScaling = unitScaling;
  }

}

// class LiveMeterSeriesOptions extends LiveSeriesOptions implements ILiveMeterSeriesOptions {
//   public showInLegend = true;
//   public colorByPoint = false;
//   public innerRadius: string;
//   public radius: string;
//   public units: string;
//   public dataLabels = {
//                         align: 'right',
//                         y: undefined,
//                         x: undefined,
//                         format: undefined
//                       };

//   constructor(type: string, 
//               color: string, 
//               name: string,
//               units: string,
//               tooltip: Highcharts.TooltipOptions, 
//               channelInfo: [{name: string, channelId: number}]) {
//     super(type, color, name, tooltip, channelInfo);
//     this.units = units;
//     this.dataLabels.format = '{y}' + this.units;
//   }
// }

class LiveChart implements ILiveChart {
  private mName: string;
  private mSeriesArray: LiveSeriesOptions[];
  private mYAxisOptions: Highcharts.YAxisOptions;
  private chartInnerRadius: 60;

  public get name(): string {
    return this.mName;
  }

  public get seriesArray(): LiveSeriesOptions[] {
    return this.mSeriesArray;
  }

  public get yAxisOptions(): Highcharts.YAxisOptions {
    return this.mYAxisOptions;
  }

  constructor(name: string, seriesArray: LiveSeriesOptions[], yAxisOptions: Highcharts.YAxisOptions, ) {
    this.mName = name;
    this.mSeriesArray = seriesArray;
    this.mYAxisOptions = yAxisOptions;
  }
}

class CustomChannel {
    private name: string; 
    private meterParam: string;
    private unit: string; 
    private unitOffset: number;
    private channelID: number;
    private factoryName: string;

    public get cName(): string {
      return this.name;
    }

    public get cMeterParam(): string {
      return this.meterParam;
    }

    public get cUnit(): string {
      return this.unit;
    }

    public get cUnitOffset(): number {
      return this.unitOffset;
    }

    public get cChannelID(): number {
      return this.channelID;
    }

    public get cFactoryName(): string {
      return this.factoryName;
    }

    constructor(name: string, meterParam: string, unit: string, unitOffset: number, channelID: number, factoryName: string) {
      this.name = name;
      this.meterParam = meterParam;
      this.unit = unit;
      this.unitOffset = unitOffset;
      this.channelID = channelID;
      this.factoryName = factoryName;
    }
}

// class LiveMeter implements ILiveMeter {
//   private mName: string;
//   private mSeriesArray: LiveMeterSeriesOptions[];
//   private mYAxisOptions: Highcharts.YAxisOptions;
//   private chartInnerRadius = 60;

//   public get name(): string {
//     return this.mName;
//   }

//   public get seriesArray(): LiveMeterSeriesOptions[] {
//     return this.mSeriesArray;
//   }

//   public get yAxisOptions(): Highcharts.YAxisOptions {
//     return this.mYAxisOptions;
//   }

//   constructor(name: string, seriesArray: LiveMeterSeriesOptions[], yAxisOptions: Highcharts.YAxisOptions, ) {
//     this.mName = name;
//     this.mSeriesArray = seriesArray;
//     this.mYAxisOptions = yAxisOptions;

//     const seriesRadiusHeight = (100 - this.chartInnerRadius) / this.mSeriesArray.length;

//     this.seriesArray.forEach((series: LiveMeterSeriesOptions, index) => {
//       const innerRadius = seriesRadiusHeight * (index) + this.chartInnerRadius;
//       const radius = seriesRadiusHeight * (index + 1) + this.chartInnerRadius;
//       series.innerRadius = String(innerRadius) + '%';
//       series.radius = String(radius) + '%';
//     });

//   }
// }


// class VoltageFluctuationsChart extends LiveChart {
  
//   constructor() {
//     super('Voltage Fluctuations',
//     [
//       new LiveSeriesOptions('line', '#FF515B', 'L1-L2', {valueDecimals: 3, valueSuffix: ' V'}, [{name: 'c_7_inst_V', channelId: 7}]),
//       new LiveSeriesOptions('line', '#FFBE08', 'L2-L3', {valueDecimals: 3, valueSuffix: ' V'}, [{name: 'c_8_inst_V', channelId: 8}]),
//       new LiveSeriesOptions('line', '#0050BF', 'L3-L1', {valueDecimals: 3, valueSuffix: ' V'}, [{name: 'c_9_inst_V', channelId: 9}])
//     ],
//     {}
//     );
//   }
// }
class PowerFactorChart extends LiveChart {
  
  constructor() {
    super('Power Factor',
    [
      new LiveSeriesOptions('line', '#FF515B', 'L1-N', {valueDecimals: 3, valueSuffix: ' V'}, [{name: 'c_125_inst_', channelId: 125}]),
      new LiveSeriesOptions('line', '#FFBE08', 'L2-N', {valueDecimals: 3, valueSuffix: ' V'}, [{name: 'c_126_inst_', channelId: 126}]),
      new LiveSeriesOptions('line', '#0050BF', 'L3-N', {valueDecimals: 3, valueSuffix: ' V'}, [{name: 'c_127_inst_', channelId: 127}])
    ],
    {}
    );
  }
}
class PhaseCurrent extends LiveChart {
  
  constructor() {
    super('Phase Current',
    [
      new LiveSeriesOptions('line', '#FF515B', 'L1', {valueDecimals: 2, valueSuffix: ' A'}, [{name: 'c_16_inst_A', channelId: 16}]),
      new LiveSeriesOptions('line', '#FFBE08', 'L2', {valueDecimals: 2, valueSuffix: ' A'}, [{name: 'c_17_inst_A', channelId: 17}]),
      new LiveSeriesOptions('line', '#0050BF', 'L3', {valueDecimals: 2, valueSuffix: ' A'}, [{name: 'c_18_inst_A', channelId: 18}])
    ],
    {}
    );
  }
}
class ThdV extends LiveChart {
  
  constructor() {
    super('THD-V',
    [
      new LiveSeriesOptions('line', '#FF515B', 'L1', {valueDecimals: 2, valueSuffix: ' %'}, [{name: 'c_281_inst_%', channelId: 281}]),
      new LiveSeriesOptions('line', '#FFBE08', 'L2', {valueDecimals: 2, valueSuffix: ' %'}, [{name: 'c_282_inst_%', channelId: 282}]),
      new LiveSeriesOptions('line', '#0050BF', 'L3', {valueDecimals: 2, valueSuffix: ' %'}, [{name: 'c_283_inst_%', channelId: 283}])
    ],
    {}
    );
  }
}
class Tdd extends LiveChart {
  
  constructor() {
    super('TDD',
    [
      new LiveSeriesOptions('line', '#FF515B', 'L1', {valueDecimals: 2, valueSuffix: ' %'}, [{name: 'c_284_inst_%', channelId: 284}]),
      new LiveSeriesOptions('line', '#FFBE08', 'L2', {valueDecimals: 2, valueSuffix: ' %'}, [{name: 'c_285_inst_%', channelId: 285}]),
      new LiveSeriesOptions('line', '#0050BF', 'L3', {valueDecimals: 2, valueSuffix: ' %'}, [{name: 'c_286_inst_%', channelId: 286}])
    ],
    {}
    );
  }
}
class FlickerPinst extends LiveChart {
  
  constructor() {
    super('Flicker Pinst',
    [
      new LiveSeriesOptions('line', '#FF515B', 'L1', {valueDecimals: 2, valueSuffix: ''}, [{name: 'c_305_inst_', channelId: 305}]),
      new LiveSeriesOptions('line', '#FFBE08', 'L2', {valueDecimals: 2, valueSuffix: ''}, [{name: 'c_306_inst_', channelId: 306}]),
      new LiveSeriesOptions('line', '#0050BF', 'L3', {valueDecimals: 2, valueSuffix: ''}, [{name: 'c_307_inst_', channelId: 307}])
    ],
    {}
    );
  }
}
class FlickerPst extends LiveChart {
  
  constructor() {
    super('Flicker Pinst',
    [
      new LiveSeriesOptions('line', '#FF515B', 'L1', {valueDecimals: 2, valueSuffix: ''}, [{name: 'c_308_inst_', channelId: 308}]),
      new LiveSeriesOptions('line', '#FFBE08', 'L2', {valueDecimals: 2, valueSuffix: ''}, [{name: 'c_309_inst_', channelId: 309}]),
      new LiveSeriesOptions('line', '#0050BF', 'L3', {valueDecimals: 2, valueSuffix: ''}, [{name: 'c_310_inst_', channelId: 310}])
    ],
    {}
    );
  }
}
class FlickerPlt extends LiveChart {
  constructor() {
    super('Flicker Plt',
    [
      new LiveSeriesOptions('line', '#FF515B', 'L1', {valueDecimals: 2, valueSuffix: ''}, [{name: 'c_311_inst_', channelId: 311}]),
      new LiveSeriesOptions('line', '#FFBE08', 'L2', {valueDecimals: 2, valueSuffix: ''}, [{name: 'c_312_inst_', channelId: 312}]),
      new LiveSeriesOptions('line', '#0050BF', 'L3', {valueDecimals: 2, valueSuffix: ''}, [{name: 'c_313_inst_', channelId: 313}])
    ],
    {}
    );
  }
}
class VoltageLN extends LiveChart {
  constructor() {
    super('Voltage L-N',
    [
      new LiveSeriesOptions('line', '#FF515B', 'L1-N', {valueDecimals: 2, valueSuffix: ' V'}, [{name: 'c_4_inst_V', channelId: 4}]),
      new LiveSeriesOptions('line', '#FFBE08', 'L2-N', {valueDecimals: 2, valueSuffix: ' V'}, [{name: 'c_5_inst_V', channelId: 5}]),
      new LiveSeriesOptions('line', '#0050BF', 'L3-N', {valueDecimals: 2, valueSuffix: ' V'}, [{name: 'c_6_inst_V', channelId: 6}])
    ],
    {}
    );
  }
}
class VoltageLL extends LiveChart {
  constructor() {
    super('Voltage L-L',
    [
      new LiveSeriesOptions('line', '#FF515B', 'L1-L2', {valueDecimals: 2, valueSuffix: ' V'}, [{name: 'c_7_inst_V', channelId: 7}]),
      new LiveSeriesOptions('line', '#FFBE08', 'L2-L3', {valueDecimals: 2, valueSuffix: ' V'}, [{name: 'c_8_inst_V', channelId: 8}]),
      new LiveSeriesOptions('line', '#0050BF', 'L3-L1', {valueDecimals: 2, valueSuffix: ' V'}, [{name: 'c_9_inst_V', channelId: 9}])
    ],
    {}
    );
  }
}
class ActivePower extends LiveChart {
  constructor() {
    super('Active Power',
    [
      new LiveSeriesOptions('line', '#FF515B', 'L1', {valueDecimals: 3, valueSuffix: ' kW'}, [{name: 'c_71_inst_W', channelId: 71}], 1 / 1000),
      new LiveSeriesOptions('line', '#FFBE08', 'L2', {valueDecimals: 3, valueSuffix: ' kW'}, [{name: 'c_72_inst_W', channelId: 72}], 1 / 1000),
      new LiveSeriesOptions('line', '#0050BF', 'L3', {valueDecimals: 3, valueSuffix: ' kW'}, [{name: 'c_73_inst_W', channelId: 73}], 1 / 1000)
    ],
    {}
    );
  }
}
class ReactivePower extends LiveChart {
  constructor() {
    super('Reactive Power',
    [
      new LiveSeriesOptions('line', '#FF515B', 'L1', {valueDecimals: 3, valueSuffix: ' kVAR'}, [{name: 'c_88_inst_VAR', channelId: 88}], 1 / 1000),
      new LiveSeriesOptions('line', '#FFBE08', 'L2', {valueDecimals: 3, valueSuffix: ' kVAR'}, [{name: 'c_89_inst_VAR', channelId: 89}], 1 / 1000),
      new LiveSeriesOptions('line', '#0050BF', 'L3', {valueDecimals: 3, valueSuffix: ' kVAR'}, [{name: 'c_90_inst_VAR', channelId: 90}], 1 / 1000)
    ],
    {}
    );
  }
}
class TotalApparentPower extends LiveChart {
  
  constructor() {
    super('Total Apparent Power',
    [
      new LiveSeriesOptions('line', '#0050BF', '3-Ph', {valueDecimals: 3, valueSuffix: ' kVA'}, [{name: 'c_106_inst_kVA', channelId: 106}])
    ],
    {}
    );
  }
}

class TotalPowerFactor extends LiveChart {
  
  constructor() {
    super('Total Power Factor',
    [
      new LiveSeriesOptions('line', '#0050BF', '3-Ph', {valueDecimals: 3, valueSuffix: ''}, [{name: 'c_124_inst_', channelId: 124}])
    ],
    {}
    );
  }
}
class TotalActivePower extends LiveChart {
  
  constructor() {
    super('Total Active Power',
    [
      new LiveSeriesOptions('line', '#0050BF', '3-Ph', {valueDecimals: 3, valueSuffix: ' kW'}, [{name: 'c_70_inst_W', channelId: 70}], 1 / 1000)
    ],
    {}
    );
  }
}
class TotalReactivePower extends LiveChart {
  
  constructor() {
    super('Total Reactive Power',
    [
      new LiveSeriesOptions('line', '#0050BF', '3-Ph', {valueDecimals: 3, valueSuffix: ' kVAR'}, [{name: 'c_88_inst_kVAR', channelId: 88}])
    ],
    {}
    );
  }
}
class VoltageLN3PH extends LiveChart {
  
  constructor() {
    super('Voltage L-N (3-ph)',
    [
      new LiveSeriesOptions('line', '#0050BF', 'L-N', {valueDecimals: 2, valueSuffix: ' V'}, [{name: 'c_1597_inst_V', channelId: 1597}])
    ],
    {}
    );
  }
}
class VoltageLL3PH extends LiveChart {
  
  constructor() {
    super('Voltage L-L (3-ph)',
    [
      new LiveSeriesOptions('line', '#0050BF', 'L-L', {valueDecimals: 2, valueSuffix: ' V'}, [{name: 'c_1598_inst_V', channelId: 1598}])
    ],
    {}
    );
  }
}
class ThdV3Ph extends LiveChart {
  
  constructor() {
    super('THD-V (3-Ph)',
    [
      new LiveSeriesOptions('line', '#0050BF', '3Ph', {valueDecimals: 2, valueSuffix: ' %'}, [{name: 'c_1609_inst_%', channelId: 1609}])
    ],
    {}
    );
  }
}
class Tdd3Ph extends LiveChart {
  
  constructor() {
    super('TDD (3-Ph)',
    [
      new LiveSeriesOptions('line', '#0050BF', '3Ph', {valueDecimals: 2, valueSuffix: ' %'}, [{name: 'c_1610_inst_%', channelId: 1610}])
    ],
    {}
    );
  }
}
class FlickerPinst3Ph extends LiveChart {
  
  constructor() {
    super('Flicker Pinst (3-Ph)',
    [
      new LiveSeriesOptions('line', '#0050BF', '3Ph', {valueDecimals: 2, valueSuffix: ''}, [{name: 'c_1611_inst_', channelId: 1611}])
    ],
    {}
    );
  }
}
class FlickerPst3Ph extends LiveChart {
  
  constructor() {
    super('Flicker Pst (3-Ph)',
    [
      new LiveSeriesOptions('line', '#0050BF', '3Ph', {valueDecimals: 2, valueSuffix: ''}, [{name: 'c_1612_inst_', channelId: 1612}])
    ],
    {}
    );
  }
}
class EarthCurrent extends LiveChart {
  
  constructor() {
    super('Earth Current',
    [
      new LiveSeriesOptions('line', '#0050BF', 'E', {valueDecimals: 2, valueSuffix: ' A'}, [{name: 'c_20_inst_A', channelId: 20}])
    ],
    {}
    );
  }
}
class Frequency extends LiveChart {
  
  constructor() {
    super('Frequency',
    [
      new LiveSeriesOptions('line', '#0e9015', 'Freq', {valueDecimals: 3, valueSuffix: ' Hz'}, [{name: 'c_21_inst_Hz', channelId: 21}])
    ],
    {}
    );
  }
}
class VNegSeqUnbalance extends LiveChart {
  
  constructor() {
    super('V Neg Seq Unbalance',
    [
      new LiveSeriesOptions('line', '#0050BF', '', {valueDecimals: 2, valueSuffix: ' %'}, [{name: 'c_287_inst_%', channelId: 287}])
    ],
    {}
    );
  }
}
class VZeroSeqUnbalance extends LiveChart {
  
  constructor() {
    super('V Zero Sequence Unbalance',
    [
      new LiveSeriesOptions('line', '#0050BF', '', {valueDecimals: 2, valueSuffix: ' %'}, [{name: 'c_289_inst_%', channelId: 289}])
    ],
    {}
    );
  }
}
class INegSeqUnbalance extends LiveChart {
  
  constructor() {
    super('I Neg Seq Unbalance',
    [
      new LiveSeriesOptions('line', '#0050BF', '', {valueDecimals: 2, valueSuffix: ' %'}, [{name: 'c_288_inst_%', channelId: 288}])
    ],
    {}
    );
  }
}
class IZeroSeqUnbalance extends LiveChart {
  
  constructor() {
    super('I Zero Seq Unbalance',
    [
      new LiveSeriesOptions('line', '#0050BF', '', {valueDecimals: 2, valueSuffix: ' %'}, [{name: 'c_290_inst_%', channelId: 290}])
    ],
    {}
    );
  }
}
class VoltageNE extends LiveChart {
  
  constructor() {
    super('Voltage N-E',
    [
      new LiveSeriesOptions('line', '#536A84', 'N-E', {valueDecimals: 2, valueSuffix: ' V'}, [{name: 'c_3_inst_V', channelId: 3}])
    ],
    {}
    );
  }
}
class NeutralCurrent extends LiveChart {
  
  constructor() {
    super('Neutral Current',
    [
      new LiveSeriesOptions('line', '#536A84', 'N', {valueDecimals: 2, valueSuffix: ' A'}, [{name: 'c_19_inst_A', channelId: 19}])
    ],
    {}
    );
  }
}
class VHarmonics extends LiveChart {
  constructor(public sName: string, public iChannel1: number, public iChannel2: number, public iChannel3: number) {
    super(name,
    [
      new LiveSeriesOptions('line', '#FF515B', 'V-' + sName + '(L1)', {valueDecimals: 2, valueSuffix: ' %'}, [{name: 'c_' + iChannel1.toString() + '_inst_%', channelId: iChannel1}]),
      new LiveSeriesOptions('line', '#FFBE08', 'V-' + sName + '(L2)', {valueDecimals: 2, valueSuffix: ' %'}, [{name: 'c_' + iChannel2.toString() + '_inst_%', channelId: iChannel2}]),
      new LiveSeriesOptions('line', '#0050BF', 'V-' + sName + '(L3)', {valueDecimals: 2, valueSuffix: ' %'}, [{name: 'c_' + iChannel3.toString() + '_inst_%', channelId: iChannel3}])
    ],
    {}
    );
  }
}
class IHarmonics extends LiveChart {
  constructor(public sName: string, public iChannel1: number, public iChannel2: number, public iChannel3: number) {
    super(name,
    [
      new LiveSeriesOptions('line', '#FF515B', 'I-' + sName + '(L1)', {valueDecimals: 2, valueSuffix: ' A'}, [{name: 'c_' + iChannel1.toString() + '_inst_A', channelId: iChannel1}]),
      new LiveSeriesOptions('line', '#FFBE08', 'I-' + sName + '(L2)', {valueDecimals: 2, valueSuffix: ' A'}, [{name: 'c_' + iChannel2.toString() + '_inst_A', channelId: iChannel2}]),
      new LiveSeriesOptions('line', '#0050BF', 'I-' + sName + '(L3)', {valueDecimals: 2, valueSuffix: ' A'}, [{name: 'c_' + iChannel3.toString() + '_inst_A', channelId: iChannel3}])
    ],
    {}
    );
  }
}
class MiscMeter extends LiveChart {
  constructor(public legend: string, public units: string, public iChannelID: number, public pName: string) {
    super(name,
    [
      new LiveSeriesOptions('line', '#FF515B', legend, {valueDecimals: 2, valueSuffix: ' ' + units}, [{name: pName, channelId: iChannelID}]),
    ],
    {}
    );
  }
}

// class VoltageFluctuationsMeter extends LiveMeter {
  
//   constructor() {
//     super('Voltage Fluctuations',
//     [
//       new LiveMeterSeriesOptions('solidgauge', '#0050BF', 'L3-L1', 'V', {valueDecimals: 3, valueSuffix: ' V'}, [{name: 'c_9_inst_V', channelId: 9}]),
//       new LiveMeterSeriesOptions('solidgauge', '#FFBE08', 'L2-L3', 'V', {valueDecimals: 3, valueSuffix: ' V'}, [{name: 'c_8_inst_V', channelId: 8}]),
//       new LiveMeterSeriesOptions('solidgauge', '#FF515B', 'L1-L2', 'V', {valueDecimals: 3, valueSuffix: ' V'}, [{name: 'c_7_inst_V', channelId: 7}])
//     ],
//     {}
//     );
//     super.seriesArray[0].dataLabels.y = -150;
//     super.seriesArray[0].dataLabels.x = 0;
//     super.seriesArray[1].dataLabels.y = -122;
//     super.seriesArray[1].dataLabels.x = 0;
//     super.seriesArray[2].dataLabels.x = 0;
//     super.seriesArray[2].dataLabels.y = -91;

//   }
// }

// class VoltageFluctuationsMeter extends LiveMeter {
  
//   constructor() {
//     super('Voltage Fluctuations',
//     [
//       new LiveMeterSeriesOptions('solidgauge', '#0050BF', 'L3-L1', 'V', {valueDecimals: 3, valueSuffix: ' V'}, [{name: 'c_9_inst_V', channelId: 9}]),
//       new LiveMeterSeriesOptions('solidgauge', '#FFBE08', 'L2-L3', 'V', {valueDecimals: 3, valueSuffix: ' V'}, [{name: 'c_8_inst_V', channelId: 8}]),
//       new LiveMeterSeriesOptions('solidgauge', '#FF515B', 'L1-L2', 'V', {valueDecimals: 3, valueSuffix: ' V'}, [{name: 'c_7_inst_V', channelId: 7}])
//     ],
//     {}
//     );
//     super.seriesArray[0].dataLabels.y = -150;
//     super.seriesArray[0].dataLabels.x = 0;
//     super.seriesArray[1].dataLabels.y = -122;
//     super.seriesArray[1].dataLabels.x = 0;
//     super.seriesArray[2].dataLabels.x = 0;
//     super.seriesArray[2].dataLabels.y = -91;

//   }
// }

export class LiveChartFactory {

  public static getCustomChannel(
    name: string, 
    meterParam: string,
    unit: string, 
    unitOffset: number, 
    channelID: number,
    factoryName: string
  ) {
    if (meterParam)
      return new CustomChannel(name, meterParam, unit, unitOffset, channelID, factoryName);
    else
      return null;   
  }

  public static createChart(chartName: string, legend?: string, units?: string, channelID?: number, pName?: string): {chartInstance: ILiveChart} {
    let chartInstance: ILiveChart;
    // let meterInstance: ILiveChart;

    switch (chartName) {
      // case 'VoltageFluctuations':
      //   chartInstance = new VoltageFluctuationsChart();
      //   break;
      case 'PowerFactor':
        chartInstance = new PowerFactorChart();
        break;
      case 'TotalApparentPower':
        chartInstance = new TotalApparentPower();
        break;
      case 'TotalPowerFactor':
        chartInstance = new TotalPowerFactor();
        break;
      case 'VoltageLN3PH':
        chartInstance = new VoltageLN3PH();
        break;
      case 'VoltageLL3PH':
        chartInstance = new VoltageLL3PH();
        break;
      case 'PhaseCurrent': 
        chartInstance = new PhaseCurrent();
        break;
      case 'ThdV3Ph': 
        chartInstance = new ThdV3Ph();
        break;
      case 'ThdV': 
        chartInstance = new ThdV();
        break;
      case 'Tdd3Ph': 
        chartInstance = new Tdd3Ph();
        break;
      case 'Tdd': 
        chartInstance = new Tdd();
        break;
      case 'FlickerPinst3Ph': 
        chartInstance = new FlickerPinst3Ph();
        break;
      case 'FlickerPst3Ph': 
        chartInstance = new FlickerPst3Ph();
        break;
      case 'FlickerPinst': 
        chartInstance = new FlickerPinst();
        break;
      case 'FlickerPst': 
        chartInstance = new FlickerPst();
        break;
      case 'FlickerPlt': 
        chartInstance = new FlickerPlt();
        break;
      case 'EarthCurrent': 
        chartInstance = new EarthCurrent();
        break;
      case 'Frequency': 
        chartInstance = new Frequency();
        break;
      case 'VNegSeqUnbalance': 
        chartInstance = new VNegSeqUnbalance();
        break;
      case 'INegSeqUnbalance': 
        chartInstance = new INegSeqUnbalance();
        break;
      case 'VZeroSeqUnbalance': 
        chartInstance = new VZeroSeqUnbalance();
        break;
      case 'IZeroSeqUnbalance': 
        chartInstance = new IZeroSeqUnbalance();
        break;
      case 'VoltageNE': 
        chartInstance = new VoltageNE();
        break;
      case 'VoltageLN': 
        chartInstance = new VoltageLN();
        break;
      case 'VoltageLL': 
        chartInstance = new VoltageLL();
        break;
      case 'ActivePower': 
        chartInstance = new ActivePower();
        break;
      case 'TotalActivePower': 
        chartInstance = new TotalActivePower();
        break;
      case 'ReactivePower':
        chartInstance = new ReactivePower();
        break;
      case 'TotalReactivePower': 
        chartInstance = new TotalReactivePower();
        break;
      case 'NeutralCurrent': 
        chartInstance = new NeutralCurrent();
        break;
      case 'VH2': 
        chartInstance = new VHarmonics('H2', 513, 563, 613);
        break;
      case 'VH3': 
        chartInstance = new VHarmonics('H3', 514, 564, 614);
        break;        
      case 'VH4':
        chartInstance = new VHarmonics('H4', 515, 565, 615);
        break;
      case 'VH5': 
        chartInstance = new VHarmonics('H5', 516, 566, 616);
        break;
      case 'VH6': 
        chartInstance = new VHarmonics('H6', 517, 567, 617);
        break;        
      case 'VH7':
        chartInstance = new VHarmonics('H7', 518, 568, 618);
        break;
      case 'VH8': 
        chartInstance = new VHarmonics('H8', 519, 569, 619);
        break;
      case 'VH9': 
        chartInstance = new VHarmonics('H9', 520, 570, 620);
        break;        
      case 'VH10':
        chartInstance = new VHarmonics('H10', 521, 571, 621);
        break;
      case 'VH11': 
        chartInstance = new VHarmonics('H11', 522, 572, 622);
        break;
      case 'VH12': 
        chartInstance = new VHarmonics('H12', 523, 573, 623);
        break;        
      case 'VH13': 
        chartInstance = new VHarmonics('H13', 524, 574, 624);
        break;        
      case 'VH14':
        chartInstance = new VHarmonics('H14', 525, 575, 625);
        break;
      case 'VH15': 
        chartInstance = new VHarmonics('H15', 526, 576, 626);
        break;
      case 'VH16': 
        chartInstance = new VHarmonics('H16', 527, 577, 627);
        break;        
      case 'VH17': 
        chartInstance = new VHarmonics('H17', 528, 578, 628);
        break;
      case 'VH18': 
        chartInstance = new VHarmonics('H18', 529, 579, 629);
        break;        
      case 'VH19':
        chartInstance = new VHarmonics('H19', 530, 580, 630);
        break;
      case 'VH20':
          chartInstance = new VHarmonics('H20', 531, 581, 631);
          break;
      case 'VH21': 
          chartInstance = new VHarmonics('H21', 532, 582, 632);
          break;
      case 'VH22': 
        chartInstance = new VHarmonics('H22', 533, 583, 633);
        break;
      case 'VH23': 
        chartInstance = new VHarmonics('H23', 534, 584, 634);
        break;        
      case 'VH24':
        chartInstance = new VHarmonics('H24', 535, 585, 635);
        break;
      case 'VH25': 
        chartInstance = new VHarmonics('H25', 536, 586, 636);
        break;
      case 'VH26': 
        chartInstance = new VHarmonics('H26', 537, 587, 637);
        break;        
      case 'VH27':
        chartInstance = new VHarmonics('H27', 538, 588, 638);
        break;
      case 'VH28': 
        chartInstance = new VHarmonics('H28', 539, 589, 639);
        break;
      case 'VH29': 
        chartInstance = new VHarmonics('H29', 540, 590, 640);
        break;        
      case 'VH30':
        chartInstance = new VHarmonics('H30', 541, 591, 641);
        break;
      case 'VH31': 
        chartInstance = new VHarmonics('H31', 542, 592, 642);
        break;
      case 'VH32': 
        chartInstance = new VHarmonics('H32', 543, 593, 643);
        break;
      case 'VH33': 
        chartInstance = new VHarmonics('H33', 544, 594, 644);
        break;        
      case 'VH34':
        chartInstance = new VHarmonics('H34', 545, 595, 645);
        break;
      case 'VH35': 
        chartInstance = new VHarmonics('H35', 546, 596, 646);
        break;
      case 'VH36': 
        chartInstance = new VHarmonics('H36', 547, 597, 647);
        break;        
      case 'VH37':
        chartInstance = new VHarmonics('H37', 548, 598, 648);
        break;
      case 'VH38': 
        chartInstance = new VHarmonics('H38', 549, 599, 649);
        break;
      case 'VH39': 
        chartInstance = new VHarmonics('H39', 550, 600, 650);
        break;        
      case 'VH40':
        chartInstance = new VHarmonics('H40', 551, 601, 651);
        break;
      case 'VH41': 
        chartInstance = new VHarmonics('H41', 552, 602, 652);
        break;
      case 'VH42': 
        chartInstance = new VHarmonics('H42', 553, 603, 653);
        break;
      case 'VH43': 
        chartInstance = new VHarmonics('H43', 554, 604, 654);
        break;        
      case 'VH44':
        chartInstance = new VHarmonics('H44', 555, 605, 655);
        break;
      case 'VH45': 
        chartInstance = new VHarmonics('H45', 556, 606, 656);
        break;
      case 'VH46': 
        chartInstance = new VHarmonics('H46', 557, 607, 657);
        break;        
      case 'VH47':
        chartInstance = new VHarmonics('H47', 558, 608, 658);
        break;
      case 'VH48': 
        chartInstance = new VHarmonics('H48', 559, 609, 659);
        break;
      case 'VH49': 
        chartInstance = new VHarmonics('H49', 560, 610, 660);
        break;        
      case 'VH50':
        chartInstance = new VHarmonics('H50', 561, 611, 661);
        break;
      case 'IH2': 
        chartInstance = new IHarmonics('H2', 663, 713, 763);
        break;
      case 'IH3': 
        chartInstance = new IHarmonics('H3', 664, 714, 764);
        break;        
      case 'IH4':
        chartInstance = new IHarmonics('H4', 665, 715, 765);
        break;
      case 'IH5': 
        chartInstance = new IHarmonics('H5', 666, 716, 766);
        break;
      case 'IH6': 
        chartInstance = new IHarmonics('H6', 667, 717, 767);
        break;        
      case 'IH7':
        chartInstance = new IHarmonics('H7', 668, 718, 768);
        break;
      case 'IH8': 
        chartInstance = new IHarmonics('H8', 669, 719, 769);
        break;
      case 'IH9': 
        chartInstance = new IHarmonics('H9', 670, 720, 770);
        break;        
      case 'IH10':
        chartInstance = new IHarmonics('H10', 671, 721, 771);
        break;
      case 'IH11': 
        chartInstance = new IHarmonics('H11', 672, 722, 772);
        break;
      case 'IH12': 
        chartInstance = new IHarmonics('H12', 673, 723, 773);
        break;        
      case 'IH13': 
        chartInstance = new IHarmonics('H13', 674, 724, 774);
        break;        
      case 'IH14':
        chartInstance = new IHarmonics('H14', 675, 725, 775);
        break;
      case 'IH15': 
        chartInstance = new IHarmonics('H15', 676, 726, 776);
        break;
      case 'IH16': 
        chartInstance = new IHarmonics('H16', 677, 727, 777);
        break;        
      case 'IH17': 
        chartInstance = new IHarmonics('H17', 678, 728, 778);
        break;
      case 'IH18': 
        chartInstance = new IHarmonics('H18', 679, 729, 779);
        break;        
      case 'IH19':
        chartInstance = new IHarmonics('H19', 680, 730, 780);
        break;
      case 'IH20':
          chartInstance = new IHarmonics('H20', 681, 731, 781);
          break;
      case 'IH21': 
          chartInstance = new IHarmonics('H21', 682, 732, 782);
          break;
      case 'IH22': 
        chartInstance = new IHarmonics('H22', 683, 733, 783);
        break;
      case 'IH23': 
        chartInstance = new IHarmonics('H23', 684, 734, 784);
        break;        
      case 'IH24':
        chartInstance = new IHarmonics('H24', 685, 735, 785);
        break;
      case 'IH25': 
        chartInstance = new IHarmonics('H25', 686, 736, 786);
        break;
      case 'IH26': 
        chartInstance = new IHarmonics('H26', 687, 737, 787);
        break;        
      case 'IH27':
        chartInstance = new IHarmonics('H27', 688, 738, 788);
        break;
      case 'IH28': 
        chartInstance = new IHarmonics('H28', 689, 739, 789);
        break;
      case 'IH29': 
        chartInstance = new IHarmonics('H29', 690, 740, 790);
        break;        
      case 'IH30':
        chartInstance = new IHarmonics('H30', 691, 741, 791);
        break;
      case 'IH31': 
        chartInstance = new IHarmonics('H31', 692, 742, 792);
        break;
      case 'IH32': 
        chartInstance = new IHarmonics('H32', 693, 743, 793);
        break;
      case 'IH33': 
        chartInstance = new IHarmonics('H33', 694, 744, 794);
        break;        
      case 'IH34':
        chartInstance = new IHarmonics('H34', 695, 745, 795);
        break;
      case 'IH35': 
        chartInstance = new IHarmonics('H35', 696, 746, 796);
        break;
      case 'IH36': 
        chartInstance = new IHarmonics('H36', 697, 747, 797);
        break;        
      case 'IH37':
        chartInstance = new IHarmonics('H37', 698, 748, 798);
        break;
      case 'IH38': 
        chartInstance = new IHarmonics('H38', 699, 749, 799);
        break;
      case 'IH39': 
        chartInstance = new IHarmonics('H39', 700, 750, 800);
        break;        
      case 'IH40':
        chartInstance = new IHarmonics('H40', 701, 751, 801);
        break;
      case 'IH41': 
        chartInstance = new IHarmonics('H41', 702, 752, 802);
        break;
      case 'IH42': 
        chartInstance = new IHarmonics('H42', 703, 753, 803);
        break;
      case 'IH43': 
        chartInstance = new IHarmonics('H43', 704, 754, 804);
        break;        
      case 'IH44':
        chartInstance = new IHarmonics('H44', 705, 755, 805);
        break;
      case 'IH45': 
        chartInstance = new IHarmonics('H45', 706, 756, 806);
        break;
      case 'IH46': 
        chartInstance = new IHarmonics('H46', 707, 757, 807);
        break;        
      case 'IH47':
        chartInstance = new IHarmonics('H47', 708, 758, 808);
        break;
      case 'IH48': 
        chartInstance = new IHarmonics('H48', 709, 759, 809);
        break;
      case 'IH49': 
        chartInstance = new IHarmonics('H49', 710, 760, 810);
        break;
      case 'IH50': 
        chartInstance = new IHarmonics('H50', 711, 761, 811);
        break;
      case 'VIH1': 
        chartInstance = new VHarmonics('IH1', 812, 862, 912);
        break;  
      case 'VIH2': 
        chartInstance = new VHarmonics('IH2', 813, 863, 913);
        break;
      case 'VIH3': 
        chartInstance = new VHarmonics('IH3', 814, 864, 914);
        break;        
      case 'VIH4':
        chartInstance = new VHarmonics('IH4', 815, 865, 915);
        break;
      case 'VIH5': 
        chartInstance = new VHarmonics('IH5', 816, 866, 916);
        break;
      case 'VIH6': 
        chartInstance = new VHarmonics('IH6', 817, 867, 917);
        break;        
      case 'VIH7':
        chartInstance = new VHarmonics('IH7', 818, 868, 918);
        break;
      case 'VIH8': 
        chartInstance = new VHarmonics('IH8', 819, 869, 919);
        break;
      case 'VIH9': 
        chartInstance = new VHarmonics('IH9', 820, 870, 920);
        break;        
      case 'VIH10':
        chartInstance = new VHarmonics('IH10', 821, 871, 921);
        break;
      case 'VIH11': 
        chartInstance = new VHarmonics('IH11', 822, 872, 922);
        break;
      case 'VIH12': 
        chartInstance = new VHarmonics('IH12', 823, 873, 923);
        break;        
      case 'VIH13': 
        chartInstance = new VHarmonics('IH13', 824, 874, 924);
        break;        
      case 'VIH14':
        chartInstance = new VHarmonics('IH14', 825, 875, 925);
        break;
      case 'VIH15': 
        chartInstance = new VHarmonics('IH15', 826, 876, 926);
        break;
      case 'VIH16': 
        chartInstance = new VHarmonics('IH16', 827, 877, 927);
        break;        
      case 'VIH17': 
        chartInstance = new VHarmonics('IH17', 828, 878, 928);
        break;
      case 'VIH18': 
        chartInstance = new VHarmonics('IH18', 829, 879, 929);
        break;        
      case 'VIH19':
        chartInstance = new VHarmonics('IH19', 830, 880, 930);
        break;
      case 'VIH20':
        chartInstance = new VHarmonics('IH20', 831, 881, 931);
        break;
      case 'VIH21': 
        chartInstance = new VHarmonics('IH21', 832, 882, 932);
        break;
      case 'VIH22': 
        chartInstance = new VHarmonics('IH22', 833, 883, 933);
        break;
      case 'VIH23': 
        chartInstance = new VHarmonics('IH23', 834, 884, 934);
        break;        
      case 'VIH24':
        chartInstance = new VHarmonics('IH24', 835, 885, 935);
        break;
      case 'VIH25': 
        chartInstance = new VHarmonics('IH25', 836, 886, 936);
        break;
      case 'VIH26': 
        chartInstance = new VHarmonics('IH26', 837, 887, 937);
        break;        
      case 'VIH27':
        chartInstance = new VHarmonics('IH27', 838, 888, 938);
        break;
      case 'VIH28': 
        chartInstance = new VHarmonics('IH28', 839, 889, 939);
        break;
      case 'VIH29': 
        chartInstance = new VHarmonics('IH29', 840, 890, 940);
        break;        
      case 'VIH30':
        chartInstance = new VHarmonics('IH30', 841, 891, 941);
        break;
      case 'VIH31': 
        chartInstance = new VHarmonics('IH31', 842, 892, 942);
        break;
      case 'VIH32': 
        chartInstance = new VHarmonics('IH32', 843, 893, 943);
        break;
      case 'VIH33': 
        chartInstance = new VHarmonics('IH33', 844, 894, 944);
        break;        
      case 'VIH34':
        chartInstance = new VHarmonics('IH34', 845, 895, 945);
        break;
      case 'VIH35': 
        chartInstance = new VHarmonics('IH35', 846, 896, 946);
        break;
      case 'VIH36': 
        chartInstance = new VHarmonics('IH36', 847, 897, 947);
        break;        
      case 'VIH37':
        chartInstance = new VHarmonics('IH37', 848, 898, 948);
        break;
      case 'VIH38': 
        chartInstance = new VHarmonics('IH38', 849, 899, 949);
        break;
      case 'VIH39': 
        chartInstance = new VHarmonics('IH39', 850, 900, 950);
        break;        
      case 'VIH40':
        chartInstance = new VHarmonics('IH40', 851, 901, 951);
        break;
      case 'VIH41': 
        chartInstance = new VHarmonics('IH41', 852, 902, 952);
        break;
      case 'VIH42': 
        chartInstance = new VHarmonics('IH42', 853, 903, 953);
        break;
      case 'VIH43': 
        chartInstance = new VHarmonics('IH43', 854, 904, 954);
        break;        
      case 'VIH44':
        chartInstance = new VHarmonics('IH44', 855, 905, 955);
        break;
      case 'VIH45': 
        chartInstance = new VHarmonics('IH45', 856, 906, 956);
        break;
      case 'VIH46': 
        chartInstance = new VHarmonics('IH46', 857, 907, 957);
        break;        
      case 'VIH47':
        chartInstance = new VHarmonics('IH47', 858, 908, 958);
        break;
      case 'VIH48': 
        chartInstance = new VHarmonics('IH48', 859, 909, 959);
        break;
      case 'VIH49': 
        chartInstance = new VHarmonics('IH49', 860, 910, 960);
        break;        
      case 'IIH1': 
        chartInstance = new IHarmonics('IH1', 962, 1012, 1062);
        break;
      case 'IIH2': 
        chartInstance = new IHarmonics('IH2', 963, 1013, 1063);
        break;
      case 'IIH3': 
        chartInstance = new IHarmonics('IH3', 964, 1014, 1064);
        break;        
      case 'IIH4':
        chartInstance = new IHarmonics('IH4', 965, 1015, 1065);
        break;
      case 'IIH5': 
        chartInstance = new IHarmonics('IH5', 966, 1016, 1066);
        break;
      case 'IIH6': 
        chartInstance = new IHarmonics('IH6', 967, 1017, 1067);
        break;        
      case 'IIH7':
        chartInstance = new IHarmonics('IH7', 968, 1018, 1068);
        break;
      case 'IIH8': 
        chartInstance = new IHarmonics('IH8', 969, 1019, 1069);
        break;
      case 'IIH9': 
        chartInstance = new IHarmonics('IH9', 970, 1020, 1070);
        break;        
      case 'IIH10':
        chartInstance = new IHarmonics('IH10', 971, 1021, 1071);
        break;
      case 'IIH11': 
        chartInstance = new IHarmonics('IH11', 972, 1022, 1072);
        break;
      case 'IIH12': 
        chartInstance = new IHarmonics('IH12', 973, 1023, 1073);
        break;        
      case 'IIH13': 
        chartInstance = new IHarmonics('IH13', 974, 1024, 1074);
        break;        
      case 'IIH14':
        chartInstance = new IHarmonics('IH14', 975, 1025, 1075);
        break;
      case 'IIH15': 
        chartInstance = new IHarmonics('IH15', 976, 1026, 1076);
        break;
      case 'IIH16': 
        chartInstance = new IHarmonics('IH16', 977, 1027, 1077);
        break;        
      case 'IIH17': 
        chartInstance = new IHarmonics('IH17', 978, 1028, 1078);
        break;
      case 'IIH18': 
        chartInstance = new IHarmonics('IH18', 979, 1029, 1079);
        break;        
      case 'IIH19':
        chartInstance = new IHarmonics('IH19', 980, 1030, 1080);
        break;
      case 'IIH20':
        chartInstance = new IHarmonics('IH20', 981, 1031, 1081);
        break;
      case 'IIH21': 
        chartInstance = new IHarmonics('IH21', 982, 1032, 1082);
        break;
      case 'IIH22': 
        chartInstance = new IHarmonics('IH22', 983, 1033, 1083);
        break;
      case 'IIH23': 
        chartInstance = new IHarmonics('IH23', 984, 1034, 1084);
        break;        
      case 'IIH24':
        chartInstance = new IHarmonics('IH24', 985, 1035, 1085);
        break;
      case 'IIH25': 
        chartInstance = new IHarmonics('IH25', 986, 1036, 1086);
        break;
      case 'IIH26': 
        chartInstance = new IHarmonics('IH26', 987, 1037, 1087);
        break;        
      case 'IIH27':
        chartInstance = new IHarmonics('IH27', 988, 1038, 1088);
        break;
      case 'IIH28': 
        chartInstance = new IHarmonics('IH28', 989, 1039, 1089);
        break;
      case 'IIH29': 
        chartInstance = new IHarmonics('IH29', 990, 1040, 1090);
        break;        
      case 'IIH30':
        chartInstance = new IHarmonics('IH30', 991, 1041, 1091);
        break;
      case 'IIH31': 
        chartInstance = new IHarmonics('IH31', 992, 1042, 1092);
        break;
      case 'IIH32': 
        chartInstance = new IHarmonics('IH32', 993, 1043, 1093);
        break;
      case 'IIH33': 
        chartInstance = new IHarmonics('IH33', 994, 1044, 1094);
        break;        
      case 'IIH34':
        chartInstance = new IHarmonics('IH34', 995, 1045, 1095);
        break;
      case 'IIH35': 
        chartInstance = new IHarmonics('IH35', 996, 1046, 1096);
        break;
      case 'IIH36': 
        chartInstance = new IHarmonics('IH36', 997, 1047, 1097);
        break;        
      case 'IIH37':
        chartInstance = new IHarmonics('IH37', 998, 1048, 1098);
        break;
      case 'IIH38': 
        chartInstance = new IHarmonics('IH38', 999, 1049, 1099);
        break;
      case 'IIH39': 
        chartInstance = new IHarmonics('IH39', 1000, 1050, 1100);
        break;        
      case 'IIH40':
        chartInstance = new IHarmonics('IH40', 1001, 1051, 1101);
        break;
      case 'IIH41': 
        chartInstance = new IHarmonics('IH41', 1002, 1052, 1102);
        break;
      case 'IIH42': 
        chartInstance = new IHarmonics('IH42', 1003, 1053, 1103);
        break;
      case 'IIH43': 
        chartInstance = new IHarmonics('IH43', 1004, 1054, 1104);
        break;        
      case 'IIH44':
        chartInstance = new IHarmonics('IH44', 1005, 1055, 1105);
        break;
      case 'IIH45': 
        chartInstance = new IHarmonics('IH45', 1006, 1056, 1106);
        break;
      case 'IIH46': 
        chartInstance = new IHarmonics('IH46', 1007, 1057, 1107);
        break;        
      case 'IIH47':
        chartInstance = new IHarmonics('IH47', 1008, 1058, 1108);
        break;
      case 'IIH48': 
        chartInstance = new IHarmonics('IH48', 1009, 1059, 1109);
        break;
      case 'IIH49': 
        chartInstance = new IHarmonics('IH49', 1010, 1060, 1110);
        break;
      case 'misc': 
        chartInstance = new MiscMeter(legend, units, channelID, pName);
        break;

    }
    return { 
      chartInstance
      // meterInstance 
    };
  }



  constructor() { }

}