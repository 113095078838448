import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import * as moment from 'moment';

import { UtilitiesService } from '../shared/services/utilities.service';
import { LoaderService } from '../shared/services';
import { IEvent } from '../shared/classes/event';
import { MeasurementPoint } from '../shared/classes/MeasurementPoint';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '../shared/classes/component-destroy.class';
import { MeasurementPointsService } from '../shared/services/measurement-points.service';

@Component({
  selector: 'app-event-graph',
  templateUrl: './event-graph.component.html',
  styleUrls: ['./event-graph.component.scss'],
})
export class EventGraphComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  public event: IEvent;
  public mp: MeasurementPoint;
  public images: Array<string> = [];

  constructor(
    private route: ActivatedRoute,
    private mpService: MeasurementPointsService,
    private loaderService: LoaderService,
    private utilitiesService: UtilitiesService
  ) {
    super();
  }

  ngOnInit() {
    this.route.paramMap
      .pipe(untilComponentDestroyed(this))
      .subscribe(async (params: ParamMap) => {
        this.loaderService.show();

        const mp = this.mpService.selectedMeasurementPoint;

        if (mp) {
          this.mp = mp;
        }

        const eMoment = moment(params.get('triggeredWhen'));
        let events;
        this.mpService
          .getEventsData(
            params.get('mpId'),
            params.get('accountId'),
            eMoment.clone().subtract(1, 'second'),
            eMoment.add(1, 'second'),
            parseInt(params.get('deviceEventTypeId'), 10),
            false
          )
          .subscribe(async (newEvents) => {
            events = newEvents;

            if (events.length > 0) {
              this.event = events[0];

              this.event.triggeredWhen = moment(
                this.event.triggeredWhen,
                'YYYY-MM-DDTHH:mm:SSSZ'
              )
                .tz(this.mpService.selectedMeasurementPoint.timezone)
                .format('MMMM D, YYYY h:mm A');
            }

            const images = await this.mpService.getEventImages(
              parseInt(params.get('deviceEventId'), 10),
              2592000
            );

            this.images = images;

            this.loaderService.hide();
          });
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  public downloadImage(imageUrl): void {
    this.loaderService.show();
    this.utilitiesService.forceDownload(imageUrl + '&dummyString=null').then(
      () => {
        this.loaderService.hide();
      },
      (error: any) => {
        this.loaderService.hide();
      }
    );
  }
}
