import { Injectable } from '@angular/core';
import { DiligentApiService } from './diligent-api.service';

@Injectable()
export class PowerQualityMeasuresService {
  constructor(
    private diligent: DiligentApiService,
  ) {
  }

  public async getPowerQualityMeasures(mpId: number) {
    return this.diligent.getPowerQualityMeasures(mpId);
  }
}