<section class="tosa-container">
    <div class="tosa-title">
        <h3>Terms of Service</h3>
    </div>
    <embed src="./assets/documents/tosa.pdf" type="application/pdf" width="900px" height="400px" />
    <div class="flex-row flex-start">
        <form>
            <mat-radio-group [(ngModel)]="tosaAccepted" name="tosaGroup">
                <mat-radio-button [value]="true" data-cy="tosa-accepted" name="tosaGroup">{{ 'tosa.agree' | translate }}</mat-radio-button>
                <mat-radio-button [value]="false" name="tosaGroup">{{ 'tosa.disagree' | translate }}</mat-radio-button>
            </mat-radio-group>
        </form>
    </div>
    <div class="flex-row flex-end">
        <button mat-flat-button color="accent" data-cy="complete-tosa" (click)="completeTosa()" [disabled]="tosaAccepted === null">{{ 'tosa.button' | translate }}</button>
    </div>
</section>