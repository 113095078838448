import { Inject, Injectable, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { IEvent } from './event';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import {
  ChartDefinitions,
  replaceAdvancedChart,
  PQEvents,
  NotesChart,
} from '../services/chart-definitions.service';
import { NotesService } from '../services/notes.service';
import { CustomChartTypes } from './customer-chart-types.class';
import { DiligentApiService } from '../services/diligent-api.service';
import * as fromChannels from './../../_store/_reducers';
import { Channels } from './channels.interface';
import { ITrendsChart, ISeriesOptions } from './chart.interface';
import { NotificationsService } from '../modules/notifications/shared/notifications.service';
import { PhaseList } from './phase-list.interface';

@Injectable()
export class ChartSet implements OnDestroy {
  private name: string;
  private loadedDataStartDate: moment.Moment;
  private loadedDataEndDate: moment.Moment;
  private mCharts: ITrendsChart[];
  private chartDefinitions: ChartDefinitions;
  private dAPIService: DiligentApiService;
  private notes: NotesService;
  private oneMinuteColumns: string[];
  private tenMinuteColumns: string[];
  private loadEventsGraph = false;
  private channelsSub$: Subscription;
  private channels: Channels;

  public get charts() {
    return this.mCharts;
  }

  public set charts(newCharts: ITrendsChart[]) {
    this.mCharts = newCharts;
  }

  constructor(
    notes: NotesService,
    @Inject(String) name: string,
    chartKeys: Array<any>,
    chartDefinitions: ChartDefinitions,
    dAPIService: DiligentApiService,
    phaseList: Array<PhaseList>,
    store: Store<fromChannels.State>,
    notificationsService: NotificationsService
  ) {
    this.chartDefinitions = chartDefinitions;
    this.name = name;

    this.channelsSub$ = store
      .pipe(select(fromChannels.getChannels))
      .subscribe((channels: Channels) => {
        this.channels = channels;
      });

    this.charts = chartKeys.map((key, index) => {
      let chartHeightMultiplier: number;
      let newChart;
      if (this.chartDefinitions[key]) {
        newChart = this.chartDefinitions[key];
      } else {
        if (this.channels && this.channels['8'][key]) {
          newChart = this.chartDefinitions.getDynamic(
            this.channels['8'][key].name,
            this.channels['8'][key].trendParam,
            this.channels['8'][key].unit,
            this.channels['8'][key].unitOffset
          );
        } else {
          return null;
        }
      }

      switch (chartKeys.length) {
        case 2:
          chartHeightMultiplier = 2.5;
          break;
        case 3:
          chartHeightMultiplier = 1.5;
          break;
        default:
          chartHeightMultiplier = 1;
          break;
      }

      if (!(newChart instanceof PQEvents || newChart instanceof NotesChart)) {
        newChart.yAxisOptions[0].height *= chartHeightMultiplier;
        if (newChart.yAxisOptions.length > 1) {
          newChart.yAxisOptions[1].height *= chartHeightMultiplier;
        }
      }

      newChart.spacer *= chartHeightMultiplier;

      newChart.yAxis = index;
      // replase phase for AdvancedChart
      if (phaseList) {
        phaseList.forEach((c) => {
          if (CustomChartTypes.customChartTypes[c.chart][0] === key) {
            const chartADV = replaceAdvancedChart(
              newChart.name,
              c.phase,
              newChart.seriesArray,
              c.phase
            );
            chartADV.replaceChannelIds(c.phase);
            chartADV.setName(c.phase);
            newChart.yAxisOptions[0].title = chartADV.yAxisOptions[0].title;
            newChart.seriesArray = chartADV.seriesArray;
          }
        });
      }
      return newChart;
    });

    if (this.charts.indexOf(null) !== -1) {
      notificationsService.alert(
        "This Measurement Point doesn't have at least one of the channels selected in this custom tab"
      );
      this.charts = this.charts.filter((chart) => chart !== null);
    }

    this.dAPIService = dAPIService;
    this.notes = notes;
    this.oneMinuteColumns = this.charts
      .filter((chart) => {
        if (chart instanceof PQEvents || chart instanceof NotesChart || chart === null) {
          this.loadEventsGraph = true;
          return false;
        } else return true;
      })
      .map((chart) => {
        return chart.oneMinuteChannelIds;
      })
      .reduce((prev, curr) => prev.concat(curr), []);

    this.tenMinuteColumns = this.charts
      .filter((chart) => {
        if (chart instanceof PQEvents || chart instanceof NotesChart || chart === null) {
          return false;
        } else return true;
      })
      .map((chart) => chart.tenMinuteChannelIds)
      .reduce((prev, curr) => prev.concat(curr), []);
  }

  public ngOnDestroy(): void {
    this.channelsSub$.unsubscribe();
  }

  public clearChartData() {
    this.charts.forEach((chart) => chart.clearSeriesData());
  }

  public initializeChartSetData(
    startDate: moment.Moment,
    endDate: moment.Moment,
    granularity: string,
    accountNum: string,
    mpID: string,
    isQubeScan?: boolean
  ) {
    let eventsApiCall: Observable<IEvent[]> = of([]);
    this.loadedDataStartDate = startDate;
    this.loadedDataEndDate = endDate;

    const parsedTrendsResponse = this.fetchAndParseJSONTrendsData(
      startDate,
      endDate,
      granularity,
      mpID
    );

    if (this.loadEventsGraph)
      eventsApiCall = this.dAPIService.getEventsData(
        mpID,
        accountNum,
        startDate,
        endDate,
        -1,
        1000,
        !isQubeScan
      );

    const notesApiCall = this.notes.getNotes(parseInt(mpID, 10), startDate, endDate);

    return forkJoin([eventsApiCall, parsedTrendsResponse, notesApiCall]).pipe(
      map(([eventsResponse, trendsResponse, notesResponse]) => {
        return this.charts.map((chart) =>
          chart.setSeriesData(trendsResponse, eventsResponse, granularity, notesResponse)
        );
      })
    );
  }

  public replaceData(
    granularity: string,
    unitOfTime: moment.unitOfTime.DurationConstructor,
    accountNum: string,
    mpId: string,
    isQubeScan?: boolean
  ): Observable<ISeriesOptions[][]> {
    let eventsApiCall: Observable<IEvent[]> = of([]);

    // const functionalEndDate = this.loadedDataStartDate.clone();
    // const newStartDate = this.loadedDataStartDate.startOf(unitOfTime);

    // get data from api
    const trendsData = this.fetchAndParseCSVTrendsData(
      this.loadedDataStartDate,
      this.loadedDataEndDate,
      granularity,
      mpId
    );

    if (this.loadEventsGraph)
      eventsApiCall = this.dAPIService.getEventsData(
        mpId,
        accountNum,
        this.loadedDataStartDate,
        this.loadedDataEndDate,
        -1,
        1000,
        !isQubeScan
      );

    const notesApiCall = this.notes.getNotes(
      parseInt(mpId, 10),
      this.loadedDataStartDate,
      this.loadedDataEndDate
    );

    return forkJoin([trendsData, eventsApiCall, notesApiCall]).pipe(
      map(([tData, eData, nData]) =>
        this.charts.map((chart) => chart.replaceChartData(tData, eData, nData))
      )
    );
  }

  private parseTrendsCSVData(channelDataCSV: string) {
    const trendsData = channelDataCSV.split('\n').map((row) => row.split(','));

    const seriesDataArraysObj = {};
    const columns = trendsData.shift();
    const seriesDataArraysByColumn = columns.map(() => []);

    // assign arrays that
    this.charts.forEach((chart) => {
      if (!(chart instanceof PQEvents || chart instanceof NotesChart)) {
        chart.seriesArray.forEach((series, index) => {
          seriesDataArraysObj[series.id] = [];
          seriesDataArraysByColumn[0].push(seriesDataArraysObj[series.id]);
          columns.slice(1).forEach((columnName, columnIndex) => {
            if (series.channelIds.includes(columnName)) {
              seriesDataArraysByColumn[columnIndex + 1].push(
                seriesDataArraysObj[series.id]
              );
            }
          });
        });
      }
    });

    trendsData.forEach((row, rowNumber) => {
      row.forEach((value, columnNumber) => {
        if (columnNumber === 0) {
          const transformedValue = moment(value).utc(false).valueOf();
          seriesDataArraysByColumn[columnNumber].forEach((arr) =>
            arr.push([transformedValue])
          );
        } else {
          const transformedValue = parseFloat(value);
          seriesDataArraysByColumn[columnNumber].forEach((arr) => {
            arr[rowNumber].push(transformedValue);
          });
        }
      });
    });

    return seriesDataArraysObj;
  }

  private fetchAndParseJSONTrendsData(startDate, endDate, granularity, mpID) {
    if (this.tenMinuteColumns.length > 0 && this.oneMinuteColumns.length > 0)
      return forkJoin([
        this.dAPIService
          .getTrendsDataJson(startDate, endDate, granularity, this.oneMinuteColumns, mpID)
          .pipe(map((response) => this.parseTrendsJSONData(response))),
        this.dAPIService
          .getTrendsDataJson(
            startDate,
            endDate,
            granularity,
            this.tenMinuteColumns,
            mpID,
            'tenminute'
          )
          .pipe(map((response) => this.parseTrendsJSONData(response))),
      ]).pipe(
        map(([oneminute, tenminute]) => {
          return {
            oneminute,
            tenminute,
          };
        })
      );
    else if (this.tenMinuteColumns.length > 0) {
      return this.dAPIService
        .getTrendsDataJson(
          startDate,
          endDate,
          granularity,
          this.tenMinuteColumns,
          mpID,
          'tenminute'
        )
        .pipe(
          map((allColumns) => {
            return {
              tenminute: this.parseTrendsJSONData(allColumns),
            };
          })
        );
    }

    return this.dAPIService
      .getTrendsDataJson(startDate, endDate, granularity, this.oneMinuteColumns, mpID)
      .pipe(
        map((allColumns) => {
          return {
            oneminute: this.parseTrendsJSONData(allColumns),
          };
        })
      );
  }

  private fetchAndParseCSVTrendsData(startDate, endDate, granularity, mpID) {
    if (this.tenMinuteColumns.length > 0 && this.oneMinuteColumns.length > 0)
      return forkJoin([
        this.dAPIService
          .getTrendsDataCsv(startDate, endDate, granularity, this.oneMinuteColumns, mpID)
          .pipe(map((response) => this.parseTrendsCSVData(response))),
        this.dAPIService
          .getTrendsDataCsv(
            startDate,
            endDate,
            granularity,
            this.tenMinuteColumns,
            mpID,
            'tenminute'
          )
          .pipe(map((response) => this.parseTrendsCSVData(response))),
      ]).pipe(
        map(([oneminute, tenminute]) => {
          return {
            oneminute,
            tenminute,
          };
        })
      );
    else if (this.tenMinuteColumns.length > 0) {
      return this.dAPIService
        .getTrendsDataCsv(
          startDate,
          endDate,
          granularity,
          this.tenMinuteColumns,
          mpID,
          'tenminute'
        )
        .pipe(
          map((allColumns) => {
            return {
              tenminute: this.parseTrendsCSVData(allColumns),
            };
          })
        );
    }

    return this.dAPIService
      .getTrendsDataCsv(startDate, endDate, granularity, this.oneMinuteColumns, mpID)
      .pipe(
        map((allColumns) => {
          return {
            oneminute: this.parseTrendsCSVData(allColumns),
          };
        })
      );
  }

  private parseTrendsJSONData(channelDataJSON: any[]) {
    const seriesObj = {};

    this.charts.forEach((chart) => {
      if (!(chart instanceof PQEvents)) {
        chart.seriesArray.forEach((series) => {
          seriesObj[series.id] = {
            columns: series.channelIds,
            newData: [],
          };
        });
      }
    });

    channelDataJSON.forEach((entry) => {
      const timestamp = moment(entry.time_stamp).valueOf();
      for (const key in seriesObj) {
        if (seriesObj.hasOwnProperty(key)) {
          const row = seriesObj[key].columns.map((column) => parseFloat(entry[column]));
          seriesObj[key].newData.push([timestamp].concat(row));
        }
      }
    });

    return seriesObj;
  }
}
