import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidationErrors,
  FormGroupDirective,
  NgForm,
} from '@angular/forms';
import { take } from 'rxjs/operators';

import { TosaComponent } from './tosa/tosa.component';
import { AuthService } from '../shared/services';
import { NotificationsService } from '../shared/modules/notifications/shared/notifications.service';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '../shared/classes/component-destroy.class';
import { MeasurementPointsService } from '../shared/services/measurement-points.service';

class ActivationErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    // const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    // const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return control.dirty && control.parent.hasError('notSame');
  }
}

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.scss'],
})
export class ActivationComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  private token: string;
  public activationForm: FormGroup;
  public loading = false;
  public minLength = 6;
  public maskPassword = true;
  public matcher = new ActivationErrorStateMatcher();
  public tosaAccepted: boolean = null;
  private qubeScanEnabled = false;

  public checkPasswords(group: FormGroup): ValidationErrors {
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPassword').value;

    if (!(group.get('password').dirty && group.get('confirmPassword').dirty)) {
      return null;
    }
    return pass === confirmPass ? null : { notSame: true };
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private measurementPointService: MeasurementPointsService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private dialog: MatDialog
  ) {
    super();
    this.route.queryParamMap
      .pipe(untilComponentDestroyed(this))
      .subscribe((params: Params) => {
        this.token = params.get('token');
      });
  }

  ngOnInit() {
    this.activationForm = new FormGroup(
      {
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(this.minLength),
        ]),
        confirmPassword: new FormControl('', [Validators.required]),
      },
      [
        this.checkPasswords,
        (group: FormGroup) =>
          this.tosaAccepted === true ? null : { tosaUnchecked: true },
      ]
    );

    this.auth.getEnvironmentSettings('featureEnableQubeScan').subscribe(
      (result) => {
        if (result && result.featureEnableQubeScan) {
          this.qubeScanEnabled = result.featureEnableQubeScan;
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  get password() {
    return this.activationForm.get('password');
  }

  get confirmPassword() {
    return this.activationForm.get('confirmPassword');
  }

  activation($event) {
    this.loading = true;
    if ($event) {
      $event.stopPropagation();
    }
    // wait for the login event to finish then redirect to the dashboard
    this.auth
      .activate(this.token, this.password.value)
      .pipe(take(1))
      .subscribe(
        (res) => {
          if (this.qubeScanEnabled && !res.account.isPartner) {
            this.measurementPointService.countByAccount(res.accountId).subscribe(
              (result) => {
                if (result && result.count === 0) {
                  this.router.navigate(['create-mp', res.accountId]);
                } else {
                  this.router.navigate(['dashboard']);
                }
              },
              (error) => {
                console.error(error);
                this.router.navigate(['dashboard']);
              }
            );
          } else {
            this.router.navigate(['dashboard']);
          }
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.notificationsService.alert(
            error.error.error || this.translateService.instant('login.login-error'),
            this.translateService.instant('login.login-failed')
          );
        }
      );
  }

  displayTosa(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    const dialog = this.dialog.open(TosaComponent, {
      data: {
        tosaAccepted: this.tosaAccepted,
      },
    });

    dialog.beforeClosed().subscribe((confirmed) => {
      this.tosaAccepted = confirmed;
      this.activationForm.updateValueAndValidity();
    });
  }

  toggleMasking() {
    this.maskPassword = !this.maskPassword;
  }
}
