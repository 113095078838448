export const VoltageHarmonics = [
  { name: 'H2', factoryName: 'VH2' },
  { name: 'H3', factoryName: 'VH3' },
  { name: 'H4', factoryName: 'VH4' },
  { name: 'H5', factoryName: 'VH5' },
  { name: 'H6', factoryName: 'VH6' },
  { name: 'H7', factoryName: 'VH7' },
  { name: 'H8', factoryName: 'VH8' },
  { name: 'H9', factoryName: 'VH9' },
  { name: 'H10', factoryName: 'VH10' },
  { name: 'H11', factoryName: 'VH11' },
  { name: 'H12', factoryName: 'VH12' },
  { name: 'H13', factoryName: 'VH13' },
  { name: 'H14', factoryName: 'VH14' },
  { name: 'H15', factoryName: 'VH15' },
  { name: 'H16', factoryName: 'VH16' },
  { name: 'H17', factoryName: 'VH17' },
  { name: 'H18', factoryName: 'VH18' },
  { name: 'H19', factoryName: 'VH19' },
  { name: 'H20', factoryName: 'VH20' },
  { name: 'H21', factoryName: 'VH21' },
  { name: 'H22', factoryName: 'VH22' },
  { name: 'H23', factoryName: 'VH23' },
  { name: 'H24', factoryName: 'VH24' },
  { name: 'H25', factoryName: 'VH25' },
  { name: 'H26', factoryName: 'VH26' },
  { name: 'H27', factoryName: 'VH27' },
  { name: 'H28', factoryName: 'VH28' },
  { name: 'H29', factoryName: 'VH29' },
  { name: 'H30', factoryName: 'VH30' },
  { name: 'H31', factoryName: 'VH31' },
  { name: 'H32', factoryName: 'VH32' },
  { name: 'H33', factoryName: 'VH33' },
  { name: 'H34', factoryName: 'VH34' },
  { name: 'H35', factoryName: 'VH35' },
  { name: 'H36', factoryName: 'VH36' },
  { name: 'H37', factoryName: 'VH37' },
  { name: 'H38', factoryName: 'VH38' },
  { name: 'H39', factoryName: 'VH39' },
  { name: 'H40', factoryName: 'VH40' },
  { name: 'H41', factoryName: 'VH41' },
  { name: 'H42', factoryName: 'VH42' },
  { name: 'H43', factoryName: 'VH43' },
  { name: 'H44', factoryName: 'VH44' },
  { name: 'H45', factoryName: 'VH45' },
  { name: 'H46', factoryName: 'VH46' },
  { name: 'H47', factoryName: 'VH47' },
  { name: 'H48', factoryName: 'VH48' },
  { name: 'H49', factoryName: 'VH49' },
  { name: 'H50', factoryName: 'VH50' },
];
