import { Component, OnInit, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { interval } from 'rxjs';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';

import { MeterComponent } from 'src/app/meter/meter.component';
import { PQubeSocketServiceService } from 'src/app/shared/services/p-qube-socket-service.service';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from 'src/app/shared/classes/component-destroy.class';
import { MeasurementPointsService } from 'src/app/shared/services/measurement-points.service';

@Component({
  selector: 'app-meter-display',
  templateUrl: './meter-display.component.html',
  styleUrls: ['./meter-display.component.scss'],
})
export class MeterDisplayComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  public hour: string;
  public minute: string;
  public second: string;
  public daypart: string;
  private fiveMinTimeoutId: any;
  private oneMinTimeoutId: any;
  public changesMade = false;
  public timeExpiring = false;
  public sessionEnded = false;
  public charts = [{ chartGroup: 'voltage', chart: 'VoltageLL' }];

  public set chartView(viewCount: string) {
    while (this.charts.length < parseInt(viewCount, 10)) {
      this.charts.push({ chartGroup: 'voltage', chart: 'VoltageLL' });
    }
    this.charts.length = parseInt(viewCount, 10);
    // Highcharts responsive features are triggered by a window resize event and ignore css changes
    setTimeout(() => window.dispatchEvent(new Event('resize')), 200);
  }

  public get chartView(): string {
    return String(this.charts.length);
  }

  public get dynamicCharts(): any {
    return {
      'single-chart': this.chartView === '1',
      'double-chart': this.chartView === '2',
      'quadruple-chart': this.chartView === '4',
      'sextuple-chart': this.chartView === '6',
    };
  }

  public get timezone(): string {
    return this.mpService.selectedMeasurementPoint.threeLetterTimezone;
  }

  public get accountName(): string {
    return this.mpService.selectedMeasurementPoint.accountName;
  }

  public get locationCity(): string {
    return this.mpService.selectedMeasurementPoint.city;
  }

  public get locationState(): string {
    return this.mpService.selectedMeasurementPoint.state;
  }

  public get mpName(): string {
    return this.mpService.selectedMeasurementPoint.mpId;
  }

  public get threeLetterTimezone(): string {
    return this.mpService.selectedMeasurementPoint.threeLetterTimezone;
  }

  @ViewChildren('meter') meters: QueryList<MeterComponent>;

  constructor(
    private mpService: MeasurementPointsService,
    private route: ActivatedRoute,
    private socket: PQubeSocketServiceService
  ) {
    super();
    interval(1000)
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        const timeOfDay = moment().tz(this.mpService.selectedMeasurementPoint.timezone);
        this.second = timeOfDay.format('ss');
        if (this.hour !== timeOfDay.format('h')) {
          this.hour = timeOfDay.format('h');
        }
        if (this.minute !== timeOfDay.format('mm')) {
          this.minute = timeOfDay.format('mm');
        }
        if (this.daypart !== timeOfDay.format('A')) {
          this.daypart = timeOfDay.format('A');
        }
      });
  }

  ngOnInit() {
    this.route.queryParams.pipe(untilComponentDestroyed(this)).subscribe((params) => {
      this.socket._accountId = parseInt(params.account, 10);
      this.socket._mpId = parseInt(params.mpId, 10);
      if (this.socket.isConnected) {
        this.socket.terminateConnection('param change');
      }

      this.socket.establishSocketConnection();

      // trigger resize event to
      // setTimeout(() => window.dispatchEvent(new Event('resize')), 200);
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.terminateConnection('destroy');
  }

  private terminateConnection(sourceRequesting: string) {
    if (this.socket.isConnected) {
      this.socket.terminateConnection(sourceRequesting);
    }
    clearTimeout(this.fiveMinTimeoutId);
    clearTimeout(this.oneMinTimeoutId);
  }

  public handleMessage(message: string) {
    window.dispatchEvent(new Event('resize'));
    if (message === 'start') {
      clearTimeout(this.fiveMinTimeoutId);
      this.fiveMinTimeoutId = setTimeout(() => {
        console.log('time expired');
        this.timeExpiring = true;
        this.oneMinTimeoutId = setTimeout(() => {
          this.timeExpiring = false;
          // TODO remove verbose approach once stable
          this.terminateConnection('timeout');
          this.sessionEnded = true;
        }, 60000);
      }, 300000);
    }
  }

  public onCancel() {
    clearTimeout(this.oneMinTimeoutId);
    this.oneMinTimeoutId = undefined;
    this.timeExpiring = false;
    // TODO remove verbose approach once stable
    this.terminateConnection('cancel button');
    this.sessionEnded = true;
  }

  public onContinue() {
    clearTimeout(this.oneMinTimeoutId);
    this.oneMinTimeoutId = undefined;
    this.timeExpiring = false;
    this.meters.forEach((meter) => meter.startTenMinutePublish());
  }

  public restartSession() {
    this.sessionEnded = false;
    this.socket.establishSocketConnection();
    this.meters.forEach((meter) => meter.startTenMinutePublish());
  }
}
