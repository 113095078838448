import { createAction, props } from '@ngrx/store';

export const getHierarchyUser = createAction(
  '[App Init] Init hierarchy - user',
  props<{ payload: { id: number; accountId: number } }>()
);
export const getHierarchySuccess = createAction(
  '[App Init] Get Hierarchy Success',
  props<{ hierarchy: any }>()
);
export const getHierarchyFail = createAction(
  '[App Init] Get Hierarchy Fail',
  props<{ error: any }>()
);
export const logout = createAction('[App Logout] Logout');

export type HierarchyActionsUnion = ReturnType<
  | typeof getHierarchyUser
  | typeof getHierarchyFail
  | typeof getHierarchySuccess
  | typeof logout
>;
