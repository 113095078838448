<section class="live-chart-container">
    <!-- <div id="meter">
        <highcharts-chart
        [Highcharts]="Highcharts"
        [constructorType]="meterConstructor"
        [options]="meterOptions"
        [(update)]="updateMeterFlag"
        [callbackFunction]="captureMeter"
        ></highcharts-chart>
    </div> -->
    <div class="chart" *ngIf="chartReady">
        <highcharts-chart
        [Highcharts]="Highcharts"
        [constructorType]="meterConstructor"
        [options]="chartOptions"
        [(update)]="updateChartFlag"
        [callbackFunction]="captureChart"
        style="width: 100%; height: 100%;"
        ></highcharts-chart>
    </div>
    <div class="values">
        <ng-container *ngFor="let data of newPoints">
            <p *ngIf="data" [innerHTML]="data"></p>
        </ng-container>
    </div>
    <div class="control-panel">
        <h1 (click)="chartPanel.expanded = !chartPanel.expanded">
            {{selectedChart?.name}}
            <mat-icon *ngIf="!chartPanel.expanded" svgIcon="expand"></mat-icon>
            <mat-icon *ngIf="chartPanel.expanded" svgIcon="collapse"></mat-icon>
        </h1>
        <mat-expansion-panel #chartPanel>
            <label id="chart-groups"></label>
            <mat-radio-group class="panel-radio-group" aria-labelledby="chart-groups" [(ngModel)]="userSelectedChartGroup">
                <mat-radio-button *ngFor="let group of chartGroups" [value]="group.propertyName">{{ group.name }}</mat-radio-button>
            </mat-radio-group>
            <mat-form-field class="panel-form-field">
                <mat-label></mat-label>
                <mat-select [(value)]="userSelectedChart">
                    <mat-option (click)="closeChartPanel($event)" *ngFor="let chart of availableCharts" [value]="chart.factoryName">{{chart.dropdownName ? chart.dropdownName : chart.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </mat-expansion-panel>
    </div>
</section>