<section>
  <h1 mat-dialog-title>{{ settings.title }} </h1>
  <hr class="mt-3" />
  <div mat-dialog-content>
    <p [innerHTML]="settings.message">
      <!-- <mat-icon *ngIf="settings.material_ligature">{{ settings.material_ligature }}</mat-icon> -->
    </p>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button data-cy="notifications-button" class="notifications-button" *ngFor="let btn of settings.buttons; let i = index" (click)="btn.callback()" color="{{ btn.color }}" [ngClass]="{'transparent': ((i+1) % 2)}">
      <!-- <mat-icon *ngIf="btn.material_ligature">{{ btn.material_ligature }}</mat-icon> -->
      {{ btn.caption }}
    </button>
  </div>
</section>