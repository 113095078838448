import { Channels } from '../../../shared/classes/channels.interface';
import { Action } from '@ngrx/store';

import { ChannelsActions } from '../actions';

export interface State {
  channels: Channels;
  mpId: string;
}

const initialState: State = {
  channels: null,
  mpId: null,
};

export function reducer(state: State = initialState, action: Action): State {
  const specificAction = action as ChannelsActions.ChannelsActionsUnion;

  switch (specificAction.type) {
    case ChannelsActions.setChannels.type:
      return {
        ...state,
        mpId: specificAction.payload.mpId,
      };

    case ChannelsActions.setChannelsSuccess.type:
      return {
        ...state,
        channels: specificAction.payload.channels,
      };

    default:
      return state;
  }
}

export const getChannels = (state: State) => state.channels;
