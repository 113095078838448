export const tabIconsConfig = {
    1: {
      icon: 'alert-octagon',
      class: 'negative'
    },
    2: {
      icon: 'check-circle-outline',
      class: 'positive'
    },
    3: {
      icon: 'help-circle-outline',
      class: 'neutral'
    },
    4: {
      icon: 'block-helper',
      class: 'neutral'
    }
  };