import { ActivatedRoute } from '@angular/router';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { take } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';

import { AccountsService } from './../shared/services/accounts.service';
import { MemberDialogComponent } from './member-dialog/member-dialog.component';
import { DeleteMemberDialogComponent } from './delete-member-dialog/delete-member-dialog.component';
import { NotificationsService } from '../shared/modules/notifications/shared/notifications.service';
import { IUserUpdateParams } from '../shared/classes/userUpdateParams.interface';
import { IMember } from './../shared/classes/member.interface';

@Component({
  selector: 'app-manage-members',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './manage-members.component.html',
  styleUrls: ['./manage-members.component.scss'],
})
export class ManageMembersComponent implements OnInit {
  public usersList: Array<any>;
  public usersListSource: MatTableDataSource<any>;
  public displayedColumns: string[] = ['fullName', 'email', 'phone', 'icons'];
  public membersCount: number;
  private accountId: number;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private accountsService: AccountsService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private notificationsService: NotificationsService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.accountId = Number(this.route.snapshot.paramMap.get('id'));
    this.setUserListTable();
  }

  private setUserListTable(): void {
    this.accountsService
      .getAccountUsersList(this.accountId.toString())
      .pipe(take(1))
      .subscribe((usersList: Array<IMember>) => {
        this.membersCount = usersList.length;
        this.usersList = usersList.map((user: IMember) => {
          return {
            ...user,
            fullName: user.firstName + ' ' + user.lastName,
          };
        });
        this.usersListSource = new MatTableDataSource<IMember>(this.usersList);
        this.usersListSource.paginator = this.paginator;
        this.usersListSource.sort = this.sort;
      });
  }

  public addMember(): void {
    const addMemberDialog = this.dialog.open(MemberDialogComponent, {
      width: '950px',
      disableClose: false,
      data: {
        accountId: this.accountId,
      },
      autoFocus: false,
      restoreFocus: false,
    });

    addMemberDialog
      .afterClosed()
      .subscribe((addMemberParams: IUserUpdateParams) => {
        if (!addMemberParams) {
          return;
        }
        this.accountsService
          .addUser(addMemberParams)
          .pipe(take(1))
          .subscribe((response) => {
            if (response) {
              this.setUserListTable();
              this.notificationsService.notify(
                this.translateService.instant('manageMembers.memberAdded')
              );
            } else {
              console.log(response);
            }
          });
      });
  }

  public editMember(member: IMember): void {
    const editMemberDialog = this.dialog.open(MemberDialogComponent, {
      width: '950px',
      disableClose: false,
      data: {
        member: member,
        accountId: this.accountId,
      },
      autoFocus: false,
      restoreFocus: false,
    });

    editMemberDialog
      .afterClosed()
      .subscribe((updateUserParams: IUserUpdateParams) => {
        if (!updateUserParams) {
          return;
        }
        this.accountsService
          .updateUser(updateUserParams, member.id)
          .pipe(take(1))
          .subscribe((response) => {
            if (response) {
              this.setUserListTable();
              this.notificationsService.notify(
                this.translateService.instant('profile.updated')
              );
            } else {
              console.log(response);
            }
          });
      });
  }

  public deleteMember(member: IMember): void {
    const deleteMemberDialog = this.dialog.open(DeleteMemberDialogComponent, {
      width: '450px',
      disableClose: false,
      data: {
        member: member,
      },
      autoFocus: false,
      restoreFocus: false,
    });

    deleteMemberDialog
      .afterClosed()
      .subscribe((deleteUserParams: IUserUpdateParams) => {
        if (!deleteUserParams) {
          return;
        }
        deleteUserParams.accountId = this.accountId;
        this.accountsService
          .updateUser(deleteUserParams, member.id)
          .pipe(take(1))
          .subscribe((response) => {
            if (response) {
              this.setUserListTable();
              this.notificationsService.notify(
                this.translateService.instant('profile.updated')
              );
            } else {
              console.log(response);
            }
          });
      });
  }

  public resendEmail(member: IMember): void {
    this.accountsService
      .resendActivationEmail(member.id, this.accountId)
      .pipe(take(1))
      .subscribe((response) => {
        if (response) {
          this.notificationsService.notify(
            this.translateService.instant('manageMembers.registrationEmailSent')
          );
        } else {
          console.log(response);
        }
      });
  }
}
