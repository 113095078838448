<div [ngStyle]="chartStyle">
    <div class="outer-flex">
        <mat-form-field>
            <mat-select [formControl]="monthControl"
                        [(value)]="monthToDisplay"
                        (selectionChange)="changeMonth($event)">
                <mat-option *ngFor="let month of monthsWithData" [value]="month">
                    {{month}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <highcharts-chart
        *ngIf="chartReady"
        [Highcharts]="Highcharts"
        [constructorType]="chartConstructor"
        [options]="chartOptions"
        [callbackFunction]="onChartComplete"
        [(update)]="updateFlag"
        [oneToOne]="oneToOneFlag"
        style="max-width: 100%"
    ></highcharts-chart>
    <div class="chart-cover" *ngIf="!chartReady">
        <mat-progress-spinner mode="indeterminate"
                                color="primary"
        ></mat-progress-spinner>
    </div>
</div>
