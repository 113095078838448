import {
  IMeasurementPoint,
  IDetails,
  IGenericPQSummary,
  IVFluxP30Summary,
  ISampleSummary,
  ISimpleSummary,
  IPowerSummary,
  IPFP30Summary,
} from './measurementpoint.interface';
import * as moment from 'moment';
import * as tz from 'moment-timezone';

class Details implements IDetails {
  private mSagsAndSwellsPrior30Days: IGenericPQSummary;
  private mHighFrequencyImpulsesPrior30Days: IGenericPQSummary;
  private mOutagesPrior30Days: IGenericPQSummary;
  private mVoltageFluctuationsPrior30Days: IVFluxP30Summary;
  private mHarmonicsPrior30Days: ISampleSummary;
  private mImbalancePrior30Days: ISampleSummary;
  private mPowerFactorPrior30Days: IPFP30Summary;
  private mGroundCurrentPrior30Days: ISimpleSummary;
  private mWastePrior30Days: ISimpleSummary;
  private mLastPowerConsumption: ISimpleSummary;
  private mPower: IPowerSummary;
  private mLastTemperature: ISimpleSummary;
  private mLastCommunication: ISimpleSummary;

  constructor(details: IDetails) {
    ({
      sagsAndSwellsPrior30Days: this.mSagsAndSwellsPrior30Days,
      highfrequencyImpulsesPrior30Days: this.mHighFrequencyImpulsesPrior30Days,
      outagesPrior30Days: this.mOutagesPrior30Days,
      voltageFluctuationsPrior30Days: this.mVoltageFluctuationsPrior30Days,
      harmonicsPrior30Days: this.mHarmonicsPrior30Days,
      imbalancePrior30Days: this.mImbalancePrior30Days,
      powerFactorPrior30Days: this.mPowerFactorPrior30Days,
      groundCurrentPrior30Days: this.mGroundCurrentPrior30Days,
      wastePrior30Days: this.mWastePrior30Days,
      lastPowerConsumption: this.mLastPowerConsumption,
      power: this.mPower,
      lastTemperature: this.mLastTemperature,
      lastCommunication: this.mLastCommunication,
    } = details);
  }

  public get sagsAndSwellsPrior30Days(): IGenericPQSummary {
    return this.mSagsAndSwellsPrior30Days;
  }

  public get highfrequencyImpulsesPrior30Days(): IGenericPQSummary {
    return this.mHighFrequencyImpulsesPrior30Days;
  }

  public get outagesPrior30Days(): IGenericPQSummary {
    return this.mOutagesPrior30Days;
  }

  public get voltageFluctuationsPrior30Days(): IVFluxP30Summary {
    return this.mVoltageFluctuationsPrior30Days;
  }

  public get harmonicsPrior30Days(): ISampleSummary {
    return this.mHarmonicsPrior30Days;
  }

  public get imbalancePrior30Days(): ISampleSummary {
    return this.mImbalancePrior30Days;
  }

  public get powerFactorPrior30Days(): IPFP30Summary {
    return this.mPowerFactorPrior30Days;
  }

  public get groundCurrentPrior30Days(): ISimpleSummary {
    return this.mGroundCurrentPrior30Days;
  }

  public get wastePrior30Days(): ISimpleSummary {
    return this.mWastePrior30Days;
  }

  public get lastPowerConsumption(): ISimpleSummary {
    return this.mLastPowerConsumption;
  }

  public get power(): IPowerSummary {
    return this.mPower;
  }

  public get lastTemperature(): ISimpleSummary {
    return this.mLastTemperature;
  }

  public get lastCommunication(): ISimpleSummary {
    return this.mLastCommunication;
  }
}

export class MeasurementPoint implements IMeasurementPoint {
  private mRoomId?: number;
  private mMeasurementPointId;
  private mMeasurementPointTypeId;
  private mMpId: string;
  private mCreatedWhen: string;
  private mCommissionedWhen: string;
  private mAccountId: number;
  private mLocationId: number;
  private mAccountName: string;
  private mCity: string;
  private mState: string;
  private mCountry: string;
  // private mPowerQualityType: string;
  // private mAcStatus: string;
  private mDetails: Details;
  private mMonthsActive: string[] = [];
  private mTimezone: string;
  private mStatus: number;
  private mPowerQualityStatus: string;

  constructor(measurementPoint: IMeasurementPoint) {
    ({
      measurementPointId: this.mMeasurementPointId,
      mpId: this.mMpId,
      createdWhen: this.mCreatedWhen,
      commissionedWhen: this.mCommissionedWhen,
      accountId: this.mAccountId,
      locationId: this.mLocationId,
      accountName: this.mAccountName,
      city: this.mCity,
      state: this.mState,
      country: this.mCountry,
      // powerQualityType: this.mPowerQualityType,
      // acStatus: this.mAcStatus,
      timezone: this.mTimezone,
      measurementPointStatusId: this.mStatus,
      measurementPointTypeId: this.mMeasurementPointTypeId,
      powerQualityType: this.mPowerQualityStatus
    } = measurementPoint);
    this.mDetails = new Details(measurementPoint.details);

    if (this.createdWhen) {
      const unloggedMonth = moment(this.createdWhen).startOf('month');
      while (unloggedMonth.valueOf() < moment().valueOf()) {
        this.mMonthsActive.unshift(unloggedMonth.format('MMMM YYYY'));
        unloggedMonth.add(1, 'month');
      }
    }
  }

  public get monthsActive(): string[] {
    return this.mMonthsActive;
  }

  /**
   * identifies pqube by assigned number
   */
  public get measurementPointId(): number {
    return this.mMeasurementPointId;
  }

  public get measurementPointTypeId(): number {
    return this.mMeasurementPointTypeId;
  }

  public get powerQualityStatus(): string {
    return this.mPowerQualityStatus;
}

  /**
   * identifies pqube by assigned number
   * same value as measurementPointId
   */
  public get roomId(): number {
    return this.mRoomId;
  }

  // public set roomId(roomId: number) {
  //     this.mRoomId = roomId;
  // }

  /**
   * identifies pqube by location
   */
  public get mpId(): string {
    return this.mMpId;
  }

  public get threeLetterTimezone(): string {
    return moment.tz.zone(this.timezone).abbr(moment().valueOf());
  }

  // public set mpId(mpId: string) {
  //     this.mMpId = mpId;
  // }

  public get createdWhen(): string {
    return this.mCreatedWhen;
  }

  // public set createdWhen(createdWhen: string) {
  //     this.mCreatedWhen = createdWhen;
  // }

  public get commissionedWhen(): string {
    return this.mCommissionedWhen;
  }

  // public set commissionedWhen(commissionedWhen: string) {
  //     this.mCommissionedWhen = commissionedWhen;
  // }

  public get accountId(): number {
    return this.mAccountId;
  }

  // public set accountId(accountId: number) {
  //     this.mAccountId = accountId;
  // }

  public get locationId(): number {
    return this.mLocationId;
  }

  // public set locationId(locationId: number) {
  //     this.mLocationId = locationId;
  // }

  public get accountName(): string {
    return this.mAccountName;
  }

  // public set accountName(accountName: string) {
  //     this.mAccountName = accountName;
  // }

  public get city(): string {
    return this.mCity;
  }

  // public set city(city: string) {
  //     this.mCity = city;
  // }

  public get state(): string {
    return this.mState;
  }

  // public set state(state: string) {
  //     this.mState = state;
  // }

  public get country(): string {
    return this.mCountry;
  }

  // public set country(country: string) {
  //     this.mCountry = country;
  // }

  /**
   * powerQualityType field is legacy on previous version of backend,
   * don't use until further notice
   */
  // public get powerQualityType(): string {
  //    return this.mPowerQualityType;
  // }

  // public set powerQualityType(powerQualityType: string) {
  //     this.mPowerQualityType = powerQualityType;
  // }

  /**
   * acStatus field is legacy on previous version of backend,
   * don't use until further notice
   */
  // public get acStatus(): string {
  //   return this.mAcStatus;
  // }

  // public set acStatus(acStatus: string) {
  //     this.mAcStatus = acStatus;
  // }

  public get details(): Details {
    return this.mDetails;
  }

  // public set details(details: Details) {
  //     this.mDetails = details;
  // }

  public get timezone(): string {
    return this.mTimezone;
  }

  // public set timezone(newTimezone: string) {
  //     this.mTimezone = newTimezone;
  // }

  public get measurementPointStatusId(): number {
    return this.mStatus;
  }

  public get status(): number {
    return this.mStatus;
  }
}
