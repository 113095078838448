import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormArray,
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { AuthService } from 'src/app/shared/services';
import { Tab } from 'src/app/shared/classes/tab.interface';
import { CustomChartTypes } from 'src/app/shared/classes/customer-chart-types.class';
import { Channels } from 'src/app/shared/classes/channels.interface';
import * as fromChannels from './../../_store/_reducers';
import { PhaseList } from 'src/app/shared/classes/phase-list.interface';
import { MeasurementPointsService } from 'src/app/shared/services/measurement-points.service';

@Component({
  selector: 'app-new-chart-tab',
  templateUrl: './new-chart-tab.component.html',
  styleUrls: ['./new-chart-tab.component.scss'],
})
export class NewChartTabComponent implements OnInit, OnDestroy {
  // private ChartDefinitions: ChartDefinitions;
  public chartOptions = {
    energy: {
      title: 'energy-power.title',
      left: [
        {
          title: 'energy-power.energy.title',
          charts: ['energy-power.energy.active', 'energy-power.energy.reactive'],
        },
      ],
      right: [
        {
          title: 'energy-power.power.title',
          charts: [
            'energy-power.power.active',
            'energy-power.power.reactive',
            'energy-power.power.factor',
          ],
        },
      ],
    },
    quality: {
      title: 'power-quality.title',
      left: [
        {
          title: 'power-quality.voltage-flux-lc.title',
          charts: [
            'power-quality.voltage-flux-lc.vrms-l-n',
            'power-quality.voltage-flux-lc.vrms-l-l',
            'power-quality.voltage-flux-lc.neutral-earth-voltage',
            'power-quality.voltage-flux-lc.flicker-pinst',
            'power-quality.voltage-flux-lc.flicker-pst',
            'power-quality.voltage-flux-lc.flicker-plt',
          ],
        },
        {
          title: 'power-quality.harmonic-distortion.title',
          charts: [
            'power-quality.harmonic-distortion.thd',
            'power-quality.harmonic-distortion.tdd',
            'power-quality.harmonic-distortion.voltage-odd',
            'power-quality.harmonic-distortion.current-odd',
          ],
        },
      ],
      right: [
        {
          title: 'power-quality.unbalance.title',
          charts: [
            'power-quality.unbalance.voltage-zero-unbalance',
            'power-quality.unbalance.voltage-negative-unbalance',
            'power-quality.unbalance.current-zero-unbalance',
            'power-quality.unbalance.current-negative-unbalance',
          ],
        },
        {
          title: 'power-quality.currents.title',
          charts: [
            'power-quality.currents.load-currents',
            'power-quality.currents.neutral-current',
            'power-quality.currents.ground-current',
          ],
        },
        {
          title: 'power-quality.frequency.title',
          charts: ['power-quality.frequency.frequency'],
        },
      ],
    },
    advanced: {
      title: 'power-quality-advanced.title',
      left: [
        {
          title: 'power-quality-advanced.harmonics.title',
          charts: [
            'power-quality-advanced.harmonics.voltage-advanced',
            'power-quality-advanced.harmonics.current-advanced',
          ],
          dropdown: [
            {
              prefix: 'H',
              first: 2,
              last: 50,
            },
            {
              prefix: 'H',
              first: 2,
              last: 50,
            },
          ],
        },
      ],
      right: [
        {
          title: 'power-quality-advanced.interharmonics.title',
          charts: [
            'power-quality-advanced.interharmonics.voltage-advanced',
            'power-quality-advanced.interharmonics.current-advanced',
          ],
          dropdown: [
            {
              prefix: 'IH',
              first: 1,
              last: 49,
            },
            {
              prefix: 'IH',
              first: 1,
              last: 49,
            },
          ],
        },
      ],
    },
    misc: {
      title: 'misc.title',
      left: [{ title: 'misc.left.title', charts: [] }],
      right: [{ title: 'misc.right.title', charts: [] }],
    },
  };

  public selected = [];
  public groups: Array<string> = ['energy', 'quality', 'advanced', 'misc'];

  public chartForm: FormGroup;

  // phaseList array of phases and charts
  public phaseList: Array<PhaseList> = [];
  // array just to keep list of available phases
  public phaseData: string[][] = [];
  public userPrefs;
  public isAddNewTab: boolean;
  public referenceTab: Tab;
  private channelsSub$: Subscription;
  private channels: Channels;

  public nameTaken = (control: AbstractControl) => {
    if (this.userPrefs.customTabs) {
      const tabWithSameName = this.userPrefs.customTabs.find((tab: Tab) => {
        return tab.name.trim() === control.value.trim();
      });
      return tabWithSameName && tabWithSameName.sortOrder !== this.referenceTab.sortOrder
        ? { nameTaken: { value: control.value } }
        : null;
    } else return null;
    // tslint:disable-next-line: semicolon
  };

  constructor(
    public dialogRef: MatDialogRef<NewChartTabComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private auth: AuthService,
    private mpService: MeasurementPointsService,
    private store: Store<fromChannels.State>
  ) {
    if (data.tab) {
      this.referenceTab = data.tab;
    } else {
      this.referenceTab = {} as Tab;
    }
    this.isAddNewTab = data.addTab;
    this.userPrefs = data.userPrefs;

    this.chartForm = new FormGroup({
      name: new FormControl(this.translate.instant('charts.new-chart.default-name'), [
        Validators.required,
        this.nameTaken,
      ]),
      makeDefault: new FormControl(),
      advanced: new FormGroup({
        left: new FormArray([
          new FormControl({ value: 'H2', disabled: true }),
          new FormControl({ value: 'H2', disabled: true }),
        ]),
        right: new FormArray([
          new FormControl({ value: 'IH1', disabled: true }),
          new FormControl({ value: 'IH1', disabled: true }),
        ]),
      }),
      // energy: new FormGroup({}),
      // quality: new FormGroup({})
    });

    if (!this.isAddNewTab && this.referenceTab && this.referenceTab.editable) {
      this.name.setValue(this.referenceTab.name);

      const charts = this.getChartGroups()
        .map((obj) => obj.charts)
        .reduce((acc, name) => [...acc, ...name]);
      this.makeDefault.setValue(
        this.userPrefs.defaultTab === this.referenceTab.sortOrder
      );

      this.selected = this.referenceTab.selected;

      if (this.referenceTab.editable && !this.referenceTab.phaseList) {
        this.selected.forEach((c) => this.phaseList.push({ chart: c, phase: '0' }));
      } else {
        this.phaseList = this.referenceTab.phaseList;
      }
    }

    this.channelsSub$ = this.store
      .pipe(select(fromChannels.getChannels))
      .subscribe((channels: Channels) => {
        this.channels = channels;

        if (!this.channels) {
          this.selected = [];
          this.groups.splice(this.groups.indexOf('misc'), 1);
        } else {
          Object.keys(channels['8']).forEach((element, index) => {
            if (index < Object.keys(channels['8']).length / 2) {
              this.chartOptions.misc.left[0].charts.push({
                channel: channels['8'][element],
                id: element,
              });
            } else {
              this.chartOptions.misc.right[0].charts.push({
                channel: channels['8'][element],
                id: element,
              });
            }
          });
        }
      });

    // const allDropdowns = this.getChartGroups().filter(c => c.hasOwnProperty("dropdown"));

    Object.keys(this.chartOptions).forEach((group) => {
      const leftGroup = this.chartOptions[group].left;
      const rightGroup = this.chartOptions[group].right;
      const leftData =
        leftGroup && leftGroup[0].dropdown
          ? leftGroup[0].dropdown.map(({ prefix, first, last }) =>
              this.createPhaseData(prefix, first, last)
            )
          : [];
      const rightData =
        rightGroup && rightGroup[0].dropdown
          ? rightGroup[0].dropdown.map(({ prefix, first, last }) =>
              this.createPhaseData(prefix, first, last)
            )
          : [];
      this.phaseData.push([leftData, rightData]);
    });
    this.replaceAdvancedControl();
  }

  get name() {
    return this.chartForm.get('name');
  }

  get makeDefault() {
    return this.chartForm.get('makeDefault');
  }

  replaceAdvancedControl() {
    // return this.chartForm.get('phaseControl');
    const advancedFG = this.chartForm.controls.advanced as FormGroup;
    const left = advancedFG.controls.left as FormArray;
    const right = advancedFG.controls.right as FormArray;
    this.phaseList.forEach((item) => {
      const advancedOptions = this.chartOptions.advanced;
      let leftIndex = 0;
      let rightIndex = 0;
      advancedOptions.left.forEach(
        (g) =>
          (leftIndex =
            g.charts.indexOf(item.chart) !== -1 ? g.charts.indexOf(item.chart) : null)
      );
      advancedOptions.right.forEach(
        (g) =>
          (rightIndex =
            g.charts.indexOf(item.chart) !== -1 ? g.charts.indexOf(item.chart) : null)
      );
      if (leftIndex !== null && item.phase) {
        left.removeAt(leftIndex);
        left.insert(leftIndex, new FormControl(item.phase));
      } else if (rightIndex !== null && item.phase) {
        right.removeAt(rightIndex);
        right.insert(rightIndex, new FormControl(item.phase));
      }
    });
  }

  public isFirstFocus(event) {
    if (
      event.target.name === 'name' &&
      !this.chartForm.get(event.target.name).touched &&
      this.isAddNewTab
    ) {
      this.chartForm.get(event.target.name).setValue('');
    }
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.channelsSub$.unsubscribe();
  }

  onClose(saved = false): void {
    this.dialogRef.close(saved);
  }

  setPhaseForm(value, sIdx, idx) {
    const advancedFG = this.chartForm.controls.advanced as FormGroup;
    const left = advancedFG.controls.left as FormArray; // '0'
    const rigth = advancedFG.controls.right as FormArray; // '1'
    if (sIdx === 0) {
      left.controls[idx].enable();
      left.at(idx).patchValue(value);
    } else {
      rigth.controls[idx].enable();
      rigth.at(idx).patchValue(value);
    }
  }

  setPhaseList(value, option, sIdx, idx) {
    let count = 0;
    this.phaseList.forEach((c) => {
      option === c.chart ? (c.phase = value) : count++;
    });
    if (option && count === this.phaseList.length) {
      this.phaseList.push({ chart: option, phase: value });
    }
    this.setPhaseForm(value, sIdx, idx);
  }

  onRemovePhaseList(option, side, index) {
    const advancedFG = this.chartForm.controls.advanced as FormGroup;
    const left = advancedFG.controls.left as FormArray; // '0'
    const rigth = advancedFG.controls.right as FormArray; // '1'
    if (side === 0) {
      left.controls[index].disable();
      left.at(index).patchValue('H2');
    } else {
      rigth.controls[index].disable();
      rigth.at(index).patchValue('IH1');
    }
    this.phaseList = this.phaseList.filter((c) => c.chart !== option);
  }

  createPhaseData(first: string, int, last) {
    const arr: string[] = [];
    while (int <= last) {
      arr.push(first + int++);
    }
    return arr;
  }

  selectChart(chart, groupIdx, sIdx, idx) {
    let label: string;
    if (typeof chart === 'string') {
      label = chart;
    } else {
      label = chart.id;
    }

    if (this.selected.includes(label)) {
      this.selected = this.selected.filter((c) => c !== label);
      if (this.getChartGroup(chart).hasOwnProperty('dropdown'))
        this.onRemovePhaseList(chart, sIdx, idx);
    } else {
      this.selected.push(label);
      if (this.getChartGroup(chart).hasOwnProperty('dropdown')) {
        const group = this.phaseData[groupIdx];
        this.setPhaseList(group[sIdx][idx][0], chart, sIdx, idx);
      }
    }
  }

  chartTitle(chart) {
    if (chart.indexOf('.') > 0) {
      const group = this.getChartGroup(chart);
      if (!group) {
        return null;
      }
      return group.title || null;
    } else {
      return this.channels['8'][chart].name;
    }
  }

  chartPhase(chart) {
    const group = this.getChartGroup(chart);
    let text = null;
    const idx = this.phaseList.findIndex((item) => item.chart === chart);
    if (!group || !group.hasOwnProperty('dropdown')) {
      return null;
    }
    if (group.hasOwnProperty('dropdown')) {
      text = '(' + this.phaseList[idx].phase + ')';
    }
    return text;
  }

  getChartGroups() {
    const { advanced, energy, quality, misc } = this.chartOptions;
    return [
      ...energy.left,
      ...energy.right,
      ...quality.left,
      ...quality.right,
      ...advanced.left,
      ...advanced.right,
      ...misc.left,
      ...misc.right,
    ];
  }

  getChartGroup(name) {
    return this.getChartGroups().find((g) => g.charts.includes(name));
  }

  deleteTab() {
    const newTabsArray = this.userPrefs.customTabs.filter(
      (tab) => tab.name.trim() !== this.referenceTab.name.trim()
    );
    newTabsArray.forEach((tab, index) => (tab.sortOrder = index));
    const newUserPrefs = {
      ...this.userPrefs,
      customChart: null,
      customTabs: newTabsArray,
    };
    if (this.referenceTab.sortOrder === this.userPrefs.defaultTab) {
      newUserPrefs.defaultTab = 0;
    }

    newTabsArray.forEach((tab, index) => {
      if (
        this.referenceTab.sortOrder <= index + this.data.standardNumberOfTabs &&
        tab.sortOrder === this.userPrefs.defaultTab
      ) {
        newUserPrefs.defaultTab = index + this.data.standardNumberOfTabs;
      }
      tab.sortOrder = index + this.data.standardNumberOfTabs;
    });

    this.auth.savePreferences(newUserPrefs);

    this.onClose(false);
  }

  saveCustomChart($event) {
    $event.stopPropagation();
    const name = this.name.value.trim();

    if (!name) {
      alert(this.translate.instant('charts.new-chart.name-required'));
      return;
    }
    const charts = this.getChartGroups()
      .map((obj) => obj.charts)
      .reduce((acc, key) => [...acc, ...key]);

    const prefs = {
      ...this.userPrefs,
    };

    if (!prefs.customTabs) {
      prefs.customTabs = [];
    }

    if (!this.isAddNewTab && this.referenceTab && this.referenceTab.editable) {
      if (this.makeDefault.value) {
        prefs.defaultTab = this.referenceTab.sortOrder;
      } else if (this.referenceTab.sortOrder === this.userPrefs.defaultTab) {
        prefs.defaultTab = 0;
      }

      const tabToSave = this.userPrefs.customTabs[
        this.data.tab.sortOrder - this.data.standardNumberOfTabs
      ];
      tabToSave.name = this.name.value;
      tabToSave.chartSetName = 'Custom' + new Date().getTime();

      tabToSave.memberCharts = this.selected
        .map((c) => {
          return CustomChartTypes.customChartTypes[c]
            ? CustomChartTypes.customChartTypes[c]
            : [c];
        })
        .reduce((accumulator, current) => {
          if (current) {
            return [...accumulator, ...current];
          }
          return accumulator;
        }, [])
        .concat('pqEvents');
      tabToSave.selected = this.selected;
      tabToSave.phaseList = this.phaseList;
      prefs.lastTabSaved = this.referenceTab.sortOrder;
    } else if (prefs.customTabs.length < 11) {
      if (this.makeDefault.value) {
        prefs.defaultTab = prefs.customTabs.length + this.data.standardNumberOfTabs;
      }

      prefs.customTabs.push({
        name: this.name.value,
        memberCharts: this.selected
          .map((c: string) => {
            return CustomChartTypes.customChartTypes[c]
              ? CustomChartTypes.customChartTypes[c]
              : [c];
          })
          .reduce((accumulator, current) => {
            if (current) {
              return [...accumulator, ...current];
            }
            return accumulator;
          }, [])
          .concat('pqEvents'),
        chartSetName: 'Custom' + new Date().getTime(),
        sortOrder: prefs.customTabs.length + this.data.standardNumberOfTabs,
        editable: true,
        phaseList: this.phaseList,
        selected: this.selected,
      });
      prefs.lastTabSaved = prefs.customTabs[prefs.customTabs.length - 1].sortOrder;
    }
    this.auth.savePreferences(prefs);

    this.onClose(true);
  }
}
