<div *ngIf="load">
  <mat-checkbox
    [(checked)]="usePreCommissionStart"
    (change)="changeAbsoluteStartDate($event)"
    *ngIf="token.metaData.isSystemAdministrator === '1' && !isQubeScan"
    data-cy="admin-include-data"
  >
    Include data prior to commissioning [<span [innerHTML]="commissioningDate"></span>]
  </mat-checkbox>
  <div class="chart dashboard-box">
    <div class="chart-container">
      <mde-popover
        #appPopover="mdePopover"
        [mdePopoverTriggerOn]="click"
        [mdeFocusTrapAutoCaptureEnabled]="false"
        [mdePopoverOverlapTrigger]="false"
        (close)="resetPopover()"
      >
        <mat-card
          *ngIf="popoverData?.deviceEventType; else meterCard"
          class="dashboard-chart-detail mat-elevation-z8"
        >
          <mat-card-header>
            <mat-card-title>
              <img
                *ngIf="popoverData?.deviceEventType"
                [src]="
                  './assets/images/legend/' + popoverIcons[popoverData?.deviceEventType]
                "
              />
              {{
                'dashboard.detail-overlay.' + popoverData?.deviceEventType + '.title'
                  | translate
              }}
              <span *ngIf="popoverData?.deviceEventStatus === 'incomplete'">
                ({{ 'dashboard.detail-overlay.eventStatus' | translate }})
              </span>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="dashboard-chart-detail-container">
              <div class="dashboard-chart-detail-stats">
                <div>
                  <div>
                    <h4>{{ 'dashboard.detail-overlay.trigger-date' | translate }}:</h4>
                    <div>
                      {{ popoverData?.triggeredWhen | date: 'longDate' }}
                    </div>
                  </div>
                  <div *ngIf="popoverData?.eventMagnitude">
                    <h4>{{ 'dashboard.detail-overlay.magnitude' | translate }}:</h4>
                    <div>
                      {{
                        (popoverData?.deviceEventType === 'highFrequencyImpulse'
                          ? 'dashboard.detail-overlay.hf-magnitude-value'
                          : 'dashboard.detail-overlay.magnitude-value'
                        ) | translate: { value: popoverData?.eventMagnitude }
                      }}
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <h4>{{ 'dashboard.detail-overlay.trigger-time' | translate }}:</h4>
                    <div>
                      {{ popoverData.triggeredWhen | date: 'shortTime' }}
                    </div>
                  </div>
                  <div>
                    <h4>{{ 'dashboard.detail-overlay.duration' | translate }}:</h4>
                    <div *ngIf="popoverData?.duration">
                      {{ popoverData.duration }}
                      {{ 'global.milliseconds' | translate }}
                    </div>
                    <div *ngIf="!popoverData?.duration">
                      {{ 'global.not-applicable' | translate }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="dashboard-chart-detail-info">
                <h4>
                  {{ 'dashboard.detail-overlay.event-identification' | translate }}:
                </h4>
                <div
                  [innerHTML]="
                    'dashboard.detail-overlay.' +
                      popoverData.deviceEventType +
                      '.event-identification' | translate
                  "
                ></div>
                <h4>{{ 'dashboard.detail-overlay.impact' | translate }}:</h4>
                <div
                  [innerHTML]="
                    'dashboard.detail-overlay.' + popoverData.deviceEventType + '.impact'
                      | translate
                  "
                ></div>
              </div>
              <div class="dashboard-chart-detail-footer">
                <span *ngIf="popoverData?.deviceEventStatus !== 'incomplete'">
                  <a
                    mat-button
                    color="accent"
                    [routerLink]="[
                      '/event-graph',
                      mpId,
                      accountId,
                      popoverData.deviceEventId,
                      popoverData.deviceEventTypeId,
                      popoverData.originalTriggeredWhen
                    ]"
                    [queryParams]="{ account: accountId, mpId: mpId }"
                    *ngIf="isPartner || token.metaData.isSystemAdministrator === '1'"
                    target="_blank"
                  >
                    {{ 'dashboard.detail-overlay.view-event-graph' | translate }}
                    <mat-icon svgIcon="launch"></mat-icon>
                  </a>
                </span>
                <br />
                <a
                  mat-button
                  color="accent"
                  [routerLink]="['/more-information', popoverData.deviceEventType]"
                  target="_blank"
                >
                  {{ 'dashboard.detail-overlay.more-info' | translate }}
                  <mat-icon svgIcon="launch"></mat-icon>
                </a>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <ng-template #meterCard>
          <mat-card class="notes-card">
            <mat-card-header>
              <mat-card-title class="align-center">
                <mat-icon svgIcon="calendar-blank"></mat-icon>
                {{ this.popoverData.summary }}
              </mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div class="dashboard-chart-detail-container">
                <div class="dashboard-chart-detail-stats">
                  <div>
                    <h4>{{ 'notes.new.date' | translate }}</h4>
                    <div>{{ this.popoverData.tzShiftedDate }}</div>
                  </div>
                  <div>
                    <h4>{{ 'notes.new.time' | translate }}</h4>
                    <div>{{ this.popoverData.tzShiftedTime }}</div>
                  </div>
                </div>
              </div>
              <div class="dashboard-chart-detail-info">
                <h4>{{ 'notes.new.details' | translate }}</h4>
                <div>{{ this.popoverData.details }}</div>
              </div>
            </mat-card-content>
          </mat-card>
        </ng-template>
      </mde-popover>
      <app-dashboard-graph
        #dashboardGraph
        (eventMarkerClick)="setPopoverEventData($event)"
        (chartUpdate)="refreshData($event)"
        [mdePopoverTriggerFor]="appPopover"
        [mdePopoverBackdropCloseOnClick]="true"
        [mdePopoverCloseOnClick]="false"
        [usePreCommissionStart]="usePreCommissionStart && commissionedWhen"
      ></app-dashboard-graph>
    </div>
    <div class="chart-detail">
      <div class="power-consumption">
        <div class="label">{{ 'dashboard.power.title' | translate }}</div>
        <div class="value">
          <div
            class="figure"
            *ngIf="info.powerQualityMeasures.lastPowerConsumption.value / 1000 < 1000"
          >
            {{
              !isMpOffline &&
              daysDisconnected < 30 &&
              info.powerQualityMeasures.lastPowerConsumption.powerQualityStatusType ==
                2 &&
              info.powerQualityMeasures.lastPowerConsumption.value
                ? (info.powerQualityMeasures.lastPowerConsumption.value / 1000 | number)
                : ('global.na' | translate)
            }}
          </div>
          <div
            class="figure"
            *ngIf="info.powerQualityMeasures.lastPowerConsumption.value / 1000 >= 1000"
          >
            {{
              !isMpOffline &&
              daysDisconnected < 30 &&
              info.powerQualityMeasures.lastPowerConsumption.powerQualityStatusType ==
                2 &&
              info.powerQualityMeasures.lastPowerConsumption.value
                ? (info.powerQualityMeasures.lastPowerConsumption.value / 1000
                  | number: '1.0-0')
                : ('global.na' | translate)
            }}
          </div>
          <div class="measurement">kW</div>
        </div>
        <div class="caption">
          {{
            !isMpOffline && daysDisconnected < 30
              ? ('dashboard.as-of-time'
                | translate
                  : {
                      time:
                        !isMpOffline &&
                        info.powerQualityMeasures.lastCommunication &&
                        info.powerQualityMeasures.lastCommunication.value
                          ? (info.powerQualityMeasures.lastCommunication.value
                            | date: 'short')
                          : ('global.na' | translate)
                    })
              : ''
          }}
        </div>
      </div>
      <div class="add-note">
        <button
          mat-flat-button
          (click)="addNewNote()"
          class="new-note-button"
          color="accent"
          data-cy="add_note_button"
        >
          {{ 'notes.newNoteButton' | translate }}
          <img class="notes_icon" src="./assets/images/legend/add_notes_icon.png" />
        </button>
      </div>
      <div class="events">
        <div class="conform">
          <mat-icon svgIcon="calendar-blank"></mat-icon>
          {{ 'dashboard.legend.notes' | translate: { count: notesArray?.length } }}
        </div>

        <div>
          <img src="./assets/images/legend/majorSags.svg" />
          {{
            'dashboard.legend.major-sags'
              | translate: { count: countEvents('voltageSag') }
          }}
        </div>
        <div>
          <img src="./assets/images/legend/swellEvents.svg" />
          {{
            'dashboard.legend.majorSwells'
              | translate: { count: countEvents('voltageSwell') }
          }}
        </div>
        <div>
          <img src="./assets/images/legend/hfImpulses.svg" />
          {{
            'dashboard.legend.hf-impulses'
              | translate: { count: countEvents('highFrequencyImpulse') }
          }}
        </div>
        <div>
          <img src="./assets/images/legend/interruptions.svg" />
          {{
            'dashboard.legend.interruptions'
              | translate: { count: countEvents('interruption') }
          }}
        </div>
        <div *ngIf="isQubeScan">
          <img src="./assets/images/legend/other_events.svg" />
          {{ 'dashboard.legend.othersEvents' | translate }} ({{ othersEvents.length }})
        </div>
      </div>
    </div>
  </div>
  <div class="details dashboard-box">
    <div class="heading">
      <div class="caption">
        <div class="range" *ngIf="!isQubeScan">{{ 'dashboard.last-30-days' | translate }}</div>
        <div class="label">{{ 'dashboard.status' | translate }}</div>
      </div>
      <div class="readings">
        <div class="detail">
          <div class="reading">
            {{ 'dashboard.consumed.title' | translate }}
          </div>
          <div class="value">
            {{
              info.powerQualityMeasures.consumptionPrior30Days.powerQualityStatusType == 2
                ? ('dashboard.consumed.value'
                  | translate
                    : {
                        value:
                          info.powerQualityMeasures.consumptionPrior30Days.value
                            .consumption | number: '1.1-1'
                      })
                : ('global.na' | translate)
            }}
          </div>
        </div>
        <div class="detail" *ngIf="!isQubeScan">
          <div class="reading">{{ 'dashboard.waste.title' | translate }}</div>
          <div class="value">
            {{
              info.powerQualityMeasures.wastePrior30Days.powerQualityStatusType == 2
                ? ('dashboard.waste.value'
                  | translate
                    : {
                        value:
                          info.powerQualityMeasures.wastePrior30Days.value
                          | number: '1.1-1'
                      })
                : ('global.na' | translate)
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="tabs-container" *ngIf="!isQubeScan">
      <mat-tab-group
        mat-stretch-tabs
        disableRipple="true"
        animationDuration="0ms"
        (selectedTabChange)="tabChanged()"
      >
        <mat-tab *ngFor="let tab of tabs">
          <ng-template mat-tab-label>
            <mat-icon
              [svgIcon]="
                tabIcons[(info?.powerQualityMeasures)[tab].powerQualityStatusType].icon
              "
              [class]="
                tabIcons[(info?.powerQualityMeasures)[tab].powerQualityStatusType].class
              "
            ></mat-icon>
            {{ 'dashboard.tabs.' + tab + '.label' | translate }}
          </ng-template>
          <div
            class="stat-detail"
            [innerHTML]="'health.' + tab + '.compact' | translate"
          ></div>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div class="tabs-container qube-scan-container" *ngIf="isQubeScan && tabs?.length > 0 " >
      <mat-tab-group>
          <mat-tab  *ngFor="let tab of tabs">
            <ng-template mat-tab-label>
              {{ 'dashboard.tabs.' + tab + '.label' | translate }}
            </ng-template>
            <mat-list [ngSwitch]="powerQualityStatus">
              <mat-list-item *ngSwitchCase="'Uncommissioned'">
                  <mat-icon mat-list-icon svgIcon="uncommissioned"></mat-icon>
                  <div mat-line>Uncommissioned</div>
              </mat-list-item>
              <mat-list-item *ngSwitchCase="'NoData'">
                  <mat-icon mat-list-icon svgIcon="no-data"></mat-icon>
                  <div mat-line>No Data</div>
              </mat-list-item>
              <mat-list-item *ngSwitchCase="'Insufficient'">
                  <mat-icon mat-list-icon svgIcon="insufficient-data"></mat-icon>
                  <div mat-line>Insufficient Data</div>
              </mat-list-item>
              <mat-list-item *ngSwitchCase="'Good'">
                  <mat-icon mat-list-icon svgIcon="good-data"></mat-icon>
                  <div mat-line>Good</div>
              </mat-list-item>
              <mat-list-item *ngSwitchCase="'Bad'">
                  <mat-icon mat-list-icon svgIcon="bad-data"></mat-icon>
                  <div mat-line>Bad</div>
              </mat-list-item>
          </mat-list>
          </mat-tab>
        </mat-tab-group>
      </div>
  </div>
</div>
