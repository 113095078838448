import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { ChannelsActions } from '../actions';
import { MeasurementPointsService } from 'src/app/shared/services/measurement-points.service';

@Injectable()
export class ChannelsEffects {
  @Effect()
  setChannels$ = this.actions$.pipe(
    ofType(ChannelsActions.setChannels.type),
    switchMap((action) => {
      return this.mpService.getChannelDefinition(action.payload.mpId).pipe(
        map((channels) => ({
          type: '[Charts] Set Channels Success',
          payload: channels,
        })),
        catchError(() => of({ type: '[Charts] Set Channels Fail' }))
      );
    })
  );

  constructor(
    private actions$: Actions<ChannelsActions.ChannelsActionsUnion>,
    private mpService: MeasurementPointsService
  ) {}
}
