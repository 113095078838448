import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Inject,
} from '@angular/core';

@Component({
  selector: 'app-auto-logout',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './auto-logout.component.html',
  styleUrls: ['./auto-logout.component.scss'],
})
export class AutoLogoutComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AutoLogoutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { warn: false }
  ) {}

  ngOnInit() {}

  close(): void {
    this.dialogRef.close();
  }

  ok() {
    this.dialogRef.close(this.data.warn);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
