import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { of } from 'rxjs';
import { switchMap, tap, take } from 'rxjs/operators';

import { AuthService } from './services';

@Injectable()
export class IsAuthenticatedGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // attempt to renew the token (if any)
    return this.auth.renewToken(state.url).pipe(
      // switch the stream to the auth observable
      switchMap(() =>
        this.auth.authed.pipe(
          take(1),
          // if we have a token at this point, allow navigation; otherwise return a UrlTree to the login page
          switchMap((payload) =>
            payload ? of(true) : of(this.router.parseUrl('/login'))
          ),
          // re-parse the token to kick off a fresh call to user/account api
          tap((p) => (p === true ? this.auth.reload() : null))
        )
      )
    );
  }
}
