import { select, Store } from '@ngrx/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';

import { AuthService, LoaderService } from '../shared/services';
import { IAccount } from '../shared/classes/account';
import { IUser } from '../shared/classes/user';
import { NotificationsService } from '../shared/modules/notifications/shared/notifications.service';
import { ReportsService } from '../shared/services/reports.service';
import { MonthlyReportsService } from './../shared/services/monthly-reports.service';
import * as fromUser from './../_store/_reducers';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '../shared/classes/component-destroy.class';
import { MeasurementPointsService } from '../shared/services/measurement-points.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  public rows = [
    'powerMonthToDate',
    'powerFactorMonthToDate',
    'voltageFluctuationsMonthToDate',
    'imbalanceMonthToDate',
    'harmonicsMonthToDate',
    'groundCurrentMonthToDate',
  ];

  public reportData: any = [] as any;

  public year: any = null;
  public mpId: number;
  public minYear = new Date().getFullYear();
  public minMonth: number;
  public maxYear = new Date().getFullYear();

  public queryParams: ParamMap;
  public userPrefs: any = {};
  public account: IAccount;
  public user: IUser;
  public reportsList = [];
  private isPartner: boolean;

  private accountId: number;

  public statusIcons = {
    1: {
      icon: 'alert-octagon',
      class: 'negative',
    },
    2: {
      icon: 'check-circle-outline',
      class: 'positive',
    },
    3: {
      icon: 'help-circle-outline',
      class: 'neutral',
    },
    4: {
      icon: 'block-helper',
      class: 'neutral block-helper',
    },
  };

  private userSub$: Subscription;
  private isPartnerSub$: Subscription;

  constructor(
    private reportsService: ReportsService,
    private authService: AuthService,
    private loaderService: LoaderService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private monthlyReportService: MonthlyReportsService,
    private mpService: MeasurementPointsService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<fromUser.State>
  ) {
    super();
    this.year = new Date().getFullYear();
  }

  ngOnInit() {
    this.isPartnerSub$ = this.store
      .pipe(select(fromUser.getIsPartner))
      .subscribe((isPartner: number) => {
        this.isPartner = isPartner === 1;
      });

    this.route.queryParamMap
      .pipe(untilComponentDestroyed(this))
      .subscribe((params: ParamMap) => {
        if (
          this.mpService.selectedMeasurementPoint &&
          this.mpService.selectedMeasurementPoint.measurementPointTypeId === 1
        ) {
          this.router.navigate(['/dashboard'], {
            queryParams: {
              account: params.get('account'),
              mpId: params.get('mpId'),
            },
          });
        }

        this.queryParams = params;

        this.userSub$ = this.store
          .pipe(select(fromUser.getUser))
          .subscribe((user: IUser) => {
            if (!user) {
              return;
            }
            this.user = user;

            this.authService.accountO.pipe(take(1)).subscribe((account: IAccount) => {
              this.account = account;

              this.authService.preferences.pipe(take(1)).subscribe(async (userPrefs) => {
                this.userPrefs = userPrefs;

                const mpId = this.queryParams.get('mpId') || this.userPrefs.mpId;
                let accountId =
                  parseInt(this.queryParams.get('account'), 10) || this.userPrefs.account;

                if (this.isPartner) {
                  accountId = this.queryParams.get('account') || this.userPrefs.account;
                }

                this.accountId = accountId;
                this.mpId = mpId;

                if (mpId) {
                  this.loaderService.show();

                  const reportData = await this.reportsService.getHistoricalReportsData(
                    mpId,
                    this.year
                  );

                  this.loaderService.hide();

                  if (reportData.errno) {
                    this.notificationsService.alert(
                      reportData.sqlMessage ||
                        this.translateService.instant('global.loading-mp-error')
                    );
                    return;
                  }
                  this.monthlyReportService.setReportList(
                    this.accountId,
                    this.mpId,
                    this.year,
                    this.isPartner
                  );

                  this.monthlyReportService.minMonth.pipe(take(2)).subscribe((month) => {
                    this.minMonth = month;
                  });

                  this.monthlyReportService.minYear.pipe(take(2)).subscribe((year) => {
                    this.minYear = year;
                  });

                  this.monthlyReportService.reportsList
                    .pipe(untilComponentDestroyed(this))
                    .subscribe((reportsList: Array<{ status: number }>) => {
                      this.reportsList = reportsList;
                    });

                  this.reportData = reportData.map((month: any) => {
                    const data = {
                      documentId: month.documentId || null,
                    };
                    const fields = Object.keys(month.powerQualityMeasurements);

                    fields.forEach((field) => {
                      data[field] = {
                        value: this.getValue(month.powerQualityMeasurements[field]),
                        icon: this.getIcon(month.powerQualityMeasurements[field]),
                      };
                    });

                    return data;
                  });
                }
              });
            });
          });
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.userSub$.unsubscribe();
    this.isPartnerSub$.unsubscribe();
  }

  getValue(property) {
    let value = null;

    if (property && property.hasOwnProperty('value') && property.value) {
      if (typeof property.value === 'object') {
        if (property.value.hasOwnProperty('samplesThisPeriod')) {
          value = property.value.samplesThisPeriod;
        } else if (property.value.hasOwnProperty('severe')) {
          value = property.value.severe;
        } else if (property.value.hasOwnProperty('total')) {
          value = property.value.total;
        }
      } else {
        value = property.value;
      }
    }

    return value;
  }

  getIcon(property) {
    if (property.powerQualityStatusType) {
      return this.statusIcons[property.powerQualityStatusType];
    }

    return this.statusIcons['3'];
  }

  navigate(num = 0) {
    this.year = this.year + num;

    const keys = this.queryParams.keys;
    const params = {};

    keys.forEach((key) => {
      params[key] = this.queryParams.get(key);
    });

    this.reportsList = [
      { status: -1 },
      { status: -1 },
      { status: -1 },
      { status: -1 },
      { status: -1 },
      { status: -1 },
      { status: -1 },
      { status: -1 },
      { status: -1 },
      { status: -1 },
      { status: -1 },
      { status: -1 },
    ];
    this.router.navigate([], {
      queryParams: {
        ...params,
        year: this.year,
      },
    });
  }

  public fillMonthlyReport(month: number): void {
    this.router.navigate(
      ['/monthly-report', this.accountId, this.mpId, this.year, month],
      {
        queryParams: {
          account: this.accountId,
          mpId: this.mpId,
        },
      }
    );
  }
}
