import { DeleteMemberDialogComponent } from './../manage-members/delete-member-dialog/delete-member-dialog.component';
import { MemberDialogComponent } from '../manage-members/member-dialog/member-dialog.component';
import { DiligentApiService } from './services/diligent-api.service';
import { LoaderComponent } from './components/loader/loader.component';
import { NotificationsModule } from './modules/notifications/notifications.module';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import {
  UtilitiesService,
  LoaderService,
  AuthService,
  TokenService,
  DashboardService,
  SitesService,
  PowerQualityMeasuresService,
  ChartDefinitions,
} from './services';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { DomSanitizer } from '@angular/platform-browser';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { TranslateModule } from '@ngx-translate/core';
import { MdePopoverModule } from '@material-extended/mde';
import { SortablejsModule } from 'ngx-sortablejs';
import { NgxCaptchaModule } from 'ngx-captcha';

import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { AnonymousLayoutComponent } from './anonymous-layout/anonymous-layout.component';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { IsAuthenticatedGuard } from './route.guards';
import { ChangeSelectionComponent } from './components/header/change-selection/change-selection.component';
import { NewChartTabComponent } from '../charts/new-chart-tab/new-chart-tab.component';
import { DeleteNoteDialogComponent } from '../notes/delete-note-dialog/delete-note-dialog.component';
import { AutoLogoutComponent } from './components/auto-logout/auto-logout.component';
import {
  AutoLogoutResolver,
  PreferencesResolver,
  MeasurementPointResolver,
} from './route.resolvers';
import { TosaComponent } from '../activation/tosa/tosa.component';
import { NewNoteComponent } from '../notes/new-note/new-note.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { MeterDisplayComponent } from '../dashboard/meter-display/meter-display.component';
import { MeterComponent } from '../meter/meter.component';
import { ForgotPasswordDialogComponent } from '../login/forgot-password-dialog/forgot-password-dialog.component';
import { ResetPasswordDialogComponent } from '../login/reset-password-dialog/reset-password-dialog.component';
import { ContinueDialogComponent } from '../dashboard/meter-display/continue-dialog/continue-dialog.component';
import { DisplayImageComponent } from './components/display-image/display-image.component';
import { ManageMembersComponent } from '../manage-members/manage-members.component';
import { MonthsPipe } from './pipes/months.pipe';
import { MonthsNumberToStringPipe } from './pipes/months-number-to-string.pipe';
import { NotesSummaryPipe } from './pipes/notes-summary.pipe';
import { NotesDetailsPipe } from './pipes/notes-details.pipe';
import { DatePickerNavComponent } from './components/date-picker-nav/date-picker-nav.component';
import { DChartNavigatorComponent } from './components/date-picker-nav/d-chart-navigator/d-chart-navigator.component';
import { SiteMeasurementPointDialogComponent } from '../fleet/site-measurement-point-dialog/site-measurement-point-dialog.component';
import { QuillModule } from 'ngx-quill';

@NgModule({
  imports: [
    CommonModule,
    DragDropModule,
    RouterModule,
    FormsModule,
    TranslateModule,
    HttpClientModule,
    NotificationsModule,
    MatSelectModule,
    MatIconModule,
    MatSliderModule,
    MatMenuModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatButtonModule,
    MatTooltipModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatDialogModule,
    MdePopoverModule,
    MatCardModule,
    MatTableModule,
    MatSortModule,
    SortablejsModule,
    MatRadioModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatMomentDateModule,
    HighchartsChartModule,
    NgxCaptchaModule,
    QuillModule,
  ],
  declarations: [
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    NavigationComponent,
    AnonymousLayoutComponent,
    AppLayoutComponent,
    ChangeSelectionComponent,
    NewChartTabComponent,
    DeleteNoteDialogComponent,
    NewNoteComponent,
    AutoLogoutComponent,
    ForgotPasswordDialogComponent,
    ResetPasswordDialogComponent,
    ContinueDialogComponent,
    TosaComponent,
    MeterComponent,
    MeterDisplayComponent,
    DisplayImageComponent,
    ManageMembersComponent,
    MemberDialogComponent,
    DeleteMemberDialogComponent,
    TosaComponent,
    MonthsPipe,
    MonthsNumberToStringPipe,
    NotesSummaryPipe,
    NotesDetailsPipe,
    DatePickerNavComponent,
    DChartNavigatorComponent,
    SiteMeasurementPointDialogComponent,
  ],
  exports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    TranslateModule,
    HttpClientModule,
    HeaderComponent,
    FooterComponent,
    NavigationComponent,
    LoaderComponent,
    MatButtonToggleModule,
    MatSelectModule,
    MatIconModule,
    MatSliderModule,
    MatMenuModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatButtonModule,
    MatTooltipModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatExpansionModule,
    ChangeSelectionComponent,
    NewChartTabComponent,
    DeleteNoteDialogComponent,
    NewNoteComponent,
    MatCheckboxModule,
    MatDialogModule,
    MdePopoverModule,
    MatCardModule,
    MatTableModule,
    MatSortModule,
    SortablejsModule,
    TosaComponent,
    MatRadioModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MeterComponent,
    MeterDisplayComponent,
    MatRadioModule,
    HighchartsChartModule,
    MonthsPipe,
    MonthsNumberToStringPipe,
    NotesSummaryPipe,
    NotesDetailsPipe,
    DatePickerNavComponent,
    DChartNavigatorComponent,
    NgxCaptchaModule,
    QuillModule,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        AutoLogoutResolver,
        UtilitiesService,
        LoaderService,
        DiligentApiService,
        ChartDefinitions,
        AuthService,
        TokenService,
        DashboardService,
        SitesService,
        PowerQualityMeasuresService,
        IsAuthenticatedGuard,
        PreferencesResolver,
        MeasurementPointResolver,
        DatePipe,
        MonthsNumberToStringPipe,
        NotesSummaryPipe,
        NotesDetailsPipe,
      ],
    };
  }

  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg')
    ); // Or whatever path you placed mdi.svg at
    matIconRegistry.addSvgIcon(
      'expand',
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/expand_more.svg')
    );
    matIconRegistry.addSvgIcon(
      'collapse',
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/expand_less.svg')
    );
    matIconRegistry.addSvgIcon(
      'doc-archive',
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/archive_icon.svg')
    );
    matIconRegistry.addSvgIcon(
      'trends',
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/trends_icon.svg')
    );
    matIconRegistry.addSvgIcon(
      'pqube',
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/PQUBE3_Blue_Icon.svg')
    );
    matIconRegistry.addSvgIcon(
      'psl',
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/insite-logo_icon.svg')
    );

    matIconRegistry.addSvgIcon(
      'bad-data',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-circle-red.svg')
    );
    matIconRegistry.addSvgIcon(
      'good-data',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-circle-green.svg')
    );
    matIconRegistry.addSvgIcon(
      'insufficient-data',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-circle-blue.svg')
    );
    matIconRegistry.addSvgIcon(
      'no-data',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-circle-white.svg')
    );
    matIconRegistry.addSvgIcon(
      'uncommissioned',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-circle-grey.svg')
    );
  }
}
