import {
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  control,
  divIcon,
  featureGroup,
  icon,
  latLng,
  LatLng,
  latLngBounds,
  layerGroup,
  Map,
  marker,
  MarkerCluster,
  point,
  polyline,
  tileLayer
} from "leaflet";
import * as L from 'leaflet';
import "leaflet.markercluster";
import "leaflet.featuregroup.subgroup";
import "leaflet-providers";
import {MapPopupComponent} from "../map-popup/map-popup.component";
import {MapTooltipComponent} from "../map-tooltip/map-tooltip.component";
import {HttpClient, HttpParams} from "@angular/common/http";

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MapComponent implements OnInit, OnChanges {
  @Input() measurementPoints: any[];
  @Output() zoomFactor = new EventEmitter<number>() ;

  map;

  markerClusterGroup: L.MarkerClusterGroup;
  markerClusterData: L.Marker[] = [];
  markerClusterOptions: L.MarkerClusterGroupOptions = {
    iconCreateFunction: this.markerClusterIconCreateFunction,
    showCoverageOnHover: false
  };

  overlayVisible = false;
  overlayLoading = new Promise((resolve) => resolve());
  abortLoading = false;

  // Define our base layers so we can reference them multiple times
  streetMaps = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    detectRetina: true,
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  });
  satelliteMaps = tileLayer(
    'http://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    {
      detectRetina: true,
      attribution:
        'Map data &copy; ' +
        '<a href="http://openstreetmap.org">OpenStreetMap</a> contributors, ' +
        '<a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
        'Imagery © <a href="http://mapbox.com">Mapbox</a>'
  });
  // watercolorMap = tileLayer.provider('Stamen.Watercolor');

  usGrid = layerGroup();

  // Layers control object with our two base layers and the three overlay layers
  layersControl = {
    baseLayers: {
      'Topological View': this.streetMaps,
      'Satellite View': this.satelliteMaps,
      // 'Watercolor View': this.watercolorMap,
    },
    overlays: {
      'US Power Grid': this.usGrid,
    },
  };

  layersControlOptions = {
    sortLayers: true,
    sortFunction: (layerA, layerB, nameA, nameB) => (nameA === 'US Power Grid') ? -1 : (nameB === 'US Power Grid') ? 1 : nameA.localeCompare(nameB)
  };

  // Set the initial set of displayed layers (we could also use the leafletLayers input binding for this)
  options = {
    layers: [this.streetMaps],
  };

  statusIcons = {
    uncommissioned: icon({
      className: 'mp-icon-uncommissioned',
      iconUrl: 'assets/images/icon-circle-grey.svg',
      iconSize: [25, 25],
      popupAnchor: [0, -16],
      shadowSize: [25, 25]
    }),
    noData: icon({
      className: 'mp-icon-no-data',
      iconUrl: 'assets/images/icon-circle-white.svg',
      iconSize: [25, 25],
      popupAnchor: [0, -16],
      shadowSize: [25, 25]
    }),
    insufficientData: icon({
      className: 'mp-icon-insufficient-data',
      iconUrl: 'assets/images/icon-circle-blue.svg',
      iconSize: [25, 25],
      popupAnchor: [0, -16],
      shadowSize: [25, 25]
    }),
    goodData: icon({
      className: 'mp-icon-good-data',
      iconUrl: 'assets/images/icon-circle-green.svg',
      iconSize: [25, 25],
      popupAnchor: [0, -16],
      shadowSize: [25, 25]
    }),
    badData: icon({
      className: 'mp-icon-bad-data',
      iconUrl: 'assets/images/icon-circle-red.svg',
      iconSize: [25, 25],
      popupAnchor: [0, -16],
      shadowSize: [25, 25]
    })
  };

  constructor(
    private http: HttpClient,
    private resolver: ComponentFactoryResolver,
    private injector: Injector
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.drawMap();
  }

  markerClusterIconCreateFunction(cluster: MarkerCluster) {
    const childCount = cluster.getChildCount();

    // These need to be ordered from lowest-priority to highest-priority.
    const colors = [
      'marker-cluster-uncommissioned',
      'marker-cluster-insufficient-data',
      'marker-cluster-no-data',
      'marker-cluster-good-data',
      'marker-cluster-bad-data'
    ];
    let colorIndex = 0;
    let clusterIndex;


    cluster.getAllChildMarkers().forEach((m) => {
      switch (m.options.icon.options.className){
        case 'mp-icon-bad-data':
          clusterIndex = colors.indexOf('marker-cluster-bad-data');
          break;

        case 'mp-icon-good-data':
          clusterIndex = colors.indexOf('marker-cluster-good-data');
          break;

        case 'mp-icon-no-data':
          clusterIndex = colors.indexOf('marker-cluster-no-data');
          break;

        case 'mp-icon-insufficient-data':
          clusterIndex = colors.indexOf('marker-cluster-insufficient-data');
          break;

        case 'mp-icon-uncommissioned':
          clusterIndex = colors.indexOf('marker-cluster-uncommissioned');
          break;

        default:
          clusterIndex = 0;
          break;
      }

      // If we've already selected a lower-priority, and now have something of higher-priority, go with the higher.
      if (colorIndex < clusterIndex) {
        colorIndex = clusterIndex;
      }
    });

    return divIcon({ html: '<div><span>' + childCount + '</span></div>', className: 'marker-cluster ' + colors[colorIndex], iconSize: new L.Point(40, 40) });
  }

  onMarkerClusterReady(group) {
    this.markerClusterGroup = group;
    this.drawMap();
  }

  onMapReady(map: Map) {
    this.map = map;

    control.scale({ position: 'bottomleft' }).addTo(map);

    map.on('overlayadd', this.onOverlayAdd.bind(this));
    map.on('overlayremove', this.onOverlayRemove.bind(this));

    map.on('zoomend', this.onZoomEnd.bind(this));
    map.on('moveend', this.onMoveEnd.bind(this));

    this.drawMap();
  }

  private drawMap() {
    if (this.measurementPoints && this.map && this.markerClusterGroup) {
      this.internalDrawMap();
    }
  }

  private internalDrawMap() {
    const centers: LatLng[] = [];

    this.measurementPoints.forEach((measurementPoint) => {
      const siteCenter = latLng(measurementPoint.latitude || 0, measurementPoint.longitude || 0);

      centers.push(siteCenter);

      const markerIcon = this.statusIcons[measurementPoint.iconStatus];

      const popupFactory = this.resolver.resolveComponentFactory(MapPopupComponent);
      const popupComponent = popupFactory.create(this.injector);
      popupComponent.instance.measurementPoint = measurementPoint;

      // we need to manually trigger change detection on our in-memory component
      // s.t. its template syncs with the data we passed in
      popupComponent.changeDetectorRef.detectChanges();

      const tooltipFactory = this.resolver.resolveComponentFactory(MapTooltipComponent);
      const tooltipComponent = tooltipFactory.create(this.injector);
      tooltipComponent.instance.measurementPoint = measurementPoint;
      tooltipComponent.changeDetectorRef.detectChanges();

      const measurementPointMarker = marker(siteCenter, {
        icon: markerIcon
      })
          .bindPopup(popupComponent.location.nativeElement, {className: 'map-popup'}).openPopup();

      // Make sure that the displaying the popup closes the tooltip on the same marker.
      // https://stackoverflow.com/questions/55267558/show-leaflet-tooltip-on-hover-only-when-popup-is-not-being-shown
      ((m, t) => {
        m.on('mouseover click', () => {
          m.unbindTooltip();
          if (!m.isPopupOpen()) {
            m.bindTooltip(t, {className: 'map-tooltip'}).openTooltip();
          }
        });
      })(measurementPointMarker, tooltipComponent.location.nativeElement);

      if (!this.layersControl.overlays[measurementPoint.accountName]) {
        this.layersControl.overlays[measurementPoint.accountName] = featureGroup.subGroup(this.markerClusterGroup).addTo(this.map);
      }
      this.layersControl.overlays[measurementPoint.accountName].addLayer(measurementPointMarker);
    });

    const bounds = polyline(centers);
    this.map.fitBounds(bounds.getBounds(), {
      padding: point(24, 24),
      maxZoom: 12,
      animate: true,
    });

    this.emitZoomFactor();
    this.displayOverlay();
  }

  displayOverlay() {
    if (this.overlayVisible && this.map.getZoom() > 7) {
      this.abortLoading = true;
      this.overlayLoading.then(() => this.usGridSelected());
    }
  }

  hideOverlay() {}

  computeBounds() {
    const bounds = latLngBounds([]);

    this.map.eachLayer(layer => {
      if (layer.getBounds) {
        const layerBounds = layer.getBounds();
        if (layerBounds.isValid) {
          bounds.extend(layerBounds);
        }
      }
    });

    if (bounds.isValid()) {
      this.map.fitBounds(bounds, {
        padding: point(24, 24),
        maxZoom: 12,
        animate: true
      });
    }
  }

  onOverlayAdd(e) {
    if (e.name === 'US Power Grid') {
      this.overlayVisible = true;
      this.displayOverlay();
    } else {
      this.computeBounds();
    }
  }

  onOverlayRemove(e) {
    if (e.name === 'US Power Grid') {
      this.abortLoading = true;
      this.overlayVisible = false;
      this.hideOverlay();
      this.usGrid.clearLayers();
    } else {
      this.computeBounds();
    }
  }

  onZoomEnd(e) {
    this.emitZoomFactor();
    this.hideOverlay();
    this.displayOverlay();
  }

  onMoveEnd(e) {
    this.hideOverlay();
    this.displayOverlay();
  }

  emitZoomFactor() {
    this.zoomFactor.emit(this.map.getZoom());
  }

  async usGridSelected() {
    let featureLength = 0;
    let resultOffset = 0;

    this.usGrid.clearLayers();

    this.overlayLoading = new Promise(async (resolve) => {
      do {
        const result = await this.getUsGrid(
          this.map.getBounds().toBBoxString(),
          resultOffset
        );

        result.features.forEach((feature) => {
          const f = polyline(
            feature.geometry.paths[0].map((elem) => [elem[1], elem[0]]),
            { color: 'red' }
          );
          this.usGrid.addLayer(f);
        });

        featureLength = result.features.length;
        resultOffset += featureLength;

        console.log('length', featureLength, 'next offset', resultOffset);
      } while (featureLength && !this.abortLoading);

      this.abortLoading = false;

      resolve();
    });
  }

  getUsGrid(bounds: string, resultOffset: number): Promise<any> {
    const url =
      'https://services1.arcgis.com/Hp6G80Pky0om7QvQ/arcgis/rest/services/Electric_Power_Transmission_Lines/FeatureServer/0/query';
    const params = new HttpParams()
      .set('where', '1=1')
      .set('outFields', '*')
      .set('geometry', bounds)
      .set('geometryType', 'esriGeometryEnvelope')
      .set('inSR', '4326')
      .set('spatialRel', 'esriSpatialRelIntersects')
      .set('outSR', '4326')
      .set('f', 'json')
      .set('resultOffset', resultOffset.toString());

    return this.http.get(url, { params }).toPromise();
  }
}
