<div class="heading">
  <h1>{{ 'notes.title' | translate }}</h1>
</div>
<section class="notes-container container">
  <div id="notes-header" class="header">
    <h1>{{ 'notes.title' | translate }} ({{ notesArray?.data.length }})</h1>
    <button
      mat-flat-button
      (click)="addNewNote()"
      class="new-button"
      color="accent"
    >
      {{ 'notes.newNoteButton' | translate }}
       <img class="notes_icon" src="./assets/images/legend/add_notes_icon.png" />
    </button>
  </div>
  <div id="notes-table" class="table-container">
    <table mat-table [dataSource]="notesArray" matSort>
      <ng-container matColumnDef="startDateTime">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ 'notes.tableHeader.start' | translate }}
        </th>
        <td class="date-table-col" mat-cell *matCellDef="let note">
          {{ note.startDateTime | formatDate: 'M/D/YYYY h:mm A z':timezone }}
        </td>
      </ng-container>
      <ng-container matColumnDef="summary">
        <th mat-header-cell mat-sort-header disabled *matHeaderCellDef>
          {{ 'notes.tableHeader.summary' | translate }}
        </th>
        <td class="summary-table-col" mat-cell *matCellDef="let note">
          {{ note.summary | notesSummaryPipe }}
        </td>
      </ng-container>
      <ng-container matColumnDef="details">
        <th mat-header-cell mat-sort-header disabled *matHeaderCellDef>
          {{ 'notes.tableHeader.details' | translate }}
        </th>
        <td class="details-table-col" mat-cell *matCellDef="let note">
          <div *ngIf="note.details" class="note-details">
            {{ note.details | notesDetailsPipe }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="createdBy">
        <th mat-header-cell mat-sort-header *matHeaderCellDef disabled>
          {{ 'notes.tableHeader.createdBy' | translate }}
        </th>
        <td class="created-by-table-col" mat-cell *matCellDef="let note">
          {{ note.firstName }} {{ note.lastName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="icons">
        <th mat-header-cell mat-sort-header disabled *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let note; let i = index">
          <div id="buttons-cell">
            <button mat-icon-button (click)="deleteNote(note.id)">
              <mat-icon svgIcon="delete-forever" color="accent"></mat-icon>
            </button>
            <button mat-icon-button (click)="editNote(note)">
              <mat-icon svgIcon="pencil" color="accent"></mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr
        mat-row
        [ngClass]="{ highlight: selectedRowId == row.id }"
        *matRowDef="let row; columns: columnsToDisplay"
      ></tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[20, 40, 60, 80, 100]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</section>
