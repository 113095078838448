import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notesDetailsPipe',
})
export class NotesDetailsPipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    if (value && value.length > 128) {
      return value.substring(0, 128) + '...';
    } else {
      return value;
    }
  }
}
