import { createAction, props } from '@ngrx/store';

import { IUser } from '../../../shared/classes/user';

export const getUser = createAction('[App Init] Get User');
export const getUserSuccess = createAction(
  '[App Init] Get User Success',
  props<{ user: IUser }>()
);
export const getUserFail = createAction(
  '[App Init] Get User Fail',
  props<{ error: any }>()
);
export const setUserIsPartnerSuccess = createAction(
  '[Login] Set User IsPartner Success',
  props<{ payload: { isPartner: number } }>()
);

export type UserActionsUnion = ReturnType<
  | typeof getUser
  | typeof getUserFail
  | typeof getUserSuccess
  | typeof setUserIsPartnerSuccess
>;
