<div class="reports">
  <div class="heading">
    <h1>{{ 'reports.title' | translate }}</h1>
    <div class="pagination">
      <button mat-button (click)="navigate(-1)" [disabled]="year <= minYear">
        <mat-icon svgIcon="chevron-left"></mat-icon>
        {{ 'reports.pagination.prev' | translate }}
      </button>
      <div class="current">{{ year }}</div>
      <button mat-button (click)="navigate(1)" [disabled]="year >= maxYear">
        {{ 'reports.pagination.next' | translate }}
        <mat-icon svgIcon="chevron-right"></mat-icon>
      </button>
    </div>
  </div>
  <div
    class="reports-table-container"
    *ngIf="reportData.length > 0 && rows.length > 0"
  >
    <table>
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th>{{ 'reports.months.jan' | translate }}</th>
          <th>{{ 'reports.months.feb' | translate }}</th>
          <th>{{ 'reports.months.mar' | translate }}</th>
          <th>{{ 'reports.months.apr' | translate }}</th>
          <th>{{ 'reports.months.may' | translate }}</th>
          <th>{{ 'reports.months.jun' | translate }}</th>
          <th>{{ 'reports.months.jul' | translate }}</th>
          <th>{{ 'reports.months.aug' | translate }}</th>
          <th>{{ 'reports.months.sep' | translate }}</th>
          <th>{{ 'reports.months.oct' | translate }}</th>
          <th>{{ 'reports.months.nov' | translate }}</th>
          <th>{{ 'reports.months.dec' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="legend-labels">
            <div>
              {{ 'global.major-sags' | translate }}
              <img src="./assets/images/legend/majorSags.svg" />
            </div>
            <div>
              {{ 'global.majorSwells' | translate }}
              <img src="./assets/images/legend/swellEvents.svg" />
            </div>
            <div>
              {{ 'global.hf-impulses' | translate }}
              <img src="./assets/images/legend/hfImpulses.svg" />
            </div>
            <div>
              {{ 'global.interruptions' | translate }}
              <img src="./assets/images/legend/interruptions.svg" />
            </div>
          </td>
          <td
            *ngFor="let num of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]"
            class="legend-values"
          >
            <div>
              {{
                reportData[num]?.sagsMonthToDate.value
                  ? (reportData[num]?.sagsMonthToDate.value | number)
                  : ('global.na' | translate)
              }}
            </div>
            <div>
              {{
                reportData[num]?.swellsMonthToDate.value
                ? (reportData[num]?.swellsMonthToDate.value | number)
                : ('global.na' | translate)
              }}
            </div>
            <div>
              {{
                reportData[num]?.highfrequencyImpulsesMonthToDate.value
                  ? (reportData[num]?.highfrequencyImpulsesMonthToDate.value
                    | number)
                  : ('global.na' | translate)
              }}
            </div>
            <div>
              {{
                reportData[num]?.outagesMonthToDate.value
                  ? (reportData[num]?.outagesMonthToDate.value | number)
                  : ('global.na' | translate)
              }}
            </div>
          </td>
        </tr>
        <tr *ngFor="let row of rows">
          <td>{{ 'global.' + row | translate }}</td>
          <td *ngFor="let report of reportData">
            <mat-icon
              [svgIcon]="report[row]?.icon.icon"
              [class]="report[row]?.icon.class"
            ></mat-icon>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>&nbsp;</td>
          <td *ngFor="let num of reportsList; let i = index">
            <button
              mat-icon-button
              data-cy="reports-button"
              color="accent"
              [disabled]="num.status === -1"
              (click)="fillMonthlyReport(i)"
            >
              <mat-icon
                *ngIf="num.status === -1"
                svgIcon="file-outline"
              ></mat-icon>
              <mat-icon
                *ngIf="num.status === 0"
                svgIcon="file-outline"
              ></mat-icon>
              <mat-icon
                *ngIf="num.status === 1"
                svgIcon="file-document-edit-outline"
              ></mat-icon>
              <mat-icon
                *ngIf="num.status === 2"
                svgIcon="file-document"
              ></mat-icon>
              <mat-icon
                *ngIf="num.status === 3"
                svgIcon="doc-archive"
              ></mat-icon>
            </button>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
