import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
} from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import * as moment from 'moment';
import * as mTZ from 'moment-timezone';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { take } from 'rxjs/operators';
import * as Highcharts from 'highcharts/highstock';
import Boost from 'highcharts/modules/boost';
import drag from 'highcharts/modules/drag-panes';
import HC_exporting from 'highcharts/modules/exporting';
import noData from 'highcharts/modules/no-data-to-display';
import More from 'highcharts/highcharts-more';
import gapUnit from 'highcharts/modules/broken-axis';

import { GraphManagerService } from 'src/app/shared/services/graph-manager.service';
import { ISeriesOptions } from 'src/app/shared/classes/chart.interface';
import { PhaseList } from 'src/app/shared/classes/phase-list.interface';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from 'src/app/shared/classes/component-destroy.class';
import { MeasurementPointsService } from 'src/app/shared/services/measurement-points.service';

(window as any).moment = moment;

@Component({
  selector: 'app-chart-set',
  templateUrl: './chart-set.component.html',
  styleUrls: ['./chart-set.component.scss'],
})
export class ChartSetComponent
  extends OnDestroyMixin
  implements OnInit, OnDestroy, OnChanges {
  private mpTimezone;
  public absoluteStartDate;
  // height applied to chart element
  public chartStyle: {};

  // setting to true forces chart rerender
  public updateFlag = false;

  // only updates chart elements as specifically
  public oneToOneFlag = true;

  // conditional read by ngIf to display component
  public chartReady = false;

  // array of months PQube has been live
  public monthsWithData: string[];
  // selected month of data
  public monthToDisplay;
  public monthControl = new FormControl();
  public Highcharts = Highcharts;
  public chartConstructor = 'stockChart';
  public currentRange: string;
  private chartInstance: Highcharts.Chart;
  private scope: moment.unitOfTime.DurationConstructor = 'month';
  private granularity = 'hour';
  private initialized: moment.Moment;
  private hardCodedChart = [
    'Power Energy',
    'Power Factor',
    'Voltage Fluctuation',
    'Harmonics',
    'Ground',
  ];

  @Input() usePreCommissionStart: boolean;
  @Input() chartSetName: string;
  @Input() chartsInSet: Array<any>;
  @Input() chartPhaseList: Array<PhaseList>;
  @Output() eventMarkerClick = new EventEmitter();
  @Output() chartRender = new EventEmitter();

  public chartOptions: any = {
    chart: {
      style: {
        fontFamily: 'Roboto, sans-serif',
      },
      events: {
        render: (chart, event) => {
          this.chartRender.emit(event);
        },
      },
      marginTop: 75,
    },
    title: {
      floating: false,
      align: 'center',
      y: 13,
    },
    boost: {
      allowForce: false,
      useAlpha: false,
    },
    exporting: {
      buttons: {
        contextButton: {
          y: 0,
        },
      },
      sourceHeight: 1200,
      sourceWidth: 1500,
    },
    noData: {
      position: {
        verticalAlign: 'top',
        y: 100,
      },
    },
    credits: {
      enabled: false,
    },
    time: {
      useUTC: true,
      timzoneOffset: undefined,
    },
    xAxis: [
      {
        plotLines: [
          {
            color: '#000000',
            width: 2,
          },
        ],
        ordinal: false,
        dateTimeLabelFormats: {
          day: {
            main: '%b %d',
          },
          hour: {
            main: '%l:%M %p',
          },
          minute: {
            main: '%l:%M %p',
          },
        },
        events: {
          afterSetExtremes: (axis, event) => {
            const newTitle = this.formatTitle();
            this.chartInstance.setTitle({ text: newTitle });
            this.chartOptions.setTitle = newTitle;
          },
        },
      },
    ],
    yAxis: [],
    plotOptions: {
      scatter: {
        boostThreshold: 10000,
        dataGrouping: {
          enabled: false,
        },
        events: {},
        findNearestPointBy: 'xy',
        point: {
          events: {
            click: (event) => {
              this.eventMarkerClick.emit(event);
            },
            // tslint:disable-next-line: space-before-function-paren
            mouseOver: function () {
              if (this.series.halo) {
                this.series.halo.attr({
                  class: 'highcharts-tracker',
                });
                this.series.halo.toFront();
              }
            },
          },
        },
      },
      line: {
        enableMouseTracking: true,
        findNearestPointBy: 'y',
        boostThreshold: 1,
        dataGrouping: {
          enabled: false,
        },
      },
      arearange: {
        tooltip: {
          shared: false,
        },
        findNearestPointBy: 'y',
        dataGrouping: {
          enabled: false,
        },
      },
      selected: 1,
    },
    scrollbar: {
      enabled: true,
    },
    navigator: {
      adaptToUpdatedData: false,
      enabled: true,
      top: 80,
    },
    rangeSelector: {
      enabled: false,
      inputEnabled: false,
      buttonPosition: {
        y: -30,
      },
      selected: 1,
    },
    tooltip: {
      split: true,
      shared: false,
      xDateFormat: '%m/%d/%Y<br/>%l:%M %p',
      snap: '5/25',
    },
    series: [],
  };

  public onChartComplete = (chart) => {
    this.chartInstance = chart;
    chart.setTitle({ text: this.formatTitle() });
    // tslint:disable-next-line: semicolon
  };

  constructor(
    private graphManager: GraphManagerService,
    private route: ActivatedRoute,
    private mpService: MeasurementPointsService,
    private router: Router
  ) {
    super();
    this.initialized = moment();

    Highcharts.setOptions({
      lang: {
        rangeSelectorZoom: '',
        thousandsSep: ',',
      },
    });

    drag(Highcharts);
    gapUnit(Highcharts);
    More(Highcharts);
    HC_exporting(Highcharts);
    Boost(Highcharts);
    noData(Highcharts);

    mTZ();
  }

  ngOnInit() {
    this.mpTimezone = this.mpService.selectedMeasurementPoint.timezone;
    this.chartOptions.yAxis = [];
    this.chartOptions.series = [];

    if (
      (this.hardCodedChart.includes(this.chartSetName) &&
        !this.graphManager.getChartSet(this.chartSetName)) ||
      !this.hardCodedChart.includes(this.chartSetName)
    ) {
      this.graphManager.newChartSet(
        this.chartSetName,
        this.chartsInSet,
        this.chartPhaseList
      );
    }
    const newChartSet = this.graphManager.getChartSet(this.chartSetName);

    // offset for extra padding added to top of chart area
    let totalHeight = 160;
    newChartSet.forEach((chart) => {
      totalHeight += chart.yAxisOptions[0].height + chart.spacer;
    });

    this.chartStyle = {
      height: totalHeight + 10 + 'px',
    };

    let indexCount = 0;
    const cumulativeHeight = newChartSet.reduce((accumulator, chart) => {
      chart.yAxisOptions.forEach((yAxisOption) => {
        this.chartOptions.yAxis.push(Object.assign({}, yAxisOption));
        this.chartOptions.yAxis[indexCount].height =
          String((yAxisOption.height / totalHeight) * 100) + '%';
        if (accumulator !== 0) {
          this.chartOptions.yAxis[indexCount].top =
            String((accumulator / totalHeight) * 100) + '%';
        }
        indexCount++;
      });
      return accumulator + chart.yAxisOptions[0].height + chart.spacer;
    }, 140);

    this.chartOptions.series = this.graphManager
      .getChartSet(this.chartSetName)
      .reduce((aggregator, current) => aggregator.concat(current.seriesArray), []);

    this.route.queryParamMap
      .pipe(untilComponentDestroyed(this))
      .subscribe((params: ParamMap) => {
        this.absoluteStartDate = this.usePreCommissionStart
          ? moment(this.mpService.selectedMeasurementPoint.createdWhen)
          : moment(this.mpService.selectedMeasurementPoint.commissionedWhen);
        this.graphManager.setStartDateBound(this.absoluteStartDate.valueOf());
        this.chartOptions.xAxis[0].plotLines[0].value = moment(
          this.mpService.selectedMeasurementPoint.commissionedWhen
        )
          .tz(this.mpTimezone)
          .valueOf();
        this.chartOptions.time.timezone = this.mpTimezone;
        this.chartReady = false;
        this.setScope(params.get('month'));
        this.graphManager
          .updateChartSet(this.chartSetName)
          .pipe(take(1))
          .subscribe(
            (seriesSet: ISeriesOptions[][]) => {
              this.chartOptions.xAxis[0].min = this.graphManager.dateRangeStart.valueOf();
              this.chartOptions.xAxis[0].max = this.graphManager.dateRangeEnd.valueOf();
              this.chartReady = true;
            },
            (err) => console.log(err),
            () => {
              this.graphManager
                .replaceDataByChartSet(this.chartSetName, 'month', 'minute')
                .pipe(take(1))
                .subscribe(
                  (response: ISeriesOptions[][]) => {
                    this.chartOptions.xAxis[0].min = this.graphManager.dateRangeStart.valueOf();
                    this.chartOptions.xAxis[0].max = this.graphManager.dateRangeEnd.valueOf();
                    this.updateFlag = true;
                  },
                  (err) => console.log(err)
                );
            }
          );
      });
  }

  ngOnChanges(changes) {
    if (changes.usePreCommissionStart) {
      this.mpTimezone = this.mpService.selectedMeasurementPoint.timezone;
      this.absoluteStartDate = this.usePreCommissionStart
        ? moment(this.mpService.selectedMeasurementPoint.createdWhen)
        : moment(this.mpService.selectedMeasurementPoint.commissionedWhen);
      this.graphManager.setStartDateBound(this.absoluteStartDate.valueOf());
      if (
        this.graphManager.dateRangeStart &&
        this.graphManager.dateRangeStart.valueOf() <=
          moment(this.mpService.selectedMeasurementPoint.commissionedWhen)
            .tz(this.mpTimezone)
            .valueOf()
      ) {
        this.graphManager.dateRangeEnd = this.graphManager.dateRangeEnd;
        this.chartReady = false;
        this.graphManager
          .updateChartSet(this.chartSetName)
          .pipe(take(1))
          .subscribe(
            (seriesSet: ISeriesOptions[][]) => {
              // // TODO remove console log
              this.chartOptions.xAxis[0].min = this.graphManager.dateRangeStart.valueOf();
              this.chartOptions.xAxis[0].max = this.graphManager.dateRangeEnd.valueOf();
              this.chartReady = true;
              // // TODO remove console log
            },
            (err) => console.log(err),
            () => {
              this.graphManager
                .replaceDataByChartSet(this.chartSetName, 'month', 'minute')
                .pipe(take(1))
                .subscribe(
                  (response: ISeriesOptions[][]) => {
                    this.chartOptions.xAxis[0].min = this.graphManager.dateRangeStart.valueOf();
                    this.chartOptions.xAxis[0].max = this.graphManager.dateRangeEnd.valueOf();
                    this.updateFlag = true;
                  },
                  (err) => console.log(err)
                );
            }
          );
      }

      this.updateFlag = true;
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    // this.chartInstance.destroy();
    this.graphManager.resetChartSet(this.chartSetName);
  }

  public disableLineAndAreaTracking = () => {
    if (this.chartOptions.plotOptions.line.enableMouseTracking) {
      this.chartOptions.plotOptions.line.enableMouseTracking = false;
      this.chartOptions.plotOptions.arearange.enableMouseTracking = false;
      this.updateFlag = true;
    }
    // tslint:disable-next-line: semicolon
  };

  public formatTitle() {
    if (this.chartInstance.xAxis[0].min) {
      return (
        moment
          .tz(this.chartInstance.xAxis[0].min, this.mpTimezone)
          .format('MMMM D, YYYY h:mm a') +
        ' - ' +
        moment
          .tz(this.chartInstance.xAxis[0].max, this.mpTimezone)
          .format('MMMM D, YYYY h:mm a')
      );
    } else return '';
  }

  private setScope(paramsMonth: string) {
    // define the granularity and time span of interest
    this.graphManager.updateGranularity(this.granularity, this.scope, 0);

    // get the array of months a selected measurepoint has been active
    this.monthsWithData = this.mpService.selectedMeasurementPoint.monthsActive;

    if (paramsMonth) {
      // sets user selected month on select component
      this.monthControl.setValue(paramsMonth);
      // create moment representing the end of the selected month
      const endDate = moment
        .tz(paramsMonth, 'MMMM YYYY', this.mpTimezone)
        .endOf('month')
        .utc();
      // if the end of the selected month is in the future set the end date to now
      this.graphManager.dateRangeEnd =
        endDate.valueOf() > moment.tz(this.mpTimezone).utc().valueOf()
          ? moment.tz(this.mpTimezone).utc()
          : endDate;
    } else {
      // if user has not selected a specific month use now as the end date
      this.graphManager.dateRangeEnd = moment.tz(this.mpTimezone).utc();
      // set the value of the select component to the current month

      this.monthControl.setValue(this.monthsWithData[0]);
    }
  }

  public changeMonth(monthChange: MatSelectChange) {
    this.router.navigate([], {
      queryParamsHandling: 'merge',
      queryParams: { month: monthChange.value },
    });
  }
}
