import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
  OnChanges,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import * as mTZ from 'moment-timezone';
import * as Highcharts from 'highcharts/highstock';
import { take } from 'rxjs/operators';
import { ActivatedRoute, ParamMap, Router, Params } from '@angular/router';
import HC_exporting from 'highcharts/modules/exporting';
import noData from 'highcharts/modules/no-data-to-display';
import gapUnit from 'highcharts/modules/broken-axis';
import Boost from 'highcharts/modules/boost';
import More from 'highcharts/highcharts-more';

import { GraphManagerService } from 'src/app/shared/services/graph-manager.service';
import { ISeriesOptions } from 'src/app/shared/classes/chart.interface';
import { DChartNavigatorComponent } from 'src/app/shared/components/date-picker-nav/d-chart-navigator/d-chart-navigator.component';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from 'src/app/shared/classes/component-destroy.class';
import { MeasurementPointsService } from 'src/app/shared/services/measurement-points.service';

(window as any).moment = moment;

@Component({
  selector: 'app-dashboard-graph',
  templateUrl: './dashboard-graph.component.html',
  styleUrls: ['./dashboard-graph.component.scss'],
})
export class DashboardGraphComponent
  extends OnDestroyMixin
  implements OnInit, OnDestroy, OnChanges {
  @ViewChild('chartNavigator', { static: false })
  chartNav: DChartNavigatorComponent;

  @Input() usePreCommissionStart: boolean;
  public absoluteStartDate;

  public chartInstance: Highcharts.Chart;

  // setting to true forces chart rerender
  public updateFlag = false;

  // only updates chart elements if changed
  public oneToOneFlag = true;

  // conditional read by ngIf to display component
  public chartReady = false;
  public Highcharts = Highcharts;
  public chartConstructor = 'stockChart';
  public currentRange: string;
  public dashboardDictatedScope: moment.unitOfTime.DurationConstructor = 'days';
  private queryParams: Params;
  private mpTimezone: string;
  public isQubeScan: boolean;

  public chartSetName = 'Dashboard Chart';
  @Output() eventMarkerClick = new EventEmitter();
  @Output() chartUpdate = new EventEmitter();

  public chartOptions: any = {
    chart: {
      style: {
        fontFamily: 'Roboto, sans-serif',
      },
      events: {
        load() {},
      },
      marginTop: 75,
      marginBottom: 20,
    },
    title: {
      y: 3,
      align: 'center',
    },
    time: {
      useUTC: true,
    },
    boost: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    xAxis: [
      {
        plotLines: [
          {
            color: '#000000',
            width: 2,
          },
        ],
        ordinal: false,
        dateTimeLabelFormats: {
          day: {
            main: '%b %d',
          },
          hour: {
            main: '%l:%M %p',
          },
          minute: {
            main: '%l:%M %p',
          },
        },
        events: {
          afterSetExtremes: (axis, event) => {
            this.chartInstance.setTitle({ text: this.getTitle() });
          },
        },
      },
    ],
    yAxis: [
      {
        labels: {
          align: 'right',
          x: -3,
        },
        title: {
          text: 'Power and Waste',
        },
        height: '75%',
        lineWidth: 2,
        resize: {
          enabled: false,
        },
      },
      {
        visible: false,
        tickPositions: [0],
        labels: {
          enabled: false,
        },
        min: 0,
        max: 0,
        height: '5%',
        top: '80%',
        lineWidth: 2,
        resize: {
          enabled: false,
        },
        offset: 0,
        // plotBands: [{
        //     acrossPanes: true,
        //     color: '#F0F5F7',
        //     from: 0,
        //     to: 0
        // }]
      },
      {
        tickPositions: null,
        labels: {
          enabled: false,
        },
        top: '85%',
        offset: 0,
        height: '12%',
        lineWidth: 1,
        resize: {
          enabled: false,
        },
        min: 0,
        max: 2,
        plotBands: [
          {
            acrossPanes: true,
            color: '#F0F5F7',
            from: 0,
            to: 2,
          },
        ],
      },
      {
        labels: {
          enabled: false,
        },
        opposite: false,
        top: '85%',
        height: '12%',
        lineWidth: 1,
        resize: {
          enabled: false,
        },
        min: 0,
        max: 2,
        plotBands: [
          {
            acrossPanes: true,
            color: '#F0F5F7',
            from: 0,
            to: 2,
          },
        ],
      },
    ],
    navigation: {
      buttonOptions: {
        y: -10,
      },
    },
    navigator: {
      enabled: false,
    },
    plotOptions: {
      area: {
        findNearestPointBy: 'y',
      },
      scatter: {
        tooltip: {
          shared: false,
        },
        findNearestPointBy: 'y',
        stickyTracking: true,
        dataGrouping: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: true,
            halo: {
              enabled: true,
            },
          },
        },
        marker: {
          enabled: true,
        },
        point: {
          events: {
            click: (event) => {
              if (event.point.series.name !== 'Others') {
                this.eventMarkerClick.emit(event);
              }
            },
            // tslint:disable-next-line: space-before-function-paren
            mouseOver: function () {
              if (this.series.halo) {
                this.series.halo
                  .attr({
                    class: 'highcharts-tracker',
                  })
                  .toFront();
              }
            },
          },
        },
      },
    },
    rangeSelector: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    tooltip: {
      split: true,
      xDateFormat:
        '%m/%d/%Y<br/><div style="width: 100%; text-align: center;">%l:%M %p</div>',
    },
    series: [],
  };

  public onChartComplete = (chart) => {
    this.chartInstance = chart;
    // tslint:disable-next-line: semicolon
  };

  public updateChart = (seriesData) => {
    // Highcharts update feature not adding series automatically. This block finds series that
    // are not part of the chart and adds them to the Highcharts object before update
    seriesData.forEach((series) => {
      if (series.name === 'Waste' && this.isQubeScan) {
        series.data = [];
      }
      if (
        !this.chartOptions.series.find(
          (currentSeries) => currentSeries.name === series.name
        )
      ) {
        this.chartInstance.addSeries(series, false);
      }
    });

    this.chartOptions.series = seriesData;
    this.chartOptions.xAxis[0].min = this.graphManager.dateRangeStart.valueOf();
    this.chartOptions.xAxis[0].max = this.graphManager.dateRangeEnd.valueOf();
    this.chartOptions.title.text = this.getTitle();

    this.updateFlag = true;

    this.chartUpdate.emit(seriesData);
    // tslint:disable-next-line: semicolon
  };

  constructor(
    private graphManager: GraphManagerService,
    private route: ActivatedRoute,
    private mpService: MeasurementPointsService,
    private router: Router
  ) {
    super();
    gapUnit(Highcharts);
    noData(Highcharts);
    More(Highcharts);
    noData(Highcharts);
    HC_exporting(Highcharts);
    noData(Highcharts);
    Boost(Highcharts);
    noData(Highcharts);

    Highcharts.setOptions({
      lang: {
        rangeSelectorZoom: '',
        thousandsSep: ',',
      },
    });

    mTZ();
  }

  ngOnInit() {
    this.route.queryParamMap
      .pipe(untilComponentDestroyed(this))
      .subscribe((params: Params) => {
        this.queryParams = params;
        this.isQubeScan =
          this.mpService.selectedMeasurementPoint.measurementPointTypeId === 1;
        if (this.isQubeScan) {
          this.chartOptions.yAxis[2].tickPositions = [0, 1, 2, 3, 4];
        } else {
          this.chartOptions.yAxis[2].tickPositions = [0, 1, 2, 3];
        }
        this.mpTimezone = this.mpService.selectedMeasurementPoint.timezone;
        this.absoluteStartDate = this.usePreCommissionStart
          ? moment(this.mpService.selectedMeasurementPoint.createdWhen)
          : moment(this.mpService.selectedMeasurementPoint.commissionedWhen);
        this.graphManager.setStartDateBound(this.absoluteStartDate.valueOf());
        this.chartOptions.xAxis[0].plotLines[0].value = moment(
          this.mpService.selectedMeasurementPoint.commissionedWhen
        )
          .tz(this.mpTimezone)
          .valueOf();
        this.chartOptions.time.timezone = this.mpTimezone;
        this.chartReady = false;
        this.chartOptions.series = [];
        this.graphManager.updateGranularity('minute', this.dashboardDictatedScope, 1);
        this.graphManager.dateRangeEnd = moment().tz(this.mpTimezone).utc();
        this.graphManager.newChartSet(this.chartSetName, [
          'dashboard',
          'notes',
          'pqEvents',
        ]);
        const start = moment();
        this.graphManager
          .updateChartSet(this.chartSetName, this.isQubeScan)
          .pipe(take(1))
          .subscribe((seriesSet: ISeriesOptions[][]) => {
            console.log('Round trip: ' + start.toNow());
            this.chartOptions.xAxis[0].min = this.graphManager.dateRangeStart.valueOf();
            this.chartOptions.xAxis[0].max = this.graphManager.dateRangeEnd.valueOf();
            seriesSet.forEach((series) => {
              series.forEach((dataSeries) => {
                if (dataSeries.name === 'Waste' && this.isQubeScan) {
                  dataSeries.data = [];
                }
                if (dataSeries.name === 'Power' && this.isQubeScan) {
                  dataSeries.color = "#202F44";
                }
                this.chartOptions.series.push(dataSeries);
              });
            });
            this.chartOptions.title.text = this.getTitle();

            this.chartReady = true;
          });
      });
  }

  ngOnChanges(changes) {
    if (changes.usePreCommissionStart) {
      this.absoluteStartDate = this.usePreCommissionStart
        ? moment(this.mpService.selectedMeasurementPoint.createdWhen)
        : moment(this.mpService.selectedMeasurementPoint.commissionedWhen);
      this.graphManager.setStartDateBound(this.absoluteStartDate.valueOf());
      if (
        this.graphManager.dateRangeStart &&
        this.graphManager.dateRangeStart.valueOf() <=
          moment(this.mpService.selectedMeasurementPoint.commissionedWhen)
            .tz(this.mpService.selectedMeasurementPoint.timezone)
            .valueOf()
      ) {
        this.graphManager.dateRangeEnd = this.graphManager.dateRangeEnd;
        this.graphManager
          .updateChartSet(this.chartSetName, this.isQubeScan)
          .pipe(take(1))
          .subscribe((newSeries) => {
            this.updateChart(newSeries.reduce((prev, curr) => prev.concat(curr)));
          });
      }
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private getTitle(): string {
    return (
      this.graphManager.dateRangeStart
        .clone()
        .tz(this.mpService.selectedMeasurementPoint.timezone)
        .format('MMMM D, YYYY h:mm A') +
      ' - ' +
      this.graphManager.dateRangeEnd
        .clone()
        .tz(this.mpTimezone)
        .format('MMMM D, YYYY h:mm A')
    );
  }

  public navigate(path: string[]): void {
    this.router.navigate(path, {
      queryParams: {
        account: this.queryParams.get('account'),
        mpId: this.queryParams.get('mpId'),
      },
    });
  }
}
