import {Component, Input, ViewEncapsulation} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-map-popup',
  templateUrl: './map-popup.component.html',
  styleUrls: ['./map-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MapPopupComponent {
  @Input() measurementPoint;

  constructor(private router: Router) {}

  getMeasurementPointStatusName() {
    const name = this.measurementPoint.measurementPointStatusName;
    return name.replaceAll(/[A-Z]/g, (match) => ` ${match}`).replace(/^./, (match) => `${match.toLocaleUpperCase()}`);
  }

  public navigate(path: string[]) {
    return this.router.navigate(path, {
      queryParams: {
        account: this.measurementPoint.accountId,
        mpId: this.measurementPoint.measurementPointId,
      },
    });
  }
}
