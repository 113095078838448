export const CurrentHarmonics = [
  { name: 'H2', factoryName: 'IH2' },
  { name: 'H3', factoryName: 'IH3' },
  { name: 'H4', factoryName: 'IH4' },
  { name: 'H5', factoryName: 'IH5' },
  { name: 'H6', factoryName: 'IH6' },
  { name: 'H7', factoryName: 'IH7' },
  { name: 'H8', factoryName: 'IH8' },
  { name: 'H9', factoryName: 'IH9' },
  { name: 'H10', factoryName: 'IH10' },
  { name: 'H11', factoryName: 'IH11' },
  { name: 'H12', factoryName: 'IH12' },
  { name: 'H13', factoryName: 'IH13' },
  { name: 'H14', factoryName: 'IH14' },
  { name: 'H15', factoryName: 'IH15' },
  { name: 'H16', factoryName: 'IH16' },
  { name: 'H17', factoryName: 'IH17' },
  { name: 'H18', factoryName: 'IH18' },
  { name: 'H19', factoryName: 'IH19' },
  { name: 'H20', factoryName: 'IH20' },
  { name: 'H21', factoryName: 'IH21' },
  { name: 'H22', factoryName: 'IH22' },
  { name: 'H23', factoryName: 'IH23' },
  { name: 'H24', factoryName: 'IH24' },
  { name: 'H25', factoryName: 'IH25' },
  { name: 'H26', factoryName: 'IH26' },
  { name: 'H27', factoryName: 'IH27' },
  { name: 'H28', factoryName: 'IH28' },
  { name: 'H29', factoryName: 'IH29' },
  { name: 'H30', factoryName: 'IH30' },
  { name: 'H31', factoryName: 'IH31' },
  { name: 'H32', factoryName: 'IH32' },
  { name: 'H33', factoryName: 'IH33' },
  { name: 'H34', factoryName: 'IH34' },
  { name: 'H35', factoryName: 'IH35' },
  { name: 'H36', factoryName: 'IH36' },
  { name: 'H37', factoryName: 'IH37' },
  { name: 'H38', factoryName: 'IH38' },
  { name: 'H39', factoryName: 'IH39' },
  { name: 'H40', factoryName: 'IH40' },
  { name: 'H41', factoryName: 'IH41' },
  { name: 'H42', factoryName: 'IH42' },
  { name: 'H43', factoryName: 'IH43' },
  { name: 'H44', factoryName: 'IH44' },
  { name: 'H45', factoryName: 'IH45' },
  { name: 'H46', factoryName: 'IH46' },
  { name: 'H47', factoryName: 'IH47' },
  { name: 'H48', factoryName: 'IH48' },
  { name: 'H49', factoryName: 'IH49' },
];
