import { Component, Input, OnInit } from '@angular/core';

export interface MeasurementPointStatusCounts {
  uncommissioned: number;
  noData: number;
  insufficientData: number;
  badData: number;
  goodData: number;
}

@Component({
  selector: 'app-map-panel',
  templateUrl: './map-panel.component.html',
  styleUrls: ['./map-panel.component.scss'],
})
export class MapPanelComponent implements OnInit {
  @Input() measurementPointStatusCounts: MeasurementPointStatusCounts;
  @Input() measurementPointCount;
  @Input() zoomFactor;

  constructor() {}

  ngOnInit() {}
}
