import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { IMember } from './../../shared/classes/member.interface';
import { IMemberDialogData } from 'src/app/shared/classes/memberDialogData.interface';
import { Role } from 'src/app/shared/enum/role.enum';
import { AuthService } from 'src/app/shared/services';
import { IAccount } from 'src/app/shared/classes/account';
import { IUserUpdateParams } from './../../shared/classes/userUpdateParams.interface';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from 'src/app/shared/classes/component-destroy.class';

@Component({
  selector: 'app-member-dialog',
  templateUrl: './member-dialog.component.html',
  styleUrls: ['./member-dialog.component.scss'],
})
export class MemberDialogComponent extends OnDestroyMixin implements OnDestroy, OnInit {
  public accountName: string;
  public memberForm: FormGroup;
  private accountId: number;
  public member: IMember;
  private isNew: boolean;
  public title: string;

  constructor(
    public dialogRef: MatDialogRef<MemberDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IMemberDialogData,
    private authService: AuthService
  ) {
    super();
    if (data.accountId) {
      this.accountId = data.accountId;
    }
    if (data.member) {
      this.isNew = false;
      this.member = data.member;
      this.title = 'manageMembers.editMember';
    } else {
      this.isNew = true;
      this.title = 'manageMembers.addMember';
    }
  }

  ngOnInit() {
    this.memberForm = new FormGroup({
      firstName: new FormControl(this.member ? this.member.firstName : '', [
        Validators.required,
      ]),
      lastName: new FormControl(this.member ? this.member.lastName : '', [
        Validators.required,
      ]),
      email: new FormControl(this.member ? this.member.email : '', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ]),
      mobilePhone: new FormControl(this.member ? this.member.mobilePhone : ''),
    });

    this.memberForm.get('email').valueChanges.subscribe((event) => {
      this.memberForm.get('email').setValue(event.toLowerCase(), { emitEvent: false });
    });

    this.authService.accountO
      .pipe(untilComponentDestroyed(this))
      .subscribe((userAccount: IAccount) => {
        this.accountName = userAccount.name;
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  public get firstName(): AbstractControl {
    return this.memberForm.get('firstName');
  }

  public get lastName(): AbstractControl {
    return this.memberForm.get('lastName');
  }

  public get email(): AbstractControl {
    return this.memberForm.get('email');
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public onSubmit(event: Event): void {
    let addUserParams: IUserUpdateParams;
    if (this.isNew) {
      addUserParams = {
        accountId: this.accountId,
        firstName: this.memberForm.get('firstName').value,
        middleName: '',
        lastName: this.memberForm.get('lastName').value,
        organization: '',
        email: this.memberForm.get('email').value,
        homePhone: '',
        workPhone: '',
        mobilePhone: this.memberForm.get('mobilePhone').value,
        preferredLocaleId: 1,
        retired: 0,
        roleId: 4,
      };
    } else {
      addUserParams = {
        accountId: this.accountId,
        firstName: this.memberForm.get('firstName').value,
        middleName: this.member.middleName ? this.member.middleName : '',
        lastName: this.memberForm.get('lastName').value,
        organization: this.member.organization ? this.member.organization : '',
        email: this.memberForm.get('email').value,
        homePhone: this.member.homePhone ? this.member.homePhone : '',
        workPhone: this.member.workPhone ? this.member.workPhone : '',
        mobilePhone: this.memberForm.get('mobilePhone').value
          ? this.memberForm.get('mobilePhone').value
          : '',
        preferredLocaleId: this.member.preferredLocaleId,
        retired: 0,
        roleId: Role[this.member.role],
      };
    }
    this.dialogRef.close(addUserParams);
  }
}
