import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromHierarchy from './../../_store/_reducers';
import { AuthService } from './../services';
import { IAccount } from '../classes/account';

@Injectable()
export class InitProvider {
  constructor(
    private store: Store<fromHierarchy.State>,
    private authService: AuthService
  ) {}

  load() {
    return new Promise((resolve, reject) => {
      this.authService.authed.pipe(take(1)).subscribe((payload) => {
        if (payload) {
          this.authService
            .getAccountById(payload.accountId)
            .pipe(take(1))
            .subscribe((account: IAccount) => {
              this.store.dispatch({
                type: '[Login] Set User IsPartner Success',
                payload: { isPartner: account.isPartner },
              });
            });
          this.store.dispatch({
            type: '[App Init] Init hierarchy - user',
            payload: { id: payload.id, accountId: payload.accountId },
          });

          setTimeout(() => {
            resolve(true);
          }, 3500);
        } else {
          resolve(true);
        }
      });
    });
  }
}
