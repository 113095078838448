<mat-checkbox
  [checked]="userPrefs?.usePreCommissionStart"
  (change)="changeAbsoluteStartDate($event)"
  *ngIf="token.metaData.isSystemAdministrator === '1'"
>
  Include data prior to commissioning [<span [innerHTML]="commissioningDate"></span>]
</mat-checkbox>
<div class="charts-container">
  <mat-tab-group
    mat-stretch-tabs
    style="max-width: 100%"
    disableRipple="true"
    [selectedIndex]="currentTab"
    (selectedIndexChange)="setCurrentTab($event)"
  >
    <mat-tab *ngFor="let tab of tabs; let i = index" [label]="tab.chartSetName">
      <ng-template mat-tab-label>
        <!-- <div
          [id]="'list-' + i"
          cdkDropList
          cdkDropListOrientation="horizontal"
          (cdkDropListDropped)="drop($event)"
          [cdkDropListConnectedTo]="getAllListConnections(i)"
        >
          <div
            cdkDrag
            cdkDragRootElement=".mat-tab-label"
            cdkDragBoundary=".mat-tab-labels"
          > -->
        {{ tab.name | translate }}
        <ng-container *ngIf="tab.chartSetName.includes('Custom')">
          <button
            mat-flat-button
            (click)="showEditTab(tab.sortOrder)"
            [disabled]="tab.sortOrder !== currentTab || !editable"
            class="edit-tab-button"
          >
            <mat-icon svgIcon="pencil-box"></mat-icon>
          </button>
        </ng-container>
        <!-- </div>
        </div> -->
      </ng-template>
      <ng-template matTabContent>
        <div class="chart">
          <app-chart-set
            [usePreCommissionStart]="userPrefs?.usePreCommissionStart && commissionedWhen"
            [chartSetName]="tab.chartSetName"
            [chartsInSet]="tab.memberCharts"
            [chartPhaseList]="tab.phaseList"
            (eventMarkerClick)="toggleInfoDrawer($event)"
            (chartRender)="hideInfoDrawer()"
          ></app-chart-set>
        </div>
        <div
          *ngIf="showInfoDrawer"
          class="info-drawer"
          [ngClass]="{ loading: loadingInfoDrawer }"
        >
          <div class="drawer-connector" [style.left]="infoEvent?.chartX + 'px'"></div>
          <mat-card
            *ngIf="infoData?.deviceEventType; else meterCard"
            class="charts-chart-detail mat-elevation-z8"
          >
            <mat-card-header>
              <mat-card-title *ngIf="infoData?.deviceEventType">
                <img
                  *ngIf="infoData?.deviceEventType"
                  [src]="'./assets/images/legend/' + infoIcons[infoData?.deviceEventType]"
                />
                {{
                  'dashboard.detail-overlay.' + infoData?.deviceEventType + '.title'
                    | translate
                }}
                <button (click)="hideInfoDrawer()" class="close">&times;</button>
              </mat-card-title>
              <mat-card-title *ngIf="!infoData?.deviceEventType">
                {{ 'global.loading' | translate }}
              </mat-card-title>
            </mat-card-header>
            <mat-card-content *ngIf="infoData?.deviceEventType">
              <div class="charts-chart-detail-container">
                <div class="charts-chart-detail-stats">
                  <div>
                    <h4>{{ 'dashboard.detail-overlay.trigger-date' | translate }}:</h4>
                    <div>{{ infoData?.triggeredWhen | date: 'longDate' }}</div>
                  </div>
                  <div *ngIf="infoData?.eventMagnitude">
                    <h4>{{ 'dashboard.detail-overlay.magnitude' | translate }}:</h4>
                    <div>
                      {{
                        (infoData?.deviceEventType === 'highFrequencyImpulse'
                          ? 'dashboard.detail-overlay.hf-magnitude-value'
                          : 'dashboard.detail-overlay.magnitude-value'
                        ) | translate: { value: infoData?.eventMagnitude }
                      }}
                    </div>
                  </div>
                  <div>
                    <h4>{{ 'dashboard.detail-overlay.trigger-time' | translate }}:</h4>
                    <div>{{ infoData?.triggeredWhen | date: 'shortTime' }}</div>
                  </div>
                  <div>
                    <h4>{{ 'dashboard.detail-overlay.duration' | translate }}:</h4>
                    <div *ngIf="infoData?.duration">
                      {{ infoData.duration }}
                      {{ 'global.milliseconds' | translate }}
                    </div>
                    <div *ngIf="!infoData?.duration">
                      {{ 'global.not-applicable' | translate }}
                    </div>
                  </div>
                  <div>
                    <h4>{{ 'dashboard.detail-overlay.trigger-channel' | translate }}:</h4>
                    <div>{{ infoData?.channel }}</div>
                  </div>
                </div>
                <div class="charts-chart-detail-info">
                  <h4>
                    {{ 'dashboard.detail-overlay.event-identification' | translate }}:
                  </h4>
                  <div
                    [innerHTML]="
                      'dashboard.detail-overlay.' +
                        infoData?.deviceEventType +
                        '.event-identification' | translate
                    "
                  ></div>
                  <h4>{{ 'dashboard.detail-overlay.impact' | translate }}:</h4>
                  <div
                    [innerHTML]="
                      'dashboard.detail-overlay.' + infoData?.deviceEventType + '.impact'
                        | translate
                    "
                  ></div>
                </div>
                <div class="charts-chart-detail-footer">
                  <a
                    mat-button
                    color="accent"
                    [routerLink]="[
                      '/event-graph',
                      mpId,
                      accountId,
                      infoData?.deviceEventId,
                      infoData?.deviceEventTypeId,
                      infoData?.originalTriggeredWhen
                    ]"
                    *ngIf="isPartner || token.metaData.isSystemAdministrator === '1'"
                    target="_blank"
                  >
                    {{ 'dashboard.detail-overlay.view-event-graph' | translate }}
                    <mat-icon svgIcon="launch"></mat-icon>
                  </a>
                  <br />
                  <a
                    mat-button
                    color="accent"
                    [routerLink]="['/more-information', infoData?.deviceEventType]"
                    target="_blank"
                  >
                    {{ 'dashboard.detail-overlay.more-info' | translate }}
                    <mat-icon svgIcon="launch"></mat-icon>
                  </a>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
          <ng-template #meterCard>
            <mat-card class="notes-card">
              <mat-card-header>
                <mat-card-title class="align-center">
                  <mat-icon svgIcon="calendar-blank"></mat-icon>
                  {{ this.infoData.summary }}
                </mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="dashboard-chart-detail-container">
                  <div class="dashboard-chart-detail-stats">
                    <div>
                      <h4>{{ 'notes.new.date' | translate }}</h4>
                      <div>{{ this.infoData.tzShiftedDate }}</div>
                    </div>
                    <div>
                      <h4>{{ 'notes.new.time' | translate }}</h4>
                      <div>{{ this.infoData.tzShiftedTime }}</div>
                    </div>
                  </div>
                </div>
                <div class="dashboard-chart-detail-info">
                  <h4>{{ 'notes.new.details' | translate }}</h4>
                  <div>{{ this.infoData.details }}</div>
                </div>
              </mat-card-content>
            </mat-card>
          </ng-template>
          <div
            class="drawer-connector-overlay"
            [style.left]="infoEvent?.chartX + 'px'"
          ></div>
          <div class="info-drawer-overlay" *ngIf="loadingInfoDrawer">
            <mat-progress-spinner
              mode="indeterminate"
              color="primary"
              diameter="48"
            ></mat-progress-spinner>
          </div>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
  <button
    mat-flat-button
    [disabled]="this.tabs.length >= 11"
    (click)="showAddTab()"
    class="add-tab-button"
  >
    <mat-icon svgIcon="plus-box" color="accent"></mat-icon>
  </button>
</div>
