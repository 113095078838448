<div mat-dialog-title class="dialog-title" data-cy="change-mp-popup">
    <h3>{{ 'header.select-measurement.title' | translate }}</h3>
    <button mat-button (click)="onClose()">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>
<mat-dialog-content class="mat-typography">
    <div class="location-group" data-cy="search-partner" *ngIf="partners.length > 0">
        <div *ngFor="let partner of partners" class="partner-group">
            <h2 *ngIf="partner.accountName" >{{ partner.accountName }}</h2>
            <mat-accordion data-cy="customer-group">
                <div *ngFor="let customer of partner.customers" class="customer-group">
                    <mat-expansion-panel data-cy="expand-collapse-button" [expanded]="customer.expanded">
                        <mat-expansion-panel-header>
                            <mat-panel-title data-cy="search-customer">
                                {{ customer.accountName }}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="location table">
                            <table data-cy="change-selection-table">
                                <thead>
                                    <tr>
                                        <th>{{ 'header.select-measurement.table.measurement-point' | translate }}</th>
                                        <th>{{ 'header.select-measurement.table.location' | translate }}</th>
                                        <th>{{ 'header.select-measurement.table.events' | translate }}</th>
                                        <th>{{ 'header.select-measurement.table.status' | translate }}</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let point of customer.measurementPoints" [ngClass]="{active: point.active}">
                                        <td data-cy="search-mp" >
                                            {{ point.mpId }}
                                        </td>
                                        <td data-cy="search-location">
                                            {{ point.city }}<span *ngIf="point.city && point.state">, </span>{{ point.state }}
                                        </td>
                                        <td>
                                            <span *ngIf="point.measurementPointStatusId === 8">
                                                {{ point.totalEvents | number }}
                                            </span>
                                        </td>
                                        <td >
                                            {{ 'measurementPoint.status.' + point.measurementPointStatusId | translate }}
                                        </td>
                                        <td>
                                            <button mat-flat-button (click)="selectPoint(point)" [disabled]="isMeasurementPointDisabled(point)" data-cy="select-mp">
                                                <mat-icon svgIcon="arrow-right" color="accent"></mat-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </mat-expansion-panel>
                </div>
            </mat-accordion>
        </div>
    </div>
    <div class="loading" *ngIf="loading && partners.length === 0">
        <mat-spinner diameter="48" color="primary"></mat-spinner>
    </div>
</mat-dialog-content>
