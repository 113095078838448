<div id="registration">
    <img src="./assets/images/psl-logo-white.svg" alt="Powerside" />

    <div class="boxed-in">
        <h1 mat-dialog-title>
            {{ 'registerUserAccount.title' | translate }}
        </h1>
        <h4 class="account-info">
            {{ 'registerUserAccount.accountInfo' | translate }}
        </h4>
        <form (submit)="registerUserAccount($event)" [formGroup]="registerForm">
            <div class="field">
                <mat-form-field appearance="fill" data-cy="account-name">
                    <mat-label>{{ 'registerUserAccount.accountName' | translate }}*</mat-label>
                    <input matInput formControlName="accountName" />
                    <mat-error *ngIf="accountName.hasError('required')">{{ 'global.errors.fieldRequired' | translate }}</mat-error>
                </mat-form-field>
            </div>
            <div class="field">
                <mat-form-field appearance="fill" data-cy="email">
                    <mat-label>{{ 'registerUserAccount.email' | translate }}*</mat-label>
                    <input matInput formControlName="email" />
                    <mat-error *ngIf="email.hasError('required')">{{ 'global.errors.fieldRequired' | translate }}</mat-error>
                </mat-form-field>
            </div>
            <div class="field">
                <mat-form-field appearance="fill" data-cy="firstName">
                    <mat-label>{{ 'registerUserAccount.firstName' | translate }}*</mat-label>
                    <input matInput formControlName="firstName" />
                    <mat-error *ngIf="firstName.hasError('required')">{{ 'global.errors.fieldRequired' | translate }}</mat-error>
                </mat-form-field>
            </div>                
            <div class="field">
                <mat-form-field appearance="fill" data-cy="lastName">
                    <mat-label>{{ 'registerUserAccount.lastName' | translate }}*</mat-label>
                    <input matInput formControlName="lastName" />
                    <mat-error *ngIf="lastName.hasError('required')">{{ 'global.errors.fieldRequired' | translate }}</mat-error>
                </mat-form-field>
            </div>
            <div class="field">
                <mat-form-field appearance="fill" data-cy="main-telephone">
                    <mat-label>{{ 'registerUserAccount.mainTelephone' | translate }}*</mat-label>
                    <input matInput formControlName="mainTelephone" />
                    <mat-error *ngIf="mainTelephone.hasError('required')">{{ 'global.errors.fieldRequired' | translate }}</mat-error>
                </mat-form-field>
            </div>
            <div class="field">
                <ngx-recaptcha2 #captchaElem
                    [siteKey]="siteKey"
                    formControlName="recaptcha">
                </ngx-recaptcha2>
            </div>
            <div class="button-group">
                <button mat-flat-button color="accent" data-cy="submit" type="submit" [disabled]="registerForm.invalid || loading">{{ 'registerUserAccount.create' | translate }}</button>
                <button mat-flat-button color="accent" data-cy="cancel" type="button" (mousedown)="onCancel()">{{ 'global.cancel' | translate }}</button>
            </div>
            
        </form>
    </div>

    <div class="registration-footer">
        {{ 'login.footer' | translate }}
    </div>
</div>