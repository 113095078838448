<div class="container">
  <div class="container-header">
    <div>
      <h1 id="title">Live Meters</h1>
      <h1 id="hour-minute">{{ hour }}:{{ minute }}</h1>
      <div class="time-details">
        <div id="seconds">:{{ second }}</div>
        <div class="daypart-timezone">
          <p id="daypart" [innerHTML]="daypart"></p>
          <p id="timezone" [innerHTML]="timezone"></p>
        </div>
      </div>
    </div>
    <div>
      <mat-label>View</mat-label>
      <mat-select [(ngModel)]="chartView">
        <mat-option value="1">1</mat-option>
        <mat-option value="2">2</mat-option>
        <mat-option value="4">4</mat-option>
        <mat-option value="6">6</mat-option>
      </mat-select>
      <button
        mat-icon-button
        [routerLink]="['/dashboard']"
        queryParamsHandling
        preserveFragement
      >
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    </div>
  </div>
  <div class="measurement-point-info">
    <p>
      {{ accountName }}<span *ngIf="locationCity"> - </span>{{ locationCity
      }}<span *ngIf="locationCity && locationState">, </span
      >{{ locationState }} | {{ mpName }} | ({{ threeLetterTimezone }})
    </p>
    <div id="discontinue-response" *ngIf="timeExpiring">
      <p>{{ 'meter.continue.message' | translate }}</p>
      <button
        mat-flat-button
        class="discontinue-btn"
        type="button"
        (click)="onCancel()"
      >
        {{ 'meter.continue.buttons.discontinue' | translate }}
      </button>
      <button
        mat-flat-button
        class="continue-btn"
        type="button"
        color="accent"
        (click)="onContinue()"
      >
        {{ 'meter.continue.buttons.continue' | translate }}
      </button>
    </div>
    <div id="session-end-display" *ngIf="sessionEnded">
      <p>{{ 'meter.sessionEnded.message' | translate }}</p>
      <button
        mat-flat-button
        type="button"
        class="restart-btn"
        (click)="restartSession()"
        color="accent"
      >
        {{ 'meter.sessionEnded.button.restart' | translate }}
      </button>
    </div>
  </div>
  <section class="meters-container">
    <div class="meters">
      <ng-container *ngFor="let chart of charts">
        <app-meter
          #meter
          [ngClass]="{
            'single-chart': this.chartView === '1',
            'double-chart': this.chartView === '2',
            'quadruple-chart': this.chartView === '4',
            'sextuple-chart': this.chartView === '6'
          }"
          [config]="chart"
          (updateFlag)="(changesMade)"
          (messageEmitter)="handleMessage($event)"
        ></app-meter>
      </ng-container>
    </div>
  </section>
</div>
