import * as moment from 'moment';
import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';

import {
  ISeriesOptions,
  IEventsChart,
  IEventSeriesOptions,
  ITrendsChart,
  PowersideYAxisOptions,
} from '../classes/chart.interface';
import { IEvent } from '../classes/event';
import { MeasurementPointsService } from './measurement-points.service';

class TrendsChart implements ITrendsChart {
  private mYAxis: number;
  private mName: string;
  private mCsvHeaders: string[][];
  private mSeriesArray: SeriesOptions[];
  private mYAxisOptions: PowersideYAxisOptions[];
  private mSpacer = 30;
  public id: string;

  public set name(name: string) {
    this.mName = name;
  }

  public get name() {
    return this.mName;
  }

  public set csvHeaders(csvHeaders: string[][]) {
    this.mCsvHeaders = csvHeaders;
  }

  public get csvHeaders(): string[][] {
    return this.mCsvHeaders;
  }

  public get oneMinuteChannelIds(): string[] {
    return this.seriesArray.reduce((accumulator, series) => {
      if (series.table === 'oneminute') {
        return accumulator.concat(series.channelIds);
      } else return accumulator;
    }, []);
  }

  public get tenMinuteChannelIds(): string[] {
    return this.seriesArray.reduce((accumulator, series) => {
      if (series.table === 'tenminute') {
        return accumulator.concat(series.channelIds);
      } else return accumulator;
    }, []);
  }

  public set seriesArray(series: SeriesOptions[]) {
    this.mSeriesArray = series;
  }

  public get seriesArray(): SeriesOptions[] {
    return this.mSeriesArray;
  }

  public set yAxis(newIndex: number) {
    this.mYAxis = newIndex;
    this.seriesArray.forEach((series) => (series.yAxis = newIndex));
  }

  public get yAxis(): number {
    return this.mYAxis;
  }

  public get yAxisOptions(): PowersideYAxisOptions[] {
    return this.mYAxisOptions;
  }

  public set yAxisOptions(newOptions: PowersideYAxisOptions[]) {
    this.mYAxisOptions = newOptions;
  }

  public set spacer(gap: number) {
    this.mSpacer = gap;
  }

  public get spacer(): number {
    return this.mSpacer;
  }

  public clearSeriesData() {
    this.seriesArray.forEach((series) => (series.data.length = 0));
  }

  public replaceChartData(trendsData: {}, eventsData: IEvent[]): ISeriesOptions[] {
    return this.seriesArray.map((series, index) => series.replaceData(trendsData));
  }

  public setSeriesData(
    dataBySeries: any,
    eventsDataArray: IEvent[],
    granularity: moment.DurationInputArg1
  ) {
    this.seriesArray.forEach((series) => {
      series.setData(dataBySeries);
      series.setDataGap(granularity);
    });

    return this.seriesArray;
  }

  public setYMinMax(min: number, max: number) {
    this.yAxisOptions.forEach((yAxis) => {
      yAxis.min = min;
      yAxis.max = max;
    });
  }

  constructor(name: string, csvHeaders: string[][], series: SeriesOptions[]) {
    this.name = name;
    this.csvHeaders = csvHeaders;
    this.seriesArray = series;

    this.id = name;

    this.yAxisOptions = [
      {
        labels: {
          align: 'right',
          x: -3,
        },
        title: {
          text: this.name,
        },
        height: 180,
        lineWidth: 1,
        resize: {
          enabled: true,
        },
        offset: 0,
        softMin: 0,
        softMax: 0.001,
        showLastLabel: true,
      },
    ];
  }
}

class AdvancedChart extends TrendsChart {
  private mPhase: string;

  public set phase(phase: string) {
    this.mPhase = phase;
  }

  public get phase() {
    return this.mPhase;
  }

  private generateChannelIds(channel: string, phase: string): string {
    const id = channel.replace(/\D+/g, '');
    const fSymbol = channel.indexOf(id);
    const newChannel = parseInt(id, 10) + parseInt(phase.replace(/\D+/g, ''), 10);

    return (
      channel.slice(0, fSymbol) +
      newChannel +
      channel.slice(fSymbol + id.length, channel.length)
    );
  }

  public setName(phase: string): string[] {
    return this.seriesArray.map((series) => {
      return (series.name = phase);
    });
  }

  public replaceChannelIds(phase: string): string[] {
    return this.seriesArray.map((series) => {
      return (series.channelIds[0] = this.generateChannelIds(
        series.channelIdBaseArray[0],
        phase
      ));
    });
  }

  constructor(
    name: string,
    csvHeaders: string[][],
    series: SeriesOptions[],
    phase: string
  ) {
    // phase: string
    super(name, csvHeaders, series);
    this.phase = phase;
    this.replaceChannelIds(this.phase);
    this.setName(this.phase);
    this.yAxisOptions[0].title.text =
      this.name +
      '<p style="width: 100%; text-align: center; margin: 0; padding: 0">' +
      '(' +
      this.phase +
      ')</p>';
    this.yAxisOptions[0].title.useHTML = true;
  }
}

export function replaceAdvancedChart(name, csvHeaders, series, phase) {
  return new AdvancedChart(name, csvHeaders, series, phase);
}

class SeriesOptions implements ISeriesOptions {
  public tooltip: Highcharts.TooltipOptions = {
    valueDecimals: 4,
  };
  public data: number[][] = [];
  public type: string;
  public color: string;
  public name: string;
  private mChannelIdBaseArray: string[];
  private mChannelIds: string[];
  public yAxis: number;
  public id: string;
  public dataGrouping = { enabled: false };
  public gapSize = 60000;
  public gapUnit = 'value';
  public turboThreshold: 5000;
  private mTable: string;
  private trackByArea: boolean;
  private states: any;
  private fillColor: string;
  //  .states;

  constructor(
    type: string,
    color: string,
    name: string,
    channelIds: string[],
    tooltip: Highcharts.TooltipOptions = {},
    table: string = 'oneminute'
  ) {
    this.type = type;
    this.color = color;
    this.name = name;
    this.id = name + channelIds.join('');
    this.mChannelIds = channelIds;
    this.mChannelIdBaseArray = [...channelIds];
    Object.assign(this.tooltip, tooltip);
    this.table = table;

    if (this.type === 'arearange') {
      this.fillColor = color;
      this.trackByArea = false;
      this.states = {
        hover: {
          enabled: false,
        },
      };
    }
  }

  public get table(): string {
    return this.mTable;
  }

  public set table(table: string) {
    this.mTable = table;
    if (this.table === 'tenminute') {
      this.gapUnit = 'relative';
    }
  }

  public get channelIdBaseArray(): string[] {
    return this.mChannelIdBaseArray;
  }

  public get channelIds() {
    return this.mChannelIds;
  }

  public setData(dataBySeries: any) {
    this.data = dataBySeries[this.table][this.id].newData;
  }

  public setDataGap(granularity) {
    if (this.table === 'tenminute') {
      this.gapSize = 10;
    } else {
      this.gapSize = moment.duration(1, granularity).valueOf() as number;
    }
  }

  public replaceData(trendsData: {}): ISeriesOptions {
    this.data.length = 0;
    this.data.push(...trendsData[this.table][this.id]);

    return this;
  }
}

class EventSeriesOptions implements IEventSeriesOptions {
  public id: string;
  public type = 'scatter';
  public color = '#FF515B';
  public deviceEventType: string;
  public eventTypeId: string;
  public data: number[][] = [];
  public channelIds = [];
  public yValue: number;
  public stickyTracking = true;
  public dataGrouping = {
    enabled: false,
  };
  public tooltip = {
    xDateFormat:
      '%m/%d/%Y<br/><div style="width: 100%; text-align: center;">%l:%M:%S.%L %p</div>',
    headerFormat: '{point.key}',
    // tslint:disable-next-line: space-before-function-paren
    pointFormatter: function () {
      if (this.series.name === 'Note') {
        const noteSummary = this.series.options.data.find((note) => {
          return note[0] === this.x;
        });
        return noteSummary[2].length > 13
          ? noteSummary[2].substring(0, 13) + '...'
          : noteSummary[2];
      }
      return this.series.name;
    },
    findNearestPointBy: 'xy',
  };
  public states = {
    hover: {
      enabled: true,
      halo: {
        opacity: 0.25,
        size: 10,
      },
    },
  };
  public turboThreshold = 0;
  public name: string;
  public marker = {
    radius: 7,
    symbol: null,
    width: null,
    height: null,
  };
  public yAxis: number;

  constructor(name: string, symbolName: string, yValue: number) {
    this.name = name;
    if (this.name === 'Others') {
      this.marker.radius = 0;
      this.marker.width = 12;
      this.marker.height = 13;
    }
    this.marker.symbol = symbolName;
    this.yValue = yValue;
  }
}

class Dynamic extends TrendsChart {
  constructor(name: string, trendParam: Array<any>, unit: string, unitOffset: number) {
    super(
      name,
      [[name]],
      [
        new SeriesOptions('line', '#0087FF', ' Avg', trendParam, {
          valueSuffix: ' ' + unit,
          valueDecimals: unitOffset - 3,
        }),
      ]
    );
  }
}

class Dashboard extends TrendsChart {
  constructor() {
    super(
      'Dashboard',
      [['Power (kW)'], ['Reactive Power 1/2 Cycle Avg (kvar)']],
      [
        // TODO channel should be changed when provided by Powerside
        new SeriesOptions('area', '#0087FF', 'Power', ['c_70_avg_w'], {
          // tslint:disable-next-line: space-before-function-paren
          pointFormatter: function () {
            return (
              this.series.name +
              ': ' +
              Highcharts.numberFormat(this.y / 1000, 2) +
              ' (kW)'
            );
          },
        }),
        new SeriesOptions('area', '#FF5003', 'Waste', ['pc_2_avg_none'], {
          // tslint:disable-next-line: space-before-function-paren
          pointFormatter: function () {
            return (
              this.series.name +
              ': ' +
              Highcharts.numberFormat(this.y / 1000, 2) +
              ' (kvar)'
            );
          },
        }),
      ]
    );
  }
}

class ActiveEnergy extends TrendsChart {
  constructor() {
    super(
      'Active Energy (kWh)',
      [['Total Active Energy Per Interval Avg (kWh)']],
      [
        new SeriesOptions('line', '#0087FF', ' Avg', ['pc_1_avg_kwhr'], {
          valueSuffix: ' kWh',
          valueDecimals: 0,
        }),
      ]
    );
  }
}

class ReactiveEnergy extends TrendsChart {
  constructor() {
    super(
      'Reactive Energy (kVARh)',
      [['Total Reactive Energy per Interval Avg (kVARh)']],
      [
        new SeriesOptions('line', '#0087FF', 'Avg', ['pc_3_avg_varhr'], {
          valueSuffix: ' kVARh',
          valueDecimals: 0,
        }),
      ]
    );
  }
}

class TotalActivePower extends TrendsChart {
  constructor() {
    super(
      'Total Active Power (kW)',
      [
        ['Total Active Power 1/2-Cycle Max(W)'],
        ['Total Active Power 1/2-Cycle Min(W)', 'Total Active Power 1/2-Cycle Max(W)'],
      ],
      [
        new SeriesOptions(
          'arearange',
          'rgba(153, 198, 253, .3)',
          'Min/Max',
          ['c_70_min_w', 'c_70_max_w'],
          { valueSuffix: ' W', valueDecimals: 0 }
        ),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_70_avg_w'], {
          valueSuffix: ' W',
          valueDecimals: 0,
        }),
      ]
    );
  }
}

class ReactivePower extends TrendsChart {
  constructor() {
    super(
      'Reactive Power',
      [['Reactive Power 1'], ['Reactive Power 2'], ['Reactive Power 3']],
      [
        new SeriesOptions('line', '#FF515B', 'L1', ['c_89_avg_var'], {
          valueSuffix: ' var',
          valueDecimals: 0,
        }),
        new SeriesOptions('line', '#FFBE08', 'L2', ['c_90_avg_var'], {
          valueSuffix: ' var',
          valueDecimals: 0,
        }),
        new SeriesOptions('line', '#0050BF', 'L3', ['c_91_avg_var'], {
          valueSuffix: ' var',
          valueDecimals: 0,
        }),
      ]
    );
  }
}

class PowerFactor extends TrendsChart {
  constructor() {
    super(
      'Power Factor',
      [['Power Factor 1'], ['Power Factor 2'], ['Power Factor 3']],
      [
        new SeriesOptions('line', '#FF515B', 'L1', ['c_125_avg_none'], {
          valueDecimals: 3,
        }),
        new SeriesOptions('line', '#FFBE08', 'L2', ['c_126_avg_none'], {
          valueDecimals: 3,
        }),
        new SeriesOptions('line', '#0050BF', 'L3', ['c_127_avg_none'], {
          valueDecimals: 3,
        }),
      ]
    );
  }
}

class ActivePower extends TrendsChart {
  constructor() {
    super(
      'Active Power',
      [['Active Power 1'], ['Active Power 2'], ['Active Power 3']],
      [
        new SeriesOptions('line', '#FF515B', 'L1', ['c_71_avg_w'], {
          valueSuffix: ' W',
          valueDecimals: 0,
        }),
        new SeriesOptions('line', '#FFBE08', 'L2', ['c_72_avg_w'], {
          valueSuffix: ' W',
          valueDecimals: 0,
        }),
        new SeriesOptions('line', '#0050BF', 'L3', ['c_73_avg_w'], {
          valueSuffix: ' W',
          valueDecimals: 0,
        }),
      ]
    );
  }
}

class TotalReactivePower extends TrendsChart {
  constructor() {
    super(
      'Total Reactive Power',
      [
        ['Total Reactive Power 1/2-Cycle Avg(kVAR)'],
        [
          'Total Reactive Power 1/2-Cycle Min(kVAR)',
          'Total Reactive Power 1/2-Cycle Max(kVAR)',
        ],
      ],
      [
        new SeriesOptions(
          'arearange',
          'rgba(153, 198, 253, .3)',
          'Min/Max',
          ['c_88_min_var', 'c_88_max_var'],
          { valueSuffix: ' VAR', valueDecimals: 0 }
        ),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_88_avg_var'], {
          valueSuffix: ' VAR',
          valueDecimals: 0,
        }),
      ]
    );
  }
}

class ApparentPower extends TrendsChart {
  constructor() {
    super(
      'Apparent Power',
      [
        ['Apparent Power 1/2-Cycle Avg(kVA)'],
        ['Apparent Power 1/2-Cycle Min(kVA)', 'Apparent Power 1/2-Cycle Max(kVA)'],
      ],
      [
        new SeriesOptions(
          'arearange',
          'rgba(153, 198, 253, .3)',
          'Min/Max',
          ['c_106_min_va', 'c_106_max_va'],
          { valueSuffix: ' VA', valueDecimals: 0 }
        ),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_106_avg_va'], {
          valueSuffix: ' VA',
          valueDecimals: 0,
        }),
      ]
    );
  }
}

class TotalPowerFactor extends TrendsChart {
  constructor() {
    super(
      'Total Power Factor',
      [['tPF 1/2-Cycle Avg'], ['tPF 1/2-Cycle Min', 'tPF 1/2-Cycle Min']],
      [
        new SeriesOptions(
          'arearange',
          'rgba(153, 198, 253, .3)',
          'Min/Max',
          ['pc_4_min_none', 'pc_6_max_none'],
          { valueDecimals: 3 }
        ),
        new SeriesOptions('line', '#0050BF', 'Avg', ['pc_5_avg_none'], {
          valueDecimals: 3,
        }),
      ]
    );

    super.setYMinMax(0, 1.1);
  }
}

class VoltageZeroSequenceUnbalance extends TrendsChart {
  constructor() {
    super(
      'Voltage Zero Sequence Unbalance',
      [
        ['Voltage Zero Sequence Unbalance Avg(%)'],
        [
          'Voltage Zero Sequence Unbalance Min(%)',
          'Voltage Zero Sequence Unbalance Max(%)',
        ],
      ],
      [
        new SeriesOptions(
          'arearange',
          'rgba(153, 198, 253, .3)',
          'Min/Max',
          ['c_289_min_%', 'c_289_max_%'],
          { valueSuffix: ' %', valueDecimals: 2 }
        ),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_289_avg_%'], {
          valueSuffix: ' %',
          valueDecimals: 2,
        }),
      ]
    );

    super.setYMinMax(0, 5);
  }
}

class CurrentZeroSequenceUnbalance extends TrendsChart {
  constructor() {
    super(
      'Current Zero Sequence Unbalance',
      [
        ['Current Zero Sequence Unbalance Avg(%)'],
        [
          'Current Zero Sequence Unbalance Min(%)',
          'Current Zero Sequence Unbalance Max(%)',
        ],
      ],
      [
        new SeriesOptions(
          'arearange',
          'rgba(153, 198, 253, .3)',
          'Min/Max',
          ['c_290_min_%', 'c_290_max_%'],
          { valueSuffix: ' %', valueDecimals: 2 }
        ),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_290_avg_%'], {
          valueSuffix: ' %',
          valueDecimals: 2,
        }),
      ]
    );

    super.setYMinMax(0, 50);
  }
}

class VoltageLNRMSMagnitude extends TrendsChart {
  constructor() {
    super(
      'Voltage L-N RMS Magnitude',
      [
        ['L-N RMS 1/2 (1-cyc) Avg(Volts)'],
        ['L-N RMS 1/2 (1-cyc) Min(Volts)', 'L-N RMS 1/2 (1-cyc) Max(Volts)'],
      ],
      [
        new SeriesOptions(
          'arearange',
          'rgba(153, 198, 253, .3)',
          'Min/Max',
          ['c_1597_min_v', 'c_1597_max_v'],
          { valueSuffix: ' Volts', valueDecimals: 1 }
        ),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_1597_avg_v'], {
          valueSuffix: ' Volts',
          valueDecimals: 1,
        }),
      ]
    );
    super.yAxisOptions[0].softMin = undefined;
    super.yAxisOptions[0].softMax = undefined;

    // this.yAxisOptions.min = 95;
    // this.yAxisOptions.max = 105;
  }
}

class VoltageLLRMSMagnitude extends TrendsChart {
  constructor() {
    super(
      'Voltage L-L RMS Magnitude',
      [
        ['L-L RMS 1/2 (1-cyc) Avg(Volts)'],
        ['L-L RMS 1/2 (1-cyc) Min(Volts)', 'L-L RMS 1/2 (1-cyc) Max(Volts)'],
      ],
      [
        new SeriesOptions(
          'arearange',
          'rgba(153, 198, 253, .3)',
          'Min/Max',
          ['c_1598_min_v', 'c_1598_max_v'],
          { valueSuffix: ' Volts', valueDecimals: 1 }
        ),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_1598_avg_v'], {
          valueSuffix: ' Volts',
          valueDecimals: 1,
        }),
      ]
    );
    super.yAxisOptions[0].softMin = undefined;
    super.yAxisOptions[0].softMax = undefined;
    // this.yAxisOptions.min = 95;
    // this.yAxisOptions.max = 105;
  }
}

class CurrentMagnitudeAndVariations extends TrendsChart {
  constructor() {
    super(
      'Current RMS Magnitude',
      [
        ['Current RMS 1/2 (1-cyc) Avg(Amps)'],
        ['Current RMS 1/2 (1-cyc) Min(Amps)', 'Current RMS 1/2 (1-cyc) Max(Amps)'],
      ],
      [
        new SeriesOptions(
          'arearange',
          'rgba(153, 198, 253, .3)',
          'Min/Max',
          ['c_1599_min_a', 'c_1599_max_a'],
          { valueSuffix: ' Amps', valueDecimals: 1 }
        ),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_1599_avg_a'], {
          valueSuffix: ' Amps',
          valueDecimals: 1,
        }),
      ]
    );
  }
}

class FlickerPinst extends TrendsChart {
  constructor() {
    super(
      'Flicker Pinst (3-Ph Summary)',
      [['Flicker Pinst Avg'], ['Flicker Pinst Min', 'Flicker Pinst Max']],
      [
        new SeriesOptions(
          'arearange',
          'rgba(153, 198, 253, .3)',
          'Min/Max',
          ['c_1611_min_none', 'c_1611_max_none'],
          { valueDecimals: 2 }
        ),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_1611_avg_none'], {
          valueDecimals: 2,
        }),
      ]
    );

    // super.setYMinMax(0, 4);
  }
}

class FlickerPst extends TrendsChart {
  constructor() {
    super(
      'Flicker PST',
      [['Flicker PST Avg'], ['Flicker PST Min', 'Flicker PST Max']],
      [
        new SeriesOptions(
          'arearange',
          'rgba(153, 198, 253, .3)',
          'Min/Max',
          ['c_1612_min_none', 'c_1612_max_none'],
          { valueDecimals: 2 }
        ),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_1612_avg_none'], {
          valueDecimals: 2,
        }),
      ]
    );

    // super.setYMinMax(0, 4);
  }
}

class FlickerPlt extends TrendsChart {
  constructor() {
    super(
      'Flicker Plt (3-Ph summary)',
      [['Flicker Plt Avg'], ['Flicker Plt Min', 'Flicker Plt Max']],
      [
        new SeriesOptions(
          'arearange',
          'rgba(153, 198, 253, .3)',
          'Min/Max',
          ['c_1613_min_none', 'c_1613_max_none'],
          { valueDecimals: 2 }
        ),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_1613_avg_none'], {
          valueDecimals: 2,
        }),
      ]
    );

    // super.setYMinMax(0, 2);
  }
}

class NegativeVoltage extends TrendsChart {
  constructor() {
    super(
      'Voltage Unbalance (Negative Sequence)',
      [
        ['IEC Negative Sequence V Avg(%)'],
        ['IEC Negative Sequence V Min(%)', 'IEC Negative Sequence V Max(%)'],
      ],
      [
        new SeriesOptions(
          'arearange',
          'rgba(153, 198, 253, .3)',
          'Min/Max',
          ['c_287_min_%', 'c_287_max_%'],
          { valueSuffix: ' %', valueDecimals: 2 }
        ),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_287_avg_%'], {
          valueSuffix: ' %',
          valueDecimals: 2,
        }),
      ]
    );

    super.setYMinMax(0, 3);
  }
}

class NegativeCurrent extends TrendsChart {
  constructor() {
    super(
      'Current Unbalance (Negative Sequence)',
      [
        ['IEC Negative Sequence A Avg'],
        ['IEC Negative Sequence A Min', 'IEC Negative Sequence A Max'],
      ],
      [
        new SeriesOptions(
          'arearange',
          'rgba(153, 198, 253, .3)',
          'Min/Max',
          ['c_288_min_%', 'c_288_max_%'],
          { valueSuffix: ' %', valueDecimals: 2 }
        ),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_288_avg_%'], {
          valueSuffix: ' %',
          valueDecimals: 2,
        }),
      ]
    );

    super.setYMinMax(0, 50);
  }
}

class TotalHarmonicDistortion extends TrendsChart {
  constructor() {
    super(
      'Total Harmonic Distortion THD',
      [['THD-V Avg(%)'], ['THD-V Min(%)', 'THD-V Max(%)']],
      [
        new SeriesOptions(
          'arearange',
          'rgba(153, 198, 253, .3)',
          'Min/Max',
          ['c_1609_min_%', 'c_1609_max_%'],
          { valueSuffix: ' %', valueDecimals: 2 }
        ),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_1609_avg_%'], {
          valueSuffix: ' %',
          valueDecimals: 2,
        }),
      ]
    );

    super.setYMinMax(0, 10);
  }
}

class TotalDemandDistortion extends TrendsChart {
  constructor() {
    super(
      'Total Demand Distortion TDD',
      [['TDD-A Avg(%)'], ['TDD-A Min(%)', 'TDD-A Max(%)']],
      [
        new SeriesOptions(
          'arearange',
          'rgba(153, 198, 253, .3)',
          'Min/Max',
          ['c_1610_min_%', 'c_1610_max_%'],
          { valueSuffix: ' %', valueDecimals: 2 }
        ),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_1610_avg_%'], {
          valueSuffix: ' %',
          valueDecimals: 2,
        }),
      ]
    );

    super.setYMinMax(0, 50);
  }
}

// TODO return after endpoint is working to verify data structure
class VoltageOddHarmonics extends TrendsChart {
  constructor() {
    super(
      'Voltage Odd Harmonics<p style="width: 100%; text-align: center; margin: 0; padding: 0">(H3, H5, H7, H9, H11)</p>',
      [
        [
          'Voltage Harmonic H3(%)',
          'Voltage Harmonic H5(%)',
          'Voltage Harmonic H7(%)',
          'Voltage Harmonic H9(%)',
          'Voltage Harmonic H11(%)',
        ],
      ],
      [
        new SeriesOptions(
          'line',
          '#00E290',
          'H3',
          ['pc_1003_rms_%'],
          { valueSuffix: ' %', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#0050BF',
          'H5',
          ['pc_1005_rms_%'],
          { valueSuffix: ' %', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#FFBE08',
          'H7',
          ['pc_1007_rms_%'],
          { valueSuffix: ' %', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#0087FF',
          'H9',
          ['pc_1009_rms_%'],
          { valueSuffix: ' %', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#202F44',
          'H11',
          ['pc_1011_rms_%'],
          { valueSuffix: ' %', valueDecimals: 1 },
          'tenminute'
        ),
      ]
    );
    super.setYMinMax(0, undefined);
    super.yAxisOptions[0].title.useHTML = true;
    super.yAxisOptions[0].title.textAlign = 'center';
  }
}

class CurrentOddHarmonics extends TrendsChart {
  constructor() {
    super(
      'Current Odd Harmonics<p style="width: 100%; text-align: center; margin: 0; padding: 0">(H3, H5, H7, H9, H11)</p>',
      [
        [
          'Current Harmonic H3(A)',
          'Current Harmonic H5(A)',
          'Current Harmonic H7(A)',
          'Current Harmonic H9(A)',
          'Current Harmonic H11(A)',
        ],
      ],
      [
        new SeriesOptions(
          'line',
          '#00E290',
          'H3',
          ['pc_2003_rms_a'],
          { valueSuffix: ' Amps', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#0050BF',
          'H5',
          ['pc_2005_rms_a'],
          { valueSuffix: ' Amps', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#FFBE08',
          'H7',
          ['pc_2007_rms_a'],
          { valueSuffix: ' Amps', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#0087FF',
          'H9',
          ['pc_2009_rms_a'],
          { valueSuffix: ' Amps', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#202F44',
          'H11',
          ['pc_2011_rms_a'],
          { valueSuffix: ' Amps', valueDecimals: 1 },
          'tenminute'
        ),
      ]
    );
    super.setYMinMax(0, undefined);
    super.yAxisOptions[0].title.useHTML = true;
    super.yAxisOptions[0].title.textAlign = 'center';
  }
}

class VoltageAdvancedHarmonics extends AdvancedChart {
  constructor() {
    super(
      'Voltage Harmonics',
      [['H[x]']],
      [
        new SeriesOptions(
          'line',
          '#FF515B',
          'H[x]',
          ['c_511_rms_%'],
          { valueSuffix: ' %', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#FFBE08',
          'H[x]',
          ['c_561_rms_%'],
          { valueSuffix: ' %', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#0087FF',
          'H[x]',
          ['c_611_rms_%'],
          { valueSuffix: ' %', valueDecimals: 1 },
          'tenminute'
        ),
      ],
      'H2'
    );
    super.setYMinMax(0, undefined);
  }
}

class VoltageAdvancedInterharmonics extends AdvancedChart {
  constructor() {
    super(
      'Voltage Interharmonics',
      [['H[x]']],
      [
        new SeriesOptions(
          'line',
          '#FF515B',
          'H[x]',
          ['c_811_rms_%'],
          { valueSuffix: ' %', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#FFBE08',
          'H[x]',
          ['c_861_rms_%'],
          { valueSuffix: ' %', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#0087FF',
          'H[x]',
          ['c_911_rms_%'],
          { valueSuffix: ' %', valueDecimals: 1 },
          'tenminute'
        ),
      ],
      'IH1'
    );
    super.setYMinMax(0, undefined);
  }
}

class CurrentAdvancedHarmonics extends AdvancedChart {
  constructor() {
    super(
      'Current Harmonics',
      [['H[x]']],
      [
        new SeriesOptions(
          'line',
          '#FF515B',
          'H1[x]',
          ['c_661_rms_a'],
          { valueSuffix: ' A', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#FFBE08',
          'H2[x]',
          ['c_713_rms_a'],
          { valueSuffix: ' A', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#0087FF',
          'H33[x]',
          ['c_763_rms_a'],
          { valueSuffix: ' A', valueDecimals: 1 },
          'tenminute'
        ),
      ],
      'H2'
    );
    super.setYMinMax(0, undefined);
  }
}

class CurrentAdvancedInterharmonics extends AdvancedChart {
  constructor() {
    super(
      'Current Interharmonics',
      [['H[x]']],
      [
        new SeriesOptions(
          'line',
          '#FF515B',
          'H[x]',
          ['c_961_rms_a'],
          { valueSuffix: ' A', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#FFBE08',
          'H[x]',
          ['c_1011_rms_a'],
          { valueSuffix: ' A', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#0087FF',
          'H[x]',
          ['c_1061_rms_a'],
          { valueSuffix: ' A', valueDecimals: 1 },
          'tenminute'
        ),
      ],
      'H1'
    );
    super.setYMinMax(0, undefined);
  }
}

class GroundCurrent extends TrendsChart {
  constructor() {
    super(
      'Ground Current IE (A)',
      [
        ['E Current RMS 1/2 (1-cyc) Avg(Amps)'],
        ['E Current RMS 1/2 (1-cyc) Min(Amps)', 'E Current RMS 1/2 (1-cyc) Max(Amps)'],
      ],
      [
        new SeriesOptions(
          'arearange',
          'rgba(0, 226, 144, .3)',
          'Min/Max',
          ['c_20_min_a', 'c_20_max_a'],
          { valueSuffix: ' Amps', valueDecimals: 2 }
        ),
        new SeriesOptions('line', '#0e9015', 'Avg', ['c_20_avg_a'], {
          valueSuffix: ' Amps',
          valueDecimals: 2,
        }),
      ]
    );
  }
}

class VoltageL1N extends TrendsChart {
  constructor() {
    super(
      'Voltage L1-N (V)',
      [['Voltage L1-N 3'], ['Voltage L1-N 1', 'Voltage L1-N 2']],
      [
        new SeriesOptions(
          'arearange',
          'rgba(255, 133, 139, .3)',
          'Min/Max',
          ['c_4_min_v', 'c_4_max_v'],
          { valueSuffix: ' V', valueDecimals: 1 }
        ),
        new SeriesOptions('line', '#FF515B', 'Avg', ['c_4_avg_v'], {
          valueSuffix: ' V',
          valueDecimals: 1,
        }),
      ]
    );
    super.yAxisOptions[0].softMin = undefined;
    super.yAxisOptions[0].softMax = undefined;
  }
}

class VoltageL2N extends TrendsChart {
  constructor() {
    super(
      'Voltage L2-N (V)',
      [['Voltage L2-N 3'], ['Voltage L2-N 1', 'Voltage L2-N 2']],
      [
        new SeriesOptions(
          'arearange',
          'rgba(255, 220, 122, .3)',
          'Min/Max',
          ['c_5_min_v', 'c_5_max_v'],
          { valueSuffix: ' V', valueDecimals: 1 }
        ),
        new SeriesOptions('line', '#FFBE08', 'Avg', ['c_5_avg_v'], {
          valueSuffix: ' V',
          valueDecimals: 1,
        }),
      ]
    );
    super.yAxisOptions[0].softMin = undefined;
    super.yAxisOptions[0].softMax = undefined;
  }
}

class VoltageL3N extends TrendsChart {
  constructor() {
    super(
      'Voltage L3-N (V)',
      [['Voltage L3-N 3'], ['Voltage L3-N 1', 'Voltage L3-N 2']],
      [
        new SeriesOptions(
          'arearange',
          'rgba(153, 198, 253, .3)',
          'Min/Max',
          ['c_6_min_v', 'c_6_max_v'],
          { valueSuffix: ' V', valueDecimals: 1 }
        ),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_6_avg_v'], {
          valueSuffix: ' V',
          valueDecimals: 1,
        }),
      ]
    );
    super.yAxisOptions[0].softMin = undefined;
    super.yAxisOptions[0].softMax = undefined;
  }
}

class VoltageL1L2 extends TrendsChart {
  constructor() {
    super(
      'Voltage L1-L2 (V)',
      [['Voltage L1-L2 3'], ['Voltage L1-L2 1', 'Voltage L1-L2 2']],
      [
        new SeriesOptions(
          'arearange',
          'rgba(255, 133, 139, .3)',
          'Min/Max',
          ['c_7_min_v', 'c_7_max_v'],
          { valueSuffix: ' V', valueDecimals: 1 }
        ),
        new SeriesOptions('line', '#FF515B', 'Avg', ['c_7_avg_v'], {
          valueSuffix: ' V',
          valueDecimals: 1,
        }),
      ]
    );
    super.yAxisOptions[0].softMin = undefined;
    super.yAxisOptions[0].softMax = undefined;
  }
}

class VoltageL2L3 extends TrendsChart {
  constructor() {
    super(
      'Voltage L2-L3 (V)',
      [['Voltage L2-L3 3'], ['Voltage L2-L3 1', 'Voltage L2-L3 2']],
      [
        new SeriesOptions(
          'arearange',
          'rgba(255, 220, 122, .3)',
          'Min/Max',
          ['c_8_min_v', 'c_8_max_v'],
          { valueSuffix: ' V', valueDecimals: 1 }
        ),
        new SeriesOptions('line', '#FFBE08', 'Avg', ['c_8_avg_v'], {
          valueSuffix: ' V',
          valueDecimals: 1,
        }),
      ]
    );
    super.yAxisOptions[0].softMin = undefined;
    super.yAxisOptions[0].softMax = undefined;
  }
}

class VoltageL3L1 extends TrendsChart {
  constructor() {
    super(
      'Voltage L3-L1 (V)',
      [['Voltage L3-L1 3'], ['Voltage L3-L1 1', 'Voltage L3-L1 2']],
      [
        new SeriesOptions(
          'arearange',
          'rgba(153, 198, 253, .3)',
          'Min/Max',
          ['c_9_min_v', 'c_9_max_v'],
          { valueSuffix: ' V', valueDecimals: 1 }
        ),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_9_avg_v'], {
          valueSuffix: ' V',
          valueDecimals: 1,
        }),
      ]
    );
    super.yAxisOptions[0].softMin = undefined;
    super.yAxisOptions[0].softMax = undefined;
  }
}

class CurrentL1 extends TrendsChart {
  constructor() {
    super(
      'Current L1 (A)',
      [['Current L1 Avg'], ['Current L1 Min', 'Current L1 Max']],
      [
        new SeriesOptions(
          'arearange',
          'rgba(255, 133, 139, .3)',
          'Min/Max',
          ['c_16_min_a', 'c_16_max_a'],
          { valueSuffix: ' A', valueDecimals: 1 }
        ),
        new SeriesOptions('line', '#FF515B', 'Avg', ['c_16_avg_a'], {
          valueSuffix: ' A',
          valueDecimals: 1,
        }),
      ]
    );
  }
}

class CurrentL2 extends TrendsChart {
  constructor() {
    super(
      'Current L2 (A)',
      [['Current L2 Avg'], ['Current L2 Min', 'Current L2 Max']],
      [
        new SeriesOptions(
          'arearange',
          'rgba(255, 220, 122, .3)',
          'Min/Max',
          ['c_17_min_a', 'c_17_max_a'],
          { valueSuffix: ' A', valueDecimals: 1 }
        ),
        new SeriesOptions('line', '#FFBE08', 'Avg', ['c_17_avg_a'], {
          valueSuffix: ' A',
          valueDecimals: 1,
        }),
      ]
    );
  }
}

class CurrentL3 extends TrendsChart {
  constructor() {
    super(
      'Current L3 (A)',
      [['Current L3 Avg'], ['Current L3 Min', 'Current L3 Max']],
      [
        new SeriesOptions(
          'arearange',
          'rgba(153, 198, 253, .3)',
          'Min/Max',
          ['c_18_min_a', 'c_18_max_a'],
          { valueSuffix: ' A', valueDecimals: 1 }
        ),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_18_avg_a'], {
          valueSuffix: ' A',
          valueDecimals: 1,
        }),
      ]
    );
  }
}

class NeutralCurrent extends TrendsChart {
  constructor() {
    super(
      'Neutral Current IN(A)',
      [
        ['N Current RMS 1/2 (1-cyc) Avg(Amps)'],
        ['N Current RMS 1/2 (1-cyc) Min(Amps)', 'N Current RMS 1/2 (1-cyc) Max(Amps)'],
      ],
      [
        new SeriesOptions(
          'arearange',
          'rgba(228, 235, 239, .3)',
          'Min/Max',
          ['c_19_min_a', 'c_19_max_a'],
          { valueSuffix: ' Amps', valueDecimals: 2 }
        ),
        new SeriesOptions('line', '#536A84', 'Avg', ['c_19_avg_a'], {
          valueSuffix: ' Amps',
          valueDecimals: 2,
        }),
      ]
    );
  }
}

class Frequency extends TrendsChart {
  constructor() {
    super(
      'Frequency (Hz)',
      [['Frequency Avg'], ['Frequency Min', 'Frequency Max']],
      [
        new SeriesOptions(
          'arearange',
          'rgba(153, 198, 253, .3)',
          'Min/Max',
          ['c_21_min_hz', 'c_21_max_hz'],
          { valueSuffix: ' Hz', valueDecimals: 3 }
        ),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_21_avg_hz'], {
          valueSuffix: ' Hz',
          valueDecimals: 3,
        }),
      ]
    );
    super.yAxisOptions[0].softMin = undefined;
    super.yAxisOptions[0].softMax = undefined;
  }
}

class VoltageNE extends TrendsChart {
  constructor() {
    super(
      'Voltage N-E (V)',
      [['Voltage N-E Avg'], ['Voltage N-E Min', 'Voltage N-E Max']],
      [
        new SeriesOptions(
          'arearange',
          'rgba(228, 235, 239, .3)',
          'Min/Max',
          ['c_3_min_v', 'c_3_max_v'],
          { valueSuffix: ' V', valueDecimals: 2 }
        ),
        new SeriesOptions('line', '#536A84', 'Avg', ['c_3_avg_v'], {
          valueSuffix: ' V',
          valueDecimals: 2,
        }),
      ]
    );
  }
}

export class PQEvents implements IEventsChart {
  public channelIds = [];
  public name = 'Events';
  public mYAxis;
  public yAxisOptions: PowersideYAxisOptions[];
  private isQubeScan: boolean;
  private mSeriesArray: EventSeriesOptions[] = [];
  private mSpacer = 10;

  public set yAxis(newYAxis: number) {
    this.mYAxis = this.seriesArray.forEach((series) => (series.yAxis = newYAxis));
  }

  public get yAxis(): number {
    return this.mYAxis;
  }

  public get seriesArray(): EventSeriesOptions[] {
    return this.mSeriesArray;
  }

  public get spacer(): number {
    return this.mSpacer;
  }

  public set spacer(gap: number) {
    this.mSpacer = gap;
  }

  public clearSeriesData() {
    this.mSeriesArray.forEach((series) => (series.data.length = 0));
  }

  private getEventProperties(type: string) {
    const eventProps = {
      name: undefined,
      imageURL: undefined,
      yValue: undefined,
    };

    switch (type) {
      case 'voltageSag':
        eventProps.name = 'Voltage Sag';
        eventProps.yValue = this.isQubeScan ? 4 : 3;
        break;
      case 'voltageSwell':
        eventProps.name = 'Voltage Swell';
        eventProps.yValue = this.isQubeScan ? 3 : 2;
        break;
      case 'interruption':
        eventProps.name = 'Interruption';
        eventProps.yValue = this.isQubeScan ? 1 : 0;
        break;
      case 'highFrequencyImpulse':
        eventProps.name = 'High Frequency Impulse';
        eventProps.yValue = this.isQubeScan ? 2 : 1;
        break;
      case 'others':
        eventProps.name = 'Others';
        eventProps.yValue = 0;
        break;
      default:
        eventProps.name = 'Others';
        eventProps.yValue = 0;
    }
    return eventProps;
  }

  private makeEventDataHCCompatible(eventTimelineData) {
    const eventsSeriesData = [];

    for (const eventType in eventTimelineData) {
      if (eventTimelineData.hasOwnProperty(eventType)) {
        eventsSeriesData.push({
          name: eventTimelineData[eventType].name,
          data: eventTimelineData[eventType].data,
          eventTypeId: eventTimelineData[eventType].description,
          findNearestPointBy: 'xy',
        });
      }
    }

    return eventsSeriesData;
  }

  private createNewEventSeries(event: IEvent) {
    const { name, yValue } = this.getEventProperties(event.deviceEventType);
    return {
      data: [],
      name,
      yValue,
      description: event.deviceEventTypeId,
    };
  }

  public setSeriesData(
    channelDataArray: any,
    eventsDataArray: IEvent[]
  ): ISeriesOptions[] {
    const eventData = {};

    if (eventsDataArray === [] || eventsDataArray === undefined) {
      return this.seriesArray;
    }

    for (const event of eventsDataArray) {
      if (
        event.deviceEventType !== 'voltageSag' &&
        event.deviceEventType !== 'voltageSwell' &&
        event.deviceEventType !== 'voltageSag' &&
        event.deviceEventType !== 'interruption' &&
        event.deviceEventType !== 'highFrequencyImpulse'
      ) {
        if (!eventData[`Others`]) {
          eventData[`Others`] = this.createNewEventSeries(event);
        }

        eventData[`Others`].data.push([
          moment(event.triggeredWhen).utc(false).valueOf(),
          eventData[`Others`].yValue,
        ]);
      } else {
        if (!eventData[event.deviceEventType]) {
          eventData[event.deviceEventType] = this.createNewEventSeries(event);
        }
        eventData[event.deviceEventType].data.push([
          moment(event.triggeredWhen).utc(false).valueOf(),
          eventData[event.deviceEventType].yValue,
        ]);
      }
    }
    // for (const key in eventData) {
    //     if (eventData.hasOwnProperty(key)) {
    //         eventData[key].data.forEach(row => row.push(eventData[key].yValue));
    //     }
    // }

    const newEventsDataArray = this.makeEventDataHCCompatible(eventData);
    this.seriesArray.forEach((series: EventSeriesOptions) => {
      const matchedSeries = newEventsDataArray.find(
        (newEvents) => newEvents.name === series.name
      );

      if (matchedSeries) {
        series.data.unshift(...matchedSeries.data);
        series.eventTypeId = matchedSeries.eventTypeId;
      }
    });

    return this.seriesArray;
  }

  public replaceChartData(trendsData: {}, result: IEvent[]): ISeriesOptions[] {
    const eventData = {};

    if (result === [] || result === undefined) {
      return this.seriesArray;
    }

    for (const event of result) {
      if (!eventData[event.deviceEventType]) {
        eventData[event.deviceEventType] = this.createNewEventSeries(event);
      }

      eventData[event.deviceEventType].data.push([
        moment(event.triggeredWhen).utc(false).valueOf(),
        eventData[event.deviceEventType].yValue,
      ]);
    }

    for (const key in eventData) {
      if (eventData.hasOwnProperty(key)) {
        this.seriesArray.forEach((existingSeries) => {
          if (eventData[key].name === existingSeries.name) {
            existingSeries.data.unshift(...eventData[key].data);
          }
        });
      }
    }

    return this.seriesArray;
  }

  constructor(private mpService: MeasurementPointsService) {
    this.isQubeScan =
      this.mpService.selectedMeasurementPoint.measurementPointTypeId === 1;
    if (this.isQubeScan) {
      this.mSeriesArray = [
        new EventSeriesOptions('Voltage Sag', 'triangle-down', 3),
        new EventSeriesOptions('Voltage Swell', 'triangle', 2),
        new EventSeriesOptions('Interruption', 'square', 0),
        new EventSeriesOptions('High Frequency Impulse', 'circle', 1),
        new EventSeriesOptions(
          'Others',
          'url(./assets/images/legend/other_events.svg)',
          4
        ),
      ];
    } else {
      this.mSeriesArray = [
        new EventSeriesOptions('Voltage Sag', 'triangle-down', 3),
        new EventSeriesOptions('Voltage Swell', 'triangle', 2),
        new EventSeriesOptions('Interruption', 'square', 0),
        new EventSeriesOptions('High Frequency Impulse', 'circle', 1),
      ];
    }

    this.yAxisOptions = [
      {
        visible: true,
        tickPositions: [0, 1, 2, 3, 4],
        labels: {
          enabled: false,
        },
        min: 0,
        max: 2,
        height: 50,
        lineWidth: 1,
        resize: {
          enabled: false,
        },
        offset: 0,
        plotBands: [
          {
            acrossPanes: true,
            color: '#F0F5F7',
            from: 0,
            to: 2,
          },
        ],
      },
      {
        visible: true,
        tickPositions: [0, 1, 2],
        labels: {
          enabled: false,
        },
        opposite: false,
        min: 0,
        max: 2,
        height: 50,
        lineWidth: 1,
        resize: {
          enabled: false,
        },
        offset: 0,
        plotBands: [
          {
            acrossPanes: true,
            color: '#F0F5F7',
            from: 0,
            to: 2,
          },
        ],
      },
    ];
  }
}

export class NotesChart implements IEventsChart {
  public channelIds = [];
  public name = 'Events';
  public mYAxis;
  public yAxisOptions: PowersideYAxisOptions[];
  private mSeriesArray: EventSeriesOptions[] = [];
  private mSpacer = 10;

  public set yAxis(newYAxis: number) {
    this.mYAxis = this.seriesArray.forEach((series) => (series.yAxis = newYAxis));
  }

  public get yAxis(): number {
    return this.mYAxis;
  }

  public get seriesArray(): EventSeriesOptions[] {
    return this.mSeriesArray;
  }

  public get spacer(): number {
    return this.mSpacer;
  }

  public set spacer(gap: number) {
    this.mSpacer = gap;
  }

  public clearSeriesData() {
    this.mSeriesArray.forEach((series) => (series.data.length = 0));
  }

  public setSeriesData(
    channelDataArray: any,
    eventsDataArray: IEvent[],
    granularity,
    notesDataArray
  ) {
    if (notesDataArray === [] || notesDataArray === undefined) {
      return this.seriesArray;
    }

    const notesData = notesDataArray.map((note) => [
      moment(note.startDateTime).valueOf(),
      1,
      note.summary,
    ]);
    this.seriesArray[0].data = notesData;

    return this.seriesArray;
  }

  public replaceChartData(
    trendsData: {},
    result: IEvent[],
    notesDataArray
  ): ISeriesOptions[] {
    if (result === [] || result === undefined) {
      return this.seriesArray;
    }

    const notesData = notesDataArray.map((note) => [
      moment(note.startDateTime).valueOf(),
      1,
      note.summary,
    ]);

    this.seriesArray[0].data.unshift(...notesData);

    return this.seriesArray;
  }

  constructor() {
    this.mSeriesArray = [
      new EventSeriesOptions('Note', 'url(./assets/images/calendar.svg)', 2),
    ];

    this.yAxisOptions = [
      {
        visible: false,
        tickPositions: [0],
        labels: {
          enabled: false,
        },
        min: 0,
        max: 0,
        height: 25,
        lineWidth: 1,
        resize: {
          enabled: false,
        },
        offset: 0,
        plotLines: [
          {
            value: 1,
          },
        ],
      },
    ];
  }
}

@Injectable()
export class ChartDefinitions {
  constructor(private mpService: MeasurementPointsService) {}

  public getDynamic(
    name: string,
    trendParam: Array<any>,
    unit: string,
    unitOffset: number
  ) {
    return new Dynamic(name, trendParam, unit, unitOffset);
  }

  public get dashboard() {
    return new Dashboard();
  }

  public get activeEnergy() {
    return new ActiveEnergy();
  }

  public get reactiveEnergy() {
    return new ReactiveEnergy();
  }

  public get activePower() {
    return new ActivePower();
  }

  public get reactivePower() {
    return new ReactivePower();
  }

  public get powerFactor() {
    return new PowerFactor();
  }

  public get totalReactivePower() {
    return new TotalReactivePower();
  }

  public get apparentPower() {
    return new ApparentPower();
  }

  public get pqEvents() {
    return new PQEvents(this.mpService);
  }

  public get notes() {
    return new NotesChart();
  }

  public get totalPowerFactor() {
    return new TotalPowerFactor();
  }

  public get totalActivePower() {
    return new TotalActivePower();
  }

  public get voltageLNRMSMagnitude() {
    return new VoltageLNRMSMagnitude();
  }

  public get voltageLLRMSMagnitude() {
    return new VoltageLLRMSMagnitude();
  }

  public get currentMagnitudeAndVariations() {
    return new CurrentMagnitudeAndVariations();
  }

  public get flickerPinst() {
    return new FlickerPinst();
  }

  public get flickerPst() {
    return new FlickerPst();
  }

  public get negativeVoltage() {
    return new NegativeVoltage();
  }

  public get negativeCurrent() {
    return new NegativeCurrent();
  }

  public get totalHarmonicDistortion() {
    return new TotalHarmonicDistortion();
  }

  public get totalDemandDistortion() {
    return new TotalDemandDistortion();
  }

  public get voltageOddHarmonics() {
    return new VoltageOddHarmonics();
  }

  public get currentOddHarmonics() {
    return new CurrentOddHarmonics();
  }

  public get voltageAdvancedHarmonics() {
    return new VoltageAdvancedHarmonics();
  }

  public get voltageAdvancedInterharmonics() {
    return new VoltageAdvancedInterharmonics();
  }

  public get currentAdvancedHarmonics() {
    return new CurrentAdvancedHarmonics();
  }

  public get currentAdvancedInterharmonics() {
    return new CurrentAdvancedInterharmonics();
  }

  public get groundCurrent() {
    return new GroundCurrent();
  }

  public get neutralCurrent() {
    return new NeutralCurrent();
  }

  public get voltageL1N() {
    return new VoltageL1N();
  }

  public get voltageL2N() {
    return new VoltageL2N();
  }

  public get voltageL3N() {
    return new VoltageL3N();
  }

  public get voltageL1L2() {
    return new VoltageL1L2();
  }

  public get voltageL2L3() {
    return new VoltageL2L3();
  }

  public get voltageL3L1() {
    return new VoltageL3L1();
  }

  public get voltageNE() {
    return new VoltageNE();
  }

  public get flickerPlt() {
    return new FlickerPlt();
  }

  public get currentL1() {
    return new CurrentL1();
  }

  public get currentL2() {
    return new CurrentL2();
  }

  public get currentL3() {
    return new CurrentL3();
  }

  public get frequency() {
    return new Frequency();
  }

  public get voltageZeroSequenceUnbalance() {
    return new VoltageZeroSequenceUnbalance();
  }

  public get currentZeroSequenceUnbalance() {
    return new CurrentZeroSequenceUnbalance();
  }
}
