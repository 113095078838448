import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  ChangeDetectionStrategy,
} from '@angular/core';
import { QueryList, ViewChildren } from '@angular/core';
import { FleetCustomerComponent } from '../fleet-customer/fleet-customer.component';

@Component({
  selector: 'app-fleet-partner',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './fleet-partner.component.html',
  styleUrls: ['./fleet-partner.component.scss'],
})
export class FleetPartnerComponent implements OnChanges, OnInit {
  @Input() partner: any;
  @Input() partnerFilter: string;
  @Input() customerFilter: string;
  @Input() mpFilter: string;
  @Input() locationFilter: string;
  @Input() withEventsOnly: boolean;
  @Input() userPrefs: any;
  @Input() expandAll: boolean;
  @Input() qubeScanEnabled: boolean;
  @Input() isSystemAdmin: boolean;
  @Input() isAdmin: boolean;

  public showComponent = true;
  public showCustomer = true;
  public isExpand: boolean;

  public customerMp = [];
  private childList: Array<FleetCustomerComponent>;

  @ViewChildren('childCustomer') childCustomer: QueryList<
    FleetCustomerComponent
  >;

  constructor() {}

  ngOnInit() {
    this.showComponent = this.partner.customers.length > 0;
    this.partner.customers.forEach((customer) => {
      this.customerMp.push(null);
      customer.expanded = !this.partner.expand;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes &&
        changes.partnerFilter &&
        !changes.partnerFilter.firstChange) ||
      (changes && changes.customerFilter && !changes.customerFilter.firstChange)
    ) {
      const temp = this.partner.customers.filter((customer) =>
        customer.accountName
          .toLowerCase()
          .includes(this.customerFilter.toLowerCase())
      );
      this.showComponent =
        temp.length > 0 &&
        this.partner.accountName
          .toLowerCase()
          .includes(this.partnerFilter.toLowerCase()) &&
        this.partner.customers.length > 0;
    } else if (changes && changes.expandAll && !changes.expandAll.firstChange) {
      this.isExpand = this.expandAll;
    }
  }

  setShow(event, index) {
    this.childList = this.childCustomer.toArray();
    this.customerMp[index] = this.childList[index].mpCount;
    if (this.customerMp.indexOf(null) === -1) {
      this.showCustomer =
        this.customerMp.reduce((a, b) => {
          return a + b;
        }) > 0;
    }
  }

  toggleAllPartnerPanels() {
    this.isExpand = this.partner.expand;
    this.partner.customers.forEach(
      (customer) => (customer.expanded = this.partner.expand)
    );
    this.partner.expand = !this.partner.expand;
  }
}
