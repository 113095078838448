export const VoltageIHarmonics = [
  { name: 'IH1', factoryName: 'VIH1' },
  { name: 'IH2', factoryName: 'VIH2' },
  { name: 'IH3', factoryName: 'VIH3' },
  { name: 'IH4', factoryName: 'VIH4' },
  { name: 'IH5', factoryName: 'VIH5' },
  { name: 'IH6', factoryName: 'VIH6' },
  { name: 'IH7', factoryName: 'VIH7' },
  { name: 'IH8', factoryName: 'VIH8' },
  { name: 'IH9', factoryName: 'VIH9' },
  { name: 'IH10', factoryName: 'VIH10' },
  { name: 'IH11', factoryName: 'VIH11' },
  { name: 'IH12', factoryName: 'VIH12' },
  { name: 'IH13', factoryName: 'VIH13' },
  { name: 'IH14', factoryName: 'VIH14' },
  { name: 'IH15', factoryName: 'VIH15' },
  { name: 'IH16', factoryName: 'VIH16' },
  { name: 'IH17', factoryName: 'VIH17' },
  { name: 'IH18', factoryName: 'VIH18' },
  { name: 'IH19', factoryName: 'VIH19' },
  { name: 'IH20', factoryName: 'VIH20' },
  { name: 'IH21', factoryName: 'VIH21' },
  { name: 'IH22', factoryName: 'VIH22' },
  { name: 'IH23', factoryName: 'VIH23' },
  { name: 'IH24', factoryName: 'VIH24' },
  { name: 'IH25', factoryName: 'VIH25' },
  { name: 'IH26', factoryName: 'VIH26' },
  { name: 'IH27', factoryName: 'VIH27' },
  { name: 'IH28', factoryName: 'VIH28' },
  { name: 'IH29', factoryName: 'VIH29' },
  { name: 'IH30', factoryName: 'VIH30' },
  { name: 'IH31', factoryName: 'VIH31' },
  { name: 'IH32', factoryName: 'VIH32' },
  { name: 'IH33', factoryName: 'VIH33' },
  { name: 'IH34', factoryName: 'VIH34' },
  { name: 'IH35', factoryName: 'VIH35' },
  { name: 'IH36', factoryName: 'VIH36' },
  { name: 'IH37', factoryName: 'VIH37' },
  { name: 'IH38', factoryName: 'VIH38' },
  { name: 'IH39', factoryName: 'VIH39' },
  { name: 'IH40', factoryName: 'VIH40' },
  { name: 'IH41', factoryName: 'VIH41' },
  { name: 'IH42', factoryName: 'VIH42' },
  { name: 'IH43', factoryName: 'VIH43' },
  { name: 'IH44', factoryName: 'VIH44' },
  { name: 'IH45', factoryName: 'VIH45' },
  { name: 'IH46', factoryName: 'VIH46' },
  { name: 'IH47', factoryName: 'VIH47' },
  { name: 'IH48', factoryName: 'VIH48' },
  { name: 'IH49', factoryName: 'VIH49' },
];
