import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ChangeDetectionStrategy, Component, OnInit, OnDestroy } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidationErrors,
  AbstractControl,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { IUser } from '../shared/classes/user';
import { AuthService, LoaderService } from '../shared/services';
import { IUserUpdateParams } from './../shared/classes/userUpdateParams.interface';
import { NotificationsService } from './../shared/modules/notifications/shared/notifications.service';
import { AccountsService } from '../shared/services/accounts.service';
import { ResetPasswordDialogComponent } from '../login/reset-password-dialog/reset-password-dialog.component';
import * as fromUser from './../_store/_reducers';

@Component({
  selector: 'app-profile',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  public profileForm: FormGroup;
  public userForm: FormGroup;

  public user: IUser;
  public minLength = 8;
  public passwordPattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  public maskPassword = true;
  public maskConfirm = true;
  public showError = false;
  private userSub$: Subscription;

  constructor(
    private authService: AuthService,
    private loaderService: LoaderService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private accountsService: AccountsService,
    private store: Store<fromUser.State>
  ) {}

  ngOnInit() {
    this.loaderService.show();
    this.userForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
    });

    this.userSub$ = this.store.pipe(select(fromUser.getUser)).subscribe((user: IUser) => {
      this.user = user;
      this.userForm.patchValue({
        firstName: this.user.firstName,
        lastName: this.user.lastName,
      });
      this.loaderService.hide();
    });

    this.profileForm = new FormGroup(
      {
        newPassword: new FormControl('', [
          Validators.required,
          Validators.pattern(this.passwordPattern),
          Validators.minLength(this.minLength),
        ]),
        confirmPassword: new FormControl('', [
          Validators.required,
          Validators.pattern(this.passwordPattern),
          Validators.minLength(this.minLength),
        ]),
      },
      {
        validators: (control: FormGroup): ValidationErrors | null => {
          const pwd = control.get('newPassword');
          const cfm = control.get('confirmPassword');

          const valid = pwd && cfm && pwd.value === cfm.value;
          const error = { passwordMismatch: true };

          let pErrors = { ...pwd.errors };
          let cErrors = { ...cfm.errors };

          if (!valid) {
            pErrors = { ...pErrors, ...error };
            cErrors = { ...cErrors, ...error };
          } else {
            if (pErrors.hasOwnProperty('passwordMismatch')) {
              delete pErrors.passwordMismatch;
            }

            if (cErrors.hasOwnProperty('passwordMismatch')) {
              delete cErrors.passwordMismatch;
            }
          }

          pwd.setErrors(Object.keys(pErrors).length > 0 ? pErrors : null);
          cfm.setErrors(Object.keys(cErrors).length > 0 ? cErrors : null);

          return valid ? null : error;
        },
      }
    );
  }

  ngOnDestroy() {
    this.userSub$.unsubscribe();
  }

  public get newPassword(): AbstractControl {
    return this.profileForm.get('newPassword');
  }

  public get confirmPassword(): AbstractControl {
    return this.profileForm.get('newPassword');
  }

  public get firstName(): AbstractControl {
    return this.userForm.get('firstName');
  }

  public get lastName(): AbstractControl {
    return this.userForm.get('lastName');
  }

  public resetPassword(): void {
    const resetDialog = this.dialog.open(ResetPasswordDialogComponent, {
      width: '350px',
      disableClose: true,
      data: { from: 'profile' },
      restoreFocus: false,
    });

    resetDialog.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      this.loaderService.show();
      this.authService.resetPassword(result).then(
        () => {
          this.loaderService.hide();
          this.notificationsService.notify(
            this.translateService.instant('profile.password-change-success')
          );
          this.profileForm.reset();
        },
        () => {
          this.loaderService.hide();
          this.showError = true;
        }
      );
    });
  }

  public updateUser(event: Event): void {
    if (this.user.middleName === null) {
      this.user.middleName = '';
    }
    if (this.user.organization === null) {
      this.user.organization = '';
    }
    if (this.user.email === null) {
      this.user.email = '';
    }
    if (this.user.homePhone === null) {
      this.user.homePhone = '';
    }
    if (this.user.workPhone === null) {
      this.user.workPhone = '';
    }
    if (this.user.mobilePhone === null) {
      this.user.mobilePhone = '';
    }
    event.stopPropagation();
    const userUpdateParams: IUserUpdateParams = {
      accountId: this.user.accountId,
      firstName: this.userForm.get('firstName').value,
      middleName: this.user.middleName,
      lastName: this.userForm.get('lastName').value,
      organization: this.user.organization,
      email: this.user.email,
      homePhone: this.user.homePhone,
      workPhone: this.user.workPhone,
      mobilePhone: this.user.mobilePhone,
      preferredLocaleId: this.user.preferredLocaleId,
      retired: this.user.retired,
      roleId: this.user.userRoleId,
    };
    this.accountsService
      .updateUser(userUpdateParams, this.user.id)
      .pipe(take(1))
      .subscribe((response) => {
        if (response) {
          this.userForm.reset({
            firstName: this.userForm.get('firstName').value,
            lastName: this.userForm.get('lastName').value,
          });
          this.notificationsService.notify(
            this.translateService.instant('profile.updated')
          );
        } else {
          console.log(response);
        }
      });
  }

  public resetUserForm(): void {
    this.userForm.reset(this.user);
  }
}
