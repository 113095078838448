<div class="map-view-container">
    <div class="map-container">
        <app-map
                [measurementPoints]="measurementPoints"
                (zoomFactor)="setZoomFactor($event)"
        ></app-map>
    </div>
    <div class="panel-container">
        <app-map-panel
                [measurementPointStatusCounts]="measurementPointStatusCounts"
                [measurementPointCount]="measurementPointCount"
                [zoomFactor]="zoomFactor"
        ></app-map-panel>
    </div>
</div>
