<mat-toolbar id="header">
    <div class="logo">
        <img src="./assets/images/psl-logo.svg">
    </div>
    <div class="controls">
        <div class="current-selection">
            <span data-cy="current-account">{{ accountName }}</span><span data-cy="current-city" *ngIf="locationCity"> - {{ locationCity }} </span><span data-cy="current-state" *ngIf="locationCity && locationState"> , {{ locationState }} | {{ mpName }} | ({{ threeLetterTimezone }}) </span>
        </div>
        <button mat-stroked-button data-cy="change-mp-button"  (click)="changeSelection()">
            {{ 'header.change-selection' | translate }}
        </button>
        <div class="buttons">
            <button mat-icon-button [matMenuTriggerFor]="userMenu">
                <mat-icon svgIcon="account"></mat-icon>
            </button>
            <mat-menu #userMenu="matMenu" xPosition="before">
                <button mat-menu-item [routerLink]="['/profile']">{{ 'header.edit-profile' | translate }}</button>
                <button *ngIf="isAdministrator" mat-menu-item [routerLink]="['/manage-members', userAccount?.id]">{{ 'global.manage-members' | translate }}</button>
                <button data-cy="logout" mat-menu-item (click)="logout()">{{ 'header.logout' | translate }}</button>
            </mat-menu>
            <mde-popover
                #contactPopover="mdePopover"
                mdePopoverTriggerOn="click"
                [mdePopoverOverlapTrigger]="false"
                [mdePopoverArrowWidth]="0"
                [mdeFocusTrapAutoCaptureEnabled]="false"
            >
                <mat-card class="mat-elevation-z8">
                    <mat-card-content>
                        <div *ngIf="contactInfo && (userAccount?.isPartner || (!userAccount?.isPartner && userAccount?.partnerAccountId))" class="contact-info">
                            <div *ngIf="!userAccount?.isPartner && userAccount?.partnerAccountId">
                                <strong>{{ 'header.contact-info.partner-contact' | translate }}:</strong>
                            </div>
                            <div *ngIf="userAccount?.isPartner">
                                <strong>{{ 'header.contact-info.customer-contact' | translate }}:</strong>
                            </div>
                            <div *ngIf="contactInfo?.name">{{ contactInfo?.name }}</div>
                            <div *ngIf="contactInfo?.contact.primary.email">
                                <a [href]="'mailto:' + contactInfo?.contact.primary.email">{{ contactInfo?.contact.primary.email }}</a>
                            </div>
                            <div *ngIf="contactInfo?.telephone && contactInfo?.telephone.number">{{ contactInfo?.telephone.number }}</div>
                        </div>
                        <div class="contact-info">
                            <div><strong>{{ 'header.contact-info.contact-powerside' | translate }}:</strong></div>
                            <div>{{ 'global.email' | translate }}: <a href="mailto:In-Site.support@powerside.com">In-Site.support@powerside.com</a></div>
                            <div>{{ 'global.phone' | translate }}: 1-877-333-8392</div>
                        </div>
                        <div class="version-info">
                            {{ 'global.version' | translate:{version: version} }}
                        </div>
                    </mat-card-content>
                </mat-card>
            </mde-popover>
            <button mat-icon-button [matTooltip]="'header.contact-button' | translate" mdePopoverTriggerOn="click" [mdePopoverTriggerFor]="contactPopover" [mdePopoverBackdropCloseOnClick]="true" [mdePopoverCloseOnClick]="false">
                <mat-icon svgIcon="phone"></mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>
