<div class="profile">
    <div class="heading">
        <h1>{{ 'profile.title' | translate }}</h1>
    </div>
    <div class="profile-detail">
        <div class="fields">
            <form class="user-form" (submit)="updateUser($event)" [formGroup]="userForm">
                <div class="field">
                    <mat-form-field appearance="fill">
                        <mat-label>{{ 'profile.firstName' | translate }}</mat-label>
                        <input [ngClass]="{'input-edit': firstName.dirty}" matInput [placeholder]="'profile.first-name' | translate" formControlName="firstName">
                    </mat-form-field>
                </div>
                <div class="field">
                    <mat-form-field appearance="fill">
                        <mat-label>{{ 'profile.lastName' | translate }}</mat-label>
                        <input [ngClass]="{'input-edit': lastName.dirty}" matInput [placeholder]="'profile.last-name' | translate" formControlName="lastName">
                    </mat-form-field>
                </div>
                <div class="field">
                    <mat-form-field appearance="fill">
                        <mat-label>{{ 'profile.organization' | translate }}</mat-label>
                        <input matInput disabled [placeholder]="'profile.organization' | translate" [value]="user?.organization">
                    </mat-form-field>
                </div>
                <div class="field">
                    <mat-form-field appearance="fill">
                        <mat-label>{{ 'profile.email' | translate }}</mat-label>
                        <input matInput disabled [placeholder]="'profile.email' | translate" [value]="user?.email">
                    </mat-form-field>
                </div>
                <div class="field">
                    <mat-form-field appearance="fill">
                        <mat-label>{{ 'profile.password' | translate }}</mat-label>
                        <input matInput disabled type="password" [placeholder]="'profile.password' | translate" value="••••••••••">
                        <mat-hint align="end">
                            <a href="javascript:void(0);" (click)="resetPassword()">
                                {{ 'profile.resetPassword' | translate }}
                            </a>
                        </mat-hint>
                    </mat-form-field>
                </div>
                <div class="field" *ngIf="userForm.dirty">
                    <div class="button-group">
                        <button mat-flat-button color="accent" type="submit" [disabled]="userForm.invalid">{{ 'global.OK' | translate }}</button>
                        <button mat-flat-button type="button" (click)="resetUserForm()">{{ 'global.cancel' | translate }}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>