import { createAction, props } from '@ngrx/store';

import { Channels } from '../../../shared/classes/channels.interface';

export const setChannels = createAction(
  '[Charts] Set Channels',
  props<{ payload: { mpId: string } }>()
);
export const setChannelsSuccess = createAction(
  '[Charts] Set Channels Success',
  props<{ payload: { channels: Channels } }>()
);
export const setChannelsFail = createAction(
  '[Charts] Set Channels Fail',
  props<{ error: any }>()
);

export type ChannelsActionsUnion = ReturnType<
  typeof setChannels | typeof setChannelsSuccess | typeof setChannelsFail
>;
