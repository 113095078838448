<section class="dialog-container">
    <h1 class="member-title">
        {{ title | translate }}
    </h1>
    <button mat-icon-button (click)="closeDialog()">
        <mat-icon class="close-button" [svgIcon]="'close'"></mat-icon>
    </button>
    <h4 class="account-info">
        Account: {{ accountName }}
    </h4>
    <div class="fields">
        <form class="member-form" (submit)="onSubmit($event)" [formGroup]="memberForm">
            <div class="field">
                <mat-form-field appearance="fill">
                    <mat-label>{{ 'profile.firstName' | translate }}*</mat-label>
                    <input matInput formControlName="firstName">
                    <mat-error *ngIf="firstName.hasError('required')">{{ 'global.errors.fieldRequired' | translate }}</mat-error>
                </mat-form-field>
            </div>
            <div class="field">
                <mat-form-field appearance="fill">
                    <mat-label>{{ 'profile.lastName' | translate }}*</mat-label>
                    <input matInput formControlName="lastName">
                    <mat-error *ngIf="lastName.hasError('required')">{{ 'global.errors.fieldRequired' | translate }}</mat-error>
                </mat-form-field>
            </div>
            <div class="field">
                <mat-form-field appearance="fill">
                    <mat-label>{{ 'global.email' | translate }}*</mat-label>
                    <input matInput formControlName="email">
                    <mat-error *ngIf="email.hasError('required')">{{ 'global.errors.fieldRequired' | translate }}</mat-error>
                </mat-form-field>
            </div>
            <div class="field">
                <mat-form-field appearance="fill">
                    <mat-label>{{ 'global.mobilePhone' | translate }}</mat-label>
                    <input matInput formControlName="mobilePhone">
                </mat-form-field>
            </div>
            <div class="member-button">
                <button mat-flat-button color="accent" type="submit" [disabled]="memberForm.invalid">{{ 'global.save' | translate }}</button>
                <button mat-flat-button type="button" (click)="closeDialog()">{{ 'global.cancel' | translate }}</button>
            </div>
        </form>
    </div>
</section>