<button
  [disabled]="beginningOfCreation || !havePast"
  (click)="changeTimePosition('past')"
  mat-flat-button
  id="past"
>
  <
</button>
<button
  (click)="changeTimeScope('years', 'day')"
  id="year"
  mat-flat-button
  [ngClass]="currentScope === 'years' ? 'clicked' : ''"
>
  y
</button>
<button
  (click)="changeTimeScope('months', 'hour')"
  id="month"
  mat-flat-button
  [ngClass]="currentScope === 'months' ? 'clicked' : ''"
>
  m
</button>
<button
  (click)="changeTimeScope('weeks', 'hour')"
  id="week"
  mat-flat-button
  [ngClass]="currentScope === 'weeks' ? 'clicked' : ''"
>
  w
</button>
<button
  (click)="changeTimeScope('days', 'minute')"
  id="day"
  mat-flat-button
  [ngClass]="currentScope === 'days' ? 'clicked' : ''"
>
  d
</button>
<button
  *ngIf="type === 'dashboard'"
  (click)="changeTimeScope('hours', 'minute')"
  id="hour"
  mat-flat-button
  [ngClass]="currentScope === 'hours' ? 'clicked' : ''"
>
  h
</button>
<button
  (click)="changeTimePosition('future')"
  mat-flat-button
  id="right"
  [disabled]="!haveFutur"
>
  >
</button>
