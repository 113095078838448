<ng-container>
  <span [hidden]="!showComponent || !showCustomer">
    <h2 class="partner-name" (click)="toggleAllPartnerPanels()">
      {{ partner.accountName }}
    </h2>
    <span class="toggle-partner">
      <mat-icon
        *ngIf="partner.expand"
        svgIcon="expand"
        color="primary"
        (click)="toggleAllPartnerPanels()"
      ></mat-icon>
      <mat-icon
        *ngIf="!partner.expand"
        svgIcon="collapse"
        color="primary"
        (click)="toggleAllPartnerPanels()"
      ></mat-icon>
    </span>
    <mat-accordion [multi]="true">
      <div *ngFor="let customer of partner.customers; let idx = index">
        <app-fleet-customer
          #childCustomer
          [customer]="customer"
          [customerFilter]="customerFilter"
          [mpFilter]="mpFilter"
          [locationFilter]="locationFilter"
          [withEventsOnly]="withEventsOnly"
          [userPrefs]="userPrefs"
          [expanded]="isExpand"
          [qubeScanEnabled]="qubeScanEnabled"
          [isAdmin]="isAdmin"
          [isSystemAdmin]="isSystemAdmin"
          (showCustomer)="setShow($event, idx)"
        ></app-fleet-customer>
      </div>
    </mat-accordion>
  </span>
</ng-container>
