<div mat-dialog-title class="dialog-title">
  <ng-container *ngIf="isAddNewTab">
    <h3>{{ 'charts.new-chart.title' | translate }}</h3>
  </ng-container>
  <ng-container *ngIf="!isAddNewTab">
    <h3>{{ 'charts.edit-chart.title' | translate }}</h3>
  </ng-container>
  <button mat-button (click)="onClose()">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>
<form (submit)="saveCustomChart($event)" [formGroup]="chartForm">
  <div mat-dialog-content>
    <p>
      {{ 'charts.instructions' | translate }}
    </p>
    <div class="new-chart-tab-container">
      <div class="options">
        <div
          class="option-group"
          *ngFor="let grp of groups; let groupIdx = index"
        >
          <h3>
            {{ 'charts.new-chart.' + chartOptions[grp].title | translate }}
          </h3>
          <div class="options-row" formGroupName="advanced">
            <div
              *ngFor="let side of ['left', 'right']; let sIdx = index"
              formArrayName="{{ side }}"
            >
              <div *ngFor="let group of chartOptions[grp][side]">
                <h4 *ngIf="group.title">
                  {{ 'charts.new-chart.' + group.title | translate }}
                </h4>
                <div
                  *ngFor="let option of group.charts; let idx = index"
                  class="chart-option"
                >
                  <div class="flex-row">
                    <mat-checkbox
                      [checked]="
                        selected.includes(option) ||
                        selected.includes(option.id)
                      "
                      [disabled]="
                        selected.length === 3 &&
                        !selected.includes(option) &&
                        !selected.includes(option.id)
                      "
                      (change)="selectChart(option, groupIdx, sIdx, idx)"
                    >
                      <span *ngIf="grp !== 'misc'"
                        >{{ 'charts.new-chart.' + option | translate }}
                      </span>
                      <span *ngIf="grp === 'misc'"
                        >{{ option.channel.name }}
                      </span>
                    </mat-checkbox>
                    <div
                      class="dropdown"
                      *ngIf="group.dropdown && group.dropdown[idx]"
                    >
                      <mat-form-field>
                        <mat-select
                          [formControlName]="idx"
                          (selectionChange)="
                            setPhaseList($event.value, option, sIdx, idx)
                          "
                        >
                          <mat-option
                            *ngFor="
                              let phase of createPhaseData(
                                group.dropdown[idx].prefix,
                                group.dropdown[idx].first,
                                group.dropdown[idx].last
                              )
                            "
                            [value]="phase"
                          >
                            {{ phase }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- </ng-container> -->
        </div>
      </div>
      <div class="details">
        <mat-form-field>
          <input
            matInput
            required
            [placeholder]="'charts.new-chart.tab-name' | translate"
            maxlength="24"
            formControlName="name"
            name="name"
            (focus)="isFirstFocus($event)"
          />
        </mat-form-field>
        <div *ngIf="name.invalid" class="errors-warning">
          <div *ngIf="name.errors.required">Name is required.</div>
          <div *ngIf="name.errors.nameTaken">
            The name "{{ name.errors.nameTaken.value }}" is being used by
            another chart.
          </div>
        </div>
        <mat-checkbox
          class="make-default"
          formControlName="makeDefault"
          *ngIf="!name.invalid"
        >
          {{ 'charts.new-chart.make-default' | translate }}
        </mat-checkbox>
        <div class="selections" *ngIf="selected.length > 0">
          <h4>{{ 'charts.new-chart.selections' | translate }}</h4>
          <div [sortablejs]="selected">
            <div *ngFor="let selection of selected" class="selection">
              <div class="handle"></div>
              <div class="selection-detail" *ngIf="selection.indexOf('.') > 0">
                <div class="title" *ngIf="chartTitle(selection)">
                  {{ 'charts.new-chart.' + chartTitle(selection) | translate }}:
                </div>
                <div class="name">
                  <span>
                    {{ 'charts.new-chart.' + selection | translate }}
                  </span>
                  <span class="phase" *ngIf="chartPhase(selection)">
                    {{ chartPhase(selection) }}
                  </span>
                </div>
              </div>
              <div
                class="selection-detail"
                *ngIf="selection.indexOf('.') === -1"
              >
                <div class="title" *ngIf="chartTitle(selection)">
                  {{ chartTitle(selection) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="new-chart-buttons">
    <div
      class="delete-btn-container"
      *ngIf="referenceTab?.editable && !isAddNewTab"
    >
      <button
        mat-flat-button
        (click)="deleteTab()"
        class="remove-tab"
        type="button"
      >
        {{ 'global.removeTab' | translate }}
      </button>
    </div>
    <div class="standard-btn-container">
      <button mat-flat-button type="button" (click)="onClose()">
        {{ 'global.cancel' | translate }}
      </button>
      <button
        mat-flat-button
        color="accent"
        type="submit"
        [disabled]="chartForm.invalid || selected.length === 0"
      >
        {{ 'global.save' | translate }}
      </button>
    </div>
  </div>
</form>
