import { Action } from '@ngrx/store';

import { IUser } from './../../../shared/classes/user';
import { UserActions } from '../actions';

export interface State {
  user: IUser;
  isPartner: number;
}

const initialState: State = {
  user: null,
  isPartner: null,
};

export function reducer(state: State = initialState, action: Action): State {
  const specificAction = action as UserActions.UserActionsUnion;

  switch (specificAction.type) {
    case UserActions.getUser.type:
      return {
        ...state,
      };

    case UserActions.getUserSuccess.type:
      return {
        ...state,
        user: specificAction.user,
        isPartner: state.isPartner,
      };
    case UserActions.setUserIsPartnerSuccess.type:
      return {
        ...state,
        isPartner: specificAction.payload.isPartner,
      };
    default:
      return state;
  }
}

export const getUser = (state: State) => state.user;
export const getIsPartner = (state: State) => state.isPartner;
