<div class="outer-flex">
  <div class="flex-container">
    <mat-checkbox
      class="compare-checkbox"
      (change)="compareClick($event)"
      [checked]="isCompare"
      >Compare</mat-checkbox
    >
    <mat-radio-group
      *ngIf="isCompare"
      (change)="updateCompareBase()"
      [(ngModel)]="compareBase"
    >
      <mat-radio-button
        class="radio-previous-period"
        value="period"
        [checked]="true"
        >Previous period</mat-radio-button
      >
      <mat-radio-button
        class="radio-previous-year"
        *ngIf="scope === 'day' || scope === 'week'"
        value="year"
        >{{ 'energyPower.monthsAgo' | translate }}</mat-radio-button
      >
    </mat-radio-group>
    <app-d-chart-navigator
      #chartNavigator
      [currentScope]="dashboardDictatedScope"
      [currentChartSetName]="chartSetName"
      (scopeChange)="updateChart($event)"
      [absoluteStartDate]="absoluteStartDate"
      [type]="'energy-power'"
      [haveFutur]="period"
      [havePast]="havePast"
    ></app-d-chart-navigator>
    <input
      class="datepicker-input"
      [matDatepicker]="picker"
      [formControl]="dateControl"
      [min]="absoluteStartDate"
      [max]="maxDate"
      (dateChange)="updateDate($event)"
    />
    <mat-datepicker
      [startView]="startView"
      #picker
      (yearSelected)="chosenYearHandler($event, picker)"
      (monthSelected)="chosenMonthHandler($event, picker)"
    >
    </mat-datepicker>
    <button mat-icon-button (click)="picker.open()">
      <mat-icon svgIcon="calendar"></mat-icon>
    </button>
    <div class="compare-legend" *ngIf="isCompare && chartReady">
      <div class="period-legend">
        <div class="previous-period-square"></div>
        <label class="previous-period-label">{{ previousPeriodTitle }}</label>
      </div>
      <div class="period-legend">
        <div class="current-period-square"></div>
        <label class="current-period-label">{{ currentPeriodTitle }}</label>
      </div>
    </div>
  </div>
</div>
<highcharts-chart
  [Highcharts]="highcharts"
  [options]="chartOptions"
  [(update)]="updateFlag"
  [callbackFunction]="onChartComplete"
  *ngIf="chartReady"
></highcharts-chart>
<mat-progress-spinner
  mode="indeterminate"
  *ngIf="!chartReady"
  color="primary"
></mat-progress-spinner>
