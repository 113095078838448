import { MatDatepicker } from '@angular/material/datepicker';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import * as Highcharts from 'highcharts/highstock';
import noData from 'highcharts/modules/no-data-to-display';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

import { AuthService } from 'src/app/shared/services';
import { EnergyPowerService } from './../../shared/services/energy-power.service';
import { IEnergyMeasure } from 'src/app/shared/classes/energyMeasure.interface';
import { EnergyPowerChartOptions } from 'src/app/shared/chart-options/energy-power-chart-options';
import { DChartNavigatorComponent } from 'src/app/shared/components/date-picker-nav/d-chart-navigator/d-chart-navigator.component';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from 'src/app/shared/classes/component-destroy.class';
import { MeasurementPointsService } from 'src/app/shared/services/measurement-points.service';

@Component({
  selector: 'app-energy-power-chart',
  templateUrl: './energy-power-chart.component.html',
  styleUrls: ['./energy-power-chart.component.scss'],
})
export class EnergyPowerChartComponent
  extends OnDestroyMixin
  implements OnChanges, OnDestroy, OnInit {
  @ViewChild('chartNavigator', { static: false })
  chartNav: DChartNavigatorComponent;

  @Output() chartUpdate = new EventEmitter();
  @Output() detailBoxUpdate = new EventEmitter();

  @Input() start: moment.Moment;
  @Input() end: moment.Moment;
  @Input() mp: string;
  @Input() mpTimezone: string;
  @Input() date: moment.Moment;
  @Input() commonScope;
  @Input() set chartTypeInput(value: string) {
    this.chartType = value;
    this.chartOptions = cloneDeep(EnergyPowerChartOptions[value].chartOptions);
    this.chartOptions.time.timezone = this.mpTimezone;
    this.chartOptions.title.text = this.setTitle(this.end);
    this.channel = EnergyPowerChartOptions[value].channel;
    this.absoluteStartDate = this.usePreCommissionStart
      ? moment(this.mpService.selectedMeasurementPoint.createdWhen)
      : moment(this.mpService.selectedMeasurementPoint.commissionedWhen);
    this.maxDate = moment();
    if (this.absoluteStartDate.isAfter(this.start)) {
      this.havePast = false;
    } else {
      this.havePast = true;
    }

    this.dashboardDictatedScope = (this.commonScope +
      's') as moment.unitOfTime.DurationConstructor;
    this.scope = this.commonScope;

    this.dateControl = new FormControl(moment(this.date));
    this.getStartEnd();
    this.updateChart(this.scope + 's', this.start, this.end);

    this.updateFlag = true;
  }

  public usePreCommissionStart: boolean;
  public dashboardDictatedScope: moment.unitOfTime.DurationConstructor;
  public scope: moment.unitOfTime.DurationConstructor;
  public chartInstance: Highcharts.Chart;
  public absoluteStartDate: moment.Moment;
  public updateFlag = false;
  public highcharts = Highcharts;
  public chartReady = false;
  public period = false;
  public havePast: boolean;
  public isCompare = false;
  public compareBase = 'period';
  public changeInterval: string;
  public previousPeriodTitle: string;
  public currentPeriodTitle: string;
  public channel: Array<string> = ['pc_162_diff_kwhr'];
  public energyMeasure: IEnergyMeasure;
  public dateControl: FormControl;
  public chartSetName = 'Dashboard Chart';
  public maxDate;
  public startView = 'year';

  private compareStart: moment.Moment;
  private compareEnd: moment.Moment;
  private chartType: string;

  public chartOptions: any = EnergyPowerChartOptions.energyConsumption;

  constructor(
    private mpService: MeasurementPointsService,
    private energyPowerService: EnergyPowerService,
    private authService: AuthService,
    private datePipe: DatePipe
  ) {
    super();
    noData(Highcharts);
  }

  ngOnInit() {
    this.authService.preferences.pipe(take(1)).subscribe((userPrefs) => {
      this.usePreCommissionStart = userPrefs.usePreCommissionStart;
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.chartReady = false;

    if (
      changes.mp &&
      changes.mp.currentValue !== changes.mp.previousValue &&
      changes.mp.firstChange === false
    ) {
      this.mp = changes.mp.currentValue;
      this.getStartEnd();
      this.setChartData();
    }
  }

  getStartEnd() {
    if (this.scope === 'day') {
      this.start = moment(this.date).tz(this.mpTimezone).startOf('day');
      this.end = moment(this.start).add(1, 'day');
    } else if (this.scope === 'week') {
      this.start = moment(this.date).tz(this.mpTimezone).startOf('week');
      this.end = moment(this.start).add(1, 'week');
    } else if (this.scope === 'month') {
      this.start = moment(this.date).tz(this.mpTimezone).startOf('month');
      this.end = moment(this.start).add(1, 'month');
    } else if (this.scope === 'year') {
      this.start = moment(this.date).tz(this.mpTimezone).startOf('year');
      this.end = moment(this.start).add(1, 'year');
    }
  }

  public onChartComplete = (chart) => {
    this.chartInstance = chart;
    // tslint:disable-next-line: semicolon
  };

  private setChartData(): void {
    this.energyPowerService
      .getTrendsDataJson(
        this.start,
        this.end,
        this.mp,
        this.scope,
        this.channel,
        this.changeInterval
      )
      .pipe(take(1))
      .subscribe((result: Array<Array<number>>) => {
        this.energyPowerService
          .getEnergyMeasure(this.start, this.end, this.mp)
          .pipe(untilComponentDestroyed(this))
          .subscribe((resultMeasure: IEnergyMeasure) => {
            this.chartOptions.plotOptions.series.gapSize = 5;
            this.detailBoxUpdate.emit({ energyMeasure: resultMeasure });
            this.energyMeasure = null;
            this.energyMeasure = resultMeasure;
            if (this.energyMeasure.dateTimeOfMaxActivePowerDemand) {
              if (
                this.end.diff(
                  moment(resultMeasure.dateTimeOfMaxActivePowerDemand),
                  'hour'
                ) /
                  this.end.diff(this.start, 'hour') <
                0.25
              ) {
                const tempMax = moment(resultMeasure.dateTimeOfMaxActivePowerDemand);

                if (
                  tempMax.date() > 9 &&
                  tempMax.month() >= 9 &&
                  ((tempMax.hour() > 9 && tempMax.hour() < 13) || tempMax.hour() > 21)
                ) {
                  this.chartOptions.xAxis.plotLines[1].label.x = -330;
                } else if (
                  (tempMax.date() > 9 && tempMax.month() >= 9) ||
                  (tempMax.month() >= 9 &&
                    ((tempMax.hour() > 9 && tempMax.hour() < 13) ||
                      tempMax.hour() > 21)) ||
                  (tempMax.date() > 9 &&
                    ((tempMax.hour() > 9 && tempMax.hour() < 13) || tempMax.hour() > 21))
                ) {
                  this.chartOptions.xAxis.plotLines[1].label.x = -329;
                } else {
                  this.chartOptions.xAxis.plotLines[1].label.x = -322;
                }
              } else {
                this.chartOptions.xAxis.plotLines[1].label.x = 5;
              }
              this.chartOptions.xAxis.plotLines[1].value = moment(
                resultMeasure.dateTimeOfMaxActivePowerDemand
              );
              this.chartOptions.xAxis.plotLines[1].maxDemand =
                resultMeasure.maxActivePowerDemand;
              this.energyMeasure.dateTimeOfMaxActivePowerDemand = moment(
                this.energyMeasure.dateTimeOfMaxActivePowerDemand
              ).tz(this.mpTimezone);
              this.chartOptions.xAxis.plotLines[1].label.text =
                '<div class="max-demand"><b>Max Power Demand:</b> <div class="max-demand-info">' +
                resultMeasure.maxActivePowerDemand.toFixed(3) +
                ' kW<br/> ' +
                this.datePipe.transform(
                  moment(this.energyMeasure.dateTimeOfMaxActivePowerDemand),
                  'M/d/yyyy, h:mm a',
                  this.energyMeasure.dateTimeOfMaxActivePowerDemand.format('z')
                ) +
                '</div></div>';
            } else {
              this.chartOptions.xAxis.plotLines[1].value = null;
            }
            this.chartOptions.series[1].timezone = this.mpTimezone;
            this.chartOptions.series[1].scope = this.scope;
            this.chartOptions.series[1].data = result[0];

            if (this.chartType === 'powerDemand') {
              if (this.chartOptions.series[1].data.length !== 0) {
                let onePeriod;
                if (this.scope === 'day') {
                  onePeriod = moment(
                    this.chartOptions.series[1].data[
                      this.chartOptions.series[1].data.length - 1
                    ][0]
                  ).add(15, 'minute');
                } else if (this.scope === 'week' || this.scope === 'month') {
                  onePeriod = moment(
                    this.chartOptions.series[1].data[
                      this.chartOptions.series[1].data.length - 1
                    ][0]
                  ).add(1, 'day');
                } else {
                  this.chartOptions.plotOptions.series.gapSize = 5;
                  onePeriod = moment(
                    this.chartOptions.series[1].data[
                      this.chartOptions.series[1].data.length - 1
                    ][0]
                  ).add(moment().date(), 'day');
                }
                this.chartOptions.series[1].data.push({
                  y: this.chartOptions.series[1].data[
                    this.chartOptions.series[1].data.length - 1
                  ][1],
                  x: onePeriod,
                  hideTooltip: true,
                  tooltip: {
                    enabled: false,
                  },
                  marker: {
                    enabled: false,
                    states: {
                      hover: {
                        enabled: false,
                      },
                    },
                  },
                });
              }
            }
            if (this.chartType === 'powerFactorVars') {
              this.chartOptions.series[2].timezone = this.mpTimezone;
              this.chartOptions.series[3].timezone = this.mpTimezone;
              this.chartOptions.series[2].data = result[1];
            }
            this.updateFlag = true;
            if (this.isCompare) {
              this.updateCompareBase();
            } else {
              this.chartReady = true;
            }
          });
      });
  }

  public updateChart(newScope: string, start?: moment.Moment, end?: moment.Moment): void {
    this.chartReady = false;
    if (newScope === 'past') {
      this.end = moment(this.start).tz(this.mpTimezone);
      this.start = moment(this.start).subtract(1, this.scope).tz(this.mpTimezone);
      this.date = this.start;
      this.dateControl = new FormControl(moment(this.date));
      this.chartOptions.xAxis.min = this.start.valueOf();
      this.setXAxis(newScope);
    } else if (newScope === 'future') {
      this.havePast = true;
      this.start = moment(this.end).tz(this.mpTimezone);
      this.end = moment(this.end).add(1, this.scope).tz(this.mpTimezone);
      this.date = this.start;
      this.dateControl = new FormControl(moment(this.date));
      this.chartOptions.xAxis.min = this.start.valueOf();
      this.setXAxis(newScope);
    } else {
      if (newScope !== 'picker') {
        this.scope = newScope.substring(
          0,
          newScope.length - 1
        ) as moment.unitOfTime.DurationConstructor;
        this.getStartEnd();
      }

      this.chartOptions.xAxis.min = this.start.valueOf();
      this.setXAxis(newScope);
      this.setDatepickerStartView();
    }

    if (this.absoluteStartDate.isAfter(this.start)) {
      this.havePast = false;
    } else {
      this.havePast = true;
    }
    if (this.start.isSameOrAfter(moment(), this.scope)) {
      this.period = false;
    } else {
      this.period = true;
    }

    this.chartUpdate.emit({
      start: this.start,
      end: this.end,
      scope: this.scope,
      period: this.period,
      date: this.date,
    });
    this.setChartData();
  }

  private setXAxis(newScope: string): void {
    const tempEnd = moment(this.end);
    if (newScope !== 'picker') {
      if (this.scope !== 'day' && this.scope !== 'week') {
        tempEnd.add(1, 'day').startOf('day');
      }
    }

    this.setGraphPerScope(tempEnd);
  }

  private setGraphPerScope(end: moment.Moment): void {
    if (this.scope === 'day') {
      this.chartOptions.series[1].pointPlacement = 0.7;
      this.chartOptions.series[1].pointWidth = 9;
      this.chartOptions.xAxis.tickInterval = 3600 * 1000;
      this.chartOptions.xAxis.max = moment(end).add(15, 'minute').valueOf();
      this.chartOptions.title.text = this.setTitle(end.subtract(30, 'minute'));
    } else if (this.scope === 'week') {
      this.chartOptions.series[1].pointPlacement = 0;
      this.chartOptions.series[1].pointWidth = 100;
      this.chartOptions.xAxis.tickInterval = 24 * 3600 * 1000;
      if (this.chartType === 'energyConsumption') {
        this.chartOptions.xAxis.max = moment(end).subtract(13, 'hour').valueOf();
      } else {
        this.chartOptions.xAxis.max = moment(end).valueOf();
      }
      this.chartOptions.title.text = this.setTitle(end.subtract(1, 'day'));
    } else if (this.scope === 'month') {
      this.chartOptions.series[1].pointPlacement = 0;
      this.chartOptions.series[1].pointWidth = 20;
      this.chartOptions.xAxis.tickInterval = 24 * 3600 * 1000;
      if (this.chartType === 'energyConsumption') {
        this.chartOptions.xAxis.max = end
          .subtract(1, 'day')
          .subtract(13, 'hour')
          .valueOf();
      } else {
        this.chartOptions.xAxis.max = end
          .subtract(1, 'day')
          .subtract(1, 'hour')
          .valueOf();
      }
      if (this.isCompare && this.compareStart.daysInMonth() > this.start.daysInMonth()) {
        this.chartOptions.xAxis.max = moment(this.chartOptions.xAxis.max)
          .add(this.compareStart.daysInMonth() - this.start.daysInMonth(), 'day')
          .valueOf();
      }
      this.chartOptions.title.text = this.setTitle(this.start);
    } else if (this.scope === 'year') {
      this.chartOptions.series[1].pointPlacement = 0;
      this.chartOptions.series[1].pointWidth = 40;
      this.chartOptions.xAxis.tickInterval = end.daysInMonth() * 24 * 3600 * 1000;
      this.chartOptions.xAxis.max = end.subtract(1, 'month').valueOf();
      this.chartOptions.title.text = this.setTitle(end);
    }
    this.updateFlag = true;
  }

  private setTitle(end: moment.Moment): string {
    if (this.scope === 'day') {
      return end.format('dddd, MMMM Do, YYYY');
    } else if (this.scope === 'week') {
      return (
        end.startOf('week').format('MMMM Do YYYY') +
        ' - ' +
        end.endOf('week').format('MMMM Do YYYY')
      );
    } else if (this.scope === 'month') {
      return end.format('MMMM YYYY');
    } else if (this.scope === 'year') {
      return end.format('YYYY');
    }
  }

  public compareClick($event: MatCheckboxChange): void {
    this.isCompare = $event.checked;
    this.chartReady = false;
    if ($event.checked) {
      this.setCompareStartEnd(this.scope);
      if (this.scope === 'day') {
        this.changeInterval = '30';
        this.setChartData();
      } else {
        this.setCompareData();
      }
    } else {
      this.chartOptions.series[0].data = null;
      this.chartInstance.get('compare').remove();
      this.changeInterval = null;
      if (this.scope === 'week') {
        this.chartOptions.series[1].pointPlacement = 0;
        this.chartOptions.series[1].pointWidth = 100;
      } else if (this.scope === 'month') {
        this.chartOptions.series[1].pointPlacement = 0;
        this.chartOptions.series[1].pointWidth = 20;
      } else if (this.scope === 'year') {
        this.chartOptions.series[1].pointPlacement = 0;
        this.chartOptions.series[1].pointWidth = 40;
      }
      if (this.chartType === 'powerFactorVars') {
        this.chartOptions.series[3].data = null;
        this.chartInstance.get('compare2').remove();
      }

      this.setChartData();
      this.updateFlag = true;
    }
  }

  private setCompareData(): void {
    const compareWith = this.compareBase === 'year' ? this.compareBase + '3' : this.scope;
    this.energyPowerService
      .getTrendsDataJson(
        this.compareStart,
        this.compareEnd,
        this.mp,
        this.scope,
        this.channel,
        'compare',
        compareWith
      )
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: Array<Array<number>>) => {
        if (
          this.scope === 'month' &&
          this.compareStart.daysInMonth() > this.start.daysInMonth()
        ) {
          this.chartOptions.xAxis.max = moment(this.chartOptions.xAxis.max)
            .add(this.compareStart.daysInMonth() - this.start.daysInMonth(), 'day')
            .valueOf();
        }
        if (this.scope === 'day') {
          this.chartOptions.series[0].pointWidth = 9;
          this.chartOptions.series[1].pointWidth = 9;
          this.chartOptions.series[1].pointPlacement = 0.25;
          this.chartOptions.series[0].pointPlacement = -0.01;
        } else if (this.scope === 'week') {
          this.chartOptions.series[0].pointWidth = 50;
          this.chartOptions.series[1].pointWidth = 50;
          this.chartOptions.series[1].pointPlacement = 0.21;
          this.chartOptions.series[0].pointPlacement = 0.01;
        } else if (this.scope === 'month') {
          this.chartOptions.series[0].pointWidth = 14;
          this.chartOptions.series[1].pointWidth = 14;
          this.chartOptions.series[1].pointPlacement = 0.21;
          this.chartOptions.series[0].pointPlacement = 0.01;
        } else if (this.scope === 'year') {
          this.chartOptions.series[0].pointWidth = 30;
          this.chartOptions.series[1].pointWidth = 30;
          this.chartOptions.series[1].pointPlacement = 0.21;
          this.chartOptions.series[0].pointPlacement = 0.01;
        }

        this.chartOptions.series[0].compareWith = compareWith;
        this.chartOptions.series[0].scope = this.scope;
        this.chartOptions.series[0].timezone = this.mpTimezone;
        this.chartOptions.series[0].data = result[0];
        if (result[1].length > 0) {
          this.chartOptions.series[3].data = result[1];
        }
        this.previousPeriodTitle = this.setTitle(moment(this.compareStart));
        this.currentPeriodTitle = this.setTitle(moment(this.start));
        this.updateFlag = true;
        this.chartReady = true;
      });
  }

  public updateCompareBase(): void {
    this.chartReady = false;
    if (this.compareBase === 'year') {
      this.setCompareStartEnd('year');
    } else {
      this.setCompareStartEnd(this.scope);
    }
    this.setCompareData();
  }

  private setCompareStartEnd(scope: moment.unitOfTime.DurationConstructor): void {
    if (this.compareBase === 'year') {
      this.compareStart = moment(this.start).subtract(3, 'month');
      if (!this.period) {
        this.compareEnd = moment(this.end).subtract(3, 'month').endOf(this.scope);
      } else {
        this.compareEnd = moment(this.end).subtract(3, 'month');
      }
    } else {
      this.compareStart = moment(this.start).subtract(1, scope);
      if (!this.period) {
        this.compareEnd = moment(this.end).subtract(1, scope).endOf(this.scope);
      } else {
        this.compareEnd = moment(this.end).subtract(1, scope);
      }
    }
  }

  public updateDate(event) {
    if (this.scope === 'week') {
      this.chartReady = false;
      this.date = moment()
        .tz(this.mpTimezone)
        .year(event.value.year())
        .month(event.value.month())
        .date(event.value.date());
      this.start = moment(event.value).tz(this.mpTimezone).startOf('week');
      this.end = moment(this.start).add(1, 'week');

      this.updateChart('picker', this.start, this.end);
    } else if (this.scope === 'day') {
      this.chartReady = false;
      this.date = moment()
        .tz(this.mpTimezone)
        .year(event.value.year())
        .month(event.value.month())
        .date(event.value.date());
      this.getStartEnd();

      this.updateChart('picker', this.start, this.end);
    }
  }

  private setDatepickerStartView(): void {
    if (this.scope === 'day' || this.scope === 'week') {
      this.startView = 'month';
    } else if (this.scope === 'month') {
      this.startView = 'year';
    } else if (this.scope === 'year') {
      this.startView = 'multi-year';
    }
  }

  chosenYearHandler(
    normalizedYear: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>
  ) {
    if (this.scope === 'year') {
      this.chartReady = false;
      this.date = normalizedYear.add(1, 'day');
      this.getStartEnd();

      this.updateChart(this.scope + 's', this.start, this.end);
      datepicker.close();
    }
    const ctrlValue = this.dateControl.value;
    ctrlValue.year(normalizedYear.year());
    this.dateControl.setValue(ctrlValue);
  }

  chosenMonthHandler(
    normalizedMonth: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>
  ) {
    if (this.scope === 'month') {
      this.chartReady = false;
      this.date = normalizedMonth.add(1, 'day');
      this.getStartEnd();

      this.updateChart(this.scope + 's', this.start, this.end);
      datepicker.close();
    }
    const ctrlValue = this.dateControl.value;
    ctrlValue.month(normalizedMonth.month());
    this.dateControl.setValue(ctrlValue);
    datepicker.close();
  }
}
