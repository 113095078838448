import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { MatSort } from '@angular/material/sort';

import { AuthService } from 'src/app/shared/services';
import { MeasurementPointsService } from './../shared/services/measurement-points.service';
import { takeUntilComponentDestroyed } from '../shared/services/utilities.service';
import { IEventNote } from '../shared/classes/event-note.interface';
import { NotesService } from '../shared/services/notes.service';
import { INote } from '../shared/classes/note.interface';
import { NewNoteComponent } from './../notes/new-note/new-note.component';
import { DeleteNoteDialogComponent } from './../notes/delete-note-dialog/delete-note-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '../shared/classes/component-destroy.class';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss'],
})
export class EventsListComponent extends OnDestroyMixin implements OnDestroy, OnInit {
  public mpId: number;
  public accountId: number;
  public endDate: moment.Moment;
  public startDate: moment.Moment;
  public scope: moment.unitOfTime.DurationConstructor = 'months';
  public chartSetName = 'Event-List Chart';
  public usePreCommissionStart: boolean;
  public absoluteStartDate: moment.Moment;
  public havePast = true;
  public date: moment.Moment;
  public title: string;
  public eventsListSource: MatTableDataSource<IEventNote>;
  public selectedRowId: number;
  public timezone: string;
  public displayedColumns: string[] = [
    'date',
    'time',
    'deviceEventType',
    'description',
    'view',
  ];

  private paginator: MatPaginator;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    if (this.eventsListSource) {
      this.eventsListSource.paginator = this.paginator;
    }
  }
  private sort: MatSort;
  @ViewChild(MatSort, { static: false }) set matSort(mp: MatSort) {
    this.sort = mp;
    if (this.eventsListSource) {
      this.eventsListSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'description':
            return item.eventMagnitude;
          default:
            return item[property];
        }
      };
      if (this.sort) {
        this.sort.disableClear = true;
      }
      this.eventsListSource.sort = this.sort;
      this.cdref.detectChanges();
    }
  }

  constructor(
    private mpService: MeasurementPointsService,
    private authService: AuthService,
    private notes: NotesService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private cdref: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.authService.preferences.pipe(take(1)).subscribe((userPrefs) => {
      this.usePreCommissionStart = userPrefs.usePreCommissionStart;
    });
    this.absoluteStartDate = this.usePreCommissionStart
      ? moment(this.mpService.selectedMeasurementPoint.createdWhen)
      : moment(this.mpService.selectedMeasurementPoint.commissionedWhen);
    this.route.queryParamMap
      .pipe(untilComponentDestroyed(this))
      .subscribe(async (params: ParamMap) => {
        if (
          this.mpService.selectedMeasurementPoint &&
          this.mpService.selectedMeasurementPoint.measurementPointTypeId === 2
        ) {
          this.router.navigate(['/dashboard'], {
            queryParams: {
              account: params.get('account'),
              mpId: params.get('mpId'),
            },
          });
        }
        this.mpId = this.mpService.selectedMeasurementPoint.measurementPointId;
        this.accountId = this.mpService.selectedMeasurementPoint.accountId;
        this.timezone = this.mpService.selectedMeasurementPoint.threeLetterTimezone;
        this.endDate = moment.tz(this.mpService.selectedMeasurementPoint.timezone);
        this.title = this.endDate.format('MMMM YYYY');
        this.setEventsData();
      });
  }

  private populateTable(): void {
    this.notes
      .getEventsNotes(
        this.mpService.selectedMeasurementPoint.measurementPointId,
        this.startDate,
        this.endDate
      )
      .pipe(take(1))
      .subscribe((returnNotes) => {
        this.eventsListSource.data = this.eventsListSource.data.concat(
          ...this.appendDateProp(returnNotes, 'startDateTime')
        );
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  public appendDateProp(events: IEventNote[], lookUpProp: string): IEventNote[] {
    return events.map((event) => {
      event.date = new Date(event[lookUpProp]).getTime();
      return { ...event };
    });
  }

  private setEventsData(): void {
    if (this.scope === 'years') {
      this.startDate =
        this.endDate.month() === 0 && this.endDate.date() === 1
          ? moment(this.endDate).subtract(1, this.scope)
          : moment(this.endDate).startOf(this.scope);
    } else if (this.scope === 'months') {
      this.startDate =
        this.endDate.date() !== 1
          ? moment(this.endDate).startOf(this.scope)
          : moment(this.endDate).subtract(1, this.scope);
    } else if (this.scope === 'weeks') {
      this.startDate =
        this.endDate.weekday() !== 0
          ? moment(this.endDate).startOf(this.scope)
          : moment(this.endDate).subtract(1, this.scope);
    } else if (this.scope === 'days') {
      this.startDate = moment(this.endDate).subtract(1, this.scope);
    }

    this.mpService
      .getEventsData(
        this.mpId.toString(),
        this.accountId.toString(),
        this.startDate,
        this.endDate,
        -1,
        false
      )
      .pipe(take(1))
      .subscribe((eventsList: Array<IEventNote>) => {
        eventsList = this.appendDateProp(eventsList, 'triggeredWhen');
        this.eventsListSource = new MatTableDataSource<IEventNote>(eventsList);
        this.populateTable();
      });
  }

  public editNote(note: INote): void {
    this.selectedRowId = note.id;

    const editNoteDialog = this.dialog.open(NewNoteComponent, {
      data: {
        existingNote: true,
        note,
      },
      disableClose: true,
      autoFocus: false,
      restoreFocus: false,
    });

    editNoteDialog
      .afterClosed()
      .pipe(takeUntilComponentDestroyed(this))
      .subscribe((result) => {
        if (result) {
          this.populateTable();
        }

        this.selectedRowId = undefined;
      });
  }

  public deleteNote(noteId: number): void {
    this.selectedRowId = noteId;

    const deleteNoteDialog = this.dialog.open(DeleteNoteDialogComponent, {
      autoFocus: false,
      disableClose: true,
      restoreFocus: false,
    });

    deleteNoteDialog.beforeClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.notes
          .delete(noteId)
          .pipe(take(1))
          .subscribe((successful) => {
            if (successful) {
              this.eventsListSource.data = this.eventsListSource.data.filter(
                (note) => note.id !== noteId
              );
            }
          });
      }
      this.selectedRowId = undefined;
    });
  }

  public updateChart(event): void {
    this.endDate = event.date;
    this.scope = event.scope;
    this.title = event.title;
    this.setEventsData();
  }
}
