import { FormGroup, FormControl,  Validators, AbstractControl } from '@angular/forms';

import { Component, OnInit, Inject, OnDestroy, ViewChild, ElementRef, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ISiteMeasurementPoint } from 'src/app/shared/classes/SiteMeasurementPoint.interface';
import { ISiteMeasurementPointDialogData } from 'src/app/shared/classes/siteMeasurementPointDialog.interface';
import { TranslateService } from '@ngx-translate/core';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-site-measurement-point-dialog',
  templateUrl: './site-measurement-point-dialog.component.html',
  styleUrls: ['./site-measurement-point-dialog.component.scss']
})
export class SiteMeasurementPointDialogComponent implements OnInit {
  
  public siteMpForm: FormGroup;
  public accountName: string;
  public siteMeasurementPoint: ISiteMeasurementPoint;
  public isMpStatusEnabled = false;
  public title: string;
  public availableStatuses: number[];
  private isAdmin = false;
  private isSystemAdmin = false;
  public latitude: string;
  public longitude: string;

  @ViewChild('search')
  public searchElementRef: ElementRef;
  

  public get siteName(): AbstractControl {
    return this.siteMpForm.get('siteName');
  }

  public get siteInformation(): AbstractControl {
    return this.siteMpForm.get('siteInformation');
  }

  public get street1(): AbstractControl {
    return this.siteMpForm.get('street1');
  }

  public get street2(): AbstractControl {
    return this.siteMpForm.get('street2');
  }

  public get city(): AbstractControl {
    return this.siteMpForm.get('city');
  }

  public get state(): AbstractControl {
    return this.siteMpForm.get('state');
  }

  public get zipCode(): AbstractControl {
    return this.siteMpForm.get('zipCode');
  }

  public get country(): AbstractControl {
    return this.siteMpForm.get('country');
  }

  public get measurementPointName(): AbstractControl {
    return this.siteMpForm.get('measurementPointName');
  }

  public get measurementPointStatus(): AbstractControl {
    return this.siteMpForm.get('measurementPointStatus');
  }

  constructor(
    public dialogRef: MatDialogRef<SiteMeasurementPointDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ISiteMeasurementPointDialogData,
    private translateService: TranslateService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {
    this.accountName = data.accountName;
    this.isAdmin = data.isAdmin;
    this.isSystemAdmin = data.isSystemAdmin;
    if (data.siteMeasurementPoint) {
      this.siteMeasurementPoint = data.siteMeasurementPoint;
      this.title = 'manageSiteMeasurementPoint.edit';
      const status = data.siteMeasurementPoint.measurementPoint.measurementPointStatusId || 1;
      if (
        ( this.isSystemAdmin || this.isAdmin && status !== 10)
        && data.siteMeasurementPoint.measurementPoint.measurementPointTypeId === 1
        && status > 4 && status !== 9
      ) {
        this.isMpStatusEnabled = true;
        this.availableStatuses = [];
        if (data.siteMeasurementPoint.measurementPoint.measurementPointStatusId < 8) {
          this.availableStatuses.push(status);
        }
        this.availableStatuses.push(8);
        if (this.isSystemAdmin) {
          this.availableStatuses.push(9, 10);
        }
        this.availableStatuses.push(11);
      }
    } else {
      this.title = 'manageSiteMeasurementPoint.add';
    }
  }

  ngOnInit(): void {
    const siteName = this.siteMeasurementPoint
      && this.siteMeasurementPoint.site.locationName 
      || '';
    const siteInformation = this.siteMeasurementPoint
      && this.siteMeasurementPoint.site.siteInformation 
      || '';
    const street1 = this.siteMeasurementPoint
      && this.siteMeasurementPoint.site.address1 
      || '';
    const street2 = this.siteMeasurementPoint
      && this.siteMeasurementPoint.site.address2 
      || '';
    const city = this.siteMeasurementPoint
      && this.siteMeasurementPoint.site.city 
      || '';
    const state = this.siteMeasurementPoint
      && this.siteMeasurementPoint.site.state 
      || '';
    const zipCode = this.siteMeasurementPoint
      && this.siteMeasurementPoint.site.zipCode 
      || '';
    const country = this.siteMeasurementPoint
      && this.siteMeasurementPoint.site.country 
      || '';
    this.latitude = this.siteMeasurementPoint
      && this.siteMeasurementPoint.site
      && this.siteMeasurementPoint.site.latitude
      || '';
    this.longitude = this.siteMeasurementPoint
      && this.siteMeasurementPoint.site
      && this.siteMeasurementPoint.site.longitude
      || '';
    const measurementPointName = this.siteMeasurementPoint
      && this.siteMeasurementPoint.measurementPoint.mpId 
      || '';
    const status = this.siteMeasurementPoint 
      && this.siteMeasurementPoint.measurementPoint 
      && this.siteMeasurementPoint.measurementPoint.measurementPointStatusId 
      || 1;
    this.siteMpForm = new FormGroup({
      siteName: new FormControl(siteName, [Validators.required]),
      siteInformation: new FormControl(siteInformation),
      street1: new FormControl(street1, [Validators.required]),
      street2: new FormControl(street2),
      city: new FormControl(city, [Validators.required]),
      state: new FormControl(state, [Validators.required]),
      zipCode: new FormControl(zipCode, [Validators.required]),
      country: new FormControl(country, [Validators.required]),
      measurementPointName: new FormControl(measurementPointName, [Validators.required])
    });
    if (this.isMpStatusEnabled) {
      this.siteMpForm.addControl('measurementPointStatus', new FormControl(status, [Validators.required]));
      this.siteMpForm.controls.measurementPointStatus.enable();
    } else {
      this.siteMpForm.addControl('measurementPointStatus', new FormControl(this.translateService.instant(`measurementPoint.status.${status}`), [Validators.required]));
      this.siteMpForm.controls.measurementPointStatus.disable();
    }

    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          const address = place.address_components;
          let selectedStreetNumber = '';
          let selectedStreet = '';
          let selectedCity = '';
          let selectedState = '';
          let selectedZip = '';
          let selectedCountry = '' ;
          address.forEach((component) => {
            const types = component.types;
            if (types.indexOf('street_number') > -1) {
              selectedStreetNumber = component.long_name;
            }
            if (types.indexOf('route') > -1) {
              selectedStreet = component.long_name;
            }
            if (types.indexOf('locality') > -1) {
              selectedCity = component.long_name;
            }
            if (types.indexOf('administrative_area_level_1') > -1) {
              selectedState = component.short_name;
            }
            if (types.indexOf('postal_code') > -1) {
              selectedZip = component.long_name;
            }
            if (types.indexOf('country') > -1) {
              selectedCountry = component.long_name;
            }
          });
          if (selectedStreetNumber || selectedStreet) {
            this.siteMpForm.get('street1').setValue(selectedStreetNumber + ' ' + selectedStreet);
          }
          if (selectedCity) {
            this.siteMpForm.get('city').setValue(selectedCity, { emitEvent: false });
          }
          if (selectedState) {
            this.siteMpForm.get('state').setValue(selectedState, { emitEvent: false });
          }
          if (selectedCountry) {
            this.siteMpForm.get('country').setValue(selectedCountry, { emitEvent: false });
          }
          if (selectedZip) {
            this.siteMpForm.get('zipCode').setValue(selectedZip, { emitEvent: false });
          }

          // set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat().toString();
          this.longitude = place.geometry.location.lng().toString();
          console.log(`Selected Lat and long: ${this.latitude} / ${this.longitude}`);
        });
      });
    });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public onSubmit(event: Event): void {
    let siteMeasurementPoint: ISiteMeasurementPoint;    
    let measurementPointStatusId = this.siteMeasurementPoint 
      && this.siteMeasurementPoint.measurementPoint 
      && this.siteMeasurementPoint.measurementPoint.measurementPointStatusId 
      || 1;
    const measurementPointTypeId = this.siteMeasurementPoint 
      && this.siteMeasurementPoint.measurementPoint 
      && this.siteMeasurementPoint.measurementPoint.measurementPointTypeId 
      || 1;
    if (this.isMpStatusEnabled) {
      measurementPointStatusId = this.siteMpForm.get('measurementPointStatus').value || 1;
    }
    siteMeasurementPoint = {
      site: {
        locationName: this.siteMpForm.get('siteName').value,
        address1: this.siteMpForm.get('street1').value,
        address2: this.siteMpForm.get('street2').value || '',
        city: this.siteMpForm.get('city').value,
        state: this.siteMpForm.get('state').value,
        zipCode: this.siteMpForm.get('zipCode').value,
        country: this.siteMpForm.get('country').value,
        siteInformation: this.siteMpForm.get('siteInformation').value || ''
      },
      measurementPoint: {
        mpId: this.siteMpForm.get('measurementPointName').value,
        measurementPointStatusId,
        measurementPointTypeId
      }
    };
    this.dialogRef.close(siteMeasurementPoint);
  }

  // private addressValidator(): ValidatorFn {
  //   return (control: AbstractControl): {[key: string]: any} | null => {
  //     console.log(`Resolved Lat and long: ${this.latitude} / ${this.longitude}`);
  //     const validAddress = this.longitude && this.latitude;
  //     return !validAddress ? {invalidAddress: {value: control.value}} : null;
  //   };
  // }

}
