<div class="map"
     leaflet
     (leafletMapReady)="onMapReady($event)"
     [leafletMinZoom]=2
     [leafletOptions]="options"
     [leafletLayersControl]="layersControl"
     [leafletLayersControlOptions]="layersControlOptions"
     [leafletMarkerCluster]="markerClusterData"
     [leafletMarkerClusterOptions]="markerClusterOptions"
     (leafletMarkerClusterReady)="onMarkerClusterReady($event)"
></div>
