import * as moment from 'moment';

export const EnergyPowerChartOptions = {
  energyConsumption: {
    chartOptions: {
      chart: {
        style: {
          fontFamily: 'Roboto, sans-serif',
          fontSize: '16px',
        },
        type: 'column',
        marginTop: 120,
      },
      title: {
        y: 3,
        align: 'center',
        text: null,
      },
      time: {
        useUTC: true,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        type: 'datetime',
        min: null,
        max: null,
        tickInterval: 3600 * 1000,
        plotLines: [
          {
            color: '#000000',
            width: 4,
          },
          {
            color: '#000',
            width: 2,
            zIndex: 4,
            value: null,
            label: {
              rotation: 0,
              text: null,
              useHTML: true,
              y: -20,
              x: 5,
            },
          },
        ],
        ordinal: false,
        dateTimeLabelFormats: {
          day: {
            main: '%b %d',
          },
          hour: {
            main: '%l:%M %p',
          },
          minute: {
            main: '%l:%M %p',
          },
        },
      },
      yAxis: {
        title: {
          text: 'kWh',
        },
        ordinal: false,
      },
      plotOptions: {
        column: {
          color: '#2A7BFB',
          stacking: null,
        },
        series: {
          states: {
            inactive: { opacity: 1 },
          },
        },
      },
      series: [
        {
          id: 'compare',
          showInLegend: false,
          data: null,
          color: '#EE82EE',
          pointPlacement: 0,
          timezone: null,
          scope: null,
        },
        {
          name: 'Demand',
          showInLegend: false,
          data: null,
          pointPlacement: 0.7,
          pointWidth: 9,
          timezone: null,
        },
        {
          showInLegend: false,
          data: null,
          pointPlacement: 0,
        },
      ],
      tooltip: {
        valueDecimals: 3,
        shape: 'rect',
        positioner: (boxWidth, boxHeight, point) => {
          return { x: point.plotX + 70, y: point.plotY + 100 };
        },
        // tslint:disable-next-line: space-before-function-paren
        formatter: function () {
          if (this.series.name === 'Demand') {
            return (
              '<b>Energy</b><br/>' +
              '<b>' +
              this.y.toFixed(3) +
              '</b> kWh ' +
              moment(this.x)
                .tz(this.series.userOptions.timezone)
                .format('DD/MM/YYYY hh:mm:ss')
            );
          } else {
            let compareTime;
            if (this.series.userOptions.scope === 'year3') {
              compareTime = moment(this.x)
                .tz(this.series.userOptions.timezone)
                .subtract(3, 'month')
                .format('DD/MM/YYYY hh:mm:ss');
            } else {
              compareTime = moment(this.x)
                .tz(this.series.userOptions.timezone)
                .subtract(1, this.series.userOptions.scope)
                .format('DD/MM/YYYY hh:mm:ss');
            }
            return (
              '<b>Energy</b><br/>' +
              '<b>' +
              this.y.toFixed(3) +
              '</b> kWh ' +
              compareTime
            );
          }
        },
      },
    },
    channel: ['pc_162_diff_kwhr'],
    defaultScope: 'months',
  },
  powerDemand: {
    chartOptions: {
      chart: {
        style: {
          fontFamily: 'Roboto, sans-serif',
          fontSize: '16px',
        },
        type: 'area',
        marginTop: 120,
      },
      title: {
        y: 3,
        align: 'center',
        text: null,
      },
      time: {
        useUTC: true,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        type: 'datetime',
        min: null,
        max: null,
        tickInterval: 3600 * 1000,
        plotLines: [
          {
            color: '#000000',
            width: 4,
          },
          {
            color: '#000',
            width: 2,
            zIndex: 4,
            value: null,
            label: {
              rotation: 0,
              text: null,
              useHTML: true,
              y: -20,
              x: 5,
            },
          },
        ],
        ordinal: false,
        dateTimeLabelFormats: {
          day: {
            main: '%b %d',
          },
          hour: {
            main: '%l:%M %p',
          },
          minute: {
            main: '%l:%M %p',
          },
        },
      },
      yAxis: {
        title: {
          text: 'kW',
        },
        ordinal: false,
      },
      plotOptions: {
        series: {
          color: '#2A7BFB',
          groupPadding: 0.02,
          pointPadding: 0,
          gapSize: 5,
        },
      },
      series: [
        {
          id: 'compare',
          showInLegend: false,
          data: null,
          pointPlacement: 0,
          timezone: null,
          step: true,
          scope: null,
          marker: {
            enabled: false,
          },
          color: '#EE82EE',
        },
        {
          name: 'Power Demand',
          showInLegend: false,
          data: null,
          pointPlacement: 0,
          timezone: null,
          step: true,
          marker: {
            enabled: false,
          },
          color: '#1C88FB',
        },
        {
          showInLegend: false,
          data: null,
          pointPlacement: 0,
        },
      ],
      tooltip: {
        valueDecimals: 3,
        shape: 'rect',
        positioner: (boxWidth, boxHeight, point) => {
          return { x: point.plotX + 70, y: point.plotY + 80 };
        },
        // tslint:disable-next-line: space-before-function-paren
        formatter: function () {
          if (this.point.hideTooltip) {
            return false;
          } else {
            if (this.series.name === 'Power Demand') {
              let timeStamp;
              if (this.series.userOptions.scope === 'day') {
                timeStamp = moment(this.x)
                  .subtract(1, 'day')
                  .tz(this.series.userOptions.timezone)
                  .add(1, 'day')
                  .format('DD/MM/YYYY hh:mm:ss');
              } else {
                timeStamp = moment(this.x)
                  .tz(this.series.userOptions.timezone)
                  .format('DD/MM/YYYY hh:mm:ss');
              }
              return (
                '<b>' +
                this.series.name +
                '</b><br/>' +
                '<b>' +
                this.y.toFixed(3) +
                '</b> kW ' +
                timeStamp
              );
            } else {
              let compareTime;
              if (this.series.userOptions.compareWith === 'year3') {
                if (this.series.userOptions.scope === 'day') {
                  compareTime = moment(this.x)
                    .tz(this.series.userOptions.timezone)
                    .subtract(3, 'month')
                    .format('DD/MM/YYYY hh:mm:ss');
                } else {
                  compareTime = moment(this.x)
                    .tz(this.series.userOptions.timezone)
                    .subtract(3, 'month')
                    .subtract(1, 'day')
                    .format('DD/MM/YYYY hh:mm:ss');
                }
              } else {
                if (this.series.userOptions.scope === 'day') {
                  compareTime = moment(this.x)
                    .tz(this.series.userOptions.timezone)
                    .subtract(1, this.series.userOptions.compareWith)
                    .format('DD/MM/YYYY hh:mm:ss');
                } else {
                  compareTime = moment(this.x)
                    .tz(this.series.userOptions.timezone)
                    .subtract(1, this.series.userOptions.compareWith)
                    .subtract(1, 'day')
                    .format('DD/MM/YYYY hh:mm:ss');
                }
              }
              return (
                '<b>Power Demand</b><br/>' +
                '<b>' +
                this.y.toFixed(3) +
                '</b> kW ' +
                compareTime
              );
            }
            // return (
            //   '<b>Power Demand</b><br/>' +
            //   '<b>' +
            //   this.y.toFixed(3) +
            //   '</b> kW ' +
            //   compareTime
            // );
          }
        },
      },
    },
    channel: ['c_70_avg_w'],
    defaultScope: 'months',
  },
  powerFactorVars: {
    chartOptions: {
      chart: {
        style: {
          fontFamily: 'Roboto, sans-serif',
          fontSize: '16px',
        },
        marginTop: 120,
      },
      title: {
        y: 3,
        align: 'center',
        text: null,
      },
      time: {
        useUTC: true,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        type: 'datetime',
        min: null,
        max: null,
        tickInterval: 3600 * 1000,
        plotLines: [
          {
            color: '#000000',
            width: 2,
          },
          {
            color: '#000',
            width: 2,
            zIndex: 4,
            value: null,
            label: {
              rotation: 0,
              text: null,
              useHTML: true,
              y: -20,
              x: 5,
            },
          },
        ],
        ordinal: false,
        dateTimeLabelFormats: {
          day: {
            main: '%b %d',
          },
          hour: {
            main: '%l:%M %p',
          },
          minute: {
            main: '%l:%M %p',
          },
        },
      },
      yAxis: [
        {
          title: {
            text: 'Power Factor',
          },
          height: '47%',
          lineWidth: 2,
        },
        {
          title: {
            text: 'kvar',
          },
          top: '52%',
          height: '47%',
          offset: 0,
          lineWidth: 1,
        },
      ],
      plotOptions: {
        column: {
          color: '#2A7BFB',
          stacking: 'false',
          groupPadding: 0.02,
          pointPadding: 0,
        },
        series: {
          gapSize: 5,
        },
      },
      series: [
        {
          id: 'compare',
          name: 'compare',
          type: 'line',
          yAxis: 0,
          showInLegend: false,
          data: null,
          pointPlacement: 0,
          timezone: null,
          scope: null,
          marker: {
            enabled: false,
          },
          color: '#EE82EE',
        },
        {
          type: 'line',
          name: 'Power Factor',
          yAxis: 0,
          showInLegend: false,
          data: null,
          marker: {
            enabled: false,
          },
          color: '#1C88FB',
        },
        {
          name: 'kvar',
          id: 'notCompare',
          type: 'area',
          showInLegend: false,
          timezone: null,
          data: null,
          marker: {
            enabled: false,
          },
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1,
            },
            stops: [
              [0, '#1C88FB'],
              [1, '#DBECFB'],
            ],
          },
          yAxis: 1,
        },
        {
          id: 'compare2',
          name: 'compare2',
          type: 'area',
          showInLegend: false,
          data: null,
          pointPlacement: 0,
          timezone: null,
          marker: {
            enabled: false,
          },
          color: '#EE82EE',
          scope: null,
          yAxis: 1,
        },
      ],
      tooltip: {
        split: true,
        borderWidth: 2,
        valueDecimals: 3,
        shape: 'rect',
        // tslint:disable-next-line: space-before-function-paren
        formatter: function () {
          const splitFormatter = (p, x, y) => {
            if (p.series.name === 'Power Factor' || p.series.name === 'kvar') {
              const unit = p.series.name === 'kvar' ? 'kvar' : '';
              return (
                '<b>' +
                p.series.name +
                '</b><br/>' +
                '<b>' +
                y.toFixed(3) +
                '</b> ' +
                unit +
                ' ' +
                moment(x)
                  .tz(p.series.userOptions.timezone)
                  .format('DD/MM/YYYY hh:mm:ss')
              );
            } else {
              let compareTime;
              if (p.series.userOptions.scope === 'year3') {
                compareTime = moment(x)
                  .tz(p.series.userOptions.timezone)
                  .subtract(3, 'month')
                  .format('DD/MM/YYYY hh:mm:ss');
              } else {
                compareTime = moment(x)
                  .tz(p.series.userOptions.timezone)
                  .subtract(1, p.series.userOptions.scope)
                  .format('DD/MM/YYYY hh:mm:ss');
              }
              if (p.series.name === 'compare') {
                return (
                  '<b>Power Factor</b><br/>' +
                  '<b>' +
                  y.toFixed(3) +
                  '</b> ' +
                  compareTime
                );
              } else {
                return (
                  '<b>kvar</b><br/>' +
                  '<b>' +
                  y.toFixed(3) +
                  '</b> kvar ' +
                  compareTime
                );
              }
            }
          };
          if (this.points.length === 4) {
            return [
              moment(this.x)
                .tz(this.points[0].series.userOptions.timezone)
                .format('DD/MM/YYYY hh:mm:ss'),
              splitFormatter(this.points[0], this.x, this.points[0].y),
              splitFormatter(this.points[1], this.x, this.points[1].y),
              splitFormatter(this.points[2], this.x, this.points[2].y),
              splitFormatter(this.points[3], this.x, this.points[3].y),
            ];
          } else {
            return [
              moment(this.x)
                .tz(this.points[0].series.userOptions.timezone)
                .format('DD/MM/YYYY hh:mm:ss'),
              splitFormatter(this.points[0], this.x, this.points[0].y),
              splitFormatter(this.points[1], this.x, this.points[1].y),
            ];
          }
        },
      },
    },
    channel: ['c_124_avg_none', 'c_88_avg_var'],
    defaultScope: 'months',
  },
};
