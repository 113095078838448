<div>
    <div class="map-title">{{ measurementPoint.mpId }}</div>
    <div *ngIf="measurementPoint.address1">{{ measurementPoint.address1 }}</div>
    <div *ngIf="measurementPoint.address2">{{ measurementPoint.address2 }}</div>
    <div *ngIf="measurementPoint.city||measurementPoint.state||measurementPoint.zipCode">{{measurementPoint.city}}, {{measurementPoint.state}} {{measurementPoint.zipCode}}</div>
    <hr>
    <div><b>Customer: </b>{{measurementPoint.accountName}}</div>
    <div><b>Site: </b>{{ measurementPoint.locationName }}</div>
    <hr>
    <div><b>Type: </b>{{measurementPoint.measurementPointTypeName}}</div>
    <div><b>Status: </b>{{getMeasurementPointStatusName()}}</div>
    <hr>
    <p><b>Go to:</b></p>
    <div><a (click)="navigate(['/dashboard'])">Dashboard</a></div>
    <div><a (click)="navigate(['/events-list'])">Events List</a></div>
    <div><a (click)="navigate(['/charts'])">Charts</a></div>
    <div><a (click)="navigate(['/energy-power'])">Energy and Power</a></div>
</div>
