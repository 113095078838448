import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notesSummaryPipe',
})
export class NotesSummaryPipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    if (value.length > 16) {
      return value.substring(0, 16) + '...';
    } else {
      return value;
    }
  }
}
