import { cloneDeep } from 'lodash';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { AuthService, SitesService, TokenService } from 'src/app/shared/services';
import { IUser } from 'src/app/shared/classes/user';
import { IAccount } from 'src/app/shared/classes/account';
import * as fromHierarchy from './../../../../_store/_reducers';
import * as fromUser from './../../../../_store/_reducers';
import * as fromChannels from './../../../../_store/_reducers';
import { Channels } from './../../../classes/channels.interface';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from 'src/app/shared/classes/component-destroy.class';
import { MeasurementPointsService } from 'src/app/shared/services/measurement-points.service';

@Component({
  selector: 'app-change-selection',
  templateUrl: './change-selection.component.html',
  styleUrls: ['./change-selection.component.scss'],
})
export class ChangeSelectionComponent
  extends OnDestroyMixin
  implements OnInit, OnDestroy {
  public partners: any = [] as any;

  private currentAccount: any;
  private currentMpId: any;

  private user: IUser;

  private userPrefs: any;

  private qubeScanEnabled = false;
  public isAdmin = false;

  public loading = true;

  private hierarchySub$: Subscription;
  private userSub$: Subscription;

  constructor(
    public dialogRef: MatDialogRef<ChangeSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private sitesService: SitesService,
    private router: Router,
    private route: ActivatedRoute,
    private mpService: MeasurementPointsService,
    private token: TokenService,
    private store: Store<fromHierarchy.State | fromUser.State | fromChannels.State>
  ) {
    super();
  }

  ngOnInit() {
    this.loading = true;

    if (this.mpService.selectedMeasurementPoint) {
      this.currentAccount = this.mpService.selectedMeasurementPoint.accountId;
      this.currentMpId = this.mpService.selectedMeasurementPoint.roomId;
    }

    this.route.queryParamMap
      .pipe(untilComponentDestroyed(this))
      .subscribe((params: ParamMap) => {
        if (params.get('account')) {
          this.currentAccount = parseInt(params.get('account'), 10);
        }

        if (params.get('mpId')) {
          this.currentMpId = parseInt(params.get('mpId'), 10);
        }
      });

    // get the logged in user
    this.userSub$ = this.store.pipe(select(fromUser.getUser)).subscribe((user: IUser) => {
      this.user = user;
      this.isAdmin = user.role.name === 'Administrator';
      // get the user's account
      this.authService.accountO
        .pipe(untilComponentDestroyed(this))
        .subscribe(async (account: IAccount) => {
          if (!this.user) {
            return;
          }

          let sites: any;

          this.hierarchySub$ = this.store
            .pipe(select(fromHierarchy.getHierarchy))
            .subscribe((site) => (sites = cloneDeep(site)));

          if (sites.length === 0) {
            return;
          }
          this.partners = sites.partners;

          this.partners.forEach((partner, i) => {
            this.partners[i].customers = partner.customers.map((customer) => {
              customer.measurementPoints.forEach((mp, n) => {
                customer.measurementPoints[n].totalEvents = this.calculateEvents(mp);
                if (
                  mp.accountId === this.currentAccount &&
                  mp.measurementPointId === this.currentMpId
                ) {
                  customer.measurementPoints[n].active = true;
                }
              });

              if (customer.accountId === this.currentAccount) {
                customer.expanded = true;
              }

              return customer;
            });
          });

          this.loading = false;
        });
    });

    // get the user's preferences
    this.authService.preferences
      .pipe(untilComponentDestroyed(this))
      .subscribe(async (prefs: any) => {
        this.userPrefs = prefs;
      });

    // Check whether QubeScan features are enabled.
    // TODO: Remove this when QubeScan features are in production
    this.authService.getEnvironmentSettings('featureEnableQubeScan').subscribe(
      (result) => {
        this.qubeScanEnabled = result && result.featureEnableQubeScan;
      },
      (error) => {
        console.error(error);
        this.qubeScanEnabled = false;
      }
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.hierarchySub$.unsubscribe();
    this.userSub$.unsubscribe();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  calculateEvents(mp) {
    let total = 0;
    const summable = [
      'sagsAndSwellsPrior30Days',
      'highfrequencyImpulsesPrior30Days',
      'outagesPrior30Days',
    ];
    if (mp.hasOwnProperty('details') && typeof mp.details === 'object') {
      summable.forEach((field) => {
        if (
          mp.details.hasOwnProperty(field) &&
          mp.details[field].value &&
          typeof mp.details[field].value === 'object'
        ) {
          total += parseInt(mp.details[field].value.severe, 10) || 0;
        }
      });
    }

    return total;
  }

  selectPoint(point) {
    const currentURL = (this.router && this.router.url) || '';
    const navigateURL = currentURL.toLowerCase().includes('event-graph')
      ? ['/dashboard']
      : [];

    const prefs = {
      ...this.userPrefs,
      account: point.accountId,
      mpId: point.measurementPointId,
    };

    // save the preferences for the user
    this.authService.savePreferences(prefs, Object.keys(this.userPrefs).length > 0);

    if (currentURL.includes('charts')) {
      this.mpService
        .getChannelDefinition(point.measurementPointId.toString())
        .pipe(take(1))
        .subscribe((response: Channels) => {
          this.store.dispatch({
            type: '[Charts] Set Channels Success',
            payload: {
              channels: response.channels,
            },
          });
          this.navigate(navigateURL, point.accountId, point.measurementPointId);
        });
    } else {
      this.navigate(navigateURL, point.accountId, point.measurementPointId);
    }

    this.dialogRef.close();
  }

  private navigate(navigateURL: Array<string>, accountId: number, mpId: number): void {
    this.router.navigate(navigateURL, {
      queryParams: {
        account: accountId,
        mpId: mpId,
      },
    });
  }

  isMeasurementPointDisabled(point): boolean {
    // status mp becomes available to user
    let mpAvailableAt: number;
    if (
      this.token.metaData.isSystemAdministrator === '1' ||
      (this.qubeScanEnabled && point.measurementPointTypeId === 1 && this.isAdmin)
    ) {
      mpAvailableAt = 4;
    } else {
      mpAvailableAt = 8;
    }
    return (
      point.measurementPointStatusId < mpAvailableAt || point.measurementPointStatusId > 8
    );
  }
}
