import { Injectable } from '@angular/core';
import { DiligentApiService } from './diligent-api.service';
import { PowerQualityMeasuresService } from './power-quality-measures.service';
import { MeasurementPointsService } from './measurement-points.service';
import { Moment } from 'moment';

@Injectable()
export class DashboardService {
  constructor(
    private diligent: DiligentApiService,
    private pqService: PowerQualityMeasuresService,
    private mpService: MeasurementPointsService
  ) {}

  public async getDashboardInfo(
    accountId: any,
    mpId: any,
    startDate: Moment = null,
    endDate: Moment = null
  ) {
    const isQubeScan =
      this.mpService.selectedMeasurementPoint.measurementPointTypeId === 1;
    const mpEvents = await this.mpService.getMeasurementPointEvents(
      mpId,
      accountId,
      startDate,
      endDate,
      null, // device event type ID
      !isQubeScan, // severe only,
      false, // include retired
      0, // offset
      100000 // count -- zero should mean "all", but it doesn't, so we use a super high number here... 100k should be good, right?
    );

    const pqMeasures = await this.pqService.getPowerQualityMeasures(mpId);

    return {
      measurementPointEvents: mpEvents,
      powerQualityMeasures: pqMeasures,
    };
  }
}
