import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NotificationsService } from './shared/notifications.service';
import { NotificationModalComponent } from './modal-dialog/notification-modal.component';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    MatButtonModule,
    MatDialogModule,
    MatSnackBarModule,
  ],
  declarations: [NotificationModalComponent],
  exports: [CommonModule, HttpClientModule],
  providers: [NotificationsService],
})
export class NotificationsModule {}
