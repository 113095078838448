import * as Highcharts from 'highcharts/highcharts';

export const MeterMeterOptions = {
  chart: {
    style: {
      fontFamily: 'Roboto, sans-serif',
      width: '100%',
    },
    type: 'solidgauge',
    spacingLeft: 300,
  },
  legend: {
    enabled: true,
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'middle',
    itemMarginBottom: 15,
  },
  title: {
    text: '',
    x: -550,
  },
  pane: {
    center: ['50%', '85%'],
    size: '140%',
    startAngle: -90,
    endAngle: 90,
    background: {
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
      innerRadius: '60%',
      outerRadius: '100%',
      shape: 'arc',
    },
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },

  tooltip: {
    enabled: false,
  },

  // the value axis
  yAxis: {
    min: 100,
    max: 700,
    lineWidth: 0,
    tickWidth: 0,
    minorTickInterval: null,
    tickPositions: [100, 700],
    tickAmount: 2,
    labels: {
      align: 'center',
      y: 16,
    },
  },

  plotOptions: {},
  series: [],
};
