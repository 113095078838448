export const tabsDashboardConfig = [
    'power',
    'powerFactorPrior30Days',
    'voltageFluctuationsPrior30Days',
    'imbalancePrior30Days',
    'harmonicsPrior30Days',
    'groundCurrentPrior30Days'
];

export const tabsQubeScanDashboardConfig  = [
   'powerQuality'
];