import { Action } from '@ngrx/store';

import { HierarchyActions } from '../actions';

export interface State {
  hierarchy: any;
  payload: { id: number; accountId: number };
}

const initialState: State = {
  hierarchy: [],
  payload: null,
};

export function reducer(state: State = initialState, action: Action): State {
  const specificAction = action as HierarchyActions.HierarchyActionsUnion;

  switch (specificAction.type) {
    case HierarchyActions.getHierarchyUser.type:
      return {
        ...state,
        payload: { ...specificAction.payload },
      };

    case HierarchyActions.getHierarchySuccess.type:
      return {
        ...state,
        hierarchy: specificAction.hierarchy,
      };

    default:
      return state;
  }
}

export const getHierarchy = (state: State) => state.hierarchy;
