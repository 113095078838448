import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import * as moment from 'moment';

import { GraphManagerService } from 'src/app/shared/services/graph-manager.service';

@Component({
  selector: 'app-d-chart-navigator',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './d-chart-navigator.component.html',
  styleUrls: ['./d-chart-navigator.component.scss'],
})
export class DChartNavigatorComponent implements OnInit {
  @Input() currentChartSetName: string;
  @Output() scopeChange = new EventEmitter();
  @Input() currentScope: moment.unitOfTime.DurationConstructor;
  @Input() absoluteStartDate: moment.Moment;
  @Input() type: string;
  @Input() isQubeScan: boolean;
  @Input() haveFutur: boolean;
  @Input() havePast: boolean;

  public beginningOfCreation = false;

  set absoluteStartData(newValue) {
    this.checkForBeginningOfCreation();
    if (this.type === 'energy-power') {
      this.currentScope = 'days';
    }
  }

  constructor(private graphManager: GraphManagerService) {}

  ngOnInit() {}

  public checkForBeginningOfCreation(): void {
    if (this.type === 'dashboard') {
      if (
        this.graphManager.dateRangeStart.valueOf() <= this.absoluteStartDate.valueOf()
      ) {
        this.beginningOfCreation = true;
      } else this.beginningOfCreation = false;
    }
  }

  public changeTimeScope(
    newScope: moment.unitOfTime.DurationConstructor,
    granularity: string
  ): void {
    if (this.currentScope === newScope) {
      return;
    } else this.currentScope = newScope;
    if (this.type === 'dashboard') {
      this.graphManager.updateGranularity(granularity, newScope, 1);
      this.graphManager.dateRangeEnd = this.graphManager.dateRangeEnd;

      this.checkForBeginningOfCreation();

      this.emitFullReplacementChartSet();
    } else {
      this.scopeChange.emit(newScope);
    }
  }

  public changeTimePosition(directionOfChange: string): void {
    if (this.type === 'dashboard') {
      if (directionOfChange === 'past') {
        this.graphManager.dateRangeEnd = this.graphManager.dateRangeStart;
        this.haveFutur = true;
      } else if (
        this.graphManager.dateRangeEnd
          .clone()
          .add(1, this.currentScope)
          .add(1, 'minutes')
          .valueOf() < moment().valueOf()
      ) {
        this.graphManager.dateRangeEnd = this.graphManager.dateRangeEnd
          .clone()
          .add(1, this.currentScope);
      } else {
        this.haveFutur = false;
        this.graphManager.dateRangeEnd = moment();
      }

      this.checkForBeginningOfCreation();

      this.emitFullReplacementChartSet();
    } else if (this.type === 'energy-power' || this.type === 'event-list') {
      this.scopeChange.emit(directionOfChange);
    }
  }

  public emitFullReplacementChartSet(): void {
    this.graphManager
      .updateChartSet(this.currentChartSetName, this.isQubeScan)
      .subscribe((newSeries) => {
        this.scopeChange.emit(newSeries.reduce((prev, curr) => prev.concat(curr)));
      });
  }
}
