import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-note-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'delete-note-dialog.component.html',
  styleUrls: ['./delete-note-dialog.component.scss'],
})
export class DeleteNoteDialogComponent implements OnInit {
  public confirmDelete: boolean;

  constructor(
    public dialogRef: MatDialogRef<DeleteNoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onCancel() {
    this.dialogRef.close(false);
  }

  onConfirm() {
    this.dialogRef.close(true);
  }

  ngOnInit() {}
}
