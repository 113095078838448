import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IUserUpdateParams } from '../classes/userUpdateParams.interface';
import { DiligentApiService } from '.';
import { IAccount } from '../classes/account';

@Injectable({
  providedIn: 'root',
})
export class AccountsService {
  accountName: string;

  constructor(private diligentService: DiligentApiService) {}

  public getAccount(id: number): Observable<IAccount> {
    return this.diligentService.getAccount(id);
  }

  public getAccountUsersList(accountId: string): Observable<any> {
    return this.diligentService.getAccountUsersList(accountId);
  }

  public addUser(addUserParams): Observable<{ id: number }> {
    return this.diligentService.addUser(addUserParams);
  }

  public updateUser(
    userUpdateParams: IUserUpdateParams,
    userId: number
  ): Observable<{}> {
    return this.diligentService.updateUser(userUpdateParams, userId);
  }

  public resendActivationEmail(
    userId: number,
    accountId: number
  ): Observable<{ id: number }> {
    return this.diligentService.resendActivationEmail(userId, accountId);
  }
}
