import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ViewChildren,
  QueryList,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { take } from 'rxjs/operators';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';

import { AuthService, TokenService, SitesService } from 'src/app/shared/services';
import { IMeasurementPoint } from './../../shared/classes/measurementpoint.interface';
import { SiteMeasurementPointDialogComponent } from '../site-measurement-point-dialog/site-measurement-point-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ISiteMeasurementPoint } from 'src/app/shared/classes/SiteMeasurementPoint.interface';
import { NotificationsService } from 'src/app/shared/modules/notifications/shared/notifications.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-fleet-customer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './fleet-customer.component.html',
  styleUrls: ['./fleet-customer.component.scss'],
})
export class FleetCustomerComponent implements OnChanges, OnInit {
  @Input() customer: any;
  @Input() customerFilter: string;
  @Input() mpFilter: string;
  @Input() locationFilter: string;
  @Input() withEventsOnly: boolean;
  @Input() userPrefs: any;
  @Input() expanded: boolean;
  @Input() qubeScanEnabled: boolean;
  @Input() isSystemAdmin: boolean;
  @Input() isAdmin: boolean;

  @Output() showCustomer = new EventEmitter<boolean>(true);

  public icons = {
    1: {
      icon: 'alert-octagon',
      class: 'negative',
    },
    2: {
      icon: 'check-circle-outline',
      class: 'positive',
    },
    3: {
      icon: 'help-circle-outline',
      class: 'neutral',
    },
    4: {
      icon: 'block-helper',
      class: 'neutral block-helper',
    },
  };

  public trends = [
    'power',
    'powerFactorPrior30Days',
    'voltageFluctuationsPrior30Days',
    'imbalancePrior30Days',
    'harmonicsPrior30Days',
    'groundCurrentPrior30Days',
  ];

  public tableColumns = [
    'mpId',
    'location',
    'status',
    'events',
    'trends',
    'comms',
    'lastTemperature',
    // 'endOfService',
    'edit',
    'choose',
  ];

  public events = [
    'sagsAndSwellsPrior30Days',
    'highfrequencyImpulsesPrior30Days',
    'outagesPrior30Days',
  ];

  public showComponent = true;
  public dataSource: MatTableDataSource<IMeasurementPoint>;
  public mpCount = 0;

  public isAddSiteMpEnabled = false;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChildren(MatTable) mpTables!: QueryList<MatTable<any>>;

  constructor(
    private authService: AuthService,
    private router: Router,
    private token: TokenService,
    private dialog: MatDialog,
    private notificationsService: NotificationsService,
    private sitesService: SitesService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    if (!this.qubeScanEnabled || (!this.isSystemAdmin && !this.isAdmin)) {
      const index = this.tableColumns.indexOf('edit', 0);
      if (index > -1) {
        this.tableColumns.splice(index, 1);
      }
      this.isAddSiteMpEnabled = false;
    } else {
      this.isAddSiteMpEnabled = true;
    }
    this.dataSource = new MatTableDataSource<IMeasurementPoint>(
      this.customer.measurementPoints
    );

  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes.customerFilter &&
      !changes.customerFilter.firstChange
    ) {
      this.showComponent = this.customer.accountName
        .toLowerCase()
        .includes(changes.customerFilter.currentValue.toLowerCase());
    }
    if (
      (changes && changes.mpFilter && !changes.mpFilter.firstChange) ||
      (changes.locationFilter && !changes.locationFilter.firstChange)
    ) {
      this.dataSource = new MatTableDataSource(this.customer.measurementPoints);

      this.dataSource.data = this.dataSource.data.filter(
        (mp: IMeasurementPoint) => {
          if (mp.city === null) {
            mp.city = '';
          }
          if (mp.country === null) {
            mp.country = '';
          }
          if (mp.state === null) {
            mp.state = '';
          }
          return (
            mp.mpId.toLowerCase().includes(this.mpFilter.toLowerCase()) &&
            (mp.city
              .toLowerCase()
              .includes(this.locationFilter.toLowerCase()) ||
              mp.country
                .toLowerCase()
                .includes(this.locationFilter.toLowerCase()) ||
              mp.state
                .toLowerCase()
                .includes(this.locationFilter.toLowerCase()))
          );
        }
      );
      this.mpCount = this.dataSource.data.length;
      this.showCustomer.emit(true);
    }
    if (
      changes &&
      changes.withEventOnly &&
      !changes.withEventOnly.firstChange
    ) {
      if (this.withEventsOnly) {
        this.dataSource.data.filter((mp) => {
          // TO DO
        });
      }
    }
  }

  sortChange($event, customer) {
    this.customer = this.customer.map((c) => {
      // only sort the data for the customer we're inspecting
      if (c.accountId !== customer.accountId) {
        return c;
      }

      c.measurementPoints = c.measurementPoints.sort((a: any, b: any) => {
        // AC status sorts numerically and is handled very different than other sortable columns
        if ($event.active === 'lastTemperature') {
          const aNum = parseInt(
            a.details[$event.active].powerQualityStatusType,
            10
          );
          const bNum = parseInt(
            b.details[$event.active].powerQualityStatusType,
            10
          );

          if (!aNum || !bNum) {
            return 0;
          }

          if ($event.direction === '' || $event.direction === 'asc') {
            return aNum < bNum ? -1 : bNum === aNum ? 0 : 1;
          }

          return bNum < aNum ? -1 : bNum === aNum ? 0 : 1;
        }

        // nothing to sort? nothing to do. bail out
        if (a[$event.active] === undefined || b[$event.active] === undefined) {
          return 0;
        }

        // dates need special treatment
        if ($event.active === 'endOfService') {
          const aDate = moment(a[$event.active]);
          const bDate = moment(b[$event.active]);

          if ($event.direction === '' || $event.direction === 'asc') {
            // ascending
            return aDate.isBefore(bDate) ? -1 : aDate.isSame(bDate) ? 0 : 1;
          }

          // descending
          return bDate.isBefore(aDate) ? -1 : bDate.isSame(aDate) ? 0 : 1;
        }

        // do a plain string comparison
        if ($event.direction === '' || $event.direction === 'asc') {
          // ascending
          return a[$event.active].localeCompare(b[$event.active]);
        }

        // descending
        return b[$event.active].localeCompare(a[$event.active]);
      });

      return c;
    });

    // tell all the tables to re-render their rows
    this.mpTables.forEach((table) => {
      table.renderRows();
    });
  }

  selectPoint(point: any) {
    const prefs = {
      ...this.userPrefs,
      account: point.accountId,
      mpId: point.measurementPointId,
    };

    // save the preferences for the user
    this.authService.savePreferences(
      prefs,
      Object.keys(this.userPrefs).length > 0
    );

    this.router.navigate(['/dashboard'], {
      queryParams: {
        account: point.accountId,
        mpId: point.measurementPointId,
      },
    });
  }

  isMeasurementPointDisabled(point): boolean {    
    // status mp becomes available to user
    let mpAvailableAt: number;
    if (this.token.metaData.isSystemAdministrator === '1' || (this.qubeScanEnabled && point.measurementPointTypeId === 1 && this.isAdmin)) {
      mpAvailableAt = 4;
    } else {
      mpAvailableAt = 8;
    }    
    return point.measurementPointStatusId < mpAvailableAt || point.measurementPointStatusId > 8;
  }

  public addSiteMeasurementPoint(): void {
    const addMemberDialog = this.dialog.open(SiteMeasurementPointDialogComponent, {
      width: '950px',
      disableClose: false,
      data: {
        isAdmin: this.isAdmin,
        isSystemAdmin: this.isSystemAdmin,
        accountName: this.customer.accountName
      },
      autoFocus: false,
      restoreFocus: false,
    });

    addMemberDialog
      .afterClosed()
      .subscribe((siteMeasurementPoint: ISiteMeasurementPoint) => {
        if (!siteMeasurementPoint) {
          return;
        }
        this.sitesService
          .createSiteMeasurementPoint( this.customer.accountId, siteMeasurementPoint)
          .pipe(take(1))
          .subscribe((response) => {
            if (response) {
              // Refresh the list of MPs
              this.refreshMeasurementPoints();
              this.notificationsService.notify(
                this.translateService.instant('manageSiteMeasurementPoint.save.success')
              );
            } else {
              this.notificationsService.notify(
                this.translateService.instant('manageSiteMeasurementPoint.save.failed')
              );
            }
          });
      });
  }

  private refreshMeasurementPoints() {
    this.sitesService
      .getSites(this.customer.accountId)
      .pipe(take(1))
      .subscribe((response) => {
        if (response && response.partners && response.partners.length === 1 && response.partners[0].customers) {
          // Refresh the list of MPs
          const refreshedCustomer = response.partners[0].customers.find((c: any) => {
            return c.accountId === this.customer.accountId;
          });
          if (refreshedCustomer) {
            this.customer.measurementPoints = refreshedCustomer.measurementPoints;
            this.dataSource = new MatTableDataSource(this.customer.measurementPoints);
            this.mpCount = this.dataSource.data.length;
            this.showCustomer.emit(true);
          } else {            
            this.notificationsService.notify(
              this.translateService.instant('manageSiteMeasurementPoint.refresh.failed')
            );
          }
        } else {
          this.notificationsService.notify(
            this.translateService.instant('manageSiteMeasurementPoint.refresh.failed')
          );
        }
      });
  }

  public editSiteMeasurementPoint(point: any): void {
    
    const siteId = point.locationId;
    const measurementPointId = point.measurementPointId;
    const accountId = this.customer.accountId;

    const siteMp: ISiteMeasurementPoint = {
      site: {
        id: siteId,
        locationName: point.locationName,
        shortname: point.locationShortname,
        siteInformation: point.siteInformation,
        address1: point.address1,
        address2: point.address2,
        city: point.city,
        state: point.state,
        zipCode: point.zipCode,
        country: point.country,
        latitude: point.latitude,
        longitude: point.longitude
      },
      measurementPoint: {
        roomId: measurementPointId,
        mpId: point.mpId,
        notes: point.notes,
        measurementPointTypeId: point.measurementPointTypeId,
        measurementPointStatusId: point.measurementPointStatusId
      }
    };
    const editMemberDialog = this.dialog.open(SiteMeasurementPointDialogComponent, {
      width: '950px',
      disableClose: false,
      data: {
        isAdmin: this.isAdmin,
        isSystemAdmin: this.isSystemAdmin,
        accountName: this.customer.accountName,
        siteMeasurementPoint: siteMp
      },
      autoFocus: false,
      restoreFocus: false,
    });

    editMemberDialog
      .afterClosed()
      .subscribe((siteMeasurementPoint: ISiteMeasurementPoint) => {
        if (!siteMeasurementPoint) {
          return;
        }
        this.sitesService
          .updateSiteMeasurementPoint( accountId, siteId, measurementPointId, siteMeasurementPoint)
          .pipe(take(1))
          .subscribe((response) => {
            if (response) {
              // Refresh the list of MPs
              this.refreshMeasurementPoints();
              this.notificationsService.notify(
                this.translateService.instant('manageSiteMeasurementPoint.save.success')
              );
            } else {
              this.notificationsService.notify(
                this.translateService.instant('manageSiteMeasurementPoint.save.failed')
              );
            }
          });
      });
  }

}
