import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { DiligentApiService } from './diligent-api.service';
import { ISiteMeasurementPoint } from '../classes/SiteMeasurementPoint.interface';

@Injectable()
export class SitesService {
  constructor(private diligent: DiligentApiService) {}

  public getAdminSites(accountId: number) {
    return this.diligent.getAccounts(accountId).then(async (response) => {
      const partnerAccounts = response.reduce((accumulator, current) => {
        if (current.isPartner === 1) {
          return [...accumulator, current];
        }

        return accumulator;
      }, []);

      return await Promise.all(
        partnerAccounts.map((account) => this.getSites(account.id).toPromise())
      );
    });
  }

  public getSites(accountId?: number): Observable<any> {
    return this.diligent.getSites(accountId).pipe(
      map((sites) => {
        let accountSites = sites;

        if (sites && sites.customers) {
          accountSites = {
            partners: [
              {
                customers: sites.customers,
              },
            ],
          };
        }

        if (sites && sites.partners) {
          // filter out customers without any measurement points
          accountSites.partners = accountSites.partners.map((s) => {
            s.customers = s.customers.filter((c) => {
              return c.measurementPoints.length > 0;
            });

            s.expand = true;

            return s;
          });
        }
        return accountSites;
      })
    );
  }

  public getMeasurementPointsForMap(accountId?: number) {
    return this.diligent.getMeasurementPointsForMap(accountId);
  }

  public getSite(siteId: number) {
    return this.diligent.getSite(siteId);
  }

  public createSiteMeasurementPoint(
    accountId: number,
    siteMeasurementPoint: ISiteMeasurementPoint
  ): Observable<any> {
    return this.diligent.createSiteMeasurementPoint(
      accountId,
      siteMeasurementPoint
    );
  }

  public updateSiteMeasurementPoint(
    accountId: number,
    siteId: number,
    measurementPointId: number,
    siteMeasurementPoint: ISiteMeasurementPoint
  ): Observable<any> {
    return this.diligent.updateSiteMeasurementPoint(
      accountId,
      siteId,
      measurementPointId,
      siteMeasurementPoint
    );
  }
}
