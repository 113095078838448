<h1 mat-dialog-title>
    {{ 'login.forgot-password.title' | translate }}
</h1>
<form (submit)="submitForm($event)" [formGroup]="requestForm">
    <div mat-dialog-content>
        <p>{{ 'login.forgot-password.copy' | translate }}</p>
        <mat-form-field appearance="fill">
            <mat-label>{{ 'login.email' | translate }}</mat-label>
            <input matInput type="email" formControlName="email">
            <mat-error *ngIf="email.hasError('email')">{{ 'login.forgot-password.email-error' | translate }}</mat-error>
            <mat-error *ngIf="email.hasError('required')">{{ 'global.errors.fieldRequired' | translate }}</mat-error>
        </mat-form-field>
    </div>
    <div mat-dialog-actions>
        <button mat-flat-button type="button" (click)="onCancel()">{{ 'global.cancel' | translate }}</button>
        <button mat-flat-button color="accent" type="submit" [disabled]="requestForm.invalid">{{ 'global.OK' | translate }}</button>
    </div>
</form>