<div class="fleet-mgmt" *ngIf="!loading">
  <div class="heading">
    <div class="heading-left">
      <div class="heading-left">
        <h1 class="heading-title">{{ 'fleet.title' | translate }}</h1>
        <label>{{ 'fleet.filterBy' | translate }}</label>
        <mat-form-field class="filter">
          <mat-label>{{ 'fleet.partner' | translate }}</mat-label>
          <input id="partnerFilter" matInput [(ngModel)]="partnerFilter" data-cy="partner-filter" />
        </mat-form-field>
        <mat-form-field class="filter">
          <mat-label>{{ 'fleet.customer' | translate }}</mat-label>
          <input matInput [(ngModel)]="customerFilter" data-cy="customer-filter"/>
        </mat-form-field>
        <mat-form-field class="filter">
          <mat-label>{{
            'fleet.table.measurement-point' | translate
          }}</mat-label>
          <input matInput [(ngModel)]="mpFilter" data-cy="mp-filter"/>
        </mat-form-field>
        <mat-form-field class="filter">
          <mat-label>{{ 'fleet.table.location' | translate }}</mat-label>
          <input matInput [(ngModel)]="locationFilter" data-cy="location-filter"/>
        </mat-form-field>
        <mat-checkbox *ngIf="false" class="filter" [(ngModel)]="withEventsOnly">
          {{ 'fleet.withEventsOnly' | translate }}
        </mat-checkbox>
      </div>
    </div>
    <div class="heading-right">
      <button
        mat-flat-button
        class="expand-collapse-button"
        (click)="toggleAllPanels()"
        data-cy="expand-collapse-button"
      >
        <mat-icon
          *ngIf="openAllPanels"
          svgIcon="expand"
          color="accent"
        ></mat-icon>
        <mat-icon
          *ngIf="!openAllPanels"
          svgIcon="collapse"
          color="accent"
        ></mat-icon>
      </button>
    </div>
  </div>
  <div class="fleet-list">
    <div *ngFor="let partner of partners; let idx = index">
      <app-fleet-partner
        [partner]="partner"
        [partnerFilter]="partnerFilter"
        [customerFilter]="customerFilter"
        [mpFilter]="mpFilter"
        [locationFilter]="locationFilter"
        [withEventsOnly]="withEventsOnly"
        [userPrefs]="userPrefs"
        [qubeScanEnabled]="qubeScanEnabled"
        [isAdmin]="isAdmin"
        [isSystemAdmin]="isSystemAdmin"
        [expandAll]="isAlExpanded"
      ></app-fleet-partner>
    </div>
  </div>
</div>
<mat-progress-spinner
  mode="indeterminate"
  *ngIf="loading"
  color="primary"
></mat-progress-spinner>
