<div class="map-panel">
    <h2>Total</h2>
    <span>
       Measurement Points:&nbsp;{{ measurementPointCount }}
    </span>
    <hr>
    <div class="map-legend">
        <mat-list>
            <mat-list-item>
                <mat-icon mat-list-icon svgIcon="uncommissioned"></mat-icon>
                <div mat-line>Uncommissioned</div>
                <div>{{measurementPointStatusCounts.uncommissioned}}</div>
            </mat-list-item>
            <mat-list-item>
                <mat-icon mat-list-icon svgIcon="no-data"></mat-icon>
                <div mat-line>No Data</div>
                <div>{{measurementPointStatusCounts.noData}}</div>
            </mat-list-item>
            <mat-list-item>
                <mat-icon mat-list-icon svgIcon="insufficient-data"></mat-icon>
                <div mat-line>Insufficient Data</div>
                <div>{{measurementPointStatusCounts.insufficientData}}</div>
            </mat-list-item>
            <mat-list-item>
                <mat-icon mat-list-icon svgIcon="good-data"></mat-icon>
                <div mat-line>Good</div>
                <div>{{measurementPointStatusCounts.goodData}}</div>
            </mat-list-item>
            <mat-list-item>
                <mat-icon mat-list-icon svgIcon="bad-data"></mat-icon>
                <div mat-line>Bad</div>
                <div>{{measurementPointStatusCounts.badData}}</div>
            </mat-list-item>
        </mat-list>
    </div>
    <div class="map-zoom">
        <div class="map-zoom-factor">Zoom Factor: {{zoomFactor}}</div>
        <div class="map-zoom-text">Must be at least 8 to display active Power Grid</div>
    </div>
</div>
