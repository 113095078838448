<div>
    <div><b>Customer: </b>{{measurementPoint.accountName}}</div>
    <div><b>Site: </b>{{measurementPoint.locationName}}</div>
    <div><b>Measurement Point: </b>{{measurementPoint.mpId}}</div>
    <hr>
    <div><b>Type: </b>{{measurementPoint.measurementPointTypeName}}</div>
    <div><b>Status: </b>{{getMeasurementPointStatusName()}}</div>
    <hr *ngIf="measurementPoint.details.lastCommunication.value">
    <div *ngIf="measurementPoint.details.lastCommunication.value"><b>Last Comms: </b>{{getLastLocalCommunicationsTime()}}</div>
</div>
