<div class="heading">
    <h1>{{ 'global.manage-members' | translate }}</h1>
</div>
<section class="manage-members-detail container">
    <div id="manage-members-header" class="header">
        <h1>{{ 'manageMembers.tableTitle' | translate }} ({{membersCount}})</h1>
        <button mat-flat-button (click)="addMember()" class="new-button" color="accent">
            {{ 'manageMembers.addMember' | translate }}
            <mat-icon [svgIcon]="'account-plus'"></mat-icon>
        </button>
    </div>
    <div class="table-container">
        <table mat-table [dataSource]="usersListSource" matSort matSortActive="fullName" matSortDirection="asc">
            <ng-container matColumnDef="fullName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'manageMembers.name' | translate }} </th>
                <td mat-cell *matCellDef="let element">{{element.fullName}} <span *ngIf="element.activated === 0">(Not Activated)</span></td>
            </ng-container> 
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'manageMembers.email' | translate }} </th>
                <td mat-cell *matCellDef="let element">{{element.email}} <a *ngIf="element.activated === 0" (click)="resendEmail(element)">Resend Registration Email</a></td>
            </ng-container>
            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'manageMembers.mobilePhone' | translate }} </th>
                <td mat-cell *matCellDef="let element"><a href="tel:{{element.mobilePhone}}">{{element.mobilePhone}}</a></td>
            </ng-container>
            <ng-container matColumnDef="icons">
                <th mat-header-cell mat-sort-header disabled *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <div id="buttons-cell">
                        <button mat-icon-button (click)="deleteMember(element)">
                            <mat-icon svgIcon="delete-forever" color="accent"></mat-icon>
                        </button>
                        <button mat-icon-button (click)="editMember(element)">
                            <mat-icon svgIcon="pencil" color="accent"></mat-icon>
                        </button>
                    </div>
                </td>
            </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
    </div>
</section>