import { ChangeDetectionStrategy, Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';

import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '../shared/classes/component-destroy.class';

@Component({
  selector: 'app-more-information',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './more-information.component.html',
  styleUrls: ['./more-information.component.scss'],
})
export class MoreInformationComponent
  extends OnDestroyMixin
  implements OnInit, OnDestroy {
  private eventTypes = [
    'voltageSag',
    'voltageSwell',
    'highFrequencyImpulse',
    'interruption',
  ];

  public eventType: any = null;

  constructor(private route: ActivatedRoute, private router: Router) {
    super();
  }

  ngOnInit() {
    this.route.paramMap
      .pipe(untilComponentDestroyed(this))
      .subscribe((params: ParamMap) => {
        if (
          !params.get('eventType') ||
          !this.eventTypes.includes(params.get('eventType'))
        ) {
          this.router.navigate(['/dashboard']);
          return;
        }

        this.eventType = params.get('eventType');
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
