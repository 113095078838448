export const Currents = [
  { name: 'Phase Current', factoryName: 'PhaseCurrent' },
  { name: 'Neutral Current', factoryName: 'NeutralCurrent' },
  { name: 'Ground Current', factoryName: 'EarthCurrent' },
  { name: 'TDD', factoryName: 'Tdd' },
  {
    name: 'I Neg Seq unbalance',
    factoryName: 'INegSeqUnbalance',
    dropdownName: 'Neg Seq Unbalance',
  },
  {
    name: 'I Zero Seq unbalance',
    factoryName: 'IZeroSeqUnbalance',
    dropdownName: 'Zero Seq Unbalance',
  },
];
