import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import * as moment from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import { FormControl } from '@angular/forms';

import { MeasurementPointsService } from '../../services/measurement-points.service';

@Component({
  selector: 'app-date-picker-nav',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './date-picker-nav.component.html',
  styleUrls: ['./date-picker-nav.component.scss'],
})
export class DatePickerNavComponent implements OnChanges, OnInit {
  @Input() scope: moment.unitOfTime.DurationConstructor;
  @Input() chartSetName: string;
  @Input() absoluteStartDate: moment.Moment;
  @Input() type: string;
  @Input() havePast: boolean;
  @Input() date: moment.Moment;

  @Output() updateChartEvent = new EventEmitter();

  public dateControl: FormControl;
  public startView = 'year';
  public maxDate: moment.Moment;
  public title: string;
  public haveFutur = false;

  constructor(private mpService: MeasurementPointsService) {}

  ngOnInit() {
    this.maxDate = moment();
    this.dateControl = new FormControl(moment(this.date));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.scope && !changes.scope.firstChange) {
      this.setDatepickerStartView();
    }
  }

  private setTitle(): void {
    if (this.scope === 'days') {
      this.title = this.date.format('dddd, MMMM Do, YYYY');
    } else if (this.scope === 'weeks') {
      this.title =
        moment(this.date).subtract(1, 'minutes').startOf('week').format('MMMM Do YYYY') +
        ' - ' +
        moment(this.date).subtract(1, 'minutes').endOf('week').format('MMMM Do YYYY');
    } else if (this.scope === 'months') {
      this.title = moment(this.date).subtract(1, 'minutes').format('MMMM YYYY');
    } else if (this.scope === 'years') {
      this.title = moment(this.date).subtract(1, 'minutes').format('YYYY');
    }
  }

  private setDatepickerStartView(): void {
    if (this.scope === 'days' || this.scope === 'weeks') {
      this.startView = 'month';
    } else if (this.scope === 'months') {
      this.startView = 'year';
    } else if (this.scope === 'years') {
      this.startView = 'multi-year';
    }
  }

  private checkIfPast(): void {
    const tempDate: moment.Moment = moment(this.date)
      .subtract(1, 'minutes')
      .startOf(this.scope);

    if (this.absoluteStartDate.isAfter(tempDate)) {
      this.havePast = false;
    } else {
      this.havePast = true;
    }
  }

  private checkIfFutur(): void {
    const tempDate: moment.Moment = moment(this.date)
      .subtract(1, 'minutes')
      .endOf(this.scope);
    if (tempDate.isAfter(moment())) {
      this.haveFutur = false;
    } else {
      this.haveFutur = true;
    }
  }

  private dateChange(): void {
    this.checkIfPast();
    this.checkIfFutur();
    this.setTitle();
    this.updateChartEvent.emit({
      scope: this.scope,
      title: this.title,
      date: this.date,
    });
  }

  public updateChart(scope: string | moment.unitOfTime.DurationConstructor): void {
    if (scope !== 'past' && scope !== 'future') {
      this.scope = scope as moment.unitOfTime.DurationConstructor;
      if (scope === 'days') {
        this.date.endOf('day');
        if (this.date.isAfter(moment())) {
          this.date = moment()
            .tz(this.mpService.selectedMeasurementPoint.timezone)
            .endOf('day');
        }
      } else {
        if (this.date.isAfter(moment())) {
          this.date = moment().tz(this.mpService.selectedMeasurementPoint.timezone);
        }
      }
      this.setDatepickerStartView();
    } else if (scope === 'past') {
      if (this.scope === 'days') {
        this.date.subtract(1, this.scope);
      } else if (this.scope === 'weeks') {
        this.date.weekday() !== 0
          ? this.date.startOf(this.scope)
          : this.date.subtract(1, this.scope);
      } else if (this.scope === 'months') {
        this.date.date() !== 1
          ? this.date.startOf(this.scope)
          : this.date.subtract(1, this.scope);
      } else if (this.scope === 'years') {
        this.date.startOf(this.scope);
      }
    } else if (scope === 'future') {
      this.date.add(1, this.scope);
    }
    this.dateControl = new FormControl(moment(this.date).subtract(1, 'minutes'));
    this.dateChange();
  }

  public updateDate(event): void {
    this.date = moment()
      .tz(this.mpService.selectedMeasurementPoint.timezone)
      .year(event.value.year())
      .month(event.value.month())
      .date(event.value.date());
    this.date.endOf(this.scope);
    this.dateChange();
  }

  public chosenYearHandler(
    normalizedYear: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>
  ): void {
    if (this.scope === 'years') {
      this.date = this.date
        .tz(this.mpService.selectedMeasurementPoint.timezone)
        .year(normalizedYear.year() + 1)
        .month(0)
        .date(1)
        .startOf('day');

      this.dateChange();
      datepicker.close();
    }
    const ctrlValue = this.dateControl.value;
    ctrlValue.year(normalizedYear.year());
    this.dateControl.setValue(ctrlValue);
  }

  public chosenMonthHandler(
    normalizedMonth: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>
  ): void {
    if (this.scope === 'months') {
      this.date = this.date
        .tz(this.mpService.selectedMeasurementPoint.timezone)
        .year(normalizedMonth.year())
        .month(normalizedMonth.month())
        .date(normalizedMonth.date())
        .endOf('month');
      this.dateChange();
      datepicker.close();
    }
    const ctrlValue = this.dateControl.value;
    ctrlValue.month(normalizedMonth.month());
    this.dateControl.setValue(ctrlValue);
  }
}
