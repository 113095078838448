import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { of, Observable, ReplaySubject, BehaviorSubject } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { IUser, ILoginResponse } from './../classes/user';
import { DiligentApiService } from './diligent-api.service';
import { TokenService } from './token.service';
import { IJwtPayload } from '../classes/generic.interfaces';
import { IAccount } from '../classes/account';
import * as fromHierarchy from './../../_store/_reducers';
import { NotificationsService } from '../../shared/modules/notifications/shared/notifications.service';

@Injectable()
export class AuthService {
  private preferences$ = new ReplaySubject<any>(1);
  public preferencesO = this.preferences$.asObservable();
  private account$ = new BehaviorSubject<IAccount>(null);
  public accountO = this.account$.asObservable();
  private accountId: number;
  public hasPref = false;

  public get authed(): Observable<IJwtPayload> {
    return this.token.payload.pipe(
      // check if the token has expired
      switchMap(() => this.token.isExpired()),
      switchMap((expired) => {
        // only send out the payload if its not expired
        return expired ? of(null) : this.token.payload;
      })
    );
  }

  public get user(): Observable<IUser> {
    return this.authed.pipe(
      switchMap((payload) => {
        return payload
          ? this.diligent.getUserById(payload.id, payload.accountId)
          : of(null);
      })
    );
  }

  public get account(): Observable<IAccount> {
    return this.authed.pipe(
      switchMap((payload) => {
        return payload && payload.accountId
          ? this.diligent.getAccount(payload.accountId)
          : of(null);
      })
    );
  }

  public getAccount(): void {
    this.diligent.getAccount(this.accountId).subscribe((res) => this.account$.next(res));
  }

  public getAccountById(accountId: number): Observable<any> {
    return this.diligent.getAccount(accountId);
  }

  public get preferences(): Observable<any> {
    return this.authed.pipe(
      switchMap((user) => {
        return user ? this.preferences$ : of(null);
      })
    );
  }

  constructor(
    private diligent: DiligentApiService,
    private token: TokenService,
    private store: Store<fromHierarchy.State>,
    private notificationsService: NotificationsService,
    private translateService: TranslateService
  ) {}

  public login(username: string, password: string): Promise<any> {
    return this.diligent.login(username, password).then((user) => {
      if (user.retired === 1) {
        this.notificationsService.alert(
          this.translateService.instant('login.retired-user-error'),
          this.translateService.instant('login.login-failed')
        );
        return;
      }
      // this only gets sent in the login call and is required to get the user data
      this.token.setMetaData('accountId', user.accountId.toString());
      this.accountId = user.accountId;
      this.token.setMetaData('guid', user.guid ? user.guid.toString() : '');
      this.token.setMetaData(
        'isSystemAdministrator',
        user.isSystemAdministrator.toString()
      );

      // setting this emits all observers
      this.token.hash = user.token;
      this.store.dispatch({
        type: '[App Init] Init hierarchy - user',
        payload: { id: user.id, accountId: user.accountId },
      });
      this.loadPreferences();
    });
  }

  public activate(token: string, password: string): Observable<any> {
    this.token.hash = token;
    return this.diligent.activate(token, password).pipe(
      switchMap((activationResponse) => this.token.payload),
      switchMap((payload: { email: string }) =>
        this.diligent.login(payload.email, password)
      ),
      map((user) => {
        // this only gets sent in the login call and is required to get the user data
        this.token.setMetaData('accountId', user.accountId.toString());
        this.token.setMetaData('guid', user.guid ? user.guid.toString() : '');
        this.token.setMetaData(
          'isSystemAdministrator',
          user.isSystemAdministrator.toString()
        );
        this.accountId = user.accountId;
        this.getAccount();
        // setting this emits all observers
        this.token.hash = user.token;
        return user;
      })
    );
  }

  public logout() {
    this.preferences$.next(null);
    this.account$.next(null);
    this.hasPref = false;
    this.token.expire();
  }

  public renewToken(returnUrl?: string) {
    return this.diligent.renewToken(returnUrl);
  }

  public reload() {
    this.token.parse();
  }

  public resetPassword(password: string): Promise<any> {
    return this.diligent.resetPassword(password);
  }

  public async loadPreferences() {
    return this.diligent.loadUserPreferences().then((prefs) => {
      if (prefs.account) {
        this.accountId = prefs.account;
      }
      if (this.accountId) {
        this.getAccount();
      }
      this.preferences$.next(prefs || {});
      this.hasPref = true;
    });
  }

  public async savePreferences(prefs, update = false) {
    const saved = await this.diligent.saveUserPreferences(prefs, update);
    this.loadPreferences();
    return saved;
  }

  public requestPasswordReset(email: string): Promise<any> {
    return this.diligent.requestPasswordReset(email);
  }

  public resetPasswordWithToken(
    password: string,
    token: string
  ): Promise<ILoginResponse> {
    return this.diligent.resetPasswordWithToken(password, token);
  }

  public registerUserAccount(userAccount): Observable<any> {
    return this.diligent.registerUserAccount(userAccount);
  }

  public getEnvironmentSettings(settingName: string): Observable<any> {
    return this.diligent.getEnvironmentSettings(settingName);
  }

  public async isQubeScanEnabled() {
    try {
      const envSettings = await this.getEnvironmentSettings(
        'featureEnableQubeScan'
      ).toPromise();
      return envSettings.featureEnabledQubeScan;
    } catch (err) {
      console.error(err);
    }
    return false;
  }
}
