<section class="new-note-container">
  <form
    cdkFocusRegionStart
    [formGroup]="noteForm"
    enctype="multipart/form-data"
    (ngSubmit)="onSubmit(noteForm.value)"
  >
    <div class="notes-header" data-cy='new_note_header'>
      <h1 *ngIf="!editMode">{{ 'notes.new.title' | translate }}</h1>
      <h1 *ngIf="editMode">{{ 'notes.edit.title' | translate }}</h1>
      <button
        mat-flat-button
        type="button"
        [autofocus]="false"
        (click)="cancelNote()"
      >
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    </div>
    <div class="notes-subheader">
      <h4>
        {{ accountName }} - {{ mpCity }}, {{ mpState }} | {{ mpName }} ({{
          tlaTimezone
        }})
      </h4>
      <input
        *ngIf="allowAttachments"
        #fileInput
        type="file"
        hidden
        id="newAttachment"
        (change)="addFiles()"
        multiple
        accept=".png,.jpg,.jpeg,.gif,.bmp"
      />
      <a *ngIf="allowAttachments" (click)="openFileUpload()">
        <mat-icon svgIcon="paperclip"></mat-icon>
        {{ 'notes.new.addAttachment' | translate }}
      </a>
    </div>
    <div class="edit-area">
      <div class="note-top">
        <mat-form-field
          *ngIf="editSummary; else summaryView"
          id="note-summary"
          data-cy="new_note_summary"
          appearance="fill"
        >
          <mat-label for="summary">{{
            'notes.new.summary' | translate
          }}</mat-label>
          <input
            matInput
            type="text"
            name="summary"
            formControlName="summary"
            (focus)="isFirstFocus($event)"
            required
          />
        </mat-form-field>
        <ng-template #summaryView>
          <div class="fColumn">
            <h3>{{ 'notes.new.summary' | translate }}</h3>
            <div class="fRow">
              <p>{{ data.note.summary }}</p>
              <button
                mat-icon-button
                color="accent"
                (click)="editSummary = true"
              >
                <mat-icon svgIcon="pencil"></mat-icon>
              </button>
            </div>
          </div>
        </ng-template>
        <mat-form-field
          *ngIf="editDate; else dateView"
          id="date-picker"
          appearance="fill"
          data-cy="new_note_date"
        >
          <mat-label>{{ 'notes.new.date' | translate }}</mat-label>
          <input
            matInput
            name="date"
            [matDatepicker]="picker"
            formControlName="date"
            (focus)="isFirstFocus($event)"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker disabled="false"></mat-datepicker>
        </mat-form-field>
        <ng-template #dateView>
          <div class="fColumn">
            <h3>{{ 'notes.new.date' | translate }}</h3>
            <p>{{ date }}</p>
          </div>
        </ng-template>
        <mat-form-field 
          *ngIf="editTime; else timeView" 
          appearance="fill" 
          data-cy="new_note_time"
        >
          <mat-label for="time">{{ 'notes.new.time' | translate }}</mat-label>
          <input
            matInput
            type="time"
            name="time"
            formControlName="time"
            (focus)="isFirstFocus($event)"
            required
          />
          <mat-icon svgIcon="clock-outline" matSuffix></mat-icon>
        </mat-form-field>
        <ng-template #timeView>
          <div class="fColumn">
            <h3>{{ 'notes.new.time' | translate }}</h3>
            <div class="fRow">
              <p>{{ time }}</p>
              <button
                mat-icon-button
                color="accent"
                (click)="editTime = true; editDate = true"
              >
                <mat-icon svgIcon="pencil"></mat-icon>
              </button>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="note-bottom">
        <mat-form-field 
          id="details-form-field" 
          appearance="outline"
          data-cy="new_note_details"
        >
          <mat-label for="details">{{
            'notes.new.details' | translate
          }}</mat-label>
          <textarea
            matInput
            name="details"
            formControlName="details"
          ></textarea>
        </mat-form-field>
        <div id="attachments">
          <h3>{{ 'notes.new.attachments' | translate }}:</h3>
          <div id="attachments-well">
            <div class="attachment" *ngFor="let file of files; let i = index">
              <button
                mat-icon-button
                class="remove-file-btn"
                (click)="removeFileFromSelected(i)"
                type="button"
              >
                <mat-icon svgIcon="minus-circle"></mat-icon>
              </button>
              <span
                class="file"
                [innerHTML]="file.name"
                (click)="transformAndDisplayFile(file)"
              ></span>
            </div>
            <div
              class="attachment"
              *ngFor="let file of fileReferences; let i = index"
            >
              <p
                class="file"
                (click)="displayFile(file.imgUrl)"
                [innerHTML]="file.filename"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="button-row">
      <button 
        mat-flat-button 
        type="button" 
        (click)="cancelNote()"
        data-cy="new_note_cancel"
      >
        {{ 'notes.new.cancelButton' | translate }}
      </button>
      <button
        mat-flat-button
        color="accent"
        [disabled]="noteForm.invalid"
        type="submit"
      >
        {{ 'notes.new.saveButton' | translate }}
      </button>
    </div>
  </form>
</section>
