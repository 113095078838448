import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
  /**
   *
   * @param value The date you want to transform
   * @param format The Moment-compatible format you want to output the date in
   * @param timezone (optional) A timezone you want to use (i.e. America/New_York)
   */
  transform(value: any, format: string, timezone: any = null): any {
    if (timezone) {
      return moment(value).tz(timezone).format(format);
    }

    return moment(value).format(format);
  }
}
