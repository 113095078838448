<div class="tabs-container">
  <mat-tab-group mat-stretch-tabs disableRipple="true" animationDuration="0ms">
    <mat-tab *ngFor="let tab of tabs">
      <ng-template mat-tab-label>
        {{ 'energyPower.tabs.' + tab | translate }}
      </ng-template>
      <ng-template matTabContent>
        <div class="chart-container">
          <div class="chart">
            <app-energy-power-chart
              [start]="start"
              [end]="end"
              [mp]="mp"
              [mpTimezone]="mpTimezone"
              [date]="date"
              [commonScope]="scope"
              [chartTypeInput]="tab"
              (chartUpdate)="updateChart($event)"
              (detailBoxUpdate)="setDetailBox($event)"
            >
            </app-energy-power-chart>
          </div>
          <div class="chart-details">
            <div class="chart-details-info">
              <div class="details-box-period">
                <h4 class="period-title">
                  {{ 'energyPower.period' | translate }}
                </h4>
                <div class="period">{{ period }}</div>
              </div>
              <h4>{{ 'energyPower.consumption' | translate }}</h4>
              <div>
                <table>
                  <tr>
                    <td>{{ 'energyPower.totalKWH' | translate }}:</td>
                    <td
                      class="align-right"
                      *ngIf="
                        energyMeasure?.status !== 3 &&
                        energyMeasure?.status !== 4
                      "
                    >
                      {{
                        energyMeasure?.totalActiveEnergyConsumed
                          | number: '1.3-3'
                      }}
                    </td>
                    <td
                      class="align-right"
                      *ngIf="
                        energyMeasure?.status === 3 ||
                        energyMeasure?.status === 4
                      "
                    >
                      -
                    </td>
                    <td>kWh</td>
                  </tr>
                  <tr>
                    <td>{{ 'energyPower.totalKVARH' | translate }}:</td>
                    <td
                      class="align-right"
                      *ngIf="
                        energyMeasure?.status !== 3 &&
                        energyMeasure?.status !== 4
                      "
                    >
                      {{
                        energyMeasure?.totalReactiveEnergyConsumed
                          | number: '1.3-3'
                      }}
                    </td>
                    <td
                      class="align-right"
                      *ngIf="
                        energyMeasure?.status === 3 ||
                        energyMeasure?.status === 4
                      "
                    >
                      -
                    </td>
                    <td>kvarh</td>
                  </tr>
                  <tr>
                    <td>{{ 'energyPower.totalKVAH' | translate }}:</td>
                    <td
                      class="align-right"
                      *ngIf="
                        energyMeasure?.status !== 3 &&
                        energyMeasure?.status !== 4
                      "
                    >
                      {{
                        energyMeasure?.totalApparentEnergyConsumed
                          | number: '1.3-3'
                      }}
                    </td>
                    <td
                      class="align-right"
                      *ngIf="
                        energyMeasure?.status === 3 ||
                        energyMeasure?.status === 4
                      "
                    >
                      -
                    </td>
                    <td>kVah</td>
                  </tr>
                </table>
              </div>
              <h4>{{ 'energyPower.15MinDemand' | translate }}</h4>
              <div>
                <table>
                  <tr>
                    <td>{{ 'energyPower.max' | translate }}:</td>
                    <td
                      class="align-right"
                      *ngIf="
                        energyMeasure?.status !== 3 &&
                        energyMeasure?.status !== 4
                      "
                    >
                      {{
                        energyMeasure?.maxActivePowerDemand | number: '1.3-3'
                      }}
                    </td>
                    <td
                      class="align-right"
                      *ngIf="
                        energyMeasure?.status === 3 ||
                        energyMeasure?.status === 4
                      "
                    >
                      -
                    </td>
                    <td>kW</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td colspan="2">
                      {{
                        energyMeasure?.dateTimeOfMaxActivePowerDemand
                          | date: 'M/d/yyyy, h:mm a':maxDemandTz
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ 'energyPower.min' | translate }}:</td>
                    <td
                      class="align-right"
                      *ngIf="
                        energyMeasure?.status !== 3 &&
                        energyMeasure?.status !== 4
                      "
                    >
                      {{
                        energyMeasure?.minActivePowerDemand | number: '1.3-3'
                      }}
                    </td>
                    <td
                      class="align-right"
                      *ngIf="
                        energyMeasure?.status === 3 ||
                        energyMeasure?.status === 4
                      "
                    >
                      -
                    </td>
                    <td>kW</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>{{ 'energyPower.avg' | translate }}:</td>
                    <td
                      class="align-right"
                      *ngIf="
                        energyMeasure?.status !== 3 &&
                        energyMeasure?.status !== 4
                      "
                    >
                      {{
                        energyMeasure?.avgActivePowerDemand | number: '1.3-3'
                      }}
                    </td>
                    <td
                      class="align-right"
                      *ngIf="
                        energyMeasure?.status === 3 ||
                        energyMeasure?.status === 4
                      "
                    >
                      -
                    </td>
                    <td>kW</td>
                    <td></td>
                  </tr>
                </table>
              </div>
              <h4>{{ 'energyPower.powerFactor' | translate }}</h4>
              <div>
                <table>
                  <tr>
                    <td>{{ 'energyPower.pfMaxDemand' | translate }}:</td>
                    <td
                      class="align-right"
                      *ngIf="
                        energyMeasure?.status !== 3 &&
                        energyMeasure?.status !== 4
                      "
                    >
                      {{
                        energyMeasure?.powerFactorAtMaxDemand | number: '1.3-3'
                      }}
                    </td>
                    <td
                      class="align-right"
                      *ngIf="
                        energyMeasure?.status === 3 ||
                        energyMeasure?.status === 4
                      "
                    >
                      -
                    </td>
                  </tr>
                  <tr>
                    <td>{{ 'energyPower.pfAvg' | translate }}:</td>
                    <td
                      class="align-right"
                      *ngIf="
                        energyMeasure?.status !== 3 &&
                        energyMeasure?.status !== 4
                      "
                    >
                      {{ energyMeasure?.avgPowerFactor | number: '1.3-3' }}
                    </td>
                    <td
                      class="align-right"
                      *ngIf="
                        energyMeasure?.status === 3 ||
                        energyMeasure?.status === 4
                      "
                    >
                      -
                    </td>
                  </tr>
                  <tr>
                    <td>{{ 'energyPower.loadFactor' | translate }}:</td>
                    <td
                      class="align-right"
                      *ngIf="
                        energyMeasure?.status !== 3 &&
                        energyMeasure?.status !== 4
                      "
                    >
                      {{ energyMeasure?.avgLoadFactor | number: '1.0-2' }}
                    </td>
                    <td
                      class="align-right"
                      *ngIf="
                        energyMeasure?.status === 3 ||
                        energyMeasure?.status === 4
                      "
                    >
                      -
                    </td>
                    <td class="power-factor-percentage">%</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
