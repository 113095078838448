import { Observable, of, throwError, forkJoin } from 'rxjs';
import { Moment } from 'moment';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take, map, switchMap, tap, catchError, skipWhile } from 'rxjs/operators';

import { IUserUpdateParams } from './../classes/userUpdateParams.interface';
import { IUser, ILoginResponse } from './../classes/user';
import { environment } from './../../../environments/environment';
import { TokenService } from './token.service';
import { IMeasurementPoint } from '../classes/measurementpoint.interface';
import { INote } from '../classes/note.interface';
import { TrendsData } from '../classes/trendsData.interface';
import { IEnergyMeasure } from '../classes/energyMeasure.interface';
import { IMonthlyReport } from '../classes/monthly-report.interface';
import { IAccount } from './../classes/account';
import { IEventNote } from './../classes/event-note.interface';
import { ISiteMeasurementPointDialogData } from '../classes/siteMeasurementPointDialog.interface';
import { ISiteMeasurementPoint } from '../classes/SiteMeasurementPoint.interface';

@Injectable()
export class DiligentApiService {
  public get endpoint(): string {
    return environment.psl.diligent_endpoint;
  }

  private get options() {
    return {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${this.token.hash}`)
        .set('content-type', 'application/json'),
      // withCredentials: true
    };
  }

  constructor(
    private http: HttpClient,
    private token: TokenService,
    private router: Router
  ) {}

  public login(user: string, password: string): Promise<ILoginResponse> {
    return this.http
      .post(`${this.endpoint}/login`, {
        email: user,
        password,
      })
      .pipe(
        map((data) => data as ILoginResponse),
        take(1)
      )
      .toPromise();
  }

  public activate(token: string, password: string): Observable<ILoginResponse> {
    return this.http
      .post(`${this.endpoint}/user/activate`, {
        token,
        password,
      })
      .pipe(
        map((data) => data as ILoginResponse),
        take(1)
      );
  }

  public getUserById(userId: number, accountId: number): Observable<IUser> {
    return this.renewToken().pipe(
      switchMap(() =>
        this.http.get(`${this.endpoint}/user/${userId}`, {
          ...this.options,
          params: new HttpParams().set('accountId', accountId.toString()),
        })
      ),
      map((data) => data as IUser),
      take(1)
    );
  }

  public renewToken(returnUrl?: string): Observable<boolean> {
    return this.token.isExpired().pipe(
      switchMap((expired) => {
        return expired
          ? this.http.get(`${this.endpoint}/token`, this.options).pipe(
              switchMap((response: any) => {
                // 401 means the token renew failed ... send an error downstream, or the response object on success
                return !response || response.code === 401
                  ? throwError(response.payload.message)
                  : of(response);
              }),
              // error renewing token - this can happen if the app is re-opened after being closed beyond token expiration
              catchError((err) => {
                // expire the token/metadata
                this.token.expire();

                // navigate to login, and send false downstream so the token updates can be ignored with skipWhile
                return this.router
                  .navigate(['/login'], {
                    queryParams: { returnUrl: returnUrl },
                  })
                  .then(() => false);
              }),
              // there was an error and the router navigated to login, and sent false back into the stream - skip the rest of the pipes and don't emit
              skipWhile((r) => r === false),
              // only writes token if the response !== false
              tap((response: any) => (this.token.hash = response.token)),
              map(() => true)
            )
          : of(true);
      }),
      // api calls constitute activity - reset the token inactivity timer
      tap(this.token.touch)
    );
  }

  public getAccount(accountId: number): Observable<IAccount> {
    return this.renewToken().pipe(
      switchMap(() =>
        this.http.get(`${this.endpoint}/account/${accountId}`, this.options)
      ),
      map((response) => response as IAccount),
      take(1)
    );
  }

  public getAccounts(accountId: number): Promise<any> {
    return this.renewToken()
      .pipe(
        switchMap(() => this.http.get(`${this.endpoint}/accounts/`, this.options)),
        take(1)
      )
      .toPromise();
  }

  public resetPassword(password: string) {
    return this.renewToken()
      .pipe(
        switchMap(() =>
          this.http.post(
            `${this.endpoint}/password`,
            { password: password },
            this.options
          )
        ),
        take(1)
      )
      .toPromise();
  }

  // TODO create TrendsData interface
  public getTrendsDataJson(
    startDate: Moment,
    endDate: Moment,
    granularity: string,
    channelIds: string[],
    measurePointId: string,
    table: string = 'oneminute',
    interval: number = 1
  ): Observable<Array<TrendsData>> {
    const endpointUrl = `${this.endpoint}/trends/measurementPoint/${measurePointId}`;
    return this.renewToken().pipe(
      switchMap(() =>
        this.http.post(
          endpointUrl,
          {
            startTime: startDate.toISOString(),
            endTime: endDate.toISOString(),
            period: granularity,
            columns: channelIds,
            output: 'json',
            writeToFile: false,
            table,
            interval: interval,
          },
          { ...this.options, responseType: 'json' }
        )
      ),
      map((response) => response as Array<TrendsData>),
      take(1)
    );
  }

  public getTrendsDataCsv(
    startDate: Moment,
    endDate: Moment,
    granularity: string,
    channelIds: string[],
    measurePointId: string,
    table: string = 'oneminute'
  ): Observable<string> {
    const endpointUrl = `${this.endpoint}/trends/measurementPoint/${measurePointId}`;
    return this.renewToken().pipe(
      switchMap(() =>
        this.http.post(
          endpointUrl,
          {
            startTime: startDate.toISOString(),
            endTime: endDate.toISOString(),
            period: granularity,
            columns: channelIds,
            output: 'csv',
            writeToFile: true,
            table,
          },
          { ...this.options, responseType: 'json' }
        )
      ),
      switchMap((response: string) => {
        return this.http.get(response, { responseType: 'text' }) as Observable<string>;
      }),
      take(1)
    );
  }

  // TODO create EventsData interface
  public getEventsData(
    measurePointId: string,
    accountId: string,
    startDate: Moment,
    endDate: Moment,
    eventTypeId = -1,
    count: number = 10000,
    severeOnly: boolean = true
  ): Observable<any> {
    let paramsQuery = new HttpParams()
      .set(`accountId`, `${accountId}`)
      .set(`includeRetired`, `false`)
      .set(`dateRangeStart`, `${startDate.toISOString()}`)
      .set(`dateRangeEnd`, `${endDate.toISOString()}`)
      .set(`count`, `${count}`)
      .set(`severeOnly`, `${severeOnly}`);

    if (eventTypeId >= 0)
      paramsQuery = paramsQuery.set(`deviceEventTypeId`, `${eventTypeId}`);
    return this.renewToken().pipe(
      switchMap(() =>
        this.http.get(`${this.endpoint}/events/measurementPoint/${measurePointId}`, {
          ...this.options,
          params: paramsQuery,
        })
      ),
      take(1)
    );
  }

  public getMeasurementPointEvents(
    mpId: number,
    accountId: number,
    startDate: Moment = null,
    endDate: Moment = null,
    deviceEventTypeId: any = null,
    severeOnly: boolean = false,
    includeRetired: boolean = false,
    offset: number = 0,
    count: number = 20
  ): Promise<any> {
    const url = `${this.endpoint}/events/measurementPoint/${mpId}`;
    let params = new HttpParams();

    if (accountId) {
      params = params.set('accountId', accountId.toString());
    }

    if (startDate) {
      params = params.set('dateRangeStart', startDate.toISOString());
    }

    if (endDate) {
      params = params.set('dateRangeEnd', endDate.toISOString());
    }

    if (deviceEventTypeId) {
      params = params.set('deviceEventTypeId', deviceEventTypeId);
    }

    if (severeOnly) {
      params = params.set('severeOnly', 'true');
    }

    if (includeRetired) {
      params = params.set('includeRetired', 'true');
    }

    if (!isNaN(offset) && offset >= 0) {
      params = params.set('offset', Math.round(offset).toString());
    }

    if (!isNaN(count) && count >= 0) {
      params = params.set('count', Math.round(count).toString());
    }
    return this.renewToken()
      .pipe(
        switchMap(() => this.http.get(url, { ...this.options, params: params })),
        take(1)
      )
      .toPromise();
  }

  public getPowerQualityMeasures(mpId: number): Promise<any> {
    return this.renewToken()
      .pipe(
        switchMap(() =>
          this.http.get(
            `${this.endpoint}/powerQualityMeasures/measurementPoint/${mpId}`,
            this.options
          )
        ),
        take(1)
      )
      .toPromise();
  }

  public getSites(
    accountId: number = 0,
    includeRetired: boolean = false
  ): Observable<any> {
    const url = `${this.endpoint}/measurementPoints/hierarchy`;
    let params = new HttpParams();

    if (accountId && accountId > 0) {
      params = params.set('accountId', accountId.toString());
    }

    if (includeRetired) {
      params = params.set('includeRetired', 'true');
    }
    return this.renewToken().pipe(
      switchMap(() => this.http.get(url, { ...this.options, params: params })),
      take(1)
    );
    // .toPromise();
  }

  public getMeasurementPointsForMap(
    accountId: number = 0,
    includeRetired: boolean = false
  ): Promise<any> {
    const url = `${this.endpoint}/measurementPoints/map`;
    let params = new HttpParams();

    if (accountId && accountId > 0) {
      params = params.set('accountId', accountId.toString());
    }

    if (includeRetired) {
      params = params.set('includeRetired', 'true');
    }
    return this.renewToken()
      .pipe(
        switchMap(() => this.http.get(url, { ...this.options, params: params })),
        take(1)
      )
      .toPromise();
  }

  public getSite(siteId: number): Promise<any> {
    return this.renewToken()
      .pipe(
        switchMap(() => this.http.get(`${this.endpoint}/site/${siteId}`, this.options)),
        take(1)
      )
      .toPromise();
  }

  public loadUserPreferences(): Promise<any> {
    return this.renewToken()
      .pipe(
        switchMap(() => this.http.get(`${this.endpoint}/user/preferences`, this.options)),
        take(1)
      )
      .toPromise();
  }

  public saveUserPreferences(prefs, update = false): Promise<any> {
    let method = 'post';
    // TODO: figure out automatically if we're updating or creating...
    if (update) {
      method = 'put';
    }
    return this.renewToken()
      .pipe(
        switchMap(() =>
          this.http[method](`${this.endpoint}/user/preferences`, prefs, this.options)
        ),
        take(1)
      )
      .toPromise();
  }

  public getHistoricalReportsData(
    mpId = null,
    startYear = null,
    endYear = null
  ): Promise<any> {
    let params = new HttpParams();

    if (startYear) {
      params = params.set('startYear', startYear);
    }

    if (endYear) {
      params = params.set('endYear', endYear);
    }
    return this.renewToken()
      .pipe(
        switchMap(() =>
          this.http.get(
            `${this.endpoint}/historicalPowerQualitymeasures/measurementPoint/${mpId}`,
            {
              ...this.options,
              params: params,
            }
          )
        ),
        take(1)
      )
      .toPromise();
  }

  public getEventImages(eventId: number): Promise<any> {
    return this.renewToken()
      .pipe(
        switchMap(() => {
          return this.http.get(`${this.endpoint}/event/images/${eventId}`, this.options);
        }),
        take(1)
      )
      .toPromise();
  }

  public getDocumentDownload(documentId: number, ttl: number = 300): Promise<any> {
    const params = new HttpParams().set('ttl', ttl.toString());
    return this.renewToken()
      .pipe(
        switchMap(() => {
          return this.http.get(`${this.endpoint}/document/download/${documentId}`, {
            ...this.options,
            responseType: 'text',
            params: params,
          });
        }),
        take(1)
      )
      .toPromise();
  }

  public getMeasurementPoint(mpId: number): Observable<any> {
    return this.renewToken().pipe(
      switchMap(() =>
        this.http.get(`${this.endpoint}/measurementPoint/${mpId}`, this.options)
      ),
      take(1)
    );
  }

  public getSocketUrl() {
    return this.renewToken().pipe(
      switchMap((topic: any) =>
        this.http.get(`${this.endpoint}/mqtt/url`, {
          ...this.options,
          responseType: 'text',
        })
      )
    );
  }

  public getSocketTopic(accountId: number, mpId: number): Observable<any> {
    const params = new HttpParams().set(`accountId`, `${accountId}`);

    return this.renewToken().pipe(
      switchMap(() =>
        this.http.get(`${this.endpoint}/mqtt/topic/${mpId}`, {
          ...this.options,
          params: params,
          responseType: 'text',
        })
      )
    );
  }

  // start data publishing from PQube
  public initiatePQubePublish(
    channelArray: number[],
    mpId: number,
    accountId: number
  ): void {
    const params = new HttpParams().set(`accountId`, `${accountId}`);
    const endpointUrl = `${this.endpoint}/mqtt/channels/${mpId}`;
    this.renewToken()
      .pipe(
        switchMap(() =>
          this.http.post(endpointUrl, channelArray, {
            ...this.options,
            responseType: 'json',
            params,
          })
        ),
        take(1)
      )
      .subscribe();
  }

  public getMeasurementPoints(
    accountId: number,
    includeRetired: boolean,
    offset: number = 0,
    count: number = 20
  ): Observable<IMeasurementPoint[]> {
    let params = new HttpParams().set('accountId', accountId.toString());

    if (includeRetired) {
      params = params.set('includeRetired', 'true');
    }

    if (!isNaN(offset) && offset >= 0) {
      params = params.set('offset', offset.toString());
    }

    if (!isNaN(count) && count >= 0) {
      params = params.set('count', count.toString());
    }

    return this.renewToken().pipe(
      switchMap(
        () =>
          this.http.get(`${this.endpoint}/measurementPoints`, {
            ...this.options,
            params: params,
          }) as Observable<IMeasurementPoint[]>
      ),
      take(1)
    );
  }

  public requestPasswordReset(email: string): Promise<any> {
    return this.http.get(`${this.endpoint}/password/${email}`).pipe(take(1)).toPromise();
  }

  public resetPasswordWithToken(
    password: string,
    token: string
  ): Promise<ILoginResponse> {
    return this.http
      .post<ILoginResponse>(`${this.endpoint}/password/reset`, {
        token: token,
        password: password,
      })
      .pipe(take(1))
      .toPromise();
  }

  public updateUser(userUpdateParams: IUserUpdateParams, userId: number): Observable<{}> {
    return this.renewToken().pipe(
      switchMap(() =>
        this.http.put(
          `${this.endpoint}/user/${userId}`,
          {
            ...userUpdateParams,
          },
          { ...this.options }
        )
      ),
      map((data) => data),
      take(1)
    );
  }

  public forceDownload(url: string): Promise<any> {
    return this.http.get(url, { responseType: 'blob' }).pipe(take(1)).toPromise();
  }

  public getNotes(
    mpId: number,
    startDate: moment.Moment,
    endDate: moment.Moment,
    count: number = 1000
  ): Observable<INote[]> {
    let queryParam = new HttpParams().set('count', String(count));

    if (startDate) queryParam = queryParam.set('dateRangeStart', startDate.toISOString());
    if (endDate) queryParam = queryParam.set('dateRangeEnd', endDate.toISOString());
    return this.renewToken().pipe(
      switchMap(() =>
        this.http.get(`${this.endpoint}/notes/${mpId}`, {
          ...this.options,
          responseType: 'json',
          params: queryParam,
        })
      ),
      map((response) => response as INote[]),
      take(1)
    );
  }

  public getEventsNotes(
    mpId: number,
    startDate: moment.Moment,
    endDate: moment.Moment,
    count: number = 1000
  ): Observable<IEventNote[]> {
    let queryParam = new HttpParams().set('count', String(count));

    if (startDate) queryParam = queryParam.set('dateRangeStart', startDate.toISOString());
    if (endDate) queryParam = queryParam.set('dateRangeEnd', endDate.toISOString());
    return this.renewToken().pipe(
      switchMap(() =>
        this.http.get(`${this.endpoint}/notes/${mpId}`, {
          ...this.options,
          responseType: 'json',
          params: queryParam,
        })
      ),
      map((response) => response as IEventNote[]),
      take(1)
    );
  }

  public getNoteDocuments(noteId: number, count: number = 1000): Observable<string[]> {
    const params = new HttpParams().set('count', String(count));
    return this.renewToken().pipe(
      switchMap(() =>
        this.http.get(`${this.endpoint}/note/documents/${noteId}`, {
          ...this.options,
          responseType: 'json',
          params,
        })
      ),
      map((response) => response as { documents: any[]; more: boolean }),
      switchMap((response) =>
        forkJoin([
          ...response.documents.map((doc) =>
            this.http
              .get(`${this.endpoint}/document/download/${doc.documentId}`, {
                ...this.options,
                responseType: 'text',
              })
              .pipe(
                map((imgUrl) => {
                  doc.imgUrl = imgUrl;
                  return doc;
                })
              )
          ),
        ])
      ),
      map((response) => response as any[]),
      take(1)
    );
  }

  public submitNewNote(formData: FormData, mpId: number): Observable<{ id: number }> {
    const httpHeaders = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.token.hash}`
    );
    return this.renewToken().pipe(
      switchMap(() =>
        this.http.post(`${this.endpoint}/note/${mpId}`, formData, {
          ...this.options,
          headers: httpHeaders,
        })
      ),
      map((response) => response as { id: number }),
      take(1)
    );
  }

  public updateNote(formData: FormData, mpId: number): Observable<{ status: string }> {
    const httpHeaders = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.token.hash}`
    );
    return this.renewToken().pipe(
      switchMap(() =>
        this.http.patch(`${this.endpoint}/note/${mpId}`, formData, {
          ...this.options,
          headers: httpHeaders,
        })
      ),
      map((response) => response as { status: string }),
      take(1)
    );
  }

  public deleteNote(noteId: number): Observable<{ status: string }> {
    return this.renewToken().pipe(
      switchMap(() =>
        this.http.delete(`${this.endpoint}/note/${noteId}`, { ...this.options })
      ),
      map((response) => response as { status: string }),
      take(1)
    );
  }

  public getAccountUsersList(accountId: string): Observable<any> {
    return this.renewToken().pipe(
      switchMap(
        () =>
          this.http.get(`${this.endpoint}/users`, {
            ...this.options,
            params: {
              accountId: accountId,
              includeRetired: 'false',
              ascColumns: 'false',
              count: '100',
            },
          }) as Observable<any>
      ),
      take(1)
    );
  }

  public addUser(addUserParams: IUserUpdateParams): Observable<{ id: number }> {
    return this.renewToken().pipe(
      switchMap(() =>
        this.http.post(`${this.endpoint}/user`, { ...addUserParams }, { ...this.options })
      ),
      map((response) => response as { id: number }),
      take(1)
    );
  }

  public resendActivationEmail(
    userId: number,
    accountId: number
  ): Observable<{ id: number }> {
    return this.renewToken().pipe(
      switchMap(() =>
        this.http.get(`${this.endpoint}/user/resend/${userId}`, {
          ...this.options,
          params: new HttpParams().set('accountId', accountId.toString()),
        })
      ),
      map((response) => response as { id: number }),
      take(1)
    );
  }

  public getEnergyMeasure(
    start: Moment,
    end: Moment,
    mp: string
  ): Observable<IEnergyMeasure> {
    let params = new HttpParams().set('dateRangeStart', start.toISOString());
    params = params.set('dateRangeEnd', end.toISOString());
    return this.renewToken().pipe(
      switchMap(() =>
        this.http.get(`${this.endpoint}/energy/measurementPoint/${mp}`, {
          ...this.options,
          params: {
            dateRangeStart: start.toISOString(),
            dateRangeEnd: end.toISOString(),
          },
        })
      ),
      map((response) => response as IEnergyMeasure),
      take(1)
    );
  }

  public getMonthlyReportsPerYear(
    mp: number,
    year: number
  ): Observable<Array<IMonthlyReport>> {
    return this.renewToken().pipe(
      switchMap(() =>
        this.http.get(`${this.endpoint}/monthlyReports/${mp}/${year}`, {
          ...this.options,
        })
      ),
      map((response) => response as Array<IMonthlyReport>),
      take(1)
    );
  }

  public getMonthlyReportsOrCreate(
    mp: number,
    year: number,
    month: number,
    create: string
  ): Observable<IMonthlyReport> {
    return this.renewToken().pipe(
      switchMap(() =>
        this.http.get(`${this.endpoint}/monthlyReports/${mp}/${year}/${month}`, {
          ...this.options,
          params: { createIfNotExists: create },
        })
      ),
      map((response) => response as IMonthlyReport),
      take(1)
    );
  }

  public actionReport(report, type: string) {
    let action: string;
    if (type === 'save') {
      action = '';
    } else {
      action = '/' + type;
    }
    return this.renewToken().pipe(
      switchMap(() =>
        this.http.patch(
          `${this.endpoint}/monthlyReports/${report.id}${action}`,
          { ...report },
          { ...this.options }
        )
      ),
      map((response) => response as any),
      take(1)
    );
  }

  public publishReport(report) {
    return this.renewToken().pipe(
      switchMap(() =>
        this.http.patch(
          `${this.endpoint}/monthlyReports/${report.id}/publish`,
          { ...report },
          { ...this.options }
        )
      ),
      map((response) => response as any),
      take(1)
    );
  }

  public archiveReport(report) {
    return this.renewToken().pipe(
      switchMap(() =>
        this.http.patch(
          `${this.endpoint}/monthlyReports/${report.id}/archive`,
          { ...report },
          { ...this.options }
        )
      ),
      map((response) => response as any),
      take(1)
    );
  }

  public registerUserAccount(userAccount): Observable<any> {
    return this.http.post(`${this.endpoint}/registerAccount`, userAccount).pipe(take(1));
  }

  public getEnvironmentSettings(settingName: string): Observable<any> {
    return this.http
      .get(`${this.endpoint}/environmentSettings`, {
        params: { settingName: settingName },
      })
      .pipe(take(1));
  }

  public publishDocuments(formData: FormData): Observable<any> {
    return this.http
      .post(`${this.endpoint}/monthlyReportDocuments`, formData, {
        ...{
          headers: new HttpHeaders().set('Authorization', `Bearer ${this.token.hash}`),
        },
      })
      .pipe(take(1));
  }

  public createSiteMeasurementPoint(
    accountId: number,
    siteMeasurementPoint: ISiteMeasurementPoint
  ): Observable<any> {
    return this.renewToken().pipe(
      switchMap(() =>
        this.http.post(`${this.endpoint}/site/measurementPoint`, siteMeasurementPoint, {
          ...this.options,
          params: { accountId: accountId.toString() },
        })
      ),
      map((data) => data),
      take(1)
    );
  }

  public updateSiteMeasurementPoint(
    accountId: number,
    siteId: number,
    measurementPointId: number,
    siteMeasurementPoint: ISiteMeasurementPoint
  ): Observable<any> {
    return this.renewToken().pipe(
      switchMap(() =>
        this.http.patch(
          `${this.endpoint}/site/${siteId}/measurementPoint/${measurementPointId}`,
          siteMeasurementPoint,
          {
            ...this.options,
            params: { accountId: accountId.toString() },
          }
        )
      ),
      map((data) => data),
      take(1)
    );
  }

  public countMeasurementPointsByAccount(accountId: number): Observable<any> {
    return this.renewToken().pipe(
      switchMap(() =>
        this.http.get(`${this.endpoint}/measurementPoints/countByAccount`, {
          ...this.options,
          params: { accountId: accountId.toString() },
        })
      ),
      map((response) => response),
      take(1)
    );
  }

  public getChannelDefinition(mpId: string): Observable<Array<any>> {
    return this.renewToken().pipe(
      switchMap(() =>
        this.http.get(`${this.endpoint}/channelDefinition/${mpId}`, {
          ...this.options,
        })
      ),
      map((response) => response as Array<any>),
      take(1)
    );
  }
}
