import { HttpClient } from '@angular/common/http';
import { MatListModule } from '@angular/material/list';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { QuillModule } from 'ngx-quill';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardGraphComponent } from './dashboard/dashboard-graph/dashboard-graph.component';
import { LoginComponent } from './login/login.component';
import { ChartsComponent } from './charts/charts.component';
import { ChartSetComponent } from './charts/chart-set/chart-set.component';
import { ReportsComponent } from './reports/reports.component';
import { FleetComponent } from './fleet/fleet.component';
import { FormatDatePipe } from './shared/pipes/format-date.pipe';
import { EventGraphComponent } from './event-graph/event-graph.component';
import { MoreInformationComponent } from './more-information/more-information.component';
import { ProfileComponent } from './profile/profile.component';
import { ActivationComponent } from './activation/activation.component';
import { NotesComponent } from './notes/notes.component';
import { EnergyPowerComponent } from './energy-power/energy-power.component';
import { EnergyPowerChartComponent } from './energy-power/energy-power-chart/energy-power-chart.component';
import packageJson from '../../package.json';
import { MonthlyReportComponent } from './monthly-report/monthly-report.component';
import { FleetPartnerComponent } from './fleet/fleet-partner/fleet-partner.component';
import { FleetCustomerComponent } from './fleet/fleet-customer/fleet-customer.component';
import { EventsListComponent } from './events-list/events-list.component';
import { MapViewComponent } from './map-view/map-view.component';
import { RegisterAccountComponent } from './register-account/register-account.component';
import { MapPopupComponent } from './map-view/map-popup/map-popup.component';
import { MapPanelComponent } from './map-view/map-panel/map-panel.component';
import { MapComponent } from './map-view/map/map.component';
import { CreateMPComponent } from './create-mp/create-mp.component';
import { MapTooltipComponent } from './map-view/map-tooltip/map-tooltip.component';
import { InitProvider } from './shared/provider/init.provider';
import { HierarchyEffects } from './_store/_hierarchy/effects/hierarchy.effects';
import { metaReducers, reducers } from './_store/_reducers';
import { ChannelsEffects } from './_store/_channels/effects/channels.effects';
import { AgmCoreModule } from '@agm/core';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    './assets/i18n/',
    '.json?no-cache=' + packageJson.version
  );
}

export function initProviderFactory(provider: InitProvider) {
  return () => provider.load();
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    DashboardGraphComponent,
    LoginComponent,
    ChartsComponent,
    ChartSetComponent,
    ReportsComponent,
    FleetComponent,
    FormatDatePipe,
    EventGraphComponent,
    MoreInformationComponent,
    ProfileComponent,
    ActivationComponent,
    NotesComponent,
    EnergyPowerComponent,
    EnergyPowerChartComponent,
    MonthlyReportComponent,
    FleetPartnerComponent,
    FleetCustomerComponent,
    EventsListComponent,
    MapViewComponent,
    RegisterAccountComponent,
    MapPopupComponent,
    MapPanelComponent,
    MapComponent,
    MapTooltipComponent,
    CreateMPComponent,
  ],
  imports: [
    HighchartsChartModule,
    LeafletModule,
    LeafletMarkerClusterModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    SharedModule.forRoot(),
    QuillModule.forRoot(),
    MatListModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({
      name: 'NgRx In Site Store App',
    }),
    EffectsModule.forRoot([HierarchyEffects, ChannelsEffects]),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDueiQtC_vAy0Nj8Osz9196Rx5-TfYnWOA',
      libraries: ['places']
    }),
  ],
  providers: [
    TranslateService,
    InitProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: initProviderFactory,
      deps: [InitProvider],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
