<section>
    <h1 class="title">
        {{ 'manageMembers.deleteMember' | translate }}
    </h1>
    <button mat-icon-button class="close-button" type="button">
        <mat-icon [svgIcon]="'close'" (click)="closeDialog()"></mat-icon>
    </button>
    <h4 class="account-info">
        {{ accountName }}
    </h4>
    <div class="confirm-message">
        <div class="delete-name">
            {{ member.firstName }} {{member.lastName}}
        </div>
        <div>
            {{ 'manageMembers.deleteConfirm' | translate }}
        </div>
        <div class="member-button">
            <button mat-flat-button color="accent" type="button" (click)="deleteMember()">{{ 'global.delete' | translate }}</button>
            <button mat-flat-button type="button" (click)="closeDialog()">{{ 'global.cancel' | translate }}</button>
        </div>
    </div>
</section>