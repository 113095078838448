import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { AuthService } from '../services';
import { IUser } from '../classes/user';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
})
export class AppLayoutComponent implements OnInit, OnDestroy {
  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.authService.user.pipe(take(1)).subscribe(async (user: IUser) => {
      if (!user) {
        this.router.navigate(['/login']);
      }
    });
  }

  ngOnDestroy() {}
}
