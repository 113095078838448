import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { take } from 'rxjs/operators';
import * as Highcharts from 'highcharts/highstock';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material/table';
import { Location } from '@angular/common';
import Quill from 'quill';
import ImageResize from 'quill-image-resize-module';
Quill.register('modules/imageResize', ImageResize);
import { Subscription } from 'rxjs';

import { ReportsService } from './../shared/services/reports.service';
import { EnergyPowerService } from './../shared/services/energy-power.service';
import { GraphManagerService } from '../shared/services/graph-manager.service';
import { ISeriesOptions } from '../shared/classes/chart.interface';
import { MeasurementPointsService } from '../shared/services/measurement-points.service';
import { tabIconsConfig } from '../shared/config/tab-icons.config';
import { MonthlyReportsService } from './../shared/services/monthly-reports.service';
import { DashboardService } from './../shared/services/dashboard.service';
import { IEnergyMeasure } from '../shared/classes/energyMeasure.interface';
import { NotesService } from '../shared/services/notes.service';
import { MonthlyReportChartOptions } from '../shared/chart-options/monthly-report-chart-options';
import { NotificationsService } from '../shared/modules/notifications/shared/notifications.service';
import { MonthsNumberToStringPipe } from '../shared/pipes/months-number-to-string.pipe';
import { IAccount } from '../shared/classes/account';
import { IUser } from '../shared/classes/user';
import { IMonthlyReport } from '../shared/classes/monthly-report.interface';
import { TokenService } from '../shared/services';
import { AuthService } from './../shared/services/auth.service';
import { environment } from 'src/environments/environment';
import * as fromUser from './../_store/_reducers';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '../shared/classes/component-destroy.class';

@Component({
  selector: 'app-monthly-report',
  templateUrl: './monthly-report.component.html',
  styleUrls: ['./monthly-report.component.scss'],
})
export class MonthlyReportComponent extends OnDestroyMixin implements OnDestroy, OnInit {
  public year: number;
  public month: number;
  public minYear: number;
  public maxYear: number;
  public minMonth: number;
  public maxMonth: number;
  public mpId: number;
  public monthYearLabel: string;
  public monthlyReportForm: FormGroup;
  public showToolbar: boolean;
  public previousIndex;
  public followingIndex;
  public maxDemandTz: string;
  public siteInformationQuillEditorRef: Quill;
  public recommendationQuillEditorRef: Quill;
  public chartInstance: Highcharts.Chart;
  public updateFlag = false;
  public oneToOneFlag = true;
  public Highcharts = Highcharts;
  public chartConstructor = 'stockChart';
  public chartSetName = 'Dashboard Chart';
  public isPublish = false;
  public isArchive = false;
  private reportsList: Array<{ status }>;
  private account: IAccount;
  chartReady = false;
  mpTimezone;
  accountId;
  info;
  load;
  loaded;
  startDate;
  endDate;
  eventsListSource;
  eventsList;
  energyMeasure: IEnergyMeasure;
  reportData;
  tz;
  notesListSource;
  powerQuality;
  public isPartner: boolean;
  public loggingInfo;
  public powerQAvailable = false;
  public voltageSagCount: number;
  public voltageSwellCount: number;
  public highFrequencyImpulseCount: number;
  public interruptionCount: number;
  public tabIcons = tabIconsConfig;
  private userSub$: Subscription;
  private isPartnerSub$: Subscription;
  public tabs = [
    'powerMonthToDate',
    'powerFactorMonthToDate',
    'voltageFluctuationsMonthToDate',
    'imbalanceMonthToDate',
    'harmonicsMonthToDate',
    'groundCurrentMonthToDate',
  ];
  public displayedColumnsEvents: string[] = [
    'date',
    'time',
    'type',
    'duration',
    'magnitude',
  ];
  public displayedColumnsNotes: string[] = ['date', 'time', 'summary', 'details'];

  public queryParams: ParamMap;
  public chartOptions: any = MonthlyReportChartOptions;

  public onChartComplete = (chart) => {
    this.chartInstance = chart;
    // tslint:disable-next-line: semicolon
  };

  constructor(
    private graphManager: GraphManagerService,
    private route: ActivatedRoute,
    private mpService: MeasurementPointsService,
    private dashboardService: DashboardService,
    private energyPowerService: EnergyPowerService,
    private monthlyReportService: MonthlyReportsService,
    private notesService: NotesService,
    private reportsService: ReportsService,
    private router: Router,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private monthsNumberToStringPipe: MonthsNumberToStringPipe,
    private location: Location,
    public token: TokenService,
    private store: Store<fromUser.State>
  ) {
    super();
    this.monthlyReportForm = new FormGroup({
      siteInformation: new FormControl('', []),
      recommendations: new FormControl('', []),
    });
  }

  ngOnInit() {
    this.isPartnerSub$ = this.store
      .pipe(select(fromUser.getIsPartner))
      .subscribe((isPartner: number) => {
        this.isPartner = isPartner === 1;
      });

    this.route.queryParams
      .pipe(untilComponentDestroyed(this))
      .subscribe((params: { mpId; accountId }) => {
        if (params && this.mpId && parseInt(params.mpId, 10) !== this.mpId) {
          this.router.navigate(['reports']);
        }
        this.mpId = parseInt(this.route.snapshot.paramMap.get('mpId'), 10);
        this.year = parseInt(this.route.snapshot.paramMap.get('year'), 10);
        this.month = parseInt(this.route.snapshot.paramMap.get('month'), 10);
        if (this.month > 11 || this.month < 0) {
          this.router.navigate(['reports']);
        }
      });

    this.loadReportData();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.userSub$) {
      this.userSub$.unsubscribe();
    }
    this.isPartnerSub$.unsubscribe();
  }

  private loadReportData(): void {
    this.chartReady = false;
    this.mpService
      .refreshMeasurementPoint(this.accountId, this.mpId)
      .then(async (response) => {
        this.minYear = moment(response.createdWhen).year();
        this.minMonth = moment(response.createdWhen).month();
        this.maxMonth = moment().month();
        this.maxYear = moment().year();

        const temp = cloneDeep(this.mpService.selectedMeasurementPoint);

        this.monthlyReportService
          .getMonthlyReportsOrCreate(this.mpId, this.year, this.month + 1, 'true')
          .pipe(take(1))
          .subscribe((res) => {
            this.isPublish = res.status.name === 'Published';
            this.isArchive = res.status.name === 'Archived';

            if (
              !this.isPartner &&
              !this.isPublish &&
              !this.isArchive &&
              this.token.metaData.isSystemAdministrator !== '1'
            ) {
              this.router.navigate(['reports']);
            }
            this.loggingInfo = res;
            this.monthlyReportForm.patchValue({
              siteInformation: this.loggingInfo.siteInformation,
              recommendations: this.loggingInfo.recommendations,
            });

            if (Object.keys(this.loggingInfo.status).length === 0) {
              this.userSub$ = this.store
                .pipe(select(fromUser.getUser))
                .subscribe((user: IUser) => {
                  this.loggingInfo.createdBy = {
                    lastName: user.lastName,
                    firstName: user.firstName,
                  };
                });

              this.loggingInfo.status = {
                name: 'Draft',
              };
            }
          });

        this.accountId = parseInt(this.route.snapshot.paramMap.get('accountId'), 10);

        this.startDate = moment([this.year, this.month]);
        this.endDate = moment([this.year, this.month]).endOf('month');
        this.mpTimezone = this.mpService.selectedMeasurementPoint.timezone;
        const test = moment().tz(this.mpTimezone);
        this.tz = test.format('z');
        this.graphManager.resetChartSet(this.chartSetName);
        this.graphManager.setStartDateBound(moment([this.year, this.month]).valueOf());
        this.graphManager.updateGranularity('hour', 'months', 1);
        this.graphManager.dateRangeEnd = moment([this.year, this.month]).endOf('month');
        this.graphManager.newChartSet(this.chartSetName, [
          'dashboard',
          'notes',
          'pqEvents',
        ]);
        this.graphManager
          .updateChartSet(this.chartSetName)
          .pipe(take(1))
          .subscribe((seriesSet: ISeriesOptions[][]) => {
            this.chartOptions.xAxis[0].min = this.graphManager.dateRangeStart.valueOf();
            this.chartOptions.xAxis[0].max = this.graphManager.dateRangeEnd.valueOf();
            seriesSet.forEach((series) => {
              series.forEach((dataSeries) => {
                this.chartOptions.series.push(dataSeries);
              });
            });
            const tempsMonth = this.monthsNumberToStringPipe.transform(this.month);
            this.monthYearLabel = '(' + tempsMonth + ' ' + this.year + ')';
            this.chartOptions.title.text = 'Dashboard ' + this.monthYearLabel;

            this.chartReady = true;
          });

        this.dashboardService
          .getDashboardInfo(this.accountId, this.mpId, this.startDate, this.endDate)
          .then(
            (info) => {
              if (info.powerQualityMeasures.errno) {
                return;
              }

              if (info.powerQualityMeasures.lastCommunication.value) {
                info.powerQualityMeasures.lastCommunication.value = moment(
                  info.powerQualityMeasures.lastCommunication.value,
                  'YYYY-MM-DDTHH:mm:SSSZ'
                )
                  .tz(this.mpService.selectedMeasurementPoint.timezone)
                  .format('MMMM D, YYYY h:mm A');
              }
              this.info = info;
              this.load = true;
              this.loaded = true;
              this.voltageSagCount = this.countEvents('voltageSag');
              this.voltageSwellCount = this.countEvents('voltageSwell');
              this.highFrequencyImpulseCount = this.countEvents('highFrequencyImpulse');
              this.interruptionCount = this.countEvents('interruption');
            },
            (error) => {
              this.load = false;
              this.loaded = true;
              console.log('error retrieving dashboard info', error);
            }
          );

        this.energyPowerService
          .getEnergyMeasure(this.startDate, this.endDate, this.mpId.toString())
          .pipe(take(1))
          .subscribe((energyMeasure: IEnergyMeasure) => {
            this.energyMeasure = energyMeasure;
            if (this.energyMeasure.dateTimeOfMaxActivePowerDemand) {
              this.energyMeasure.dateTimeOfMaxActivePowerDemand = moment(
                this.energyMeasure.dateTimeOfMaxActivePowerDemand
              ).tz(this.mpTimezone);
              this.maxDemandTz = this.energyMeasure.dateTimeOfMaxActivePowerDemand.format(
                'z'
              );
            }
          });

        this.mpService
          .getEventsData(
            this.mpId.toString(),
            this.accountId,
            this.startDate,
            this.endDate
          )
          .pipe(take(1))
          .subscribe((eventsList) => {
            this.eventsListSource = new MatTableDataSource<any>(eventsList);
          });

        this.notesService
          .getNotes(this.mpId, this.startDate, this.endDate)
          .pipe(take(1))
          .subscribe((notesList) => {
            this.notesListSource = new MatTableDataSource<any>(notesList);
          });

        this.reportData = await this.reportsService.getHistoricalReportsData(
          this.mpId,
          this.year
        );
        this.powerQuality = this.reportData[this.month].powerQualityMeasurements;
        this.powerQAvailable = true;

        this.monthlyReportService.setReportList(
          this.accountId,
          this.mpId,
          this.year,
          this.isPartner
        );

        this.monthlyReportService.reportsList.pipe(take(2)).subscribe((reportsList) => {
          this.reportsList = reportsList;
          if (!this.isPartner && this.token.metaData.isSystemAdministrator !== '1') {
            this.checkPublishReport();
          }
        });
      });
  }

  public navigate(num): void {
    if (this.isPartner || this.token.metaData.isSystemAdministrator === '1') {
      if (this.month === 0 && num < 0) {
        this.month = 11;
        this.year--;
      } else if (this.month === 11 && num > 0) {
        this.month = 0;
        this.year++;
      } else {
        this.month += num;
      }
    } else {
      if (num < 0) {
        if (this.previousIndex > this.month) {
          this.year--;
        }
        this.month = this.previousIndex;
      } else if (num > 0) {
        if (this.followingIndex < this.month) {
          this.year++;
        }
        this.month = this.followingIndex;
      }
    }

    const url = this.router
      .createUrlTree([
        '/monthly-report',
        this.accountId,
        this.mpId,
        this.year,
        this.month,
      ])
      .toString();

    this.location.go(url);

    this.loadReportData();
  }

  public onActionReport(form, type: string, notify: boolean) {
    this.loggingInfo.siteInformation = form.siteInformation;
    this.loggingInfo.recommendations = form.recommendations;

    if (this.loggingInfo.siteInformation === null) {
      this.loggingInfo.siteInformation = '';
    }
    if (this.loggingInfo.recommendations === null) {
      this.loggingInfo.recommendations = '';
    }
    if (this.loggingInfo.modifiedWhen === null) {
      this.loggingInfo.modifiedWhen = '';
    }

    if (this.loggingInfo.createdBy.middleName === null) {
      this.loggingInfo.createdBy.middleName = '';
    }

    if (this.loggingInfo.modifiedBy.middleName === null) {
      this.loggingInfo.modifiedBy.middleName = '';
    }
    this.monthlyReportService
      .actionReport(this.loggingInfo, type)
      .pipe(take(1))
      .subscribe(
        (result) => {
          if (notify) {
            if (type === 'save') {
              this.monthlyReportForm.markAsPristine();
              this.notificationsService.notify(
                this.translateService.instant('monthlyReport.reportSaved')
              );
            } else if (type === 'publish') {
              this.loggingInfo = result;
              this.notificationsService.notify(
                this.translateService.instant('monthlyReport.reportPublish')
              );
              this.isPublish = true;
            } else if (type === 'archive') {
              this.loggingInfo = result;
              this.notificationsService.notify(
                this.translateService.instant('monthlyReport.reportArchive')
              );
              this.isArchive = true;
            }
          }
        },
        (error) => {
          this.notificationsService.notify(
            this.translateService.instant('monthlyReport.reportError')
          );
        }
      );
  }

  public getEditorInstance(editorInstance: Quill, index: number): void {
    if (index === 1) {
      this.siteInformationQuillEditorRef = editorInstance;
    } else {
      this.recommendationQuillEditorRef = editorInstance;
    }
    const toolbar = editorInstance.getModule('toolbar');
    toolbar.addHandler('image', this.imageHandler);
  }

  imageHandler = () => {
    let fileUploadInput = document.getElementById('fileUpload') as HTMLInputElement;
    if (!fileUploadInput) {
      fileUploadInput = document.createElement('input');
      fileUploadInput.id = 'fileUpload';
      fileUploadInput.setAttribute('type', 'file');
      fileUploadInput.setAttribute('accept', 'image/*');
      fileUploadInput.onchange = async () => {
        let file: File;
        file = fileUploadInput.files[0];
        const fileSize = file.size / (1024 * 1024);
        if (/\.(gif|jpe?g|png|GIF|JPE?G|PNG)$/.test(file.name) && fileSize <= 15) {
          this.saveToServer(file);
        } else if (fileSize > 15) {
          alert('Image size greater than 15MB cannot be uploaded');
        }
      };
    }
    fileUploadInput.click();
    // tslint:disable-next-line: semicolon
  };

  saveToServer = async (file: File) => {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('monthlyReportId', this.loggingInfo.id);
    formData.append('documentTypeId', '2');
    this.monthlyReportService
      .publishDocuments(formData)
      .pipe(take(1))
      .subscribe((result) => {
        const imageUrl = `${environment.psl.diligent_endpoint}/monthlyReportDocuments/public/${result.documentGuid}`;
        let range = this.siteInformationQuillEditorRef.getSelection();
        let activeQuill: Quill;
        activeQuill = this.siteInformationQuillEditorRef;
        if (!range) {
          range = this.recommendationQuillEditorRef.getSelection();
          activeQuill = this.recommendationQuillEditorRef;
        }
        activeQuill.insertEmbed(range.index, 'image', imageUrl);
        activeQuill.setSelection(range.index + 1, Quill.sources.SILENT);
        activeQuill.insertText(range.index + 1, '\n', 'user');
      });
    // tslint:disable-next-line: semicolon
  };

  private countEvents(type) {
    if (!this.info || !this.info.measurementPointEvents) {
      return 0;
    }

    return this.info.measurementPointEvents.filter((mp) => {
      return mp.deviceEventType === type && mp.isSevere === 1;
    }).length;
  }

  public checkPublishReport() {
    this.previousIndex = -1;
    this.followingIndex = -1;
    const tempBefore = this.reportsList.slice(0, this.month);
    const tempAfter = this.reportsList.slice(
      this.month + 1,
      this.year === this.maxYear ? this.maxMonth : 11
    );
    this.previousIndex =
      tempBefore.map((report) => report.status).indexOf(2) >
      tempBefore.map((report) => report.status).indexOf(3)
        ? tempBefore.map((report) => report.status).lastIndexOf(2)
        : tempBefore.map((report) => report.status).lastIndexOf(3);
    this.followingIndex =
      tempAfter.map((report) => report.status).indexOf(2) >
      tempAfter.map((report) => report.status).indexOf(3)
        ? tempAfter.map((report) => report.status).indexOf(2)
        : tempAfter.map((report) => report.status).indexOf(3);

    if (this.previousIndex === -1 && this.year !== this.minYear) {
      this.monthlyReportService
        .getMonthlyReportsPerYear(this.mpId, this.year - 1)
        .pipe(take(1))
        .subscribe((reports: Array<IMonthlyReport>) => {
          const temp = reports
            .filter((report) => {
              if (report.status.id === 2 || report.status.id === 3) {
                return report;
              }
            })
            .map((report) => report.reportMonth);
          this.previousIndex = Math.max(...temp) - 1;
        });
    }

    if (this.followingIndex === -1 && this.year !== this.maxYear) {
      this.monthlyReportService
        .getMonthlyReportsPerYear(this.mpId, this.year + 1)
        .pipe(take(1))
        .subscribe((reports: Array<IMonthlyReport>) => {
          const temp = reports
            .filter((report) => {
              if (report.status.id === 2 || report.status.id === 3) {
                return report;
              }
            })
            .map((report) => report.reportMonth);
          this.followingIndex = Math.min(...temp) - 1;
        });
    } else if (this.followingIndex !== -1) {
      this.followingIndex += this.month + 1;
    }
  }
}
