import { ReplaySubject, pipe } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { DiligentApiService } from './diligent-api.service';

@Injectable()
export class UtilitiesService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private apiService: DiligentApiService
  ) {
  }

  generate_uuid(len = 40) {
    const arr = new Uint8Array(len / 2);

    window.crypto.getRandomValues(arr);
    return Array.from(arr, this.dec2hex).join('');
  }

  dec2hex(dec) {
    return ('0' + dec.toString(16)).substr(-2);
  }

  forceDownload(url: string): Promise<any>{
    return new Promise((resolve, reject) => {
      this.apiService.forceDownload(url).then(
        (response: any) => {
          // split up the URL so we can get the filename out of it
          const urlParts = url.split('?').shift().split('/');

          // get the filename and replace '.gzip' with nothing so the file
          // can be opened on a computer
          const filename = urlParts.pop().replace('.gzip', '');

          // IE is not good
          if (navigator.appVersion.toString().indexOf('.NET') > 0) {
              window.navigator.msSaveBlob(response, filename);
              return;
          }

          // make a URL object out of the blob data
          const wUrl = window.URL.createObjectURL(response);

          // we need to make a link and 'click' it to force a download
          const dlLink = document.createElement('a');

          // nobody should see the link
          dlLink.style.display = 'none';

          // set some attributes on the link so it'll download the file
          dlLink.href = wUrl;
          dlLink.download = filename;

          // 'click' the link
          dlLink.click();

          // wait a moment and revoke the URL object
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
          });

          // all done
          resolve();
        },
        (error: any) => {
          reject(error);
        }
      );

    });

  }
}

// RxJs pipeable operator for subscribing until component fires onDestroy
export function takeUntilComponentDestroyed(component: OnDestroy) {
  const componentDestroyed = (comp: OnDestroy) => {
    const oldNgOnDestroy = comp.ngOnDestroy;
    const destroyed$ = new ReplaySubject<void>(1);
    comp.ngOnDestroy = () => {
      oldNgOnDestroy.apply(comp);
      destroyed$.next(undefined);
      destroyed$.complete();
    };
    return destroyed$;
  };

  return pipe(
    takeUntil(componentDestroyed(component))
  );
}