<app-d-chart-navigator
  #chartNavigator
  [currentScope]="scope"
  [currentChartSetName]="chartSetName"
  (scopeChange)="updateChart($event)"
  [absoluteStartDate]="absoluteStartDate"
  [type]="type"
  [haveFutur]="haveFutur"
  [havePast]="havePast"
></app-d-chart-navigator>
<input
  class="datepicker-input"
  [matDatepicker]="picker"
  [formControl]="dateControl"
  [min]="absoluteStartDate"
  [max]="maxDate"
  (dateChange)="updateDate($event)"
/>
<mat-datepicker
  [startView]="startView"
  #picker
  (yearSelected)="chosenYearHandler($event, picker)"
  (monthSelected)="chosenMonthHandler($event, picker)"
>
</mat-datepicker>
<button mat-icon-button (click)="picker.open()">
  <mat-icon svgIcon="calendar"></mat-icon>
</button>