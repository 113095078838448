import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { timer, of } from 'rxjs';
import { switchMap, take, map, filter } from 'rxjs/operators';

import { TokenService, AuthService } from './services';
import { environment } from 'src/environments/environment';
import { AutoLogoutComponent } from './components/auto-logout/auto-logout.component';
import { MeasurementPointsService } from './services/measurement-points.service';

@Injectable()
export class AutoLogoutResolver implements Resolve<Promise<boolean>> {
  private activeDialog: MatDialogRef<AutoLogoutComponent>;

  constructor(
    private token: TokenService,
    private auth: AuthService,
    private dialog: MatDialog
  ) {
    // wait 1s, then poll the Auth observable every 2 seconds
    timer(1000, 2000)
      .pipe(
        switchMap(() => this.auth.authed.pipe(take(1))),
        switchMap((payload) => {
          // if we have a payload we're still authed, but only show expiration it we also have a token hash (previously-authed)
          return of(payload ? false : !!this.token.hash);
        }),
        map((expired) => {
          if (expired) return 1;

          const lastAccess = this.token.lastAccessedMs || Date.now();
          const isInactive =
            Math.floor((Date.now() - lastAccess) / 1000) >
            environment.psl.inactivity_timeout;
          const isAlmostInactive =
            Math.floor((Date.now() - lastAccess) / 1000) >
            environment.psl.inactivity_timeout -
              environment.psl.inactivity_warning_threshold;

          if (isInactive) return 1;
          else if (isAlmostInactive) return -1;
          else return 0;
        }),
        filter(() => {
          // don't keep emitting if there's an activeDialog that isn't the warning (which can expire)
          return (
            !this.activeDialog ||
            (this.activeDialog &&
              this.activeDialog.componentInstance &&
              this.activeDialog.componentInstance.data.warn)
          );
        })
      )
      .subscribe((expired) => {
        // session expired
        if (expired === 1) {
          // warning dialog still open; close it
          if (this.activeDialog) {
            this.activeDialog.close();
          }

          this.token.expire();

          this.activeDialog = this.dialog.open(AutoLogoutComponent, {
            data: {
              warn: false,
            },
          });
          this.activeDialog.afterClosed().subscribe(() => {
            // hard url swap to force a full reload
            window.location.href = '/login';
          });
        }
        // session *almost* expired
        else if (expired === -1) {
          if (this.activeDialog) return;

          this.activeDialog = this.dialog.open(AutoLogoutComponent, {
            data: {
              warn: true,
            },
          });
          this.activeDialog.afterClosed().subscribe((result) => {
            if (result) {
              this.token.touch();
            } else if (result === false) {
              this.token.expire();
              window.location.href = '/login';
            }
            this.activeDialog = null;
          });
        }
      });
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.token.touch();
    return Promise.resolve(true);
  }
}

@Injectable()
export class PreferencesResolver implements Resolve<Promise<any>> {
  constructor(private auth: AuthService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.auth.hasPref) {
      return this.auth.loadPreferences();
    } else {
      return Promise.resolve(true);
    }
  }
}

@Injectable()
export class MeasurementPointResolver implements Resolve<Promise<any>> {
  constructor(
    private mpService: MeasurementPointsService,
    private authService: AuthService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (
      (route.queryParams.account && route.queryParams.mpId) ||
      (route.params.accountId && route.params.mpId)
    ) {
      const account = route.queryParams.account
        ? route.queryParams.account
        : route.params.accountId;
      const mpId = route.queryParams.mpId ? route.queryParams.mpId : route.params.mpId;
      if (
        !this.mpService.selectedMeasurementPoint ||
        !(mpId === this.mpService.selectedMeasurementPoint.measurementPointId)
      ) {
        return this.mpService.refreshMeasurementPoint(account, parseInt(mpId, 10));
      }
      return Promise.resolve(false);
    } else {
      let accountId;
      let mpId;
      this.authService.preferences.pipe(take(1)).subscribe(async (userPrefs: any) => {
        accountId = userPrefs.accountId;
        mpId = userPrefs.mpId;
      });
      if (accountId) {
        return this.mpService.refreshMeasurementPoint(accountId, parseInt(mpId, 10));
      } else {
        return Promise.resolve(false);
      }
    }
  }
}
