import { Injectable } from '@angular/core';
import { DiligentApiService } from '.';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(
    private diligentService: DiligentApiService,
  ) { }

  public getHistoricalReportsData(mpId = null, startYear = null, endYear = null): Promise<any> {
    return this.diligentService.getHistoricalReportsData(mpId, startYear, endYear);
  }
}
