import {Component, Input, ViewEncapsulation} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-map-tooltip',
  templateUrl: './map-tooltip.component.html',
  styleUrls: ['./map-tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class MapTooltipComponent {
  @Input() measurementPoint;

  constructor(
  ) { }

  getLastLocalCommunicationsTime() {
    let lastLocalCommsTime = 'None Reported';
    const lastCommunication = this.measurementPoint.details.lastCommunication.value;
    if (lastCommunication) {
      lastLocalCommsTime = moment.utc(lastCommunication).tz(this.measurementPoint.timezone).format('YYYY-MM-DD HH:mm:ss');
    }

    return lastLocalCommsTime;
  }

  getMeasurementPointStatusName() {
    const name = this.measurementPoint.measurementPointStatusName;
    return name.replaceAll(/[A-Z]/g, (match) => ` ${match}`).replace(/^./, (match) => `${match.toLocaleUpperCase()}`);
  }
}
