<div id="createMP">
  <img src="./assets/images/psl-logo-white.svg" alt="Powerside" />

  <div class="boxed-in">
    <h1 mat-dialog-title>
      {{ 'createMP.title' | translate }}
    </h1>
    <form (submit)="createMP()" [formGroup]="createMPForm">
      <div class="fields">
        <div class="field">
          <mat-form-field appearance="fill">
            <mat-label>{{ 'site.name' | translate }}*</mat-label>
            <input matInput formControlName="siteName" data-cy="site-name" />
            <mat-error *ngIf="siteName.hasError('required')">{{
              'global.errors.fieldRequired' | translate
            }}</mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field appearance="fill">
            <mat-label
              >{{ 'measurementPoint.name' | translate }}*</mat-label
            >
            <input matInput formControlName="measurementPointName" data-cy="mp-name" />
            <mat-error *ngIf="measurementPointName.hasError('required')">{{
              'global.errors.fieldRequired' | translate
            }}</mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field appearance="fill">
            <mat-label
              >{{ 'site.street1' | translate }}*</mat-label
            >
            <input matInput formControlName="street1" data-cy="street1" (keydown.enter)="$event.preventDefault()" #search/>
            <mat-error *ngIf="street1.hasError('required')">{{
              'global.errors.fieldRequired' | translate
            }}</mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field appearance="fill">
            <mat-label>{{
              'site.street2' | translate
            }}</mat-label>
            <input matInput formControlName="street2" />
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field appearance="fill">
            <mat-label>{{ 'site.city' | translate }}*</mat-label>
            <input matInput formControlName="city" data-cy="city" />
            <mat-error *ngIf="street1.hasError('city')">{{
              'global.errors.fieldRequired' | translate
            }}</mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field appearance="fill">
            <mat-label
              >{{ 'site.state' | translate }}*</mat-label
            >
            <input matInput formControlName="state" data-cy="state" />
            <mat-error *ngIf="state.hasError('required')">{{
              'global.errors.fieldRequired' | translate
            }}</mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field appearance="fill">
            <mat-label
              >{{ 'site.zipCode' | translate }}*</mat-label
            >
            <input matInput formControlName="zipCode" data-cy="zipCode" />
            <mat-error *ngIf="zipCode.hasError('required')">{{
              'global.errors.fieldRequired' | translate
            }}</mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field appearance="fill">
            <mat-label
              >{{ 'site.country' | translate }}*</mat-label
            >
            <input matInput formControlName="country" data-cy="country"  />
            <mat-error *ngIf="country.hasError('required')">{{
              'global.errors.fieldRequired' | translate
            }}</mat-error>
          </mat-form-field>
        </div>        
      </div>
      <div class="site-info-row">
        <span>{{ 'site.siteInformation' | translate }}</span>
        <div class="site-info-editor">
          <quill-editor formControlName="siteInformation"></quill-editor>
        </div>
      </div>
      <div class="button-group">
        <button
          mat-flat-button
          color="accent"
          type="submit"
          data-cy="submit"
          [disabled]="createMPForm.invalid || loading"
        >
          {{ 'global.save' | translate }}
        </button>
        <button
          mat-flat-button
          color="accent"
          type="button"
          (mousedown)="onCancel()"
        >
          {{ 'global.cancel' | translate }}
        </button>
      </div>
    </form>
  </div>

  <div class="registration-footer">
    {{ 'login.footer' | translate }}
  </div>
</div>
