import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  Router,
} from '@angular/router';
import { of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

import { AuthService } from './services';
import * as fromUser from './../_store/_reducers';

@Injectable({
  providedIn: 'root',
})
export class AdministratorGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private store: Store<fromUser.State>
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.renewToken().pipe(
      take(1),
      // switch the stream to the auth observable
      switchMap(() =>
        this.store.pipe(select(fromUser.getUser)).pipe(
          take(1),
          // if we have a token at this point, allow navigation; otherwise return a UrlTree to the login page
          switchMap((payload) => {
            return payload.role.name === 'Administrator'
              ? of(true)
              : of(this.router.parseUrl('/profile'));
          }),
          // re-parse the token to kick off a fresh call to user/account api
          tap((p) => (p === true ? this.authService.reload() : null))
        )
      )
    );
  }
}
