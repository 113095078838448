import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-tosa',
  templateUrl: './tosa.component.html',
  styleUrls: ['./tosa.component.scss'],
})
export class TosaComponent implements OnInit {
  public tosaGroup: FormGroup;
  public tosaAccepted: boolean;

  constructor(
    public dialogRef: MatDialogRef<TosaComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    // this.tosaGroup = new FormGroup({
    //   tosaRadio: new FormControl(null, [Validators.required])
    // });

    this.tosaAccepted = this.data.tosaAccepted;
  }

  ngOnInit() {}

  completeTosa(): void {
    this.dialogRef.close(this.tosaAccepted);
  }
}
