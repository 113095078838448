import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AnonymousLayoutComponent } from './shared/anonymous-layout/anonymous-layout.component';
import { AppLayoutComponent } from './shared/app-layout/app-layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { ChartsComponent } from './charts/charts.component';
import { ReportsComponent } from './reports/reports.component';
import { FleetComponent } from './fleet/fleet.component';
import { IsAuthenticatedGuard } from './shared/route.guards';
import { EventGraphComponent } from './event-graph/event-graph.component';
import { MoreInformationComponent } from './more-information/more-information.component';
import { ProfileComponent } from './profile/profile.component';
import {
  AutoLogoutResolver,
  PreferencesResolver,
  MeasurementPointResolver,
} from './shared/route.resolvers';
import { ActivationComponent } from './activation/activation.component';
import { NotesComponent } from './notes/notes.component';
import { ManageMembersComponent } from './manage-members/manage-members.component';
import { AdministratorGuard } from './shared/administrator.guard';
import { EnergyPowerComponent } from './energy-power/energy-power.component';
import { MonthlyReportComponent } from './monthly-report/monthly-report.component';
import { MeterDisplayComponent } from './dashboard/meter-display/meter-display.component';
import { EventsListComponent } from './events-list/events-list.component';
import { MapViewComponent } from './map-view/map-view.component';
import { RegisterAccountComponent } from './register-account/register-account.component';
import { CreateMPComponent } from './create-mp/create-mp.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [IsAuthenticatedGuard],
    runGuardsAndResolvers: 'always',
    component: AppLayoutComponent,
    resolve: {
      inactivity: AutoLogoutResolver,
      preferences: PreferencesResolver,
    },
    children: [
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: DashboardComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: { measurePoint: MeasurementPointResolver },
      },
      {
        path: 'charts',
        component: ChartsComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: { measurePoint: MeasurementPointResolver },
      },
      {
        path: 'notes',
        component: NotesComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: { measurePoint: MeasurementPointResolver },
      },
      {
        path: 'energy-power',
        component: EnergyPowerComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: { measurePoint: MeasurementPointResolver },
      },
      {
        path: 'live-meters',
        component: MeterDisplayComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: { measurePoint: MeasurementPointResolver },
      },
      {
        path: 'reports',
        component: ReportsComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: { measurePoint: MeasurementPointResolver },
      },
      {
        path: 'monthly-report/:accountId/:mpId/:year/:month',
        component: MonthlyReportComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: { measurePoint: MeasurementPointResolver },
      },
      {
        path: 'fleet',
        component: FleetComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: { measurePoint: MeasurementPointResolver },
      },
      {
        path: 'map-view',
        component: MapViewComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: { measurePoint: MeasurementPointResolver },
      },
      {
        path: 'events-list',
        component: EventsListComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: { measurePoint: MeasurementPointResolver },
      },
      {
        path:
          'event-graph/:mpId/:accountId/:deviceEventId/:deviceEventTypeId/:triggeredWhen',
        component: EventGraphComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: { measurePoint: MeasurementPointResolver },
      },
      {
        path: 'more-information/:eventType',
        component: MoreInformationComponent,
      },
      { path: 'profile', component: ProfileComponent },
      {
        path: 'manage-members/:id',
        canActivate: [AdministratorGuard],
        runGuardsAndResolvers: 'always',
        component: ManageMembersComponent,
      },
    ],
  },
  {
    path: '',
    component: AnonymousLayoutComponent,
    children: [
      { path: 'register', component: ActivationComponent },
      { path: 'register-account', component: RegisterAccountComponent },
      { path: 'login', component: LoginComponent },
      { path: 'passwordRecovery', redirectTo: 'password-recovery' },
      { path: 'password-recovery', component: LoginComponent },
      { path: 'create-mp/:accountId', component: CreateMPComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
