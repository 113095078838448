import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'months',
})
export class MonthsPipe implements PipeTransform {
  transform(value: number, ...args: any[]): string {
    const monthsName = [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAY',
      'JUN',
      'JUL',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC',
    ];
    return monthsName[value];
  }
}
