import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { map, take } from 'rxjs/operators';

import { DiligentApiService } from 'src/app/shared/services';
import { IMonthlyReport } from '../classes/monthly-report.interface';
import { TrendsData } from '../classes/trendsData.interface';
import { IMeasurementPoint } from '../classes/measurementpoint.interface';
import { TokenService } from './token.service';
import { MeasurementPointsService } from './measurement-points.service';

@Injectable({
  providedIn: 'root',
})
export class MonthlyReportsService implements OnDestroy {
  private mMinYear = new BehaviorSubject<number>(null);
  public minYear = this.mMinYear.asObservable();
  private mMinMonth = new BehaviorSubject<number>(null);
  public minMonth = this.mMinMonth.asObservable();
  private mReportsList = new BehaviorSubject<Array<{ status }>>([
    { status: -1 },
    { status: -1 },
    { status: -1 },
    { status: -1 },
    { status: -1 },
    { status: -1 },
    { status: -1 },
    { status: -1 },
    { status: -1 },
    { status: -1 },
    { status: -1 },
    { status: -1 },
  ]);
  public reportsList = this.mReportsList.asObservable();

  constructor(
    private diligentApiService: DiligentApiService,
    private mpService: MeasurementPointsService,
    public token: TokenService
  ) {}

  ngOnDestroy() {}

  public setReportList(accountId, mpId, year, isPartner: boolean): void {
    const tempList = [
      { status: -1 },
      { status: -1 },
      { status: -1 },
      { status: -1 },
      { status: -1 },
      { status: -1 },
      { status: -1 },
      { status: -1 },
      { status: -1 },
      { status: -1 },
      { status: -1 },
      { status: -1 },
    ];

    this.mpService
      .getMeasurementPoints(accountId)
      .pipe(take(1))
      .subscribe((mPoints: IMeasurementPoint[]) => {
        if (Array.isArray(mPoints)) {
          const roomId = parseInt(mpId, 10);
          const mp = mPoints.find((m) => m.roomId === roomId);

          if (mp) {
            this.mMinYear.next(moment(mp.createdWhen).year());
            this.mMinMonth.next(moment(mp.createdWhen).month());
          }
        }
      });

    this.getMonthlyReportsPerYear(mpId, year)
      .pipe(take(1))
      .subscribe((reports: Array<IMonthlyReport>) => {
        let now = moment().month();
        let index = 0;
        if (year === this.mMinYear.value) {
          index = this.mMinMonth.value;
          if (year !== moment().year()) {
            now = 11;
          }
        }
        if (isPartner || this.token.metaData.isSystemAdministrator === '1') {
          for (index; index <= now; index++) {
            tempList[index].status = 0;
          }
        }
        reports.forEach((report) => {
          if (
            (!isPartner && report.status.id !== 1 && report.status.id !== 0) ||
            isPartner ||
            this.token.metaData.isSystemAdministrator === '1'
          ) {
            tempList[report.reportMonth - 1].status = report.status.id;
          } else {
            tempList[report.reportMonth - 1].status = -1;
          }
        });
        this.mReportsList.next(tempList);
      });
  }

  public getMonthlyReportsPerYear(
    mp: number,
    year: number
  ): Observable<Array<IMonthlyReport>> {
    return this.diligentApiService.getMonthlyReportsPerYear(mp, year);
  }

  public getMonthlyTrendsData(mpId: string, year: number, month: number) {
    const params = {
      startTime: moment([year, month]),
      endTime: moment([year, month]).endOf('month'),
      columns: ['c_70_avg_w', 'pc_2_avg_none'],
      interval: 1,
      output: 'json',
      period: 'hour',
      table: 'oneminute',
      writeToFile: false,
    };

    return this.diligentApiService
      .getTrendsDataJson(
        moment([year, month]),
        moment([year, month]).endOf('month'),
        'hour',
        ['c_70_avg_w', 'pc_2_avg_none'],
        mpId,
        'oneminute',
        1
      )
      .pipe(
        map((res: Array<TrendsData>) => {
          return res;
        })
      );
  }

  public getMonthlyReportsOrCreate(
    mp: number,
    year: number,
    month: number,
    create: string
  ) {
    return this.diligentApiService.getMonthlyReportsOrCreate(mp, year, month, create);
  }

  public actionReport(report, type: string) {
    return this.diligentApiService.actionReport(report, type);
  }

  public publishDocuments(report: FormData): Observable<any> {
    return this.diligentApiService.publishDocuments(report);
  }
}
