<div id="activation">
    <img src="./assets/images/psl-logo-white.svg" alt="Powerside" />

    <div class="boxed-in">
        <form (submit)="activation($event)" [formGroup]="activationForm">
            <mat-form-field appearance="fill">
                <mat-label>{{ 'activation.createPassword' | translate }} </mat-label>
                <input [type]="maskPassword ? 'password' : 'text'" matInput formControlName="password" data-cy="create-psw">
                <mat-icon class="mask-toggle" matSuffix color="accent" (click)="toggleMasking()" [svgIcon]="maskPassword ? 'eye' : 'eye-off'"></mat-icon>
                <!-- <mat-error *ngIf="password.hasError('email')">{{ 'activation.errors.password.invalid' | translate }}</mat-error> -->
                <mat-error *ngIf="password.hasError('minLength')">{{ 'activation.errors.password.minLength' | translate:{minLength: minLength} }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>{{ 'activation.confirmPassword' | translate }}</mat-label>
                <input [type]="maskPassword ? 'password' : 'text'" matInput formControlName="confirmPassword" [errorStateMatcher]="matcher" data-cy="confirm-psw">
                <mat-icon class="mask-toggle" matSuffix color="accent" (click)="toggleMasking()" [svgIcon]="maskPassword ? 'eye' : 'eye-off'"></mat-icon>
                <mat-error *ngIf="activationForm.hasError('notSame')">{{ 'activation.errors.password.mismatch' | translate }}</mat-error>

            </mat-form-field>
            <a *ngIf="!tosaAccepted" data-cy="display-tosa" mat-flat-button color="accent" (click)="displayTosa($event)">{{ 'tosa.cta' | translate }}</a>
            <p *ngIf="tosaAccepted"  class="tosa-accepted" (click)="displayTosa($event)">
                <mat-icon [svgIcon]="'check'"></mat-icon> 
                {{ 'tosa.userAgreed' | translate }}
            </p>
            <button type="submit" data-cy="activation-login" mat-flat-button color="accent" [disabled]="activationForm.invalid || loading">{{ 'login.login' | translate }} </button>
        </form>
    </div>

    <div class="activation-footer">
        {{ 'login.footer' | translate }}
    </div>
</div>