export const Voltage = [
  { name: 'Voltage L-L', factoryName: 'VoltageLL' },
  { name: 'Voltage L-N', factoryName: 'VoltageLN' },
  { name: 'Voltage NE', factoryName: 'VoltageNE' },
  { name: 'Frequency', factoryName: 'Frequency' },
  { name: 'THD-V', factoryName: 'ThdV' },
  {
    name: 'V Neg Seq unbalance',
    factoryName: 'VNegSeqUnbalance',
    dropdownName: 'Neg Seq Unbalance',
  },
  {
    name: 'V Zero Seq unbalance',
    factoryName: 'VZeroSeqUnbalance',
    dropdownName: 'Zero Seq Unbalance',
  },
  { name: 'Flicker Pinst', factoryName: 'FlickerPinst' },
  { name: 'Flicker Pst', factoryName: 'FlickerPst' },
  { name: 'Flicker Plt', factoryName: 'FlickerPlt' },
];
