import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { take } from 'rxjs/operators';

import { NotesService } from '../shared/services/notes.service';
import { NewNoteComponent } from './new-note/new-note.component';
import { INote } from '../shared/classes/note.interface';
import { DeleteNoteDialogComponent } from './delete-note-dialog/delete-note-dialog.component';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '../shared/classes/component-destroy.class';
import { MeasurementPointsService } from '../shared/services/measurement-points.service';

@Component({
  selector: 'app-notes',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public columnsToDisplay = ['startDateTime', 'summary', 'details', 'createdBy', 'icons'];
  public notesArray: MatTableDataSource<INote>;
  public defaultSortStart: 'asc' | 'desc' = 'desc';
  public selectedRowId: number;
  public timezone: string;

  constructor(
    private notes: NotesService,
    private mpService: MeasurementPointsService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
      this.route.queryParamMap
      .pipe(untilComponentDestroyed(this))
      .subscribe(async (params: ParamMap) => {
        if (
          this.mpService.selectedMeasurementPoint &&
          this.mpService.selectedMeasurementPoint.measurementPointTypeId === 1
        ) {
          this.router.navigate(['/dashboard'], {
            queryParams: {
              account: params.get('account'),
              mpId: params.get('mpId'),
            },
          });
        }
        this.timezone = this.mpService.selectedMeasurementPoint.timezone;
        this.populateTable();
    });
     
}

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private populateTable(): void {
    this.notes
      .getNotes(this.mpService.selectedMeasurementPoint.measurementPointId, null, null)
      .pipe(take(1))
      .subscribe((returnNotes) => {
        // const sortedNotes = returnNotes.sort((a, b) => moment(b.startDateTime).valueOf() - moment(a.startDateTime).valueOf());
        this.notesArray = new MatTableDataSource(returnNotes);
        this.notesArray.paginator = this.paginator;
        this.sort.sort({ id: null, start: 'desc', disableClear: false });
        this.sort.sort({
          id: 'startDateTime',
          start: 'desc',
          disableClear: false,
        });
        this.notesArray.sort = this.sort;
      });
  }

  public addNewNote(): void {
    const newNoteDialog = this.dialog.open(NewNoteComponent, {
      data: {
        existingNote: false,
      },
      maxWidth: '90vw',
      autoFocus: false,
      disableClose: true,
      restoreFocus: false,
    });

    newNoteDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.populateTable();
      }
    });
  }

  public editNote(note: INote): void {
    this.selectedRowId = note.id;

    const editNoteDialog = this.dialog.open(NewNoteComponent, {
      data: {
        existingNote: true,
        note,
      },
      disableClose: true,
      autoFocus: false,
      restoreFocus: false,
    });

    editNoteDialog
      .afterClosed()
      .pipe(untilComponentDestroyed(this))
      .subscribe((result) => {
        if (result) {
          this.populateTable();
        }

        this.selectedRowId = undefined;
      });
  }

  public deleteNote(noteId: number): void {
    this.selectedRowId = noteId;

    const deleteNoteDialog = this.dialog.open(DeleteNoteDialogComponent, {
      autoFocus: false,
      disableClose: true,
      restoreFocus: false,
    });

    deleteNoteDialog.beforeClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.notes
          .delete(noteId)
          .pipe(take(1))
          .subscribe((successful) => {
            if (successful) {
              this.notesArray.data = this.notesArray.data.filter(
                (note) => note.id !== noteId
              );
            }
          });
      }
      this.selectedRowId = undefined;
    });
  }
}
