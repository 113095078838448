export const Powers = [
  { name: 'Total Active Power', factoryName: 'TotalActivePower' },
  { name: 'Active Power', factoryName: 'ActivePower' },
  { name: 'Total Reactive Power', factoryName: 'TotalReactivePower' },
  { name: 'Reactive Power', factoryName: 'ReactivePower' },
  { name: 'Total Apparent Power', factoryName: 'TotalApparentPower' },
  { name: 'Apparent Power', factoryName: 'ApparentPower' },
  { name: 'Total Power Factor', factoryName: 'TotalPowerFactor' },
  { name: 'Power Factor', factoryName: 'PowerFactor' },
];
