export const MonthlyReportChartOptions = {
  chart: {
    style: {
      fontFamily: 'Roboto, sans-serif',
    },
    marginTop: 75,
    marginBottom: 20,
  },
  title: {
    y: 3,
    align: 'center',
    text: null,
    style: {
      fontSize: '22px',
      fontWeight: 'bold',
    },
  },
  time: {
    useUTC: true,
  },
  boost: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  xAxis: [
    {
      plotLines: [
        {
          color: '#000000',
          width: 2,
        },
      ],
      ordinal: false,
      dateTimeLabelFormats: {
        day: {
          main: '%b %d',
        },
        hour: {
          main: '%l:%M %p',
        },
        minute: {
          main: '%l:%M %p',
        },
      },
    },
  ],
  yAxis: [
    {
      labels: {
        align: 'right',
        x: -3,
      },
      title: {
        text: 'Power and Waste',
      },
      height: '75%',
      lineWidth: 2,
      resize: {
        enabled: false,
      },
    },
    {
      visible: false,
      tickPositions: [0],
      labels: {
        enabled: false,
      },
      min: 0,
      max: 0,
      height: '5%',
      top: '80%',
      lineWidth: 2,
      resize: {
        enabled: false,
      },
      offset: 0,
    },
    {
      tickPositions: [0, 1, 2, 3],
      labels: {
        enabled: false,
      },
      top: '85%',
      offset: 0,
      height: '12%',
      lineWidth: 1,
      resize: {
        enabled: false,
      },
      min: 0,
      max: 2,
      plotBands: [
        {
          acrossPanes: true,
          color: '#F0F5F7',
          from: 0,
          to: 2,
        },
      ],
    },
    {
      labels: {
        enabled: false,
      },
      opposite: false,
      top: '85%',
      height: '12%',
      lineWidth: 1,
      resize: {
        enabled: false,
      },
      min: 0,
      max: 2,
      plotBands: [
        {
          acrossPanes: true,
          color: '#F0F5F7',
          from: 0,
          to: 2,
        },
      ],
    },
  ],
  navigation: {
    buttonOptions: {
      y: -10,
    },
  },
  navigator: {
    enabled: false,
  },
  plotOptions: {
    area: {
      findNearestPointBy: 'y',
    },
    scatter: {
      tooltip: {
        shared: false,
      },
      findNearestPointBy: 'y',
      stickyTracking: true,
      dataGrouping: {
        enabled: false,
      },
      states: {
        hover: {
          enabled: true,
          halo: {
            enabled: true,
          },
        },
      },
      marker: {
        enabled: true,
      },
      point: {
        events: {
          // tslint:disable-next-line: space-before-function-paren
          mouseOver: function () {
            if (this.series.halo) {
              this.series.halo
                .attr({
                  class: 'highcharts-tracker',
                })
                .toFront();
            }
          },
        },
      },
    },
  },
  rangeSelector: {
    enabled: false,
  },
  scrollbar: {
    enabled: false,
  },
  tooltip: {
    split: true,
    xDateFormat:
      '%m/%d/%Y<br/><div style="width: 100%; text-align: center;">%l:%M %p</div>',
  },
  series: [
    {
      data: null,
    },
  ],
};
