import { Injectable } from '@angular/core';

@Injectable()
export class LoaderService {

  public active = false;

  constructor() {}

  hide() {
    this.active = false;
  }

  show() {
    this.active = true;
  }

}
