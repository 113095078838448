import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import * as Highstocks from 'highcharts/highstock';
import * as Highcharts from 'highcharts/highcharts';
import More from 'highcharts/highcharts-more';
import gauge from 'highcharts/modules/solid-gauge';
import noData from 'highcharts/modules/no-data-to-display';
import gapUnit from 'highcharts/modules/broken-axis';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { MatExpansionPanel } from '@angular/material/expansion';
import { take } from 'rxjs/operators';

import { LiveChartFactory } from '../shared/classes/live-chart-factory';
import { PQubeSocketServiceService } from '../shared/services/p-qube-socket-service.service';
import { MeterMeterOptions } from './../shared/chart-options/meter.meter-options';
import { MeterChartOptions } from './../shared/chart-options/meter.chart-options';
import { CurrentIHarmonics } from './../shared/config/live-meter/current-i-harmonics.config';
import { VoltageIHarmonics } from './../shared/config/live-meter/voltage-i-harmonics.config';
import { CurrentHarmonics } from './../shared/config/live-meter/current-harmonics.config';
import { VoltageHarmonics } from './../shared/config/live-meter/voltage-harmonics.config';
import { Currents } from './../shared/config/live-meter/currents.config';
import { Powers } from './../shared/config/live-meter/powers.config';
import { Voltage } from './../shared/config/live-meter/voltage.config';
import { ChartGroups } from './../shared/config/live-meter/chart-groups.config';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '../shared/classes/component-destroy.class';
import { MeasurementPointsService } from '../shared/services/measurement-points.service';

@Component({
  selector: 'app-meter',
  templateUrl: './meter.component.html',
  styleUrls: ['./meter.component.scss'],
})
export class MeterComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  private mConfig;
  // TODO define config interface
  @Input() set config(options) {
    this.mConfig = options;
    this.updateChartFlag = true;
  }

  @Output() messageEmitter: EventEmitter<string> = new EventEmitter();
  private chartInstance: any;
  public Highcharts = Highcharts;
  public Highstocks = Highstocks;
  public meterConstructor = 'chart';
  public chartConstructor = 'stockChart';
  @Input() updateChartFlag = false;
  @ViewChild('chartPanel', { static: false }) chartPanel: MatExpansionPanel;
  public chartReady = true;
  public newPoints: string[];
  public mUserSelectedChartGroup = 'voltage';
  public mUserSelectedChart = 'VoltageLL';
  public miscGroup = 8;
  public chartGroups = ChartGroups;
  public voltage = Voltage;
  public powers = Powers;
  public currents = Currents;
  public voltageHarmonics = VoltageHarmonics;
  public currentHarmonics = CurrentHarmonics;
  public voltageIHarmonics = VoltageIHarmonics;
  public currentIHarmonics = CurrentIHarmonics;
  public misc = new Array();
  public defaultCharts = [
    this.voltage[0].factoryName,
    this.powers[0].factoryName,
    this.currents[0].factoryName,
    this.voltageHarmonics[1].factoryName,
    this.currentHarmonics[1].factoryName,
    this.voltageIHarmonics[0].factoryName,
    this.currentIHarmonics[0].factoryName,
  ];
  private meterIdsToPublish: number[] = [];

  public get availableCharts(): { name: string; factoryName: string }[] {
    return this[this.userSelectedChartGroup];
  }

  public get timezone(): string {
    return this.mp.selectedMeasurementPoint.timezone;
  }

  public set userSelectedChartGroup(chartGroup: string) {
    this.mUserSelectedChartGroup = chartGroup;
    const index = this.chartGroups.findIndex((p) => p.propertyName === chartGroup);
    this.updateChart(this.defaultCharts[index]);
  }

  public get userSelectedChartGroup(): string {
    return this.mUserSelectedChartGroup;
  }

  public set userSelectedChart(chart: string) {
    this.updateChart(chart);
  }

  public get userSelectedChart(): string {
    return this.mUserSelectedChart;
  }

  private get allCharts(): { name: string; factoryName: string }[] {
    return this.voltage
      .concat(this.powers)
      .concat(this.currents)
      .concat(this.voltageHarmonics)
      .concat(this.currentHarmonics)
      .concat(this.voltageIHarmonics)
      .concat(this.currentIHarmonics)
      .concat(this.misc);
  }

  public get selectedChart(): { name: string; factoryName: string } {
    return this.allCharts.find((chart) => chart.factoryName === this.userSelectedChart);
  }

  private get mpId(): number {
    return this.mp.selectedMeasurementPoint.measurementPointId;
  }

  private get accountId(): number {
    return this.mp.selectedMeasurementPoint.accountId;
  }

  public chartOptions: any = { ...MeterChartOptions };

  public meterOptions: any = { ...MeterMeterOptions };

  public closeChartPanel(event) {
    this.chartPanel.expanded = false;
  }

  public captureChart = (chart: Highcharts.Chart) => {
    this.chartInstance = chart;
    // tslint:disable-next-line: semicolon
  };

  constructor(
    private mp: MeasurementPointsService,
    private socket: PQubeSocketServiceService,
    private route: ActivatedRoute
  ) {
    super();
    // uncomment next line to add gaps capability back into chart
    gapUnit(Highcharts);
    More(Highcharts);
    gauge(Highcharts);
    noData(Highcharts);

    // More(Highstocks);
    // noData(Highstocks);

    Highcharts.setOptions({
      lang: {
        thousandsSep: ',',
      },
    });
    Highstocks.setOptions({
      lang: {
        thousandsSep: ',',
      },
    });
  }

  ngOnInit() {
    this.userSelectedChartGroup = this.mConfig.chartGroup;
    // this.userSelectedChart = this.mConfig.chart;
    this.route.queryParams.pipe(untilComponentDestroyed(this)).subscribe((params) => {
      this.populateMeterGroups(this.userSelectedChartGroup);
      this.chartOptions.series = [];
      this.updateChartFlag = true;
      let charts;
      const index = this.chartGroups.findIndex((i) => i.propertyName === 'misc');
      if (
        index >= 0 &&
        this.misc.findIndex((i) => i.factoryName === this.userSelectedChart) >= 0
      ) {
        const chartsIndex = this.misc.findIndex(
          (i) => i.factoryName === this.userSelectedChart
        );
        const selectedChart = this.misc[chartsIndex];
        charts = LiveChartFactory.createChart(
          this.userSelectedChartGroup,
          selectedChart.name,
          selectedChart.units,
          selectedChart.channelID,
          selectedChart.pName
        );
      } else charts = LiveChartFactory.createChart(this.userSelectedChart);

      const liveChart = charts.chartInstance;
      if (liveChart) {
        // this.meterOptions.series = liveMeter.seriesArray;
        // this.meterOptions.series.forEach(series => series.data[0] = { y: 0});
        this.chartOptions.series = liveChart.seriesArray;
        this.chartOptions.xAxis.min = moment().subtract(1, 'minute').valueOf();
        this.chartOptions.time.timezone = this.timezone;
        this.updateChartFlag = true;
        // this.updateMeterFlag = true;

        this.meterIdsToPublish = liveChart.seriesArray.reduce((accumulator, series) => {
          const seriesChannelIds = series.channelInfo.map((info) => info.channelId);
          return accumulator.concat(seriesChannelIds);
        }, []);

        this.startTenMinutePublish();
      }
    });

    this.socket.pQubeSocket
      .pipe(untilComponentDestroyed(this))
      .subscribe((response: any) => {
        const payload = JSON.parse(response.payloadString);
        this.newPoints = [null, null];

        if (
          this.chartInstance &&
          this.chartInstance.series.length === this.chartOptions.series.length &&
          payload.data
        ) {
          const time = moment(payload.time)
            .tz(this.mp.selectedMeasurementPoint.timezone)
            .valueOf();
          this.chartOptions.series.forEach((series, index) => {
            const currentSeries = this.chartInstance.series[index];
            if (currentSeries.points.length > 0) {
              const lastPointIndex = currentSeries.points.length - 1;

              currentSeries.points[lastPointIndex].update(
                { marker: { enabled: false } },
                false
              );
            }
            series.populateNextDataPoint(time, payload.data, index);
            currentSeries.addPoint(series.nextDataPoint, false);
            if (series.nextDataPoint.y !== null) {
              this.newPoints.push(
                series.nextDataPoint.y.toFixed(series.tooltip.valueDecimals) +
                  ` ${series.tooltip.valueSuffix}`
              );
            }
          });
          this.chartInstance.redraw(true);
          if (
            this.chartInstance.xAxis[0].min < moment().subtract(10, 'minute').valueOf()
          ) {
            this.chartInstance.xAxis[0].update({
              min: moment().subtract(10, 'minute').valueOf(),
            });
          }
        }
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  public startTenMinutePublish(): void {
    this.socket.requestPQubePublish(this.meterIdsToPublish, this.mpId, this.accountId);
    this.messageEmitter.emit('start');
  }

  public updateChart(chart: string): void {
    this.chartReady = false;
    this.mUserSelectedChart = chart;
    if (this.chartInstance) {
      this.chartOptions.series.forEach((series) => {
        const destroyableSeries = this.chartInstance.get(series.id);
        if (destroyableSeries) {
          destroyableSeries.remove(false);
        }
      });
    }

    this.chartOptions.series = [];
    let charts;
    const index = this.chartGroups.findIndex((i) => i.propertyName === 'misc');
    if (
      index >= 0 &&
      this.misc.findIndex((i) => i.factoryName === this.userSelectedChart) >= 0
    ) {
      const chartsIndex = this.misc.findIndex(
        (i) => i.factoryName === this.userSelectedChart
      );
      const selectedChart = this.misc[chartsIndex];
      charts = LiveChartFactory.createChart(
        this.userSelectedChartGroup,
        selectedChart.name,
        selectedChart.unit,
        selectedChart.channelID,
        selectedChart.meterParam
      );
    } else charts = LiveChartFactory.createChart(this.userSelectedChart);

    const liveChart = charts.chartInstance;
    if (liveChart) {
      this.chartOptions.series = liveChart.seriesArray;
      if (this.chartInstance) {
        this.chartOptions.series.forEach((series) => {
          this.chartInstance.addSeries(series);
        });
      }
      // this.updateChartFlag = true;

      this.meterIdsToPublish = liveChart.seriesArray.reduce((accumulator, series) => {
        const seriesChannelIds = series.channelInfo.map((info) => info.channelId);
        return accumulator.concat(seriesChannelIds);
      }, []);
      this.startTenMinutePublish();
    }
    this.chartReady = true;
  }

  public populateMeterGroups(chartGroup: string): void {
    this.mp
      .getChannelDefinition(
        this.mp.selectedMeasurementPoint.measurementPointId.toString()
      )
      .pipe(take(1))
      .subscribe((res) => {
        const channelDefs = res.channels['8'];
        let newMisc;
        this.misc = [];
        for (const item in channelDefs) {
          if (channelDefs.hasOwnProperty(item)) {
            newMisc = LiveChartFactory.getCustomChannel(
              channelDefs[item].name,
              channelDefs[item].meterParam,
              channelDefs[item].unit,
              channelDefs[item].unitOffset,
              parseInt(item, 10),
              item
            );
            this.misc.push(newMisc);
          }
        }

        if (this.misc.indexOf(null) !== -1) {
          this.misc = this.misc.filter((customChannel) => customChannel !== null);
        }

        // Remove Misc group if it doesn't contain any meter
        // and Select VoltageLL if a misc meter was selected
        const index = this.chartGroups.findIndex((i) => i.propertyName === 'misc');
        if (Object.keys(this.misc).length === 0 && index !== -1) {
          this.chartGroups.splice(index, 1);
          this.defaultCharts.splice(index, 1);
          if (chartGroup === 'misc') this.userSelectedChartGroup = 'voltage';
        } else if (Object.keys(this.misc).length > 0 && index === -1) {
          this.chartGroups.push({ propertyName: 'misc', name: 'Misc' });
          this.defaultCharts.push(this.misc[0].factoryName);
        }
      });
  }
}
