import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { Observable } from 'rxjs';

import { DiligentApiService } from '.';
import { TrendsData } from '../classes/trendsData.interface';
import { IEnergyMeasure } from '../classes/energyMeasure.interface';

@Injectable({
  providedIn: 'root',
})
export class EnergyPowerService {
  constructor(private diligentApiService: DiligentApiService) {}

  public getTrendsDataJson(
    startDate: Moment,
    endDate: Moment,
    measurePointId: string,
    scope: string,
    channel: Array<string>,
    compare?: string,
    compareWith?: string
  ): Observable<Array<Array<number>>> {
    const tempData = moment(startDate);
    let granularity: string;
    let table: string;
    let interval: number;
    if (scope === 'day') {
      granularity = 'minute';
      table = 'fiveminute';
      interval = 15;
    } else if (scope === 'week') {
      granularity = 'hour';
      table = 'fiveminute';
      interval = 24;
    } else if (scope === 'month') {
      granularity = 'day';
      table = 'fiveminute';
      interval = 1;
    } else if (scope === 'year') {
      granularity = 'month';
      table = 'fiveminute';
      interval = 1;
    }

    return this.diligentApiService
      .getTrendsDataJson(
        tempData,
        endDate,
        granularity,
        channel,
        measurePointId,
        table,
        interval
      )
      .pipe(
        map((res: Array<TrendsData>) => {
          if (compare === 'compare') {
            return this.jsonToDataCompare(res, channel, compareWith, scope);
          }
          return this.jsonToData(res, channel, scope);
        })
      );
  }

  private jsonToData(
    res: Array<TrendsData>,
    channel: Array<string>,
    scope
  ): Array<Array<number>> {
    const returnData = [[], []];
    if (channel[0] === 'pc_162_diff_kwhr') {
      res.forEach((entry: TrendsData) => {
        returnData[0].push([
          moment(entry.time_stamp).valueOf(),
          entry[channel[0]],
        ]);
      });
    } else if (channel[0] === 'c_70_avg_w') {
      if (scope === 'day') {
        res.forEach((entry: TrendsData) => {
          returnData[0].push([
            moment(entry.time_stamp).valueOf(),
            +entry[channel[0]] / 1000,
          ]);
        });
      } else {
        res.forEach((entry: TrendsData) => {
          returnData[0].push([
            moment(entry.time_stamp).valueOf(),
            +entry[channel[0]] / 1000,
          ]);
        });
      }
    } else if (channel[0] === 'c_124_avg_none') {
      res.forEach((entry: TrendsData) => {
        returnData[0].push([
          moment(entry.time_stamp).valueOf(),
          +entry[channel[0]],
        ]);
        returnData[1].push([
          moment(entry.time_stamp).valueOf(),
          +entry[channel[1]] / 1000,
        ]);
      });
    }
    return returnData;
  }

  private jsonToDataCompare(
    res: Array<TrendsData>,
    channel: Array<string>,
    compareWith,
    scope
  ) {
    const returnData = [[], []];
    if (channel[0] === 'pc_162_diff_kwhr') {
      res.forEach((entry: TrendsData) => {
        if (compareWith === 'year3') {
          returnData[0].push([
            moment(entry.time_stamp).add(3, 'month').valueOf(),
            entry.pc_162_diff_kwhr,
          ]);
        } else {
          if (compareWith === 'month') {
            returnData[0].push([
              moment(entry.time_stamp)
                .add(moment(entry.time_stamp).daysInMonth(), 'day')
                .valueOf(),
              entry.pc_162_diff_kwhr,
            ]);
          } else {
            returnData[0].push([
              moment(entry.time_stamp).add(1, compareWith).valueOf(),
              entry.pc_162_diff_kwhr,
            ]);
          }
        }
      });
    } else if (channel[0] === 'c_70_avg_w') {
      if (scope === 'day') {
        res.forEach((entry: TrendsData) => {
          if (compareWith === 'year3') {
            returnData[0].push([
              moment(entry.time_stamp).add(3, 'month').valueOf(),
              +entry.c_70_avg_w / 1000,
            ]);
          } else {
            if (compareWith === 'month') {
              returnData[0].push([
                moment(entry.time_stamp)
                  .add(moment(entry.time_stamp).daysInMonth(), 'day')
                  .valueOf(),
                +entry.c_70_avg_w / 1000,
              ]);
            } else {
              returnData[0].push([
                moment(entry.time_stamp).add(1, compareWith).valueOf(),
                +entry.c_70_avg_w / 1000,
              ]);
            }
          }
        });
      } else {
        res.forEach((entry: TrendsData) => {
          if (compareWith === 'year3') {
            returnData[0].push([
              moment(entry.time_stamp).add(3, 'month').add(1, 'day').valueOf(),
              +entry.c_70_avg_w / 1000,
            ]);
          } else {
            if (compareWith === 'month') {
              returnData[0].push([
                moment(entry.time_stamp)
                  .add(moment(entry.time_stamp).daysInMonth(), 'day')
                  .add(1, 'day')
                  .valueOf(),
                +entry.c_70_avg_w / 1000,
              ]);
            } else {
              returnData[0].push([
                moment(entry.time_stamp)
                  .add(1, compareWith)
                  .add(1, 'day')
                  .valueOf(),
                +entry.c_70_avg_w / 1000,
              ]);
            }
          }
        });
      }
    } else if (channel[0] === 'c_124_avg_none') {
      res.forEach((entry: TrendsData) => {
        if (compareWith === 'year3') {
          returnData[0].push([
            moment(entry.time_stamp).add(3, 'month').valueOf(),
            +entry[channel[0]],
          ]);
          returnData[1].push([
            moment(entry.time_stamp).add(3, 'month').valueOf(),
            +entry[channel[1]] / 1000,
          ]);
        } else {
          if (compareWith === 'month') {
            returnData[0].push([
              moment(entry.time_stamp)
                .add(moment(entry.time_stamp).daysInMonth(), 'day')
                .valueOf(),
              +entry[channel[0]],
            ]);
            returnData[1].push([
              moment(entry.time_stamp)
                .add(moment(entry.time_stamp).daysInMonth(), 'day')
                .valueOf(),
              +entry[channel[1]] / 1000,
            ]);
          } else {
            returnData[0].push([
              moment(entry.time_stamp).add(1, compareWith).valueOf(),
              +entry[channel[0]],
            ]);
            returnData[1].push([
              moment(entry.time_stamp).add(1, compareWith).valueOf(),
              +entry[channel[1]] / 1000,
            ]);
          }
        }
      });
    }
    return returnData;
  }

  public getEnergyMeasure(
    start: Moment,
    end: Moment,
    mp: string
  ): Observable<IEnergyMeasure> {
    return this.diligentApiService
      .getEnergyMeasure(start, end, mp)
      .pipe(map((res) => res));
  }
}
