import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar } from "@angular/material/snack-bar";
import { NotificationModalComponent } from "../modal-dialog/notification-modal.component";

// enum notify_types {
//   default,
//   info,
//   success,
//   wait,
//   error,
//   warning
// }

@Injectable()
export class NotificationsService {

  private configDefaults: MatSnackBarConfig = new MatSnackBarConfig();

  // public types = notify_types;

  public alerts = 0;

  constructor(
    private snackbar: MatSnackBar,
    private dialog: MatDialog
  ) {

    this.configDefaults.verticalPosition = "bottom";
    this.configDefaults.duration = 4000;
  }

  notify(message: string, action?: string, config?: MatSnackBarConfig): MatSnackBarRef<SimpleSnackBar> | null {
    config = { ...this.configDefaults, ...config };
    return this.snackbar.open(message, action, config);
  }

  alert(message: string, title?: string, buttonCaption?: string, width: string = "40%", options?: object): MatDialogRef<NotificationModalComponent> {
    let dialogRef: MatDialogRef<NotificationModalComponent>;
    const dataObj: any = {
      message: message,
      material_ligature: "check_circle",
      buttons: [
        {
          caption: buttonCaption || "Ok",
          callback: () => {
            dialogRef.close(true);
          },
          material_ligature: "check_circle",
          color: "primary"
        }
      ],
      ...options
    };

    // so it's not automatically overridden
    if (title) { dataObj.title = title; }

    let defaultWidth = '40%';

    if (screen.width <= 768) {
      defaultWidth = '90%';
      if (width === '40%') {
        width = defaultWidth;
      }
    }

    dialogRef = this.dialog.open(NotificationModalComponent, {
      panelClass: "notification",
      width: width || defaultWidth,
      data: dataObj,
    });

    this.alerts++;

    dialogRef.afterClosed().subscribe(
      () => {
        this.alerts--;
      }
    );

    return dialogRef;
  }

  confirm(message: string, title: string = "Are you sure?", buttons?: object[], width: string = "40%", options?: object): MatDialogRef<NotificationModalComponent> {
    let dialogRef: any = {};
    const dataObj: any = {
      title: title,
      message: message,
      material_ligature: "help",
      buttons: buttons || [
        {
          caption: "Yes",
          callback: () => {
            dialogRef.close(true);
          },
          material_ligature: "check_circle",
          color: "primary"
        },
        {
          caption: "No",
          callback: () => {
            dialogRef.close(false);
          },
          material_ligature: "cancel",
          color: "warn"
        }
      ],
      ...options
    };

    let defaultWidth = '40%';

    if (screen.width <= 768) {
      defaultWidth = '90%';
      if (width === '40%') {
        width = defaultWidth;
      }
    }

    dialogRef = this.dialog.open(NotificationModalComponent, {
      width: width || defaultWidth,
      disableClose: true,
      panelClass: "notification",
      data: dataObj
    });

    return dialogRef;
  }
}
