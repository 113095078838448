import { StandardTabs } from './../shared/config/standard-tabs.config';
import { cloneDeep } from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import * as moment from 'moment';
import { MdePopoverTrigger } from '@material-extended/mde';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { IAccount } from '../shared/classes/account';
import { popoverIcons } from '../shared/classes/EventPopoverIcons';
import { Tab } from '../shared/classes/tab.interface';
import { NotesService } from '../shared/services/notes.service';
import { NewChartTabComponent } from './new-chart-tab/new-chart-tab.component';
import { AuthService, TokenService } from '../shared/services';
import * as fromChannels from './../_store/_reducers';
import { CustomChartTypes } from '../shared/classes/customer-chart-types.class';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '../shared/classes/component-destroy.class';
import * as fromUser from './../_store/_reducers';
import { GraphManagerService } from '../shared/services/graph-manager.service';
import { MeasurementPointsService } from '../shared/services/measurement-points.service';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss'],
})
export class ChartsComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  @ViewChild(MdePopoverTrigger, { static: false })
  chartPopover: MdePopoverTrigger;
  @ViewChild(ElementRef, { static: false }) popoverContent: ElementRef;

  public commissioningDate: string;
  public userPrefs: any;
  public tabs: Tab[] = [];
  public standardTabs = cloneDeep(StandardTabs);
  public customChart: any = null;
  public customChartTabs: Tab[] = null;
  public currentTab = -1;
  public customChartSaved = false;
  public editable = false;

  // measurement point ID
  public mpId: string;

  // selected account ID
  public accountId: string;

  // the user's actual account
  public account: IAccount;

  // data for the info drawer
  public loadingInfoDrawer = false;
  public showInfoDrawer = false;
  public infoData: any = {};
  public infoEvent: any = {};
  public infoIcons = popoverIcons;
  public isPartner: boolean;
  private isPartnerSub$: Subscription;

  constructor(
    public dialog: MatDialog,
    private auth: AuthService,
    private graphManager: GraphManagerService,
    private mpService: MeasurementPointsService,
    private route: ActivatedRoute,
    public token: TokenService,
    private notes: NotesService,
    private store: Store<fromChannels.State | fromUser.State>
  ) {
    super();
  }

  public get commissionedWhen(): string {
    return this.mpService.selectedMeasurementPoint.commissionedWhen;
  }

  ngOnInit() {
    this.route.queryParamMap
      .pipe(untilComponentDestroyed(this))
      .subscribe((params: ParamMap) => {
        this.store.dispatch({
          type: '[Charts] Set Channels',
          payload: {
            mpId: params.get('mpId').toString(),
          },
        });
      });

    this.isPartnerSub$ = this.store
      .pipe(select(fromUser.getIsPartner))
      .subscribe((isPartner: number) => {
        this.isPartner = isPartner === 1;
      });

    this.auth.preferences
      .pipe(untilComponentDestroyed(this))
      .subscribe((userPrefs: any) => {
        if (!userPrefs) {
          return;
        }
        this.userPrefs = userPrefs;
        this.auth.accountO.pipe(take(1)).subscribe((account: IAccount) => {
          this.account = account;
          this.customChart = userPrefs.customChart || null;
          this.customChartTabs = userPrefs.customTabs || null;

          // this.saveCustomTabs(this.customChartTabs, this.userPrefs);

          if (
            this.customChartTabs &&
            !(this.customChart && this.customChart.phaseList !== null)
          ) {
            this.tabs = [
              ...this.standardTabs,
              ...this.customChartTabs.sort(
                (chart1, chart2) => chart1.sortOrder - chart2.sortOrder
              ),
            ];
          } else if (this.customChart) {
            if (typeof this.customChart.selected === 'string') {
              this.customChart.selected = [];
            }

            // setTimeout(() => {
            const customTab = {
              name: this.customChart.name,
              memberCharts: this.customChart.selected
                .map((c: string) => CustomChartTypes.customChartTypes[c])
                .reduce((accumulator, current) => {
                  if (current) {
                    return [...accumulator, ...current];
                  }
                  return accumulator;
                }, [])
                .concat('pqEvents'),
              chartSetName: 'Custom' + new Date().getTime(),
              sortOrder: 6,
              editable: true,
              selected: this.customChart.selected,
            } as Tab;

            this.saveCustomTabs(customTab, userPrefs);

            // });
          } else {
            this.tabs = [...this.standardTabs].sort(
              (chart1, chart2) => chart1.sortOrder - chart2.sortOrder
            );
          }

          if (this.customChartSaved) {
            this.currentTab = userPrefs.lastTabSaved;
            this.customChartSaved = false;
          } else if (this.currentTab === -1 && userPrefs.defaultTab) {
            this.currentTab = userPrefs.defaultTab;
          } else if (this.currentTab === -1) {
            this.currentTab = 0;
          }

          // listen for URL changes
          this.route.queryParamMap
            .pipe(untilComponentDestroyed(this))
            .subscribe((params: ParamMap) => {
              this.commissioningDate = moment(
                this.mpService.selectedMeasurementPoint.commissionedWhen
              )
                .tz(this.mpService.selectedMeasurementPoint.timezone)
                .format('MM/DD/YY h:mm A');
              this.hideInfoDrawer();
              if (params.get('mpId') && params.get('account')) {
                this.accountId =
                  this.isPartner || this.token.metaData.isSystemAdministrator === '1'
                    ? params.get('account')
                    : account.id.toString();
                this.mpId = params.get('mpId');
              }
            });
        });
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.isPartnerSub$.unsubscribe();
  }

  public changeAbsoluteStartDate(event: MatCheckboxChange): void {
    this.auth.savePreferences({
      ...this.userPrefs,
      usePreCommissionStart: event.checked,
    });
  }

  public drop(event: CdkDragDrop<any>): void {
    const previousIndex = parseInt(event.previousContainer.id.replace('list-', ''), 10);
    const currentIndex = parseInt(event.container.id.replace('list-', ''), 10);

    moveItemInArray(this.tabs, previousIndex, currentIndex);

    const chartsOrder = this.tabs.map((tab) => tab.sortOrder);
    this.auth.savePreferences({ ...this.userPrefs, chartsOrder: chartsOrder }, true);
  }

  private sortTab(): void {
    if (this.userPrefs.chartsOrder) {
      this.tabs.sort((a, b) => {
        const tabA = a.sortOrder;
        const tabB = b.sortOrder;

        if (
          this.userPrefs.chartsOrder.indexOf(tabA) >
          this.userPrefs.chartsOrder.indexOf(tabB)
        ) {
          return 1;
        } else {
          return -1;
        }
      });
    }
  }

  public getAllListConnections(index): Array<string> {
    const connections = [];
    for (let i = 0; i < this.tabs.length; i++) {
      if (i !== index) {
        connections.push('list-' + i);
      }
    }
    return connections;
  }

  showAddTab() {
    const dialog = this.dialog.open(NewChartTabComponent, {
      data: {
        tab: null,
        addTab: true,
        userPrefs: this.userPrefs,
        standardNumberOfTabs: this.standardTabs.length,
      },
    });

    dialog.afterOpened().subscribe(() => {
      this.customChartSaved = false;
    });

    dialog.beforeClosed().subscribe((saved) => {
      this.customChartSaved = saved;
    });
  }

  showEditTab(order) {
    if (order === this.currentTab) {
      const dialog = this.dialog.open(NewChartTabComponent, {
        data: {
          tab: this.tabs[this.currentTab],
          addTab: false,
          userPrefs: this.userPrefs,
          standardNumberOfTabs: this.standardTabs.length,
        },
      });

      dialog.afterOpened().subscribe(() => {
        this.customChartSaved = false;
      });

      dialog.beforeClosed().subscribe((saved) => {
        this.customChartSaved = saved;
      });
    }
  }

  setCurrentTab(event) {
    if (event === -1) {
      return;
    }
    this.currentTab = event;
    this.editable = this.tabs[event].editable;
    this.hideInfoDrawer();
  }

  // create userPrefs interface
  saveCustomTabs(tab: Tab, userPrefs: any) {
    const prefs = {
      ...userPrefs,
      customTabs: null,
      customChart: null,
    };

    this.auth.savePreferences(prefs);
  }

  getCustomCharts(selectedCharts) {
    return selectedCharts.map((chart) => CustomChartTypes.customChartTypes[chart]);
  }

  // handle the event marker click event
  public toggleInfoDrawer(event): void {
    this.infoEvent = event;
    this.loadingInfoDrawer = true;
    this.showInfoDrawer = true;

    // scroll the page so we can display the drawer, even if it just shows 'loading'
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    });

    if (event.point.series.userOptions.eventTypeId) {
      this.graphManager
        .getSingleEventData(
          parseInt(event.point.series.userOptions.eventTypeId, 10),
          moment(event.point.x)
        )
        .pipe(untilComponentDestroyed(this))
        .subscribe((oneEvent) => {
          this.loadingInfoDrawer = false;

          if (
            this.infoData.documentId &&
            this.infoData.documentId === oneEvent[0].documentId
          ) {
            this.hideInfoDrawer();
            return;
          }

          this.infoData = oneEvent[0];
          this.infoData.originalTriggeredWhen = this.infoData.triggeredWhen;
          this.infoData.triggeredWhen = moment(
            this.infoData.triggeredWhen,
            'YYYY-MM-DDTHH:mm:SSSZ'
          )
            .tz(this.mpService.selectedMeasurementPoint.timezone)
            .format('MMMM D, YYYY h:mm A');

          // do this again so we can scroll the whole loaded drawer into view
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          });
        });
    } else {
      this.notes
        .getSingleNote(this.mpId, moment(event.point.x))
        .pipe(untilComponentDestroyed(this))
        .subscribe((oneNote) => {
          this.loadingInfoDrawer = false;

          if (this.infoData.id && this.infoData.id === oneNote[0].id) {
            this.hideInfoDrawer();
            return;
          }

          this.infoData = oneNote[0];
          this.infoData.tzShiftedDate = moment(this.infoData.startDateTime)
            .tz(this.mpService.selectedMeasurementPoint.timezone)
            .format('MM/DD/YY');
          this.infoData.tzShiftedTime = moment(this.infoData.startDateTime)
            .tz(this.mpService.selectedMeasurementPoint.timezone)
            .format('hh:mm A z');

          // do this again so we can scroll the whole loaded drawer into view
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          });
        });
    }
  }

  public hideInfoDrawer(): void {
    this.showInfoDrawer = false;
    this.infoData = {};
    this.infoEvent = {};
  }
}
