<div class="reports">
  <div class="heading">
    <h1>{{ 'eventsList.title' | translate }}</h1>
  </div>
  <div>
    <section class="events-container container">
      <div class="date-picker-nav">
        <h4 class="title">{{ title }}</h4>
        <app-date-picker-nav
          [scope]="scope"
          [chartSetName]="chartSetName"
          [absoluteStartDate]="absoluteStartDate"
          [type]="'event-list'"
          [havePast]="havePast"
          [date]="endDate"
          (updateChartEvent)="updateChart($event)"
        >
        </app-date-picker-nav>
      </div>
      <div
        id="events-table"
        class="table-container"
        *ngIf="eventsListSource?.data?.length > 0"
      >
        <table
          mat-table
          [dataSource]="eventsListSource"
          matSortActive="date"
          matSortDirection="desc"
          matSort
        >
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'notes.new.date' | translate }}
            </th>
            <td class="date-table-col" mat-cell *matCellDef="let element">
              <span *ngIf="element.deviceEventId > 0">{{
                element.triggeredWhen | date: 'MMMM d, y'
              }}</span>
              <span *ngIf="element.id > 0">{{
                element.startDateTime | date: 'MMMM d, y'
              }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="time">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'notes.new.time' | translate }}
            </th>
            <td class="time-table-col" mat-cell *matCellDef="let element">
              <span *ngIf="element.deviceEventId > 0">{{
                element.triggeredWhen | date: 'h:mm a':timezone
              }}</span>
              <span *ngIf="element.id > 0">{{
                element.startDateTime | date: 'h:mm a':timezone
              }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="deviceEventType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'monthlyReport.type' | translate }}
            </th>
            <td class="type-table-col" mat-cell *matCellDef="let element">
              <span
                *ngIf="element.deviceEventId > 0 && element.defaultDisplayName === null"
              >
                {{ 'eventType.' + element.deviceEventType | translate }}
              </span>
              <span
                *ngIf="element.deviceEventId > 0 && element.defaultDisplayName !== null"
              >
                {{ element.deviceEventType }}
              </span>
              <span *ngIf="element.id > 0"> Note </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'dashboard.detail-overlay.description' | translate }}
            </th>
            <td class="description-table-col" mat-cell *matCellDef="let element">
              <span *ngIf="element.deviceEventId > 0 && element?.eventMagnitude !== null">
                {{ 'dashboard.detail-overlay.magnitude' | translate }}
                {{
                  (element?.deviceEventType === 'highFrequencyImpulse'
                    ? 'dashboard.detail-overlay.hf-magnitude-value'
                    : 'dashboard.detail-overlay.magnitude-value'
                  ) | translate: { value: element?.eventMagnitude }
                }}
                <span *ngIf="element.deviceEventId > 0 && element?.duration !== null">
                  {{ 'dashboard.detail-overlay.duration' | translate }}
                  {{ element.duration }} {{ element.duration ? 'ms' : '' }}
                </span>
              </span>
              <span *ngIf="element.id > 0">{{ element.summary }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="view">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'event-graph.graph' | translate }}
            </th>
            <td class="view-table-col" mat-cell *matCellDef="let element">
              <span *ngIf="element.deviceEventId > 0">
                <a
                  mat-button
                  [routerLink]="[
                    '/event-graph',
                    mpId,
                    accountId,
                    element.deviceEventId,
                    element.deviceEventTypeId,
                    element.triggeredWhen
                  ]"
                  target="_blank"
                >
                  <img
                    src="./assets/images/legend/iconmonstr-sound-wave-4(correct_size).png"
                  />
                </a>
              </span>
              <span *ngIf="element.id > 0">
                <div>
                  <button mat-icon-button (click)="deleteNote(element.id)">
                    <mat-icon svgIcon="delete-forever" color="accent"></mat-icon>
                  </button>
                  <button mat-icon-button (click)="editNote(element)">
                    <mat-icon svgIcon="pencil" color="accent"></mat-icon>
                  </button>
                </div>
              </span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator
          [pageSizeOptions]="[20, 40, 60, 100]"
          showFirstLastButtons
        ></mat-paginator>
      </div>
      <div *ngIf="eventsListSource?.data?.length === 0">
        <h3>
          {{ 'monthlyReport.eventsEmpty' | translate }}
        </h3>
      </div>
    </section>
  </div>
</div>
