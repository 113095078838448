<nav>
<button
  mat-flat-button
  [color]="current === '/fleet' ? 'accent' : 'primary'"
  [matTooltip]="'navigation.fleet' | translate"
  matTooltipPosition="right"
  (click)="navigate(['fleet'])"
>
  <mat-icon svgIcon="map-marker-multiple"></mat-icon>
</button>
<button
  mat-flat-button
  [color]="current === '/map-view' ? 'accent' : 'primary'"
  [matTooltip]="'navigation.mapView' | translate"
  matTooltipPosition="right"
  (click)="navigate(['map-view'])"
  *ngIf="true"
>
  <mat-icon svgIcon="map-outline"></mat-icon>
</button>
  <button
    mat-flat-button
    [color]="current === '/dashboard' ? 'accent' : 'primary'"
    [matTooltip]="'navigation.dashboard' | translate"
    matTooltipPosition="right"
    (click)="navigate(['dashboard'])"
    data-cy="dashboard-button"
  >
    <img class="view-list" src="./assets/images/dashboard_icon.png" />
  </button>
  <button
    mat-flat-button
    [color]="current === '/events-list' ? 'accent' : 'primary'"
    [matTooltip]="'navigation.eventsList' | translate"
    matTooltipPosition="right"
    (click)="navigate(['events-list'])"
    *ngIf="isQubeScan"
  >
    <img class="view-list" src="./assets/images/menu_list_icon.png" />
  </button>
  <button
  mat-flat-button
  [color]="current === '/notes' ? 'accent' : 'primary'"
  [matTooltip]="'navigation.notes' | translate"
  matTooltipPosition="right"
  (click)="navigate(['notes'])"
  *ngIf="!isQubeScan"
>
  <img class="view-list" src="./assets/images/notes_icon.png" />
</button>
  <button
    mat-flat-button
    [color]="current === '/charts' ? 'accent' : 'primary'"
    [matTooltip]="'navigation.charts' | translate"
    matTooltipPosition="right"
    (click)="navigate(['charts'])"
  >
  <img class="view-list" src="./assets/images/TrendsGraph_Icon.png" />
  </button>
  <button
    mat-flat-button
    [color]="current === '/energy-power' ? 'accent' : 'primary'"
    [matTooltip]="'navigation.energyPower' | translate"
    matTooltipPosition="right"
    (click)="navigate(['energy-power'])"
  >
    <img class="energy-power" src="./assets/images/meter-icon.svg" />
  </button>
  <button
    mat-flat-button
    [color]="current === '/reports' ? 'accent' : 'primary'"
    [matTooltip]="'navigation.reports' | translate"
    matTooltipPosition="right"
    (click)="navigate(['reports'])"
    *ngIf="!isQubeScan"
    data-cy="reports-icon"
  >
    <mat-icon svgIcon="clipboard-check-outline"></mat-icon>
  </button>
</nav>
