import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as moment from 'moment';

import { ChartSet } from '../classes/ChartSet';
import { DiligentApiService } from './diligent-api.service';
import { ChartDefinitions } from './chart-definitions.service';
import { ISeriesOptions, ITrendsChart } from '../classes/chart.interface';
import { MeasurementPointsService } from './measurement-points.service';
import { NotesService } from './notes.service';
import * as fromChannels from './../../_store/_reducers';
import { NotificationsService } from '../modules/notifications/shared/notifications.service';
import { PhaseList } from '../classes/phase-list.interface';

@Injectable({
  providedIn: 'root',
})
export class GraphManagerService {
  private mDateRangeStart: moment.Moment;
  private mDateRangeEnd: moment.Moment;
  private granularity: string;
  public unitOfTime: moment.unitOfTime.DurationConstructor;
  private numberOfTimeUnits: moment.DurationInputArg1 = 1;
  private chartSetContainer = {};
  private absoluteStartDate: number;

  private mAccountId;
  private mMeasurementPointId;
  // true if date range start is relative to date range end
  private mDateRangeRelative: boolean;

  public set dateRangeRelative(flag: boolean) {
    this.mDateRangeRelative = flag;
  }

  public get dateRangeRelative(): boolean {
    return this.mDateRangeRelative;
  }

  public get accountId() {
    return this.mpService.selectedMeasurementPoint.accountId.toString();
  }

  public set accountId(accountId: string) {
    this.mAccountId = accountId;
  }

  public get measurementPointId() {
    return this.mpService.selectedMeasurementPoint.measurementPointId.toString();
  }

  public set measurementPointId(measurePointId: string) {
    this.mMeasurementPointId = measurePointId;
  }

  public get dateRangeEnd(): moment.Moment {
    return this.mDateRangeEnd;
  }

  public set dateRangeEnd(newEndDate: moment.Moment) {
    this.mDateRangeEnd = newEndDate.clone();
    if (!this.dateRangeRelative) {
      this.mDateRangeStart = newEndDate
        .clone()
        .tz(this.mpService.selectedMeasurementPoint.timezone)
        .startOf(this.unitOfTime)
        .utc();
    } else {
      this.mDateRangeStart = newEndDate
        .clone()
        .subtract(this.numberOfTimeUnits, this.unitOfTime);
    }
    if (
      this.absoluteStartDate &&
      this.mDateRangeStart.valueOf() < this.absoluteStartDate
    ) {
      this.mDateRangeStart = moment(this.absoluteStartDate).tz(
        this.mpService.selectedMeasurementPoint.timezone
      );
    }
  }

  public get dateRangeStart(): moment.Moment {
    return this.mDateRangeStart;
  }

  constructor(
    private dAPIService: DiligentApiService,
    private chartDefinitions: ChartDefinitions,
    private mpService: MeasurementPointsService,
    private notes: NotesService,
    private store: Store<fromChannels.State>,
    private notificationsService: NotificationsService
  ) {}

  public newChartSet(setName: string, chartKeys: string[], phaseList?: Array<PhaseList>) {
    this.chartSetContainer[setName] = new ChartSet(
      this.notes,
      setName,
      chartKeys,
      this.chartDefinitions,
      this.dAPIService,
      phaseList,
      this.store,
      this.notificationsService
    );
  }

  public resetChartSet(setName: string) {
    if (this.chartSetContainer[setName]) {
      this.chartSetContainer[setName].clearChartData();
    }
  }

  public setStartDateBound(absoluteStartDate: number) {
    this.absoluteStartDate = absoluteStartDate;
  }

  public updateChartSet(
    setName: string,
    isQubeScan?: boolean
  ): Observable<ISeriesOptions[][]> {
    this.resetChartSet(setName);
    return this.chartSetContainer[setName].initializeChartSetData(
      this.dateRangeStart,
      this.dateRangeEnd,
      this.granularity,
      this.accountId,
      this.measurementPointId,
      isQubeScan
    );
  }

  public replaceDataByChartSet(
    setName: string,
    markStartBy: moment.unitOfTime.StartOf,
    granularity: string,
    isQubeScan?: boolean
  ): Observable<ISeriesOptions[][]> {
    return this.chartSetContainer[setName].replaceData(
      granularity,
      markStartBy,
      this.accountId,
      this.measurementPointId,
      isQubeScan
    );
  }

  public getChartSet(chartSetName: string): ITrendsChart[] {
    if (this.chartSetContainer[chartSetName])
      return this.chartSetContainer[chartSetName].charts;

    return null;
  }

  public updateGranularity(
    newGranularity: string,
    timeUnit: moment.unitOfTime.DurationConstructor,
    range: moment.DurationInputArg1
  ) {
    this.granularity = newGranularity;
    this.unitOfTime = timeUnit;
    this.numberOfTimeUnits = range;
    if (range > 0) {
      this.dateRangeRelative = true;
    } else this.dateRangeRelative = false;
  }

  public getSingleEventData(eventTypeId: number, eventMoment: moment.Moment) {
    return this.dAPIService.getEventsData(
      this.measurementPointId,
      this.accountId,
      eventMoment.clone().subtract(1, 'second'),
      eventMoment.add(1, 'second'),
      eventTypeId,
      1000,
      false
    );
  }
}
