import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

import { SitesService } from '../../../shared/services';
import { HierarchyActions } from '../actions';
import { UserActions } from '../../_user/actions';
import { DiligentApiService } from '../../../shared/services';

@Injectable()
export class HierarchyEffects {
  @Effect()
  initHierarchy$ = this.actions$.pipe(
    ofType(HierarchyActions.getHierarchyUser.type),
    switchMap((action) => {
      return forkJoin([
        this.diligentApiService.getUserById(action.payload.id, action.payload.accountId),
        this.sitesService.getSites(),
      ]);
    }),
    switchMap(([user, sites]) => {
      return [
        UserActions.getUserSuccess({ user: user }),
        HierarchyActions.getHierarchySuccess({ hierarchy: sites }),
      ];
    })
  );

  constructor(
    private actions$: Actions<HierarchyActions.HierarchyActionsUnion>,
    private sitesService: SitesService,
    private diligentApiService: DiligentApiService
  ) {}
}
