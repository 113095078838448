<div class="outer-flex">
  <div class="flex-container">
    <app-d-chart-navigator
      #chartNavigator
      *ngIf="chartReady"
      [currentScope]="dashboardDictatedScope"
      [currentChartSetName]="chartSetName"
      [absoluteStartDate]="absoluteStartDate"
      [type]="'dashboard'"
      [havePast]="true"
      [isQubeScan]="isQubeScan"
      (scopeChange)="updateChart($event)"
    ></app-d-chart-navigator>
  </div>
  <button
    *ngIf="chartReady"
    mat-flat-button
    color="accent"
    (click)="navigate(['live-meters'])"
  >
    View Live Meters <mat-icon svgIcon="gauge"></mat-icon>
  </button>
</div>
<highcharts-chart
  *ngIf="chartReady"
  [Highcharts]="Highcharts"
  [constructorType]="chartConstructor"
  [options]="chartOptions"
  [callbackFunction]="onChartComplete"
  [(update)]="updateFlag"
  [oneToOne]="oneToOneFlag"
></highcharts-chart>
<mat-progress-spinner
  mode="indeterminate"
  *ngIf="!chartReady"
  color="primary"
></mat-progress-spinner>
