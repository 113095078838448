<div class="reports">
  <form [formGroup]="monthlyReportForm">
    <div class="heading">
      <h1>
        {{ month | months }} {{ year }} {{ 'monthlyReport.title' | translate }}
      </h1>
      <div class="header">
        <div class="pagination">
          <button
            mat-button
            (click)="navigate(-1)"
            [disabled]="
              (year <= minYear && month <= minMonth) ||
              (!isPartner &&
                token.metaData.isSystemAdministrator !== '1' &&
                previousIndex === -1)
            "
          >
            <mat-icon svgIcon="chevron-left"></mat-icon>
            {{ 'reports.pagination.prev' | translate }}
          </button>
          <div class="current">{{ month | months }} {{ year }}</div>
          <button
            mat-button
            (click)="navigate(1)"
            [disabled]="
              (year === maxYear && month === maxMonth) ||
              (!isPartner &&
                token.metaData.isSystemAdministrator !== '1' &&
                followingIndex === -1)
            "
          >
            {{ 'reports.pagination.next' | translate }}
            <mat-icon svgIcon="chevron-right"></mat-icon>
          </button>
          <span class="status" data-cy="reports-status">
            {{ 'dashboard.status' | translate }}: {{ loggingInfo?.status.name }}
          </span>
        </div>
        <div class="button-container">
          <button
            class="save-button"
            data-cy="reports-save"
            mat-flat-button
            [disabled]="!monthlyReportForm.dirty"
            color="accent"
            (click)="onActionReport(monthlyReportForm.value, 'save', true)"
            *ngIf="
              (!isPublish && !isArchive && isPartner) ||
              token.metaData.isSystemAdministrator === '1'
            "
          >
            {{ 'monthlyReport.save' | translate }}
          </button>
          <button
            class="publish-button"
            data-cy="reports-publish"
            mat-flat-button
            color="accent"
            (click)="onActionReport(monthlyReportForm.value, 'publish', true)"
            *ngIf="
              (!isPublish && !isArchive && isPartner) ||
              token.metaData.isSystemAdministrator === '1'
            "
          >
            {{ 'monthlyReport.publishReport' | translate }}
          </button>
          <button
            class="archive-button"
            data-cy="reports-archive"
            mat-flat-button
            color="accent"
            (click)="onActionReport(monthlyReportForm.value, 'archive', true)"
            *ngIf="
              (!isArchive && isPartner) ||
              token.metaData.isSystemAdministrator === '1'
            "
          >
            {{ 'monthlyReport.archive' | translate }}
          </button>
        </div>
      </div>
    </div>
    <section class="container">
      <h3>{{ 'monthlyReport.siteInformation' | translate }}</h3>
      <quill-editor
        *ngIf="
          (!isPublish && !isArchive && isPartner) ||
          token.metaData.isSystemAdministrator === '1'
        "
        [modules]="{imageResize: {}}"
        (onEditorCreated)="getEditorInstance($event, 1)"
        formControlName="siteInformation"
      ></quill-editor>
      <quill-editor
        *ngIf="
          (isPublish || isArchive || !isPartner) &&
          token.metaData.isSystemAdministrator !== '1'
        "
        [readOnly]="true"
        [modules]="{ toolbar: false }"
        formControlName="siteInformation"
      ></quill-editor>
    </section>
    <section class="container">
      <h3>{{ 'monthlyReport.conclusions' | translate }}</h3>
      <quill-editor
        *ngIf="
          (!isPublish && !isArchive && isPartner) ||
          token.metaData.isSystemAdministrator === '1'
        "
        [modules]="{imageResize: {}}"
        (onEditorCreated)="getEditorInstance($event, 2)"
        formControlName="recommendations"
      ></quill-editor>
      <quill-editor
        *ngIf="
          (isPublish || isArchive || !isPartner) &&
          token.metaData.isSystemAdministrator !== '1'
        "
        [readOnly]="true"
        [modules]="{ toolbar: false }"
        formControlName="recommendations"
      ></quill-editor>
    </section>
    <section class="container">
      <highcharts-chart
        *ngIf="chartReady"
        [Highcharts]="Highcharts"
        [constructorType]="chartConstructor"
        [options]="chartOptions"
        [callbackFunction]="onChartComplete"
        [(update)]="updateFlag"
        [oneToOne]="oneToOneFlag"
      ></highcharts-chart>
      <mat-progress-spinner
        mode="indeterminate"
        *ngIf="!chartReady"
        color="primary"
      ></mat-progress-spinner>
      <div class="tabs-container" *ngIf="powerQAvailable">
        <div class="icon" *ngFor="let tab of tabs">
          <mat-icon
            [svgIcon]="tabIcons[powerQuality[tab].powerQualityStatusType]?.icon"
            [class]="tabIcons[powerQuality[tab].powerQualityStatusType]?.class"
          ></mat-icon>
          <label>{{ 'global.' + tab | translate }}</label>
        </div>
      </div>
    </section>
    <section class="container">
      <h3>
        {{ 'monthlyReport.energyPower' | translate }} {{ monthYearLabel }}
      </h3>
      <div class="energy-power-container">
        <div class="energy-power-consumption">
          <h3>{{ 'energyPower.consumption' | translate }}</h3>
          <table>
            <tr>
              <td>{{ 'energyPower.totalKWH' | translate }}:</td>
              <td class="align-right">
                {{ energyMeasure?.totalActiveEnergyConsumed | number: '1.0-3' }}
              </td>
              <td>kWh</td>
            </tr>
            <tr>
              <td>{{ 'energyPower.totalKVARH' | translate }}:</td>
              <td class="align-right">
                {{
                  energyMeasure?.totalReactiveEnergyConsumed | number: '1.0-3'
                }}
              </td>
              <td>kvarh</td>
            </tr>
            <tr>
              <td>{{ 'energyPower.totalKVAH' | translate }}:</td>
              <td class="align-right">
                {{
                  energyMeasure?.totalApparentEnergyConsumed | number: '1.0-3'
                }}
              </td>
              <td>kVah</td>
            </tr>
          </table>
        </div>
        <div class="energy-power-demand">
          <h3>{{ 'energyPower.15MinDemand' | translate }}</h3>
          <div class="max-demand">
            <span class="max-label">{{ 'energyPower.max' | translate }}: </span>
            <div>
              <span class="align-right">{{
                energyMeasure?.maxActivePowerDemand | number: '1.3-3'
              }}</span>
              <span>kW</span>
              <br />
              <span>{{
                energyMeasure?.dateTimeOfMaxActivePowerDemand
                  | date: 'M/d/yyyy, h:mm a':maxDemandTz
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="container">
      <div *ngIf="eventsListSource?.filteredData?.length > 0">
        <div class="severe-events">
          <h3>
            {{ 'monthlyReport.severeEvents' | translate }} ({{
              eventsListSource?.filteredData?.length
            }})
          </h3>
          <h4>{{ 'global.major-sags' | translate }} ({{ voltageSagCount }})</h4>
          <h4>{{ 'global.majorSwells' | translate }} ({{ voltageSwellCount }})</h4>
          <h4>
            {{ 'global.hf-impulses' | translate }} ({{
              highFrequencyImpulseCount
            }})
          </h4>
          <h4>
            {{ 'global.interruptions' | translate }} ({{ interruptionCount }})
          </h4>
        </div>
        <table mat-table [dataSource]="eventsListSource">
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'notes.new.date' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.triggeredWhen | date: 'MMMM d, y' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="time">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'notes.new.time' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.triggeredWhen | date: 'h:mm a':tz }}
            </td>
          </ng-container>
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'monthlyReport.type' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ 'monthlyReport.' + element.deviceEventType | translate }}
            </td>
          </ng-container>
          <ng-container matColumnDef="duration">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'event-graph.event-duration' | translate }} (ms)
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.duration }} <span *ngIf="element.duration">ms</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="magnitude">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'dashboard.detail-overlay.magnitude' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element?.eventMagnitude !== null">
              {{
                (element?.deviceEventType === 'highFrequencyImpulse'
                  ? 'dashboard.detail-overlay.hf-magnitude-value'
                  : 'dashboard.detail-overlay.magnitude-value'
                ) | translate: { value: element?.eventMagnitude }
              }}
              </span>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumnsEvents"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumnsEvents"
          ></tr>
        </table>
      </div>
      <div *ngIf="eventsListSource?.filteredData?.length === 0">
        <h3>
          {{ 'monthlyReport.severeEventsEmpty' | translate }}
        </h3>
      </div>
    </section>
    <section class="container">
      <div *ngIf="notesListSource?.filteredData?.length > 0">
        <div>
          <h3>
            {{ 'navigation.notes' | translate }} ({{
              notesListSource?.filteredData?.length
            }})
          </h3>
        </div>
        <table mat-table [dataSource]="notesListSource">
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'notes.new.date' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.startDateTime | date: 'MMMM d, y' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="time">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'notes.new.time' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.startDateTime | date: 'h:mm a':tz }}
            </td>
          </ng-container>
          <ng-container matColumnDef="summary">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'monthlyReport.summary' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.summary | notesSummaryPipe }}
            </td>
          </ng-container>
          <ng-container matColumnDef="details">
            <th mat-header-cell *matHeaderCellDef class="notes-details">
              {{ 'monthlyReport.details' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.details | notesDetailsPipe }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumnsNotes"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsNotes"></tr>
        </table>
      </div>
      <div *ngIf="notesListSource?.filteredData?.length === 0">
        <h3>
          {{ 'monthlyReport.notesEmpty' | translate }}
        </h3>
      </div>
    </section>
    <section
      class="container logging"
      *ngIf="isPartner || token.metaData.isSystemAdministrator === '1'"
    >
      <h3>{{ 'monthlyReport.activityLog' | translate }}</h3>
      <h4>
        {{ 'dashboard.status' | translate }}: {{ loggingInfo?.status.name }}
      </h4>
      <div>
        <p>
          {{ 'monthlyReport.createdBy' | translate }}:
          {{ loggingInfo?.createdBy.lastName }},
          {{ loggingInfo?.createdBy.firstName }} on
          {{ loggingInfo?.createdWhen | date: 'longDate' }}
        </p>
        <p *ngIf="loggingInfo?.modifiedWhen">
          {{ 'monthlyReport.modifiedBy' | translate }}:
          {{ loggingInfo?.modifiedBy.lastName }},
          {{ loggingInfo?.modifiedBy.firstName }}
          {{ loggingInfo?.modifiedWhen | date: 'longDate' }}
        </p>
      </div>
    </section>
  </form>
</div>
