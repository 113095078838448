import {
  Action,
  ActionReducerMap,
  createSelector,
  createFeatureSelector,
  ActionReducer,
  MetaReducer,
} from '@ngrx/store';

import * as fromHierarchy from '../_hierarchy/reducers/hierarchy.reducer';
import * as fromUser from '../_user/reducers/user.reducer';
import * as fromChannels from '../_channels/reducers/channels.reducer';
import { environment } from './../../../environments/environment';

export interface State {
  hierarchy: fromHierarchy.State;
  user: fromUser.State;
  channels: fromChannels.State;
}

export const reducers: ActionReducerMap<State> = {
  hierarchy: fromHierarchy.reducer,
  user: fromUser.reducer,
  channels: fromChannels.reducer,
};

export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();

    return result;
  };
}

// tslint:disable-next-line: no-shadowed-variable
export function clearStateMetaReducer<State extends {}>(
  reducer: ActionReducer<State>
): ActionReducer<State> {
  return (state: State, action: Action) => {
    if (action.type === '[App Logout] Logout') {
      state = {} as State;
    }
    return reducer(state, action);
  };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [logger, clearStateMetaReducer]
  : [clearStateMetaReducer];

/**
 * Hierarchy Reducers
 */
export const getHierarchyState = createFeatureSelector<
  State,
  fromHierarchy.State
>('hierarchy');

export const getHierarchy = createSelector(
  getHierarchyState,
  fromHierarchy.getHierarchy
);

export const getUserState = createFeatureSelector<State, fromUser.State>(
  'user'
);

export const getUser = createSelector(getUserState, fromUser.getUser);

export const getIsPartner = createSelector(getUserState, fromUser.getIsPartner);

export const getChannelsState = createFeatureSelector<
  State,
  fromChannels.State
>('channels');

export const getChannels = createSelector(
  getChannelsState,
  fromChannels.getChannels
);
