import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
  Inject,
} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';

import { NotesService } from 'src/app/shared/services/notes.service';
import { DisplayImageComponent } from 'src/app/shared/components/display-image/display-image.component';
import { MeasurementPointsService } from 'src/app/shared/services/measurement-points.service';

@Component({
  selector: 'app-new-note',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './new-note.component.html',
  styleUrls: ['./new-note.component.scss'],
})
export class NewNoteComponent implements OnInit {
  public note: {
    summary: string;
    date: moment.Moment;
    time: string;
    details: string;
  } = {
    summary: null,
    date: null,
    time: null,
    details: null,
  };

  public noteForm: FormGroup;

  @ViewChild('fileInput', { static: false }) fileInput;

  public fileReferences: string[] = [];
  public files: File[] = [];
  public editSummary = true;
  public editTime = true;
  public editDate = true;

  private composeDateTime(date: moment.Moment, time: string): string {
    const compositeDateTime = moment.tz(
      `${date.format('YYYY-MM-DD')} ${time}`,
      this.mpService.selectedMeasurementPoint.timezone
    );

    return compositeDateTime.toISOString();
  }

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<NewNoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mpService: MeasurementPointsService,
    private noteService: NotesService
  ) {
    this.editSummary = !this.data.existingNote;
    this.editTime = !this.data.existingNote;
    this.editDate = !this.data.existingNote;

    if (!this.data.existingNote) {
      this.note.time = moment.tz(this.mpTimezone).format('hh:mm');
      this.note.date = moment.tz(this.mpTimezone);

      this.noteForm = new FormGroup({
        summary: new FormControl(
          `Event [${this.note.date.format('MM/DD/YYYY')} ${this.note.time}]`,
          [Validators.required]
        ),
        date: new FormControl(moment.tz(this.mpTimezone), [Validators.required]),
        time: new FormControl(moment.tz(this.mpTimezone).format('HH:mm'), [
          Validators.required,
        ]),
        details: new FormControl(''),
      });
    } else {
      this.noteForm = new FormGroup({
        summary: new FormControl(this.data.note.summary, [Validators.required]),
        date: new FormControl(moment.tz(this.data.note.startDateTime, this.mpTimezone), [
          Validators.required,
        ]),
        time: new FormControl(
          moment.tz(this.data.note.startDateTime, this.mpTimezone).format('HH:mm'),
          [Validators.required]
        ),
        details: new FormControl(this.data.note.details),
      });
    }
  }

  public get editMode(): boolean {
    return this.data.existingNote;
  }

  public get allowAttachments(): boolean {
    return !this.data.existingNote;
  }

  public get accountId(): number {
    return this.mpService.selectedMeasurementPoint.accountId;
  }
  public get measurementPointId(): number {
    return this.mpService.selectedMeasurementPoint.measurementPointId;
  }
  public get mpName(): string {
    return this.mpService.selectedMeasurementPoint.mpId;
  }
  public get mpCity(): string {
    return this.mpService.selectedMeasurementPoint.city;
  }
  public get mpState(): string {
    return this.mpService.selectedMeasurementPoint.state;
  }

  public get accountName(): string {
    return this.mpService.selectedMeasurementPoint.accountName;
  }

  public get tlaTimezone(): string {
    return this.mpService.selectedMeasurementPoint.threeLetterTimezone;
  }

  public get mpTimezone(): string {
    return this.mpService.selectedMeasurementPoint.timezone;
  }

  public get date(): string {
    return moment(this.data.note.startDateTime).tz(this.mpTimezone).format('MM/DD/YYYY');
  }

  public get time(): string {
    return moment(this.data.note.startDateTime).tz(this.mpTimezone).format('h:mm A z');
  }

  private get noteId(): number {
    return this.data.note.id;
  }

  ngOnInit() {
    if (this.data.existingNote === true) {
      this.noteService
        .getNoteDocuments(this.noteId)
        .pipe(take(1))
        .subscribe((documents) => {
          this.fileReferences = documents;
        });
    }
  }

  public openFileUpload(): void {
    this.fileInput.nativeElement.click();
  }

  public cancelNote(saved = false): void {
    this.dialogRef.close(saved);
  }

  public addFiles() {
    const newFiles = this.fileInput.nativeElement.files;
    for (const key in newFiles) {
      if (!isNaN(parseInt(key, 10))) {
        this.files.push(newFiles[key]);
      }
    }
  }

  public onSubmit(formData) {
    const details = formData.details
      ? formData.details
      : this.data.note
      ? this.data.note.details
      : null;
    const startTime = formData.date
      ? this.composeDateTime(formData.date.clone(), formData.time)
      : this.data.note.startTime;
    const endTime = formData.endDate ? formData.endTime : null;
    const summary = formData.summary ? formData.summary : this.data.note.summary;

    const finalFormData = new FormData();
    if (details) {
      finalFormData.append('details', details);
    }
    finalFormData.append('startTime', startTime);
    finalFormData.append('endTime', null);
    finalFormData.append('summary', formData.summary);

    if (this.files.length > 0) {
      this.files.forEach((file, index) => {
        finalFormData.append(`note${index}`, file, file.name);
      });
    }

    if (this.data.existingNote) {
      this.noteService.updateNote(finalFormData, this.data.note.id).subscribe(
        (response) => {
          this.dialogRef.close(true);
        },
        (err) => console.error(err)
      );
    } else {
      this.noteService.submitNewNote(finalFormData, this.measurementPointId).subscribe(
        (response) => {
          this.dialogRef.close(true);
        },
        (err) => console.error(err)
      );
    }
  }

  public isFirstFocus(event) {
    if (!this.noteForm.get(event.target.name).touched) {
      this.noteForm.get(event.target.name).setValue('');
    }
  }

  public removeFileFromSelected(index: number) {
    this.files = this.files.filter((file, i) => i !== index);
  }

  public displayFile(url: string) {
    this.dialog.open(DisplayImageComponent, {
      data: {
        url,
        autoFocus: false,
      },
    });
  }

  public transformAndDisplayFile(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const imgURL = reader.result as string;
      this.displayFile(imgURL);
    };
  }
}
