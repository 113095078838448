import {Component, OnInit, OnDestroy} from '@angular/core';
import {SitesService} from "../shared/services";
import * as moment from "moment";
import {MeasurementPointStatusCounts} from "./map-panel/map-panel.component";

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss'],
})
export class MapViewComponent implements OnInit, OnDestroy {
  measurementPointCount;
  measurementPoints;
  zoomFactor;

  measurementPointStatusCounts: MeasurementPointStatusCounts = {
    uncommissioned: 0,
    noData: 0,
    insufficientData: 0,
    badData: 0,
    goodData: 0
  };

  constructor(
      private sitesService: SitesService,
  ) {  }


  async ngOnInit() {
    const measurementPoints = await this.sitesService.getMeasurementPointsForMap();
    measurementPoints.forEach(measurementPoint => {
      this.measurementPointStatusCounts[measurementPoint.iconStatus]++;
    });
    this.measurementPoints = measurementPoints;
    this.measurementPointCount = this.measurementPoints.length;
  }

  ngOnDestroy() {}

  setZoomFactor(zoomFactor) {
    this.zoomFactor = zoomFactor;
  }
}
