import { select, Store } from '@ngrx/store';
import {
  Component,
  OnInit,
  HostListener,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MdePopoverTrigger } from '@material-extended/mde';
import { MatDialog } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subscription } from 'rxjs';

import { NewNoteComponent } from '../notes/new-note/new-note.component';
import { NotesService } from '../shared/services/notes.service';
import { INote } from '../shared/classes/note.interface';
import { DashboardGraphComponent } from './dashboard-graph/dashboard-graph.component';
import { tabIconsConfig } from 'src/app/shared/config/tab-icons.config';
import { DashboardService, AuthService, TokenService } from '../shared/services';
import { IUser } from '../shared/classes/user';
import { IAccount } from '../shared/classes/account';
import { GraphManagerService } from '../shared/services/graph-manager.service';
import { popoverIcons } from '../shared/classes/EventPopoverIcons';
import { tabsDashboardConfig, tabsQubeScanDashboardConfig  } from '../shared/config/tabs-dashboard.config';
import * as fromUser from './../_store/_reducers';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '../shared/classes/component-destroy.class';
import { MeasurementPointsService } from '../shared/services/measurement-points.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  @ViewChild(MdePopoverTrigger, { static: false })
  trigger: MdePopoverTrigger;
  @ViewChild(ElementRef, { static: false }) popoverContent: ElementRef;
  @ViewChild('dashboardGraph', { static: false })
  dashboardGraph: DashboardGraphComponent;

  public commissioningDate: string;
  public userPrefs: any;
  public usePreCommissionStart;
  public isMpOffline: boolean;
  public daysDisconnected: number;
  public othersEvents: Array<any>;
  public isQubeScan: boolean;

  // is the dashboard loading?
  public load = false;

  // have we loaded data at least once?
  public loaded = false;
  public click;
  // keep track of where the user scrolls
  public scrollPos = 0;

  public tabs = tabsDashboardConfig;

  public tabIcons = tabIconsConfig;
  // the results from the DashboardService::getDashboardInfo will go here,
  // and be used to populate the tabs and data to the right of the chart
  public info: any = {};

  public notesArray: INote[];

  // logged in user
  public user: IUser;

  // sites the user has access to
  public userSites: any;

  // currently selected site... this should probably be moved somewhere 'higher'
  // up in the app so it can be shared
  public currentSite: any;

  // measurement point ID
  public mpId: string;

  // selected account ID
  public accountId: string;

  // the user's actual account
  public account: IAccount;

  // data for the popover on the chart
  public popoverData: any = {};
  public popoverIcons = popoverIcons;

  // we need the following variable and listeners so we can keep the page from
  // scrolling up when a user clickson a tab. not sure why that happens, but it
  // does, and it is annoying.
  public clicked = false;

  public isPartner: boolean;
  private userSub$: Subscription;
  private isPartnerSub$: Subscription;

  // listen for a click event so we can _not_ update the scroll position in case
  // the scroll was triggered by clicking on a tab.
  @HostListener('document:click', ['$event'])
  onDocumentClick($event: Event) {
    const target = $event.target as HTMLElement;
    const tabLabel = target.closest('.mat-tab-label');
    if (tabLabel) {
      this.clicked = true;
      // give the tab time to switch before we start tracking scroll position again
      setTimeout(() => {
        this.clicked = false;
      }, 250);
    }
  }

  // update the scroll position so we can re-scroll the page after a tab has
  // been clicked
  @HostListener('document:scroll', ['$event'])
  onDocumentScroll($event) {
    // if we're here because a user clicked on a tab, bail out
    if (this.clicked) {
      this.tabChanged();
      return;
    }
    // track the scroll position
    this.scrollPos = document.scrollingElement.scrollTop;
  }

  constructor(
    private authService: AuthService,
    private dashboardService: DashboardService,
    private route: ActivatedRoute,
    private graphManager: GraphManagerService,
    private mpService: MeasurementPointsService,
    private router: Router,
    public dialog: MatDialog,
    public token: TokenService,
    public notes: NotesService,
    private store: Store<fromUser.State>
  ) {
    super();
  }

  ngOnInit() {
    this.authService.preferences.pipe(take(1)).subscribe((userPrefs) => {
      this.userPrefs = userPrefs;
      this.usePreCommissionStart = userPrefs.usePreCommissionStart;
    });
    // get the logged in user

    this.isPartnerSub$ = this.store
      .pipe(select(fromUser.getIsPartner))
      .subscribe((isPartner: number) => {
        this.isPartner = isPartner === 1;
      });

    this.userSub$ = this.store.pipe(select(fromUser.getUser)).subscribe((user: IUser) => {
      // set the user object
      this.user = user;
      if (!user) {
        return;
      }

      // listen for URL changes
      this.route.queryParamMap
        .pipe(untilComponentDestroyed(this))
        .subscribe(async (params: ParamMap) => {
          if (params.get('mpId') && params.get('account')) {
            this.commissioningDate = moment(
              this.mpService.selectedMeasurementPoint.commissionedWhen
            )
              .tz(this.mpService.selectedMeasurementPoint.timezone)
              .format('MM/DD/YY h:mm A');
          }
          // get the user account
          this.authService.accountO.pipe(take(1)).subscribe(async (account: IAccount) => {
            this.account = account;
            if (params.get('mpId') && params.get('account')) {
              this.accountId = params.get('account');
              this.mpId = params.get('mpId');
              this.loadData();
            } else {
              this.router.navigate([], {
                queryParams: {
                  account: this.userPrefs.account,
                  mpId: this.userPrefs.mpId,
                },
              });
            }
          });
        });
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.isPartnerSub$.unsubscribe();
    this.userSub$.unsubscribe();
  }

  public changeAbsoluteStartDate(event: MatCheckboxChange): void {
    this.authService.savePreferences({
      ...this.userPrefs,
      usePreCommissionStart: event.checked,
    });
    this.usePreCommissionStart = event.checked;
  }

  public addNewNote() {
    const newNoteDialog = this.dialog.open(NewNoteComponent, {
      data: {
        existingNote: false,
      },
      maxWidth: '90vw',
      autoFocus: false,
      disableClose: true,
      closeOnNavigation: true,
    });

    newNoteDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.loadData();
        this.dashboardGraph.chartNav.emitFullReplacementChartSet();
      }
    });
  }

  public get commissionedWhen(): string {
    return this.mpService.selectedMeasurementPoint.commissionedWhen;
  }

  public get powerQualityStatus(): string {
    return this.mpService.selectedMeasurementPoint.powerQualityStatus;
  }

  public resetPopover(): void {
    this.popoverData = {};
  }

  // handle the event marker click event
  public setPopoverEventData(event): void {
    const sidebarWidth = 100;
    const offsetPosX = (document.body.offsetWidth - sidebarWidth) / 2;
    if (event.point.series.userOptions.eventTypeId) {
      this.graphManager
        .getSingleEventData(
          parseInt(event.point.series.userOptions.eventTypeId, 10),
          moment(event.point.x)
        )
        .pipe(untilComponentDestroyed(this))
        .subscribe((oneEvent) => {
          this.popoverData = oneEvent[0];

          this.popoverData.originalTriggeredWhen = this.popoverData.triggeredWhen;
          this.popoverData.triggeredWhen = moment(
            this.popoverData.triggeredWhen,
            'YYYY-MM-DDTHH:mm:SSSZ'
          )
            .tz(this.mpService.selectedMeasurementPoint.timezone)
            .format('MMMM D, YYYY h:mm A');

          // this feels quite hacky, but so does the entire popover library so...
          // it's also entirely possible that i just don't understand how to use
          // the library properly. :shrug: FWIW, the documentation is not great.
          //
          // (1) we need to set the triggerEvent to 'click' so the popover gets
          // a backdrop that is clickable, and will dismiss the popover when clicked.
          //
          // (2) we have to tell the popover that it's OK to close when the backdrop
          // is clicked on, even though we should be able to do this on the trigger
          // element in the template.
          //
          // (3) we need to position the popover at a specific X/Y coordinate,
          // but the popover component doesn't actually support doing that
          // (at least not according to the docs, which are horrendous), so we stuff
          // the layer X/Y coords into the popover object and for some reason it works!
          //
          // IF YOU MOVE THIS CODE, ALSO MOVE THE RIDICULOUS COMMENTS WITH IT!!!
          this.trigger.triggerEvent = 'click';
          this.trigger.backdropCloseOnClick = true;

          this.trigger.popover.targetOffsetX = event.clientX;

          if (this.trigger.popover.targetOffsetX > offsetPosX) {
            this.trigger.popover.targetOffsetX = 0;
          }

          // show the popover
          this.trigger.togglePopover();

          this.trigger.closed.pipe(take(1)).subscribe(() => {
            // we have to destroy the popover, otherwise next time the popover is shown
            // the positions won't update, and it'll stick at wherever it was shown
            // the first time... so awesome.
            this.trigger.destroyPopover();
            this.popoverData.deviceEventType = undefined;
          });
        });
    } else {
      this.notes
        .getSingleNote(this.mpId, moment(event.point.x))
        .pipe(untilComponentDestroyed(this))
        .subscribe((oneNote: INote[]) => {
          this.popoverData = oneNote[0];

          this.popoverData.tzShiftedDate = moment(this.popoverData.startDateTime)
            .tz(this.mpService.selectedMeasurementPoint.timezone)
            .format('MM/DD/YY');
          this.popoverData.tzShiftedTime = moment(this.popoverData.startDateTime)
            .tz(this.mpService.selectedMeasurementPoint.timezone)
            .format('hh:mm A z');
          // this feels quite hacky, but so does the entire popover library so...
          // it's also entirely possible that i just don't understand how to use
          // the library properly. :shrug: FWIW, the documentation is not great.
          //
          // (1) we need to set the triggerEvent to 'click' so the popover gets
          // a backdrop that is clickable, and will dismiss the popover when clicked.
          //
          // (2) we have to tell the popover that it's OK to close when the backdrop
          // is clicked on, even though we should be able to do this on the trigger
          // element in the template.
          //
          // (3) we need to position the popover at a specific X/Y coordinate,
          // but the popover component doesn't actually support doing that
          // (at least not according to the docs, which are horrendous), so we stuff
          // the layer X/Y coords into the popover object and for some reason it works!
          //
          // IF YOU MOVE THIS CODE, ALSO MOVE THE RIDICULOUS COMMENTS WITH IT!!!
          this.trigger.triggerEvent = 'click';
          this.trigger.backdropCloseOnClick = true;

          this.trigger.popover.targetOffsetX = event.clientX;

          if (this.trigger.popover.targetOffsetX > offsetPosX) {
            this.trigger.popover.targetOffsetX = 0;
          }

          // show the popover
          this.trigger.togglePopover();

          this.trigger.closed.pipe(take(1)).subscribe(() => {
            // we have to destroy the popover, otherwise next time the popover is shown
            // the positions won't update, and it'll stick at wherever it was shown
            // the first time... so awesome.
            this.trigger.destroyPopover();
          });
        });
    }
  }

  loadData() {
    this.tabs = [];
    this.isQubeScan =
    this.mpService.selectedMeasurementPoint.measurementPointTypeId === 1;
    let start = null;
    let end = null;
    const now = moment();

    // if we've loaded the data at least once, we can use the date range
    // values from the graph manager. if we try to use the dates from the
    // graph manager every time: the first load of the dashboard they will
    // be null, so we make up our own (last 24 hours); if you then go to, for
    // example, the 'charts' page, which changes the date ranges on the graph
    // manager, and then go back to the 'dashboard', the date ranges in the
    // graph manager still reflect the date ranges that were in use on the
    // 'charts' page.
    if (this.loaded) {
      start = this.graphManager.dateRangeStart;
      end = this.graphManager.dateRangeEnd;
    }

    if (!start) {
      start = now.clone().subtract(1, 'day');
    }

    if (!end) {
      end = now.clone();
    }

    this.notes.getNotes(parseInt(this.mpId, 10), start, end).subscribe((notes) => {
      this.notesArray = notes;
    });

    this.dashboardService.getDashboardInfo(this.accountId, this.mpId, start, end).then(
      (info) => {
        this.othersEvents = info.measurementPointEvents.filter(
          (mpEvent) =>
            mpEvent.deviceEventType !== 'voltageSag' &&
            mpEvent.deviceEventType !== 'voltageSwell' &&
            mpEvent.deviceEventType !== 'highFrequencyImpulse' &&
            mpEvent.deviceEventType !== 'interruption'
        );
        if (info.powerQualityMeasures.errno) {
          // this.notificationsService.alert(info.powerQualityMeasures.sqlMessage || this.translateService.instant('global.loading-mp-error'));
          return;
        }

        if (info.powerQualityMeasures.lastCommunication.value) {
          info.powerQualityMeasures.lastCommunication.value = moment(
            info.powerQualityMeasures.lastCommunication.value,
            'YYYY-MM-DDTHH:mm:SSSZ'
          )
            .tz(this.mpService.selectedMeasurementPoint.timezone)
            .format('MMMM D, YYYY h:mm A');
        }
        this.info = info;
        this.tabs = this.isQubeScan ? tabsQubeScanDashboardConfig : tabsDashboardConfig;
        this.isMpOffline =
          this.mpService.selectedMeasurementPoint.measurementPointStatusId === 11
            ? true
            : false;

        const date = new Date();
        const nowUtc = Date.UTC(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          date.getUTCHours(),
          date.getUTCMinutes(),
          date.getUTCSeconds()
        );
        const time =
          Date.now() -
          new Date(info.powerQualityMeasures.lastCommunication.value).getTime();
        this.daysDisconnected = time / (1000 * 3600 * 24);
        this.load = true;
        this.loaded = true;
      },
      (error) => {
        this.load = false;
        this.loaded = true;
        console.log('error retrieving dashboard info', error);
      }
    );
  }

  refreshData($event) {
    this.loadData();
  }

  tabChanged() {
    // fix the scroll position of the page
    document.scrollingElement.scrollTop = this.scrollPos;
  }

  countEvents(type) {
    if (!this.info || !this.info.measurementPointEvents) {
      return 0;
    }

    return this.info.measurementPointEvents.filter((mp) => {
      return mp.deviceEventType === type;
    }).length;
  }
}
