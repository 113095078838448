// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  psl: {
    diligent_endpoint: "https://integration.api.powerside.com/v1",
    token_refresh_threshold: 60 * 5,
    inactivity_timeout: 60 * 15,
    inactivity_warning_threshold: 60 * 1
  },
  google: {
    reCaptchaSiteKey: '6LdvMswZAAAAAEnXVaqJUpw3xf1l84yUckbq73ZZ',
  },
};