<section class="dialog-container">
  <form
    class="site-mp-form"
    data-cy='site_mp_form'
    (submit)="onSubmit($event)"
    [formGroup]="siteMpForm"
  >
    <div class="site-mp-header" data-cy='site_mp_header'>
      <h1 class="site-mp-title">
        {{ title | translate }}
      </h1>
      <button mat-icon-button (click)="closeDialog()">
        <mat-icon class="close-button" [svgIcon]="'close'"></mat-icon>
      </button>
    </div>
    <div class="site-mp-subheader">
      <h4>
        {{ 'manageSiteMeasurementPoint.account' | translate }}: {{ accountName }}
      </h4>
      <div class="status-container">
        <div class="status-label">{{ 'measurementPoint.statusType' | translate }} </div>
        <div class="status-component">
          <mat-select
            *ngIf="isMpStatusEnabled; else displayOnlyStastusBlock"
            formControlName="measurementPointStatus"
          >
            <mat-option
              *ngFor="let status of availableStatuses"
              [value]="status"
              >{{
                'measurementPoint.status.' + status | translate
              }}</mat-option
            >
          </mat-select>
          <ng-template #displayOnlyStastusBlock>
              <input matInput formControlName="measurementPointStatus" />
          </ng-template>
        </div>
      </div>      
    </div>
    
  <mat-error *ngIf="(!latitude || !longitude)">
    {{ 'site.invalidAddress' | translate }}
  </mat-error>
    <mat-dialog-content>
      <div class="fields">
        <div class="mp-site-info">
          <div class="field">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'site.name' | translate }}*</mat-label>
              <input matInput formControlName="siteName" data-cy='add-site-name'/>
              <mat-error *ngIf="siteName.hasError('required')">{{
                'global.errors.fieldRequired' | translate
              }}</mat-error>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'measurementPoint.name' | translate }}*</mat-label>
              <input matInput formControlName="measurementPointName" data-cy='add-mp-name'/>
              <mat-error *ngIf="measurementPointName.hasError('required')">{{
                'global.errors.fieldRequired' | translate
              }}</mat-error>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field appearance="fill" >
              <mat-label>{{ 'site.street1' | translate }}* </mat-label>
              <input matInput formControlName="street1" (keydown.enter)="$event.preventDefault()" #search data-cy='add-street1' />
              <mat-error *ngIf="street1.hasError('required')">{{
                'global.errors.fieldRequired' | translate
              }}</mat-error>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'site.street2' | translate }}</mat-label>
              <input matInput formControlName="street2" />
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'site.city' | translate }}*</mat-label>
              <input matInput formControlName="city" data-cy='add-city'/>
              <mat-error *ngIf="city.hasError('required')">{{
                'global.errors.fieldRequired' | translate
              }}</mat-error>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'site.state' | translate }}*</mat-label>
              <input matInput formControlName="state" data-cy='add-state'/>
              <mat-error *ngIf="state.hasError('required')">{{
                'global.errors.fieldRequired' | translate
              }}</mat-error>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'site.zipCode' | translate }}*</mat-label>
              <input matInput formControlName="zipCode" data-cy='add-zipCode'/>
              <mat-error *ngIf="zipCode.hasError('required')">{{
                'global.errors.fieldRequired' | translate
              }}</mat-error>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'site.country' | translate }}*</mat-label>
              <input matInput formControlName="country" data-cy='add-country' />
              <mat-error *ngIf="country.hasError('required')">{{
                'global.errors.fieldRequired' | translate
              }}</mat-error>
            </mat-form-field>
          </div>
        </div>  
      <div class="site-info-field">
        <h4>{{ 'site.siteInformation' | translate }}</h4>
        <div class="site-info-editor">
          <quill-editor formControlName="siteInformation"></quill-editor>
        </div>
      </div>
          
      <div class="site-mp-button">
        <button
          mat-flat-button
          data-cy='add-submit'
          color="accent"
          type="submit"
          [disabled]="siteMpForm.invalid"
        >
          {{ 'global.save' | translate }}
        </button>
        <button mat-flat-button type="button" (click)="closeDialog()">
          {{ 'global.cancel' | translate }}
        </button>
      </div>
    </div>
    </mat-dialog-content>
</form>
</section>
