<ng-container *ngIf="showComponent && dataSource?.data.length > 0">
  <mat-expansion-panel [(expanded)]="customer.expanded">
    <mat-expansion-panel-header>
      <mat-panel-title class="customer-title">
        {{ customer.accountName }}
        <ng-container *ngIf="customer.measurementPoints.length > 1"
          >({{ dataSource.data.length }})</ng-container
        >
      </mat-panel-title>
      <button
        *ngIf="isAddSiteMpEnabled"
        mat-flat-button
        (click)="addSiteMeasurementPoint()"
        class="new-button add-site-mp-button"
        color="accent"
        data-cy="add-site-mp-button"
      >
        <mat-icon [svgIcon]="'map-marker-plus'"></mat-icon>
      </button>
    </mat-expansion-panel-header>
    <div class="fleet-table-container table">
      <table
        mat-table
        data-cy="fleet-table"
        matSort
        [dataSource]="dataSource"
        (matSortChange)="sortChange($event, customer)"
      >
        <ng-container matColumnDef="mpId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'fleet.table.measurement-point' | translate }}
          </th>
          <td mat-cell *matCellDef="let point">
            <span *ngIf="point.measurementPointTypeId === 1"
              ><mat-icon svgIcon="pqube" color="accent"></mat-icon
            ></span>
            <span *ngIf="point.measurementPointTypeId === 2"
              ><mat-icon svgIcon="psl" color="accent"></mat-icon
            ></span>

            {{ point.mpId }}
          </td>
        </ng-container>
        <ng-container matColumnDef="location">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'fleet.table.location' | translate }}
          </th>
          <td mat-cell *matCellDef="let point">
            {{ point.city }}<span *ngIf="point.city && point.state">, </span>
            {{ point.state
            }}<span *ngIf="point.state && point.country">, </span>
            {{ point.country }}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'fleet.table.status' | translate }}
          </th>
          <td mat-cell *matCellDef="let point">
            {{
              'measurementPoint.status.' + point.measurementPointStatusId
                | translate
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="events">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'fleet.table.events' | translate }}
          </th>
          <td mat-cell *matCellDef="let point">
            <div class="events">
              <div *ngFor="let e of events">
                <span
                  *ngIf="
                    point.details[e].value &&
                    point.details[e].value.severe &&
                    point.details[e].value.severe > 0
                  "
                >
                  <img [src]="'./assets/images/legend/' + e + '.svg'" />
                </span>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="trends">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'fleet.table.trends' | translate }}
          </th>
          <td mat-cell *matCellDef="let point">
            <div class="trends">
              <div *ngFor="let t of trends">
                <mat-icon
                  [svgIcon]="
                    icons[point.details[t].powerQualityStatusType].icon
                  "
                  [class]="icons[point.details[t].powerQualityStatusType].class"
                ></mat-icon>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="comms">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'fleet.table.comms' | translate }}
          </th>
          <td mat-cell *matCellDef="let point">
            <mat-icon
              [svgIcon]="
                icons[point.details.lastCommunication.powerQualityStatusType]
                  .icon
              "
              [class]="
                icons[point.details.lastCommunication.powerQualityStatusType]
                  .class
              "
              [matTooltip]="
                'fleet.table.comms-label'
                  | translate
                    : {
                        date:
                          point.details.lastCommunication.value | date: 'short'
                      }
              "
            ></mat-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="lastTemperature">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'fleet.table.gateway' | translate }}
          </th>
          <td mat-cell *matCellDef="let point">
            <mat-icon
              [svgIcon]="
                icons[point.details.lastTemperature.powerQualityStatusType].icon
              "
              [class]="
                icons[point.details.lastTemperature.powerQualityStatusType]
                  .class
              "
            ></mat-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let point">
            <button *ngIf="point.measurementPointTypeId === 1" mat-icon-button (click)="editSiteMeasurementPoint(point)">
              <mat-icon svgIcon="pencil" color="accent"></mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="choose">
          <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
          <td mat-cell *matCellDef="let point">
            <button
              mat-flat-button
              (click)="selectPoint(point)"
              [disabled]="isMeasurementPointDisabled(point)"
            >
              <mat-icon svgIcon="arrow-right" color="accent"></mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
        <tr
          mat-row
          *matRowDef="let point; columns: tableColumns"
          [ngClass]="{ active: point.active }"
        ></tr>
      </table>
    </div>
  </mat-expansion-panel>
</ng-container>
