<div class="event-graph-container">
  <div class="heading">
    <h1>{{ 'event-graph.title' | translate }}</h1>
  </div>
  <div class="event-graph" *ngIf="event">
    <div class="info">
      <h2>
        {{ 'eventType.' + event?.deviceEventType | translate }}
      </h2>
      <div class="location">
        {{ mp?.city }}<span *ngIf="mp?.state">, </span> {{ mp?.state
        }}<span *ngIf="mp?.country">, </span>{{ mp?.country }}<br />
        {{ mp?.mpId }}
      </div>
      <div class="measurements">
        <div *ngIf="event?.eventMagnitude">
          <h4>{{ 'event-graph.event-magnitude.title' | translate }}</h4>
          <div>
            {{
              (event?.deviceEventType === 'highFrequencyImpulse'
                ? 'event-graph.event-magnitude.hf-value'
                : 'event-graph.event-magnitude.value'
              ) | translate: { value: event?.eventMagnitude }
            }}
          </div>
        </div>
        <div>
          <h4>{{ 'event-graph.event-duration' | translate }}</h4>
          <div *ngIf="event?.duration">
            {{ event?.duration }} {{ 'global.milliseconds' | translate }}
          </div>
          <div *ngIf="!event?.duration">
            {{ 'global.not-applicable' | translate }}
          </div>
        </div>

        <h4>{{ 'event-graph.trigger-date' | translate }}</h4>
        <div>{{ event?.triggeredWhen | date: 'longDate' }}</div>

        <h4>{{ 'event-graph.trigger-time' | translate }}</h4>
        <div>{{ event?.triggeredWhen | date: 'shortTime' }}</div>

        <h4>{{ 'event-graph.trigger-channel' | translate }}</h4>
        <div>{{ event?.channel }}</div>
      </div>
    </div>
    <div class="graphs">
      <div *ngIf="images.length === 0" class="no-images">
        {{ 'event-graph.no-images' | translate }}
      </div>
      <div class="graph" *ngFor="let image of images">
        <button mat-button color="accent" (click)="downloadImage(image)">
          <mat-icon svgIcon="download"></mat-icon>
          {{ 'event-graph.download' | translate }}
        </button>
        <div class="chart">
          <img [src]="image" />
        </div>
      </div>
    </div>
  </div>
</div>
